import { premiumactivityConstants } from '../_constants';

import { premiumactivityService } from '../_services';
import { alertActions } from '.';
export const premiumactivityActions = {
    createPremiumActivity,
    getAllPremiumActivity,
    disablePremiumActivity,
    getPremiumActivityList,
    updatePremiumActivity,
    deletePremiumActivity
};


function createPremiumActivity(data) {

    let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
 
    return dispatch => {
        dispatch(request());
        //console.log("Create PremiumActivity Data:",data)
        premiumactivityService.createPremiumActivity(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                    dispatch(this.getPremiumActivityList(datatempsurvey));
                    dispatch(alertActions.success("PremiumActivity Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.ADD_PREMIUMACTIVITY_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.ADD_PREMIUMACTIVITY_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.ADD_PREMIUMACTIVITY_FAILURE, error } }
}

function deletePremiumActivity(data,datatempsurvey) {
 
    return dispatch => {
        dispatch(request());
        premiumactivityService.deletePremiumActivity(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                    dispatch(this.getPremiumActivityList(datatempsurvey));
                    dispatch(alertActions.success("PremiumActivity Deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.DELETE_PREMIUMACTIVITY_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.DELETE_PREMIUMACTIVITY_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.DELETE_PREMIUMACTIVITY_FAILURE, error } }
}

function updatePremiumActivity(data,tempdata) {

 
    return dispatch => {
        dispatch(request());
        //console.log("data controllerUpdate: ",data)
        premiumactivityService.updatePremiumActivity(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                    dispatch(this.getPremiumActivityList(tempdata));
                    dispatch(alertActions.success("PremiumActivity Updated successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_FAILURE, error } }
}


function disablePremiumActivity(data) {

    let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
 
    return dispatch => {
        dispatch(request());
        premiumactivityService.disablePremiumActivity(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                    dispatch(this.getPremiumActivityList(datatempsurvey));
                    dispatch(alertActions.success("PremiumActivity Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.UPDATE_PREMIUMACTIVITY_FAILURE, error } }
}

function getAllPremiumActivity(data) {
    return dispatch => {
        dispatch(request());
        premiumactivityService.getAllPremiumActivity(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.PREMIUMACTIVITY_GETALL_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.PREMIUMACTIVITY_GETALL_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.PREMIUMACTIVITY_GETALL_FAILURE, error } }
}

function getPremiumActivityList(data) {
    return dispatch => {
        dispatch(request());
        premiumactivityService.getPremiumActivityList(data)
            .then(
                premiumactivity => {
                    dispatch(success(premiumactivity));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_REQUEST } }
    function success(premiumactivity) { return { type: premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_SUCCESS, premiumactivity } }
    function failure(error) { return { type: premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_FAILURE, error } }
}
