
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { userActions, companyActions, parametertypeActions, parameterActions } from '../../_actions';

import { withRouter } from "react-router";
import Pagination from "material-ui-flat-pagination";
import {
  Grid,
  TextField,
  Dialog,
  Slide,
  CssBaseline,
  Button
} from "@material-ui/core";

import Typography from '@material-ui/core/Typography';
import SurveyTable from "./components/SurveyTable/SurveyTable";
import styles from './styles';

import AddSurvey from './SurveyModal/AddSurvey';
import UpdateSurvey from './SurveyModal/UpdateSurvey';

const theme = createMuiTheme();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class CompanyDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      newUserModal: false,
      updateUserModal: false,
      newSurveyModal: false,
      newSurveyUpdateModal: false,
      viewEmployeeDetailsModel: false,
      password: "",
      formData: {
        "email": "",
        "password": ""
      },
      updateformData:{
        "password": ""
      },
      surveyformData: {
        "name": "",
        "desc": "",
        "type": "",
        "subtypeId":"",
        "category":"BUCKET",
        "imageLink": "",
        "colorCode":""
      },
      updatesurveyformData:{
        "name": "",
        "desc": "",
        "type": "",
        "subtypeId":"",
        "category":"BUCKET",
        "imageLink": "",
        "colorCode":""
      },
      amount: 0,
      size: 10,
      page: 1,

      employeesize: 10,
      employeeoffset: 0,
      employeepage: 0,

      surveysize: 10,
      surveyoffset: 0,
      surveypage: 1,


      file: {},
      data: [],
      cols: [],

      selectedFile: null,
      imageWebLink: "",
      imageWebURL:""

    }
  }
  componentDidMount() {
   

    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    // console.log(data);

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    this.props.dispatch(companyActions.getSurveyList(datatempsurvey));

    this.props.dispatch(parametertypeActions.getAllParameterType(data));

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "email": "",
          "password": ""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.addSurveySuccess) {
      return {
        ...nextProps,
        surveyformData: {
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId":"",
          "category":"BUCKET",
          "imageLink": "",
          "colorCode":""
        },
        updatesurveyformData: {
          "id":"",
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId":"",
          "category":"BUCKET",
          "imageLink": "",
          "colorCode":""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.updateSurveySuccess) {
      return {
        ...nextProps,
        updatesurveyformData: {
          "id":"",
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId":"",
          "category":"BUCKET",
          "imageLink": "",
          "colorCode":""
        },
        surveyformData: {
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId":"",
          "category":"BUCKET",
          "imageLink": "",
          "colorCode":""
        },
        newUserModal: false,
        newSurveyUpdateModal: false,

      }
    }
    if (nextProps.company.passwordUpdated) {

      return {
        ...nextProps,
        apikeyModal: false,
        formData: {
          "email": "",
          "password": ""
        },
        currentAPI_details: {
          "id": ""
        },
        apiformData: {
          "ip": ""
        },
        startDate: new Date(),
        password: ''
      }
    } 
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }
    else {
      return {
        ...nextProps
      }
    }

  }

  handleClickOpenSurvey = () => {
    console.log("-------------------- 1111111 clicked : ",this.state.newSurveyModal)
    this.setState({ newSurveyModal: true });
  }
  handleCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyModal: false });

  }

  onDisable = (data) => {
    console.log(data);
    this.props.dispatch(companyActions.updateApiKeyStatus({ id: data.id }, this.props));
  }
  onDelete = (data) => {
    console.log(data);
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": this.state.surveypage,
      "size": this.state.surveysize
    }
    confirmAlert({
      title: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.key,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(companyActions.deleteApiKey({ id: data.id }, datatempsurvey))
        },
        {
          label: 'No'
        }
      ]
    });

  }
  handleChangeInputSurvey = (event) => {
    const { surveyformData } = this.state;
    surveyformData[event.target.name] = event.target.value;
    this.setState({ surveyformData });
  }

  handleSubmitSurvey = () => {
    console.log("Working....");

    let reqData = {
      "name": this.state.surveyformData.name,
      "desc": this.state.surveyformData.desc,
      "type": this.state.surveyformData.type,
      "typeName": this.state.surveyformData.typeName,
      "subtypeId": this.state.surveyformData.subtypeId,
      "subtypeName": this.state.surveyformData.subtypeName,
      "category": this.state.surveyformData.category,
      "imageLink": this.state.surveyformData.imageLink,
      "colorCode":this.state.surveyformData.colorCode
    }
    console.log("reqData  ", reqData);

    let datatempsurvey = {
      "keyWord": "",
      "pageNo": this.state.surveypage,
      "size": this.state.surveysize
    }
    console.log("this.props  ", this.props);
    // console.log("this.props  ", this.state.company.surveyTypeList[0].id);

    this.props.dispatch(companyActions.createSurvey(reqData, datatempsurvey));
    this.setState({ newSurveyModal:false });
  }

  surveyhandleClick = (surveyoffset, surveypage) => {
    // console.log(offset, page);

    this.setState({ surveyoffset, surveypage });

    let data = {
      "keyWord": this.state.surveykeyWord,
      "pageNo": surveypage,
      "size": this.state.surveysize
    }
    this.props.dispatch(companyActions.getEmployeeList(data));


  }
  handleChangesurvey = (value) => {

    let data = {
      parameterTypeId: value
    }
    this.props.dispatch(parameterActions.getParameterListbyParameterType(data));

    console.log("value  ", value);
    const { surveyformData, updatesurveyformData } = this.state;

    surveyformData['type'] = value;
    updatesurveyformData['type'] = value;

    this.setState({ surveyformData, updatesurveyformData });
  };

  handleChangesurveyCategory= (value) => {

    const { surveyformData, updatesurveyformData } = this.state;

    surveyformData['category'] = value;
    updatesurveyformData['category'] = value;

    this.setState({ surveyformData, updatesurveyformData });
  };


  handleChangesurveySubType = (value) => {


    const { surveyformData, updatesurveyformData } = this.state;


    surveyformData['subtypeId'] = value;
    updatesurveyformData['subtypeId'] = value;


    this.setState({ surveyformData, updatesurveyformData });

  };



  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:",value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getSurveyList(data));
  }


  //survey list
  onUpdateSurvey = (data) => {
 
    let tempdata = {
      "id": data.id,
      "name":data.name,
      "desc":data.desc,
      "type":data.type,
      "typeName":data.typeName,
      "subtypeId":data.subtypeId,
      "subtypeName":data.subtypeName,
      "category":data && data.category? data.category:"BUCKET",
      "imageLink":data.imageLink,
      "imageLinkV2":data.imageLinkV2,
      "colorCode":data.colorCode

    }
    this.setState({ newSurveyUpdateModal: true, updatesurveyformData: tempdata });
  }

  onCampaign=(data)=>{
    let survey = {
      surveyId: data.id
    }
    this.props.history.push(`/app/campaign/${survey.surveyId}`)
  }


  updatehandleChangeInputSurvey= (event) => {
    const { updatesurveyformData } = this.state;
    updatesurveyformData[event.target.name] = event.target.value;
        this.setState({ updatesurveyformData });
 
  }



  handleUpdateSurveySubmit = () => {

    let reqData = {
      "id":this.state.updatesurveyformData.id,
      "name": this.state.updatesurveyformData.name,
      "desc":this.state.updatesurveyformData.desc,
      "type":this.state.updatesurveyformData.type,
      "typeName":this.state.updatesurveyformData.typeName,
      "subtypeId":(this.state.updatesurveyformData.typeName==='Happiness')?'':this.state.updatesurveyformData.subtypeId,
      "subtypeName":(this.state.updatesurveyformData.typeName==='Happiness')?'':this.state.updatesurveyformData.subtypeName,
      "category":this.state.updatesurveyformData.category,
      "imageLink":this.state.updatesurveyformData.imageLink,
      "colorCode":this.state.updatesurveyformData.colorCode
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    console.log("Final Update: ",reqData)
    this.props.dispatch(companyActions.updateSurvey(reqData, datatempsurvey));
    // this.setState({ newSurveyUpdateModal:false });
  }


  handleUpdateCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyUpdateModal: false });

  }

  onAddQuesClick = (data) => {
    this.props.history.push(`/app/addquestion/${data.id}`)
  }


  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  handleUpload = () => {

    const { surveyformData, updatesurveyformData, imageWebLink } = this.state;
    surveyformData['imageLink'] = imageWebLink;
    updatesurveyformData['imageLink'] = imageWebLink;
    this.setState({ surveyformData, updatesurveyformData });

  }



  render() {

    let { classes, company } = this.props;
    let { surveyitems, surveytotal } = company;
    const { surveyformData, updatesurveyformData, submitted } = this.state;

    console.log("surveyformData",this.state.surveyformData)
    console.log("updatesurveyformData",this.state.updatesurveyformData)


    let { parametertype, parameter } = this.props;
    const { parameterTypesList } = parametertype;
    let { allParametersListByParameterTypeData } = parameter;

    let categoryList=["BUCKET","TEST"];

    return (
      <>
        <PageTitle title="Survey List" />
        <Grid container >
    


          <Grid item xs={12}>
            <Widget >
            <Grid container >
                {/* <Grid item xs={3}>
                  <h3>Survey List</h3>
                </Grid> */}
              <Grid item xs={5} style={{marginLeft:'17%',marginBottom:'5%'}}/>
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1} style={{marginTop:'20px'}}>
                  <Button variant="contained"  color="primary" onClick={() => this.handleClickOpenSurvey()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                surveyitems && surveyitems.length > 0 ?
                  <>
                    <SurveyTable data={surveyitems} offset={this.state.surveyoffset} onRowClick={this.onRowClick}
                      onDeleteClick={this.onDeleteClick}
                      onDelete={this.onDelete}
                      isShowMoreButton={true}
                      onDisable={this.onDisable}
                      onAddQuesClick ={this.onAddQuesClick}
                      onUpdateSurvey={this.onUpdateSurvey}
                      onCampaign={this.onCampaign}/>
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                surveytotal && surveytotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.surveysize}
                      offset={this.state.surveyoffset}
                      total={surveytotal}
                      onClick={(e, surveyoffset, surveypage) => this.surveyhandleClick(surveyoffset, surveypage)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
     

        <Dialog fullScreen open={this.state.newSurveyModal} onClose={this.handleCloseSurvey} TransitionComponent={Transition}>
              <AddSurvey
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  surveyformData ={surveyformData}
                  handleCloseSurvey={this.handleCloseSurvey}
                  handleSubmitSurvey={this.handleSubmitSurvey}
                  handleChangeInputSurvey={this.handleChangeInputSurvey}
                  handleChangesurvey={this.handleChangesurvey}
                  handleChangesurveySubType={this.handleChangesurveySubType}
                  categoryList={categoryList}
                  handleChangesurveyCategory={this.handleChangesurveyCategory}
                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  imageWebLink={this.state.imageWebLink}
                  imageWebURL={this.state.imageWebURL}

                  parameterTypesList={parameterTypesList}
                  allParametersListByParameterTypeData={allParametersListByParameterTypeData}
                />
        </Dialog>

        <Dialog fullScreen open={this.state.newSurveyUpdateModal} onClose={this.handleUpdateCloseSurvey} TransitionComponent={Transition}>
        <UpdateSurvey
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  updatesurveyformData ={updatesurveyformData}
                  handleUpdateCloseSurvey={this.handleUpdateCloseSurvey}
                  handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
                  updatehandleChangeInputSurvey={this.updatehandleChangeInputSurvey}
                  handleChangesurvey={this.handleChangesurvey}
                  handleChangesurveySubType={this.handleChangesurveySubType}
                  categoryList={categoryList}
                  handleChangesurveyCategory={this.handleChangesurveyCategory}
                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  imageWebLink={this.state.imageWebLink}
                  imageWebURL={this.state.imageWebURL}

                  parameterTypesList={parameterTypesList}
                  allParametersListByParameterTypeData={allParametersListByParameterTypeData}


                />
        </Dialog>

       
      </>
    );

  }
}
function mapStateToProps(state) {
  const { company, users, parametertype, parameter, user } = state;
  return {
    company, users, parametertype, parameter, user
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(CompanyDetails)));
