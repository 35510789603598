import {
  insstoryConstants
} from '../_constants';

export function insstory(state = {}, action) {

  switch (action.type) {
    case insstoryConstants.INSSTORY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case insstoryConstants.INSSTORY_GETALL_SUCCESS:
      return {
        ...state,
        addInsStorySuccess: false,
        updateInsStorySuccess:false,
        deleteInsStorySuccess: false,

          InsStoryList: action.insstory.listOfInsStorys.list,
          items: action.insstory.listOfInsStorys.list,
          total: action.insstory.listOfInsStorys.total
      };
    case insstoryConstants.INSSTORY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case insstoryConstants.ADD_INSSTORY_REQUEST:
      return {
        ...state
      };
    case insstoryConstants.ADD_INSSTORY_SUCCESS:
      return {
        ...state,
        addInsStorySuccess: true
      };
    case insstoryConstants.ADD_INSSTORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case insstoryConstants.UPDATE_INSSTORY_REQUEST:
      return {
        ...state
      };
    case insstoryConstants.UPDATE_INSSTORY_SUCCESS:
      return {
        ...state,
        updateInsStorySuccess: true
      };
    case insstoryConstants.UPDATE_INSSTORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case insstoryConstants.DELETE_INSSTORY_REQUEST:
        return {
          ...state
        };
      case insstoryConstants.DELETE_INSSTORY_SUCCESS:
        return {
          ...state,
          deleteInsStorySuccess: true
        };
      case insstoryConstants.DELETE_INSSTORY_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}