import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { parametertypeActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddParameterType from './Modal/AddParameterType';
import UpdateParameterType from './Modal/UpdateParameterType';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class ParameterType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newParameterTypeModal: false,
      newUpdateParameterTypeModal:false,
      submitted: false,
      formData: {
        "name": ""
      },
      updateformData:{
        "id":"",
        "name": ""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(parametertypeActions.getAllParameterType(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.parametertype.updateParameterTypeSuccess) {
      return {
        ...nextProps,
        updateformData: {
          "id":"",
          "name": ""
        },
        formData: {
          "name": ""
        },
        newUpdateParameterTypeModal: false,
      }
    }

    if (nextProps.parametertype.addParameterTypeSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": ""
        },
        updateformData: {
          "id":"",
          "name": ""
        },
        newParameterTypeModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(parametertypeActions.getAllParameterType(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(parametertypeActions.getAllParameterType(data));
  }
  handleClickOpen = () => {
    this.setState({ newParameterTypeModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newParameterTypeModal: false });

  }
  handleChangeInput = (event) => {
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;
    this.setState({ formData, updateformData });
  }
  onDelete = (data) => {
    let datatemp = {
    "keyWord": "",
    "pageNo": 1,
    "size": this.state.size
  }

  confirmAlert({
    title: 'Confirm to delete?',
    message: 'Are you sure to delete ' + data.key,
    buttons: [
      {
        label: 'Yes',
        onClick: () => this.props.dispatch(parametertypeActions.deleteParameterType({ id: data.id},datatemp))
      },
      {
        label: 'No'
      }
    ]
  });

}

  handleSubmit = () => {

    let reqData = {
      "name": this.state.formData.name.trim()
    }
    this.props.dispatch(parametertypeActions.createNewParameterType(reqData));
  }
  onRowClick = (data) => {
    this.props.history.push(`/app/parametertypedetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id.toString(),
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(parametertypeActions.disableParameterType(tempdata));
  }
  onUpdate = (data) => {
    let tempdata = {
      "id": data.id,
      "name": data.name
    }
    this.setState({ newUpdateParameterTypeModal: true, updateformData: tempdata });
  }

  
  // updatehandleChangeInput= (event) => {
  //   event.preventDefault();
  //   const { updateformData } = this.state;
  //       updateformData[event.target.name] = event.target.value;
  //       this.setState({ updateformData });
  //       if(this.state.updateformData){
  //         console.log("----updateformdata----");
  //         console.log(updateformData);
  //       }
  // }

  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id":this.state.updateformData.id.toString(),
      "name": this.state.updateformData.name.trim()
    }
    console.log("-----reqData-----",reqData)
    this.props.dispatch(parametertypeActions.updateParameterType(reqData,tempdata));
  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateParameterTypeModal: false });

  }
  render() {

    let { parametertype, classes } = this.props;
    let { parameterTypesList, total } = parametertype;
    const { formData,updateformData, submitted } = this.state;

    console.log("HHHH: ", parameterTypesList)

    return (
      <>
        <PageTitle title="ParameterType List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                parameterTypesList && parameterTypesList.length > 0 ?
                  <>
                    <Table data={parameterTypesList} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newParameterTypeModal} onClose={this.handleClose} TransitionComponent={Transition}>
               <AddParameterType
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateParameterTypeModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateParameterType
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.handleChangeInput}

                />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { parametertype } = state;
  return {
    parametertype
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(ParameterType)));
