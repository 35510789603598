import { musicConstants } from '../_constants';
import { musicService } from '../_services';
import { alertActions, userActions } from '.';
export const musicActions = {
    disableMusic,
    createNewMusic,
    updateMusic,
    getAllMusic,
    deleteMusic
};

function getAllMusic(data) {
    return dispatch => {
        dispatch(request());
        musicService.getAllMusic(data)
            .then(
                music => dispatch(success(music)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: musicConstants.MUSIC_GETALL_REQUEST } }
    function success(music) { return { type: musicConstants.MUSIC_GETALL_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.MUSIC_GETALL_FAILURE, error } }
}
function createNewMusic(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        musicService.createNewMusic(data)
            .then(
                music => {
                    dispatch(success(music));
                    dispatch(this.getAllMusic(tempdata));
                    dispatch(alertActions.success("Music created successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: musicConstants.ADD_MUSIC_REQUEST } }
    function success(music) { return { type: musicConstants.ADD_MUSIC_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.ADD_MUSIC_FAILURE, error } }
}

function updateMusic(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        musicService.updateMusic(data)
            .then(
                music => {
                    dispatch(success(music));
                    dispatch(this.getAllMusic(tempdata));
                    dispatch(alertActions.success("Music updated successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: musicConstants.UPDATE_MUSIC_REQUEST } }
    function success(music) { return { type: musicConstants.UPDATE_MUSIC_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.UPDATE_MUSIC_FAILURE, error } }
}

function deleteMusic(data, dataemp) {

    return dispatch => {
        dispatch(request());
        musicService.deleteMusic(data)
            .then(
                music => {
                    dispatch(success(music));
                    dispatch(this.getAllMusic(dataemp));
                    dispatch(alertActions.success("Music deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: musicConstants.DELETE_MUSIC_REQUEST } }
    function success(music) { return { type: musicConstants.DELETE_MUSIC_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.DELETE_MUSIC_FAILURE, error } }
}
function disableMusic(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        musicService.disableMusic({ id: data.id })
            .then(
                music => {
                    dispatch(success(music));
                    dispatch(this.getAllMusic(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: musicConstants.ADD_MUSIC_REQUEST } }
    function success(music) { return { type: musicConstants.ADD_MUSIC_SUCCESS, music } }
    function failure(error) { return { type: musicConstants.ADD_MUSIC_FAILURE, error } }
}