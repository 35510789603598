
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const trackerService = {
    logout,
    createTracker,
    updateTracker,
    disableTracker,
    getTrackerList,
    deleteTracker
};
function logout() {
    localStorage.removeItem('admintracker');
    window.location.href = "#/login";
}

function createTracker(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createTracker`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let trackerObj = {
                createTracker: data.data
            }
            // console.log("After API: ",data.data);

            return trackerObj;
        });
}

function deleteTracker(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteTracker`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let trackerObj = {
                createTracker: data.data
            }

            return trackerObj;
        });
}

function updateTracker(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTracker`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let trackerObj = {
                updateTracker: data.data
            }
            // console.log("After API: ",data.data);

            return trackerObj;
        });
}

function disableTracker(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateTrackerStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let trackerObj = {
                updateTracker: data.data
            }
            // console.log("After API: ",data.data);

            return trackerObj;
        });
}

function getTrackerList(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getTrackerList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let trackerObj = {
                trackerList: data.data
            }
            // console.log("After API: ",data.data);

            return trackerObj;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}