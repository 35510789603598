import {
  enquiryConstants
} from '../_constants';

export function enquiry(state = {}, action) {

  switch (action.type) {
    case enquiryConstants.ENQUIRY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case enquiryConstants.ENQUIRY_GETALL_SUCCESS:
      return {
        ...state,
        addEnquirySuccess: false,
        updateEnquirySuccess:false,
        deleteEnquirySuccess: false,

          EnquiryList: action.enquiry.listOfEnquirys.list,
          items: action.enquiry.listOfEnquirys.list,
          total: action.enquiry.listOfEnquirys.total
      };
    case enquiryConstants.ENQUIRY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case enquiryConstants.ADD_ENQUIRY_REQUEST:
      return {
        ...state
      };
    case enquiryConstants.ADD_ENQUIRY_SUCCESS:
      return {
        ...state,
        addEnquirySuccess: true
      };
    case enquiryConstants.ADD_ENQUIRY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case enquiryConstants.UPDATE_ENQUIRY_REQUEST:
      return {
        ...state
      };
    case enquiryConstants.UPDATE_ENQUIRY_SUCCESS:
      return {
        ...state,
        updateEnquirySuccess: true
      };
    case enquiryConstants.UPDATE_ENQUIRY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case enquiryConstants.DELETE_ENQUIRY_REQUEST:
        return {
          ...state
        };
      case enquiryConstants.DELETE_ENQUIRY_SUCCESS:
        return {
          ...state,
          deleteEnquirySuccess: true
        };
      case enquiryConstants.DELETE_ENQUIRY_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}