import { benchmarkConstants } from '../_constants';
import { benchmarkService } from '../_services';
import { alertActions } from '.';
export const benchmarkActions = {
    disableBenchMark,
    createNewBenchMark,
    updateBenchMark,
    getAllBenchMark,
    deleteBenchMark
};

function getAllBenchMark(data) {
    return dispatch => {
        dispatch(request());
        benchmarkService.getAllBenchMark(data)
            .then(
                benchmark => dispatch(success(benchmark)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: benchmarkConstants.BENCHMARK_GETALL_REQUEST } }
    function success(benchmark) { return { type: benchmarkConstants.BENCHMARK_GETALL_SUCCESS, benchmark } }
    function failure(error) { return { type: benchmarkConstants.BENCHMARK_GETALL_FAILURE, error } }
}
function createNewBenchMark(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        benchmarkService.createNewBenchMark(data)
            .then(
                benchmark => {
                    dispatch(success(benchmark));
                    dispatch(this.getAllBenchMark(tempdata));
                    dispatch(alertActions.success("BenchMark created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: benchmarkConstants.ADD_BENCHMARK_REQUEST } }
    function success(benchmark) { return { type: benchmarkConstants.ADD_BENCHMARK_SUCCESS, benchmark } }
    function failure(error) { return { type: benchmarkConstants.ADD_BENCHMARK_FAILURE, error } }
}

function updateBenchMark(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        benchmarkService.updateBenchMark(data)
            .then(
                benchmark => {
                    dispatch(success(benchmark));
                    dispatch(this.getAllBenchMark(tempdata));
                    dispatch(alertActions.success("BenchMark updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: benchmarkConstants.UPDATE_BENCHMARK_REQUEST } }
    function success(benchmark) { return { type: benchmarkConstants.UPDATE_BENCHMARK_SUCCESS, benchmark } }
    function failure(error) { return { type: benchmarkConstants.UPDATE_BENCHMARK_FAILURE, error } }
}

function deleteBenchMark(data, dataemp) {

    return dispatch => {
        dispatch(request());
        benchmarkService.deleteBenchMark(data)
            .then(
                benchmark => {
                    dispatch(success(benchmark));
                    dispatch(this.getAllBenchMark(dataemp));
                    dispatch(alertActions.success("BenchMark deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: benchmarkConstants.DELETE_BENCHMARK_REQUEST } }
    function success(benchmark) { return { type: benchmarkConstants.DELETE_BENCHMARK_SUCCESS, benchmark } }
    function failure(error) { return { type: benchmarkConstants.DELETE_BENCHMARK_FAILURE, error } }
}
function disableBenchMark(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        benchmarkService.disableBenchMark({ id: data.id })
            .then(
                benchmark => {
                    dispatch(success(benchmark));
                    dispatch(this.getAllBenchMark(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: benchmarkConstants.ADD_BENCHMARK_REQUEST } }
    function success(benchmark) { return { type: benchmarkConstants.ADD_BENCHMARK_SUCCESS, benchmark } }
    function failure(error) { return { type: benchmarkConstants.ADD_BENCHMARK_FAILURE, error } }
}