import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  // Select,
  // Input,
  // MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
import Select2 from 'react-select';


export default function UpdateExpert(props) {

  const {
    classes, updateformData, addSlot,addDay, submitted, handleUpdateClose,
    listOfExpertCategory, handleParameterTypeName, handleUpdateSubmit,
    updatehandleChangeInput,
    updatehandleChangeInputday,
    updatehandleChangeInputslot,
    handleFile, handleUpload,imageWebLink, imageWebURL  } = props;


    console.log('updateformData: ',updateformData)

    let colourOptions=[];
    listOfExpertCategory.forEach((emp)=>{
      colourOptions.push(
        {
          value:emp.name,
          label:emp.name,
          id:emp.id,
        }
      )
    })
    let colourDefault=[];
    if(updateformData.expertCategoryId){
          
    updateformData.expertCategoryId.forEach((upid)=>{
      listOfExpertCategory.forEach((exp)=>{
      if(exp.id === upid)
      colourDefault.push(
        {
          value:exp.name,
          label:exp.name,
          id:exp.id,
        }
      )
    })
  })
}

  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update New Expert
            </Typography>
          <Button color="inherit" onClick={handleUpdateClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Expert
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleUpdateSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="name"
                  id="name"
                  value={updateformData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Desc*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="desc"
                  id="desc"
                  value={updateformData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Type*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="type"
                  id="type"
                  value={updateformData.type}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <div>Schedule</div>
                <Button style={{ marginLeft: '20px', "float": 'right' }} color="secondary" onClick={() => addDay()}>Add</Button>
                {
                  updateformData.timing && updateformData.timing.length > 0 ?
                    updateformData.timing.map((element, index) => (
                      <div className="ex1" key={index}>
                        <TextValidator
                          label="Day*"
                          fullWidth
                          onChange={updatehandleChangeInputday}
                          name="day"
                          id={index}
                          value={element.day}
                        // validators={['required']}
                        // errorMessages={['this field is required']}
                        />
                        <Button style={{ marginLeft: '20px', "float": 'right' }} color="secondary" onClick={() => addSlot(index)}>Slot</Button>
                        {
                          element.slot.map((element1, index1) => (
                            <div key={index1}>
                              
                              <TextValidator
                                label="Slot*"
                                fullWidth
                                onChange={updatehandleChangeInputslot}
                                name={index1}
                                id={index}
                                id2={index1}
                                value={element1}
                              // validators={['required']}
                              // errorMessages={['this field is required']}
                              />
                            </div>
                          ))
                        }
                      </div>
                    )) : null
                }
              </Grid>
              <div style={{ margin: "7px" }}></div>
              <Grid item xs={12} style={{ margin: "0px" }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Expert Category</label>
                <Select2
                  id="expertCategoryId"
                  defaultValue={(colourDefault)?colourDefault.map((val)=>{return val}):""}
                  isMulti
                  name="expertCategoryId"
                  onChange={handleParameterTypeName}
                  options={colourOptions}
                  className="basic-multi-select"
                  classNamePrefix="Category"
                />
              </Grid>
              <div style={{ margin: "7px" }}></div>
              {/* <Grid item xs={12}>
                <Select
                  name="expertCategoryId"
                  value={updateformData.expertCategoryId ? updateformData.expertCategoryId : listOfExpertCategory && listOfExpertCategory.length > 0 ? listOfExpertCategory[0].id : ''}
                  style={{ width: '100%' }}
                  label="Parameter Type"
                  onChange={handleParameterTypeName}
                  input={<Input id="expertCategoryId" />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Parameter Type
                                                </MenuItem>
                  {
                    listOfExpertCategory && listOfExpertCategory.length > 0 ?
                      listOfExpertCategory.map((tempdata, index) => (
                        <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                      )) : null
                  }

                </Select>
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={updateformData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                    </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
                  </Button>



            </Grid>



            {(imageWebURL && (imageWebLink === updateformData.imageLink)) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              : 
              <Grid item xs={12} style={{ margin: '4%' }}>
              <img src={updateformData.imageLinkV2} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              }


            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}