import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";

import { resourceActions } from '../../_actions/resource.actions'
import { resourcetypeActions } from '../../_actions/resourcetype.actions'

import { userActions } from '../../_actions/user.actions'


import { withRouter } from "react-router";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './styles';

import UpdateResource from './Modal/UpdateResource';
import AddResource from './Modal/AddResource';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();



class Resource extends Component {
  constructor(props) {
    super(props);
    this.state = {

      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',

      newResourceModal: false,
      newUpdateResourceModal: false,

      selectedResourceType: "",
      title: "",
      desc: "",
      name: "",
      link: "",
      resourceTypeName: "",
      resourceTypeId: "",

      rating: "",
      author: "",
      idmb: "",
      subjectIndex: "",

      selectedFile: null,
      imageWebLink: "",
      imageWebURL:"",


      submitted: false,
      formData: {
        "name": "",
        "desc": "",
        "link": "",
        "imageLink": "",
        "resourceTypeName": "",
        "resourceTypeId": "",
        "rating": "",

        "author": "",

        "idmb": "",

        "host": "",

        "type": "",


        "template": 0

      },
      updateformData: {
        "id": "",
        "name": "",
        "desc": "",
        "link": "",
        "imageLink": "",
        "imageLinkV2": "",
        "resourceTypeName": "",
        "resourceTypeId": "",
        "rating": "",

        "author": "",

        "idmb": "",

        "host": "",

        "type": "",


        "template": 0

      }

    }
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    let data22 = {

      "typeId": "",
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);
    this.props.dispatch(resourceActions.getResourceList(data22));
    this.props.dispatch(resourcetypeActions.getAllResourceType(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);

    if (nextProps.resource.updateResourceSuccess) {

      return {
        ...nextProps,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "link": "",
          "imageLink": "",
          "imageLinkV2": "",
          "resourceTypeName": "",
          "resourceTypeId": "",
          "rating": "",

          "author": "",

          "idmb": "",

          "host": "",

          "type": "",


          "template": 0

        },
        formData: {
          "name": "",
          "desc": "",
          "link": "",
          "imageLink": "",
          "resourceTypeName": "",
          "resourceTypeId": "",
          "rating": "",

          "author": "",

          "idmb": "",

          "host": "",

          "type": "",


          "template": 0

        },
        newUpdateResourceModal: false

      }
    }

    if (nextProps.resource.addResourceSuccess) {

      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "link": "",
          "imageLink": "",
          "resourceTypeName": "",
          "resourceTypeId": "",
          "rating": "",

          "author": "",

          "idmb": "",

          "host": "",

          "type": "",


          "template": 0

        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "link": "",
          "imageLink": "",
          "imageLinkV2": "",
          "resourceTypeName": "",
          "resourceTypeId": "",
          "rating": "",

          "author": "",

          "idmb": "",

          "host": "",

          "type": "",


          "template": 0

        },
        newResourceModal: false

      }
    }

    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }

    else {
      return {
        ...nextProps
      }
    }

  }

  onDisable = (data) => {

    let tempdata = {
      "id": data.id
    }
    this.props.dispatch(resourceActions.disableResource(tempdata));
  }
  onUpdate = (data) => {

    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "link": data.link,
      "imageLink": data.imageLink,
      "imageLinkV2":data.imageLinkV2,
      "resourceTypeName": data.resourceTypeName,
      "resourceTypeId": data.resourceTypeId,
      "template": data.template,

      "rating": (data && data.other && data.other.rating) ? data.other.rating : '',
      "author": (data && data.other && data.other.author) ? data.other.author : '',
      "idmb": (data && data.other && data.other.idmb) ? data.other.idmb : '',
      "host": (data && data.other && data.other.host) ? data.other.host : '',
      "type": (data && data.other && data.other.type) ? data.other.type : '',
    }
    console.log("tempdata: ", tempdata)
    this.setState({ newUpdateResourceModal: true, updateformData: tempdata });
  }
  handleClickOpen = () => {
    this.setState({ newResourceModal: true });
  }
  handleClose = () => {
    this.setState({ newResourceModal: false });
    this.setState({ imageWebLink: "" });
    this.props.dispatch(userActions.uploadImageClear());
  }
  handleUpdateClose = () => {
    this.setState({ newUpdateResourceModal: false });
    this.setState({ imageWebLink: "" });
    this.props.dispatch(userActions.uploadImageClear());
  }
  handleResourceName = (event) => {
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;

    this.setState({ formData, updateformData });
  }
  handleResourceDesc = (value) => {
    const { formData, updateformData } = this.state;
    formData["desc"] = value;
    updateformData["desc"] = value;

    this.setState({ formData, updateformData });
  }
  handleChangeResourceType = (value) => {

    let { resourcetype } = this.props;
    let { resourceTypesList } = resourcetype;

    const { formData, updateformData } = this.state;
    resourceTypesList.forEach((temp) => {
      if (temp.id === value) {
        console.log("Value:--------------------------------------------- x ", temp.name, temp.id)

        formData["resourceTypeName"] = temp.name;
        formData["resourceTypeId"] = temp.id;
        formData["template"] = temp.template;
        updateformData["resourceTypeName"] = temp.name;
        updateformData["resourceTypeId"] = temp.id;
        updateformData["template"] = temp.template;

        this.setState({ formData, updateformData });
      }

    })

    this.setState({ subjectIndex: value })
  }
  submitResourceDetails = () => {

    let ResTypeTemplate = this.state.formData.template;

    let data = {};
    let flag = 0;

    if (ResTypeTemplate === 1) {

      data = {
        name: this.state.formData.name,
        desc: this.state.formData.desc,
        link: this.state.formData.link,
        imageLink: this.state.formData.imageLink.trim(),
        resourceTypeId: this.state.formData.resourceTypeId,
        resourceTypeName: this.state.formData.resourceTypeName.trim(),
        other: {
          rating: this.state.formData.rating,
          idmb: this.state.formData.idmb
        }
      }

    }
    else if (ResTypeTemplate === 2) {

      data = {
        name: this.state.formData.name,
        desc: this.state.formData.desc,
        link: this.state.formData.link,
        imageLink: this.state.formData.imageLink.trim(),
        resourceTypeId: this.state.formData.resourceTypeId,
        resourceTypeName: this.state.formData.resourceTypeName.trim(),
        other: {
          rating: this.state.formData.rating,
          author: this.state.formData.author
        }
      }

    }
    else if (ResTypeTemplate === 3) {

      data = {
        name: this.state.formData.name,
        desc: this.state.formData.desc,
        link: this.state.formData.link,
        imageLink: this.state.formData.imageLink.trim(),
        resourceTypeId: this.state.formData.resourceTypeId,
        resourceTypeName: this.state.formData.resourceTypeName.trim(),
        other: {}
      }

    }
    else if (ResTypeTemplate === 4) {

      data = {
        name: this.state.formData.name,
        desc: this.state.formData.desc,
        link: this.state.formData.link,
        imageLink: this.state.formData.imageLink.trim(),
        resourceTypeId: this.state.formData.resourceTypeId,
        resourceTypeName: this.state.formData.resourceTypeName.trim(),
        other: {
          host: this.state.formData.host
        }
      }

    }
    else if (ResTypeTemplate === 5) {

      data = {
        name: this.state.formData.name,
        desc: this.state.formData.desc,
        link: this.state.formData.link,
        imageLink: this.state.formData.imageLink.trim(),
        resourceTypeId: this.state.formData.resourceTypeId,
        resourceTypeName: this.state.formData.resourceTypeName.trim(),
        other: {
          rating: this.state.formData.rating,
          type: this.state.formData.type
        }
      }

    }
    else {

      flag = 1;

    }

    if (flag !== 1) {
      this.props.dispatch(resourceActions.createResource(data));
      this.setState({ imageWebLink: "" });

    }



  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "typeId": this.state.selectedResourceType,

      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(resourceActions.getResourceList(data));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "typeId": this.state.selectedResourceType,
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(resourceActions.getResourceList(data));
  }

  submitUpdateResourceDetails = () => {

    let ResType = this.state.updateformData.resourceTypeName.trim();

    let data = {};
    if (ResType === "Movie") {

      data = {
        id: this.state.updateformData.id,
        name: this.state.updateformData.name,
        desc: this.state.updateformData.desc,
        link: this.state.updateformData.link,
        imageLink: this.state.updateformData.imageLink.trim(),
        resourceTypeId: this.state.updateformData.resourceTypeId,
        resourceTypeName: this.state.updateformData.resourceTypeName.trim(),
        other: {
          rating: this.state.updateformData.rating,
          idmb: this.state.updateformData.idmb,
        }
      }

    }
    else if (ResType === "Book") {

      data = {
        id: this.state.updateformData.id,

        name: this.state.updateformData.name,
        desc: this.state.updateformData.desc,
        link: this.state.updateformData.link,
        imageLink: this.state.updateformData.imageLink.trim(),
        resourceTypeId: this.state.updateformData.resourceTypeId,
        resourceTypeName: this.state.updateformData.resourceTypeName.trim(),
        other: {
          rating: this.state.updateformData.rating,
          author: this.state.updateformData.author,
        }
      }

    }
    else if (ResType === "Podcast") {

      data = {
        id: this.state.updateformData.id,

        name: this.state.updateformData.name,
        desc: this.state.updateformData.desc,
        link: this.state.updateformData.link,
        imageLink: this.state.updateformData.imageLink.trim(),
        resourceTypeId: this.state.updateformData.resourceTypeId,
        resourceTypeName: this.state.updateformData.resourceTypeName.trim(),
        other: {
          host: this.state.updateformData.host
        }
      }

    }
    else if (ResType === "Course") {

      data = {
        id: this.state.updateformData.id,

        name: this.state.updateformData.name,
        desc: this.state.updateformData.desc,
        link: this.state.updateformData.link,
        imageLink: this.state.updateformData.imageLink.trim(),
        resourceTypeId: this.state.updateformData.resourceTypeId,
        resourceTypeName: this.state.updateformData.resourceTypeName.trim(),
        other: {
          rating: this.state.updateformData.rating,
          type: this.state.updateformData.type

        }
      }

    }
    else {

      data = {
        id: this.state.updateformData.id,
        name: this.state.updateformData.name,
        desc: this.state.updateformData.desc,
        link: this.state.updateformData.link,
        imageLink: this.state.updateformData.imageLink.trim(),
        resourceTypeId: this.state.updateformData.resourceTypeId,
        resourceTypeName: this.state.updateformData.resourceTypeName.trim(),
        other: {}
      }

    }

    this.props.dispatch(resourceActions.updateResource(data));
    this.setState({ imageWebLink: "" });


  }
  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['imageLink'] = imageWebLink;
    updateformData['imageLink'] = imageWebLink;
    this.setState({ formData, updateformData });

  }
  onChangeResourceType = (event) => {
    // console.log(offset, page);
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:", value)
    this.setState({ selectedResourceType: value });
    let data22 = {

      "typeId": value,
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log("data22data22  ", data22);

    this.props.dispatch(resourceActions.getResourceList(data22));
    // this.setState({ offset, page });

    // let data = {
    //   "keyWord": this.state.keyWord,
    //   "pageNo": page,
    //   "size": this.state.size
    // }
    // this.props.dispatch(resourceActions.getResourceList(data));
  }


  render() {

    let { classes, users, resource, resourcetype } = this.props;
    let { items, total } = resource;
    const { resourceTypesList } = resourcetype;
    const { formData, updateformData, submitted } = this.state;

    if (this.state.selectedFile) {
      console.log("selectedFile", this.state.selectedFile)
    }


    if (this.state.resourceTypeName) {
      console.log("aasdkkk---->", this.state.resourceTypeName, this.state.resourceTypeId)
    }

    let receivedImage = null;
    if (users.filesDetails) {
      receivedImage = users.filesDetails;
    }

    console.log("updateformData----> ", updateformData)

    
    return (
      <>


        <PageTitle title="Resource List" />

        <Grid container spacing={2} style={{ marginTop: '5%' }}>



          <Grid container >
            <Grid item xs={12}>
              <Widget >
                <Grid container >
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <FormControl className={classes.formControl} style={{ minWidth: "200px" }}>
                      <InputLabel id="demo-simple-select-label">Resource Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={this.state.selectedResourceType}
                        onChange={this.onChangeResourceType}
                      >
                        <MenuItem value="">
                          <em>ALL</em>
                        </MenuItem>
                        {
                          resourceTypesList && resourceTypesList.length > 0 ?
                            resourceTypesList.map((element) => (<MenuItem key={element.id} value={element.id}>{element.name}</MenuItem>)) : null
                        }


                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      id="standard-search"
                      label="Search field"
                      type="search"
                      name="keyWord"
                      onChange={this.handleSearch}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                      Add
                  </Button>

                  </Grid>

                </Grid>

                {
                  items && items.length > 0 ?
                    <>
                      <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                        onDisable={this.onDisable}
                        onUpdate={this.onUpdate} />
                    </>
                    : <Typography >Data not found.</Typography>
                }
                {
                  total && total > 10 ?
                    <MuiThemeProvider theme={theme}>
                      <CssBaseline />
                      <Pagination
                        limit={this.state.size}
                        offset={this.state.offset}
                        total={total}
                        onClick={(e, offset, page) => this.handleClick(offset, page)}
                      />
                    </MuiThemeProvider>
                    : null}
              </Widget>
            </Grid>

          </Grid>
          <Dialog fullScreen open={this.state.newResourceModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddResource
              data={this.data}
              classes={classes}
              formData={formData}
              submitted={submitted}
              handleClose={this.handleClose}

              resourceTypesList={resourceTypesList}

              subjectIndex={this.state.subjectIndex}
              handleResourceName={this.handleResourceName}
              handleChangeResourceType={this.handleChangeResourceType}
              submitResourceDetails={this.submitResourceDetails}
              handleResourceDesc={this.handleResourceDesc}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              receivedImage={receivedImage}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}


              handleChangeInput={this.handleChangeInput}
            />
          </Dialog>
          <Dialog fullScreen open={this.state.newUpdateResourceModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
            <UpdateResource
              data={this.data}
              classes={classes}
              updateformData={updateformData}
              submitted={submitted}
              handleClose={this.handleUpdateClose}

              resourceTypesList={resourceTypesList}

              subjectIndex={this.state.subjectIndex}
              handleResourceName={this.handleResourceName}
              handleChangeResourceType={this.handleChangeResourceType}
              submitUpdateResourceDetails={this.submitUpdateResourceDetails}
              handleResourceDesc={this.handleResourceDesc}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              receivedImage={receivedImage}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}

            />
          </Dialog>


        </Grid>


      </>
    );
  }
}
Resource.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, resource, resourcetype } = state;
  return {
    loggingIn,
    users,
    resource,
    resourcetype


  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Resource)));
