import React, { Component } from 'react';

import { connect } from 'react-redux';


import { questionsActions } from '../../../_actions';
import { companyActions } from '../../../_actions';



class WelcomePage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isAdd:false,
      editId: null,
      QuesName: "",
      OptionName: "",
      dropdown: "Choose",
      radioButton:false,
      starCount:0,


      save: false,
      questionsize: 10,
      editCurrentQuestion: {},
      editCurrentOption:{}
    }
  }
  
  componentDidMount() {
    let survey = {
      surveyId: this.props.match.params.id
    }
    let surveyList ={
      "keyWord": "",
      "pageNo": 1,
      "size": 10
  }
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(survey));
    this.props.dispatch(companyActions.getSurveyList(surveyList));
  }




  
  goToQuestionPage(){


    let survey = {
        surveyId: this.props.match.params.id
      }
    this.props.history.push(`/app/questionpreview/${survey.surveyId}`)
  }

  

  render() {
    let { company } = this.props;

    let {  surveyitems } = company;



 
    return (
      <>
        <div className="container">

         

     
        <div className="container">
                  <div className="card" variant="outlined" style={{backgroundColor:'white',padding:'10%', marginTop:'5%'}}> 
                    <div className="card-body">

                    { (surveyitems && surveyitems.length) > 0 ?
                        surveyitems.map((surveys, surveyIndex) => 
                        (surveys.id===this.props.match.params.id)?
                            <div className="container" key={surveyIndex} item xs={12}
                            style={{marginTop:'15%',marginBottom:'23%'}}
                            >
                            <div className="container"
                            style={{margin:'20px'}}
                            >
                      
                
                                     <div className="container">
                                          
                                          <div className="container" key={surveyIndex}  >
                                                  <h1 variant="h1"  style={{textAlign:'center',marginRight:'30%'}}>
                                                      {surveys.name}
                                                  </h1>
                                              </div>
                                   
                                      </div>
                              
                            
                                </div>
                                        <div className="container"
                                        style={{margin:'20px'}}
                                        >
                                                <button
                                                
                                                color="secondary"
                                                size="small"
                                                className="px-2 btn btn-secondary" 
                                                variant="contained"
                                                style={{ marginLeft:'32%',marginTop:'3%' ,color:'white',backgroundColor:'#545059'}}
                                                onClick={()=>this.goToQuestionPage()}
                                                >
                                                Start Survey
                                                </button>
                                        </div>
                                </div>
                           :null
                        
                        )
                    
                        :null
                }
                    </div>
                  </div>
                </div>
              
      



        </div>
      </>
    );
  }
}
WelcomePage.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { company, questions, users } = state;
  return {
    loggingIn,
    company,
    questions,
    users

  };
}

export default connect(mapStateToProps)(WelcomePage);
