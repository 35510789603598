import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { badgeActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddBadge from './Modal/AddBadge';
import UpdateBadge from './Modal/UpdateBadge';

import { userActions } from '../../_actions/user.actions'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class Badge extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newBadgeModal: false,
      newUpdateBadgeModal:false,
      submitted: false,
      selectedFile: null,
      imageWebLink: "",
      imageWebURL:"",      
      formData: {
        "name":"",
        "point":"",
        "template":"",
        "desc":"",
        "icon":"",
        "colorCode":""
      },
      updateformData:{
        "id":"",
        "name":"",
        "point":"",
        "template":"",
        "desc":"",
        "icon":"",
        "colorCode":""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(badgeActions.getAllBadge(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }

    
    if (nextProps.badge.addBadgeSuccess) {
      console.log("nextProps.badge.updateBadgeSuccess",nextProps.badge.addBadgeSuccess)

      return {
        ...nextProps,
        formData: {
          "name":"",
          "point":"",
          "template":"",
          "desc":"",
          "icon":"",
          "colorCode":""
        },
        newBadgeModal: false,
      }
    }

    if (nextProps.badge.updateBadgeSuccess) {
      console.log("nextProps.badge.updateBadgeSuccess",nextProps.badge.updateBadgeSuccess)
      return {
        ...nextProps,
        updateformData: {
          "id":"",
          "name":"",
          "point":"",
          "template":"",
          "desc":"",
          "icon":"",
          "colorCode":""

        },
        newUpdateBadgeModal: false,
      }
    }
    if (nextProps.badge.deleteBadgeSuccess) {
      return {
        ...nextProps,
        formData: {
          "name":"",
          "point":"",
          "template":"",
          "desc":"",
          "icon":"",
          "colorCode":""

        },
        updateformData: {
          "id":"",
          "name":"",
          "point":"",
          "template":"",
          "desc":"",
          "icon":"",
          "colorCode":""

        }
      }
    }
     else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(badgeActions.getAllBadge(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(badgeActions.getAllBadge(data));
  }
  handleClickOpen = () => {
    this.setState({ newBadgeModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newBadgeModal: false });
    this.props.dispatch(userActions.uploadImageClear());

  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    let reqData = {
      "name":this.state.formData.name,
      "point":this.state.formData.point,
      "template":this.state.formData.template,
      "desc":this.state.formData.desc,
      "icon": (this.state.imageWebLink) ? this.state.imageWebLink :  this.state.formData.icon.trim(),
      "colorCode":this.state.formData.colorCode,
    }
    this.props.dispatch(badgeActions.createNewBadge(reqData));
    let temp = {
      "name":"",
      "point":"",
      "template":"",
      "desc":"",
      "icon":"",
      "colorCode":""
    }
    this.setState({ imageWebLink:"",formData:temp });
    this.setState({ newBadgeModal: false });

  }


  onDelete = (data) => {
    let datatemp = {
    "keyWord": "",
    "pageNo": 1,
    "size": this.state.size
  }

  confirmAlert({
    name: 'Confirm to delete?',
    message: 'Are you sure to delete ' + data.name,
    buttons: [
      {
        label: 'Yes',
        onClick: () => this.props.dispatch(badgeActions.deleteBadge({ id: data.id},datatemp))
      },
      {
        label: 'No'
      }
    ]
  });

}


  onRowClick = (data) => {
    this.props.history.push(`/app/badgedetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(badgeActions.disableBadge(tempdata));
  }
  onUpdate = (data) => {
    console.log(data);
    let tempdata = {
      "id":data.id,
      "name":data.name,
      "point":data.point,
      "template":data.template,
      "desc":data.desc,
      "icon":data.icon,
      "colorCode":data.colorCode,
      "imageLinkV2":data.imageLinkV2,

    }
    console.log("tempdata",tempdata)
    this.setState({ newUpdateBadgeModal: true, updateformData: tempdata });
  }
  updatehandleChangeInput= (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
        updateformData[event.target.name] = event.target.value;
        this.setState({ updateformData });
    
  }


  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id":this.state.updateformData.id,
      "name":this.state.updateformData.name,
      "point":this.state.updateformData.point,
      "template":this.state.updateformData.template,
      "desc":this.state.updateformData.desc,
      "icon": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.icon,
      "colorCode":this.state.updateformData.colorCode,
    }
    console.log("-----reqData-----")
    console.log(reqData)
    this.props.dispatch(badgeActions.updateBadge(reqData,tempdata));
    this.setState({ imageWebLink:"" });

  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateBadgeModal: false });
    this.props.dispatch(userActions.uploadImageClear());
  }


  handleFile=(event)=>{

    this.setState({ selectedFile: event.target.files[0] }); 

    if(event.target.files[0]){
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length-1]));
      this.setState({ selectedFile: null });
      
     }
     else{
       console.log("No File To Upload!")
     }

  }

  handleUpload=()=>{

    const {formData, updateformData, imageWebLink } = this.state;
    formData['icon'] = imageWebLink;
    updateformData['icon'] = imageWebLink;
    this.setState({formData, updateformData });

  }



  render() {

    let { users, badge, classes } = this.props;
    let { items, total } = badge;
    const { formData,updateformData, submitted } = this.state;

    if(this.state.updateformData){
      console.log("----updateformdata----",updateformData);
    }

    // let receivedImage=null;
    if(users.filesDetails){
      console.log("filesDetails: ",users.filesDetails)
      // receivedImage = users.filesDetails;
    }

  console.log("imageweblink: ",this.state.imageWebLink)

    return (
      <>
        <PageTitle title="Badge" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newBadgeModal} onClose={this.handleClose} TransitionComponent={Transition}>
               <AddBadge
                  data={this.data}
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}

                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  imageWebLink={this.state.imageWebLink}
                  imageWebURL={this.state.imageWebURL}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateBadgeModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateBadge
                  data={this.data}
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.updatehandleChangeInput}

                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  imageWebLink={this.state.imageWebLink}
                  imageWebURL={this.state.imageWebURL}

                />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { users, badge } = state;
  return {
    users,
    badge
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Badge)));
