import {
  faqConstants
} from '../_constants';

export function faq(state = {}, action) {

  switch (action.type) {
    case faqConstants.FAQ_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case faqConstants.FAQ_GETALL_SUCCESS:
      return {
        ...state,
        addFaqSuccess: false,
        updateFaqSuccess:false,
        deleteFaqSuccess: false,

          FaqList: action.faq.listOfFaqs.list,
          items: action.faq.listOfFaqs.list,
          total: action.faq.listOfFaqs.total
      };
    case faqConstants.FAQ_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case faqConstants.ADD_FAQ_REQUEST:
      return {
        ...state
      };
    case faqConstants.ADD_FAQ_SUCCESS:
      return {
        ...state,
        addFaqSuccess: true
      };
    case faqConstants.ADD_FAQ_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case faqConstants.UPDATE_FAQ_REQUEST:
      return {
        ...state
      };
    case faqConstants.UPDATE_FAQ_SUCCESS:
      return {
        ...state,
        updateFaqSuccess: true
      };
    case faqConstants.UPDATE_FAQ_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case faqConstants.DELETE_FAQ_REQUEST:
        return {
          ...state
        };
      case faqConstants.DELETE_FAQ_SUCCESS:
        return {
          ...state,
          deleteFaqSuccess: true
        };
      case faqConstants.DELETE_FAQ_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}