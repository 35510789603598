export const questionsConstants = {

    LOGOUT: 'USERS_LOGOUT',

    ADD_QUESTIONS_REQUEST: 'ADD_QUESTIONS_REQUEST',
    ADD_QUESTIONS_SUCCESS: 'ADD_QUESTIONS_SUCCESS',
    ADD_QUESTIONS_FAILURE: 'ADD_QUESTIONS_FAILURE',

    DELETE_QUESTIONS_REQUEST: 'DELETE_QUESTIONS_REQUEST',
    DELETE_QUESTIONS_SUCCESS: 'DELETE_QUESTIONS_SUCCESS',
    DELETE_QUESTIONS_FAILURE: 'DELETE_QUESTIONS_FAILURE',
    
    UPDATE_QUESTIONS_REQUEST: 'UPDATE_QUESTIONS_REQUEST',
    UPDATE_QUESTIONS_SUCCESS: 'UPDATE_QUESTIONS_SUCCESS',
    UPDATE_QUESTIONS_FAILURE: 'UPDATE_QUESTIONS_FAILURE',
    

    DELETE_QUESTIONOPTION_REQUEST: 'DELETE_QUESTIONOPTION_REQUEST',
    DELETE_QUESTIONOPTION_SUCCESS: 'DELETE_QUESTIONOPTION_SUCCESS',
    DELETE_QUESTIONOPTION_FAILURE: 'DELETE_QUESTIONOPTION_FAILURE',

    QUESTIONS_GETALL_REQUEST: 'QUESTIONS_GETALL_REQUEST',
    QUESTIONS_GETALL_SUCCESS: 'QUESTIONS_GETALL_SUCCESS',
    QUESTIONS_GETALL_FAILURE: 'QUESTIONS_GETALL_FAILURE',

    ANSWERS_SUBMIT_REQUEST: 'ANSWERS_SUBMIT_REQUEST',
    ANSWERS_SUBMIT_SUCCESS: 'ANSWERS_SUBMIT_SUCCESS',
    ANSWERS_SUBMIT_FAILURE: 'ANSWERS_SUBMIT_FAILURE'


};
