import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Select,
  Input,
  MenuItem,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function AddUser(props) {

  const { classes,surveyTypeList, surveyformData,submitted, handleCloseSurvey, handleSubmitSurvey, handleChangeInputSurvey,handleChangesurvey } = props;

  return (
    <div >
         <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseSurvey} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add New Survey
            </Typography>
              <Button color="inherit" onClick={handleCloseSurvey}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Add Survey
              </Typography>

              <ValidatorForm
                // ref="form"
                onSubmit={handleSubmitSurvey}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                    <TextValidator
                      label="Name*"
                      fullWidth
                      onChange={handleChangeInputSurvey}
                      name="name"
                      id="name"
                      value={surveyformData.name}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Description*"
                      id="desc"
                      fullWidth
                      onChange={handleChangeInputSurvey}
                      name="desc"
                      value={surveyformData.desc}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>

                    <Select
                      name="name"
                      value={surveyformData.type ? surveyformData.type : surveyTypeList && surveyTypeList.length > 0 ? surveyTypeList[0].id : ''}
                      style={{ width: '100%' }}
                      onChange={event => handleChangesurvey(event.target.value)}
                      input={<Input id="name" />}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>
                        Survey Type
                      </MenuItem>
                      {
                        surveyTypeList && surveyTypeList.length > 0 ?
                          surveyTypeList.map((tempdata, index) => (
                            <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                          )) : null
                      }

                    </Select>
                  </Grid>


                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                >
                  Save
                </Button>
              </ValidatorForm>
            </div>
          </Container>


    </div>
  );}