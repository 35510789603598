import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { confirmAlert } from 'react-confirm-alert'; // Import

import { resourceActions } from '../../_actions/resource.actions'
import { activityActions } from '../../_actions/activity.actions';

import { withRouter } from "react-router";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './styles';

import UpdateActivity from './Modal/UpdateActivity';
import AddActivity from './Modal/AddActivity';
import { userActions } from '../../_actions/user.actions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();



class Activity extends Component {
  constructor(props) {
    super(props);
    this.state = {

      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',

      title: "",
      desc: "",
      name: "",
      resourceList: [],
      activityTypeName: "",
      activityTypeId: "",

      rating: "",
      author: "",
      idmb: "",

      submitted: false,
      newActivityModal: false,
      newUpdateActivityModal: false,

      formData: {
        "name": "",
        "desc": "",
        "type": "",
        "resourceList": [],
        "subactivityList": [],
        "icon": "",
        "colorCode": ""


      },
      updateformData: {
        "id": "",
        "name": "",
        "desc": "",
        "type": "",
        "resourceList": [],
        "subactivityList": [],
        "icon": "",
        "colorCode": ""
      },
      activityType: [
        {
          name: 'Normal',
          value: 'generic'
        },
        {
          name: 'Habit',
          value: 'habit'
        },
      ],

      subName: '',
      subIndex: '',
      imageWebLink:"",
      imageWebURL:""

    }
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(activityActions.getActivityList(data));
    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 10000
    }
    this.props.dispatch(resourceActions.getAllResource(data2));

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);
    // if (nextProps.users.imageUploadSuccess) {

    //   return {
    //     ...nextProps,
    //     imageWebLink: nextProps.users.filesDetails.uploadedImageName

    //   }
    // }
    if (nextProps.activity.updateActivitySuccess) {

      return {
        ...nextProps,
        apikeyModal: false,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        newUpdateActivityModal: false

      }
    }

    if (nextProps.activity.addActivitySuccess) {

      return {
        ...nextProps,
        apikeyModal: false,
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        newActivityModal: false

      }
    } 
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }
    else {
      return {
        ...nextProps
      }
    }

  }

  handleActivityName = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;

    this.setState({ formData });
  }

  handleActivityNameUpdate = (event) => {
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;



    this.setState({ updateformData });
  }

  handleActivityResource = (e, val) => {
    console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.resourceList.push(val.option.id);
      updateformData.resourceList.push(val.option.id)
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.resourceList.splice(delIndex, 1);
        }
      })

      updateformData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.resourceList.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }

  }

  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(activityActions.deleteActivity({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  submitActivityDetails = () => {

    let data = {
      name: this.state.formData.name.trim(),
      desc: this.state.formData.desc,
      type: this.state.formData.type.trim(),
      resourceList: this.state.formData.resourceList ? this.state.formData.resourceList : [],
      subactivityList: this.state.formData.subactivityList ? this.state.formData.subactivityList : [],
      colorCode: this.state.formData.colorCode,
      icon: (this.state.imageWebLink) ? this.state.imageWebLink : this.state.formData.icon.trim()
    }
    this.props.dispatch(activityActions.createActivity(data));
    this.setState({ subIndex: '', imageWebLink: "" });
  }



  submitUpdateActivityDetails = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let data = {
      id: this.state.updateformData.id,
      name: this.state.updateformData.name.trim(),
      desc: this.state.updateformData.desc,
      type: this.state.updateformData.type.trim(),
      resourceList: this.state.updateformData.resourceList ? this.state.updateformData.resourceList : [],
      subactivityList: this.state.updateformData.subactivityList ? this.state.updateformData.subactivityList : [],
      colorCode: this.state.updateformData.colorCode,
      icon: (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.icon

    }

    this.setState({ updateformData: data })

    console.log("data update: ", data)
    this.props.dispatch(activityActions.updateActivity(data, tempdata));

    this.setState({ subIndex: '', imageWebLink: "" });

  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(activityActions.getActivityList(data));
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.setState({ page: page })
    this.props.dispatch(activityActions.getActivityList(data));
  }
  handleClickOpen = () => {
    this.setState({ newActivityModal: true });
  }
  handleClose = () => {
    let data = {
      "name": "",
      "desc": "",
      "type": "",
      "resourceList": [],
      "subactivityList": [],
      "icon": "",
      "colorCode": ""
    }
    this.setState({ newActivityModal: false, formData: data });

  }


  handleUpdateClose = () => {

    let data = {
      "id": "",
      "name": "",
      "desc": "",
      "type": "",
      "resourceList": [],
      "subactivityList": [],
      "icon": "",
      "colorCode": ""
    }

    this.setState({ newUpdateActivityModal: false, updateformData: data });

  }

  onDisable = (data) => {

    let tempdata = {
      "id": data.id
    }
    this.props.dispatch(activityActions.disableActivity(tempdata));
  }

  onUpdate = (data) => {

    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "type": data.type,
      "resourceList": data.resourceList,
      "subactivityList": data.subactivityList,
      "icon": data.icon,
      "colorCode": data.colorCode,
      "imageLinkV2": data.imageLinkV2

    }

    console.log("onUpdate:::", data)

    // if(tempdata.subactivityList){

    // let tempSubId = tempdata.subactivityList;

    // tempdata.subactivityList =[]

    // tempSubId.forEach((res)=>{
    //   tempdata.subactivityList.push({
    //     name:res.name,
    //     desc:res.desc,
    //     rank:res.rank
    //   })

    // })
    // }

    if (tempdata.resourceList) {
      let tempEmpId = tempdata.resourceList;

      tempdata.resourceList = []

      tempEmpId.forEach((res) => {
        tempdata.resourceList.push(res.id)
      })
    }



    this.setState({ newUpdateActivityModal: true, updateformData: tempdata });
  }

  addSubactivityField = () => {
    const { formData } = this.state;

    formData.subactivityList.push({
      'name': '',
      'desc': '',
      'desc2': '',
      'rank': formData.subactivityList.length + 1
    })

    this.setState({ formData })
  }

  updateSubactivityField = () => {
    const { updateformData } = this.state;

    updateformData.subactivityList.push({
      'name': '',
      'desc': '',
      'desc2': '',
      'rank': updateformData.subactivityList.length + 1
    })

    this.setState({ updateformData })

    console.log("update here: ", updateformData)
  }

  onSubActivityName = (index) => {
    this.setState({
      subIndex: index
    })
  }


  handleSubActivityName = (event) => {
    const { formData } = this.state;
    formData.subactivityList[this.state.subIndex][event.target.name] = event.target.value;


    this.setState({ formData });
  }

  handleSubActivityNameUpdate = (event) => {
    const { updateformData } = this.state;
    updateformData.subactivityList[this.state.subIndex][event.target.name] = event.target.value;

    this.setState({ updateformData });
  }

  deleteSubactivityField = (index) => {
    const { formData } = this.state;
    formData.subactivityList.splice(index, 1)


    this.setState({ formData });
  }

  deleteSubactivityFieldUpdate = (index) => {
    const { updateformData } = this.state;
    updateformData.subactivityList.splice(index, 1)


    this.setState({ updateformData });
  }

  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['icon'] = imageWebLink;
    updateformData['icon'] = imageWebLink;
    this.setState({ formData, updateformData });

  }

  render() {

    let { classes, resource, activity } = this.props;
    const { allResources } = resource;
    const { items, total } = activity;
    const { formData, updateformData, submitted, activityType } = this.state;


    if (allResources) {
      console.log("allResources---->", allResources,)
    }


    console.log("Selected Resources---->", this.state.formData.resourceList)

    console.log("this.state.formData---->", this.state.formData)


    console.log("this.state.imageWebLink---->", this.state.imageWebLink)
    console.log("this.state.imageWebURL---->", this.state.imageWebURL)


    return (
      <>


        <PageTitle title="Activity List" />

        <Grid container spacing={2} style={{ marginTop: '5%' }}>



          <Grid container >
            <Grid item xs={12}>
              <Widget >
                <Grid container >
                  <Grid item xs={8} />
                  <Grid item xs={3}>
                    <TextField
                      id="standard-search"
                      label="Search field"
                      type="search"
                      name="keyWord"
                      onChange={this.handleSearch}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                      Add
                  </Button>
                  </Grid>
                </Grid>

                {
                  items && items.length > 0 ?
                    <>
                      <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                        onDisable={this.onDisable}
                        onUpdate={this.onUpdate}
                        onDelete={this.onDelete} />
                    </>
                    : <Typography >Data not found.</Typography>
                }
                {
                  total && total > 10 ?
                    <MuiThemeProvider theme={theme}>
                      <CssBaseline />
                      <Pagination
                        limit={this.state.size}
                        offset={this.state.offset}
                        total={total}
                        onClick={(e, offset, page) => this.handleClick(offset, page)}
                      />
                    </MuiThemeProvider>
                    : null}
              </Widget>
            </Grid>

          </Grid>
          <Dialog fullScreen open={this.state.newActivityModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddActivity
              data={this.data}
              classes={classes}
              formData={formData}
              submitted={submitted}
              handleClose={this.handleClose}

              allResources={allResources}

              handleActivityResource={this.handleActivityResource}
              handleActivityName={this.handleActivityName}
              submitActivityDetails={this.submitActivityDetails}

              addSubactivityField={this.addSubactivityField}
              handleSubActivityName={this.handleSubActivityName}
              onSubActivityName={this.onSubActivityName}
              deleteSubactivityField={this.deleteSubactivityField}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}
              activityType={activityType}

            />
          </Dialog>
          <Dialog fullScreen open={this.state.newUpdateActivityModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
            <UpdateActivity
              data={this.data}
              classes={classes}
              updateformData={updateformData}
              submitted={submitted}
              handleClose={this.handleUpdateClose}

              allResources={allResources}

              handleActivityResource={this.handleActivityResource}
              handleActivityNameUpdate={this.handleActivityNameUpdate}
              submitUpdateActivityDetails={this.submitUpdateActivityDetails}

              updateSubactivityField={this.updateSubactivityField}
              handleSubActivityNameUpdate={this.handleSubActivityNameUpdate}
              onSubActivityNameUpdate={this.onSubActivityName}
              deleteSubactivityFieldUpdate={this.deleteSubactivityFieldUpdate}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}
              activityType={activityType}
            />
          </Dialog>


        </Grid>



      </>
    );
  }
}
Activity.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, activity, resource } = state;
  return {
    loggingIn,
    users,
    activity,
    resource


  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Activity)));
