export const resourcetypeConstants = {

    RESOURCETYPE_GETALL_REQUEST: 'RESOURCETYPE_GETALL_REQUEST',
    RESOURCETYPE_GETALL_SUCCESS: 'RESOURCETYPE_GETALL_SUCCESS',
    RESOURCETYPE_GETALL_FAILURE: 'RESOURCETYPE_GETALL_FAILURE',

    ADD_RESOURCETYPE_REQUEST: 'ADD_RESOURCETYPE_REQUEST',
    ADD_RESOURCETYPE_SUCCESS: 'ADD_RESOURCETYPE_SUCCESS',
    ADD_RESOURCETYPE_FAILURE: 'ADD_RESOURCETYPE_FAILURE',
    
    UPDATE_RESOURCETYPE_REQUEST: 'UPDATE_RESOURCETYPE_REQUEST',
    UPDATE_RESOURCETYPE_SUCCESS: 'UPDATE_RESOURCETYPE_SUCCESS',
    UPDATE_RESOURCETYPE_FAILURE: 'UPDATE_RESOURCETYPE_FAILURE',

};
