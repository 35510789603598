import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
import Select2 from 'react-select';


export default function UpdateMusic(props) {

  const {
    classes, updateformData,handleFileAudio, addSlot,addDay, submitted, handleUpdateClose,
    listOfMusicCategory, handleParameterTypeName, handleUpdateSubmit,
    updatehandleChangeInput,
    updatehandleChangeInputday,
    updatehandleChangeInputslot,
    handleFile, handleUpload,imageWebLink, imageWebURL ,handleUpdateCategoryType,categoryList,audioWebLink, audioWebURL, handleUploadAudio } = props;


    console.log('imageWebURL: ',imageWebURL)
    console.log('imageWebLink: ',imageWebLink)
    console.log('updateformData.imageUrl: ',updateformData.imageUrl)



  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update New Music
            </Typography>
          <Button color="inherit" onClick={handleUpdateClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update Music
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleUpdateSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="name"
                  id="name"
                  value={updateformData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Desc*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="desc"
                  id="desc"
                  value={updateformData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="duration*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="duration"
                  id="duration"
                  value={updateformData.duration}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="category*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="category"
                  id="category"
                  value={updateformData.category}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              <Grid item xs={12}>
                                              <Select
                                                name="category"
                                                value={updateformData.category ? updateformData.category : categoryList && categoryList.length > 0 ? categoryList[0] : ''}
                                                style={{ width: '100%' }}
                                                label="Category Type"
                                                onChange={handleUpdateCategoryType}
                                                input={<Input id="category" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Category Type
                                                </MenuItem>
                                                {
                                                  categoryList && categoryList.length > 0 ?
                                                  categoryList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="points*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="points"
                  id="points"
                  value={updateformData.points}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="composer*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="composer"
                  id="composer"
                  value={updateformData.composer}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>

              
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={updateformData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse Image
                    </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
                  </Button>



            </Grid>



            {(imageWebURL && (imageWebLink === updateformData.imageLink)) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={imageWebURL} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              : 
              <Grid item xs={12} style={{ margin: '4%' }}>
              <img src={updateformData.imageUrl} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              }



      <Grid item xs={12}>
      <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Audio Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="audioLink"
                  id="audioLink"
                  value={updateformData.audioLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
       </Grid>
        <div style={{margin:'20px'}}></div>
        <Grid item xs={12}>

      <label htmlFor="audio">
        <Button color="default" variant="contained" component="span" >
          Browse Audio
            </Button>
      </label>
      <input
        style={{ display: 'none' }}
        id="audio"
        name="audio"
        type="file"
        onChange={handleFileAudio}
      />

      <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUploadAudio()}>
        Set
          </Button>



      </Grid>



      {(audioWebURL && (audioWebLink === updateformData.url)) ?
      <Grid item xs={12} style={{ margin: '4%' }}>
        <img src={audioWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
      </Grid>
      : 
      <Grid item xs={12} style={{ margin: '4%' }}>
                <audio
                  // ref="audio_tag"
                  src={updateformData.url}
                  autoPlay={false}
                  controls={true} >
                  {/* <source type="audio/mp3" src={tempdata.url} /> */}
                </audio>      
      </Grid>
      }


            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}