
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { userActions, companyActions, happinessActions, parameterActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';


import ReactSpeedometer from "react-d3-speedometer";




import { withRouter } from "react-router";
import Pagination from "material-ui-flat-pagination";
import {
  Grid,
  TextField,
  Dialog,
  Slide,
  CssBaseline,
  Button
} from "@material-ui/core";

import styles from './styles';

const theme = createMuiTheme();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class CompanyDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      newUserModal: false,
      updateUserModal: false,
      newSurveyModal: false,
      newSurveyUpdateModal: false,
      viewEmployeeDetailsModel: false,
      password: "",
      formData: {
        "email": "",
        "password": ""
      },
      updateformData: {
        "password": ""
      },
      surveyformData: {
        "name": "",
        "desc": "",
        "type": "",
        "subtypeId": "",
        "category": "BUCKET",
        "imageLink": "",
        "colorCode": ""
      },
      updatesurveyformData: {
        "name": "",
        "desc": "",
        "type": "",
        "subtypeId": "",
        "category": "BUCKET",
        "imageLink": "",
        "colorCode": ""
      },
      amount: 0,
      size: 10,
      page: 1,

      employeesize: 10,
      employeeoffset: 0,
      employeepage: 0,

      surveysize: 10,
      surveyoffset: 0,
      surveypage: 1,


      file: {},
      data: [],
      cols: [],

      selectedFile: null,
      imageWebLink: "",
      imageWebURL: "",



      crrCompanyId: this.props.match.params.id,


      levelList: [
        {
          "label": "Location",
          "name": "Location"
        },
        {
          "label": "Gender",
          "name": "gender"
        },
        {
          "label": "Department",
          "name": "Department"
        },
        {
          "label": "Country",
          "name": "country"
        }
      ],


    }
  }
  componentDidMount() {

    let data = {
      "companyId": this.state.crrCompanyId
    }

    this.props.dispatch(happinessActions.getHappyPlusEmpRespondants(data));
    this.props.dispatch(happinessActions.getHappyPlusOrgHappiness(data));


    // let data2x = {
    //   "companyId": this.state.crrCompanyId,
    //   "levelList": this.state.levelList.map((element) => (element.name))
    // }
    // this.props.dispatch(happinessActions.getHappyPlusFilterDMG(data2x));



  }




  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "email": "",
          "password": ""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.addSurveySuccess) {
      return {
        ...nextProps,
        surveyformData: {
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId": "",
          "category": "BUCKET",
          "imageLink": "",
          "colorCode": ""
        },
        updatesurveyformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId": "",
          "category": "BUCKET",
          "imageLink": "",
          "colorCode": ""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.updateSurveySuccess) {
      return {
        ...nextProps,
        updatesurveyformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId": "",
          "category": "BUCKET",
          "imageLink": "",
          "colorCode": ""
        },
        surveyformData: {
          "name": "",
          "desc": "",
          "type": "",
          "subtypeId": "",
          "category": "BUCKET",
          "imageLink": "",
          "colorCode": ""
        },
        newUserModal: false,
        newSurveyUpdateModal: false,

      }
    }
    if (nextProps.company.passwordUpdated) {

      return {
        ...nextProps,
        apikeyModal: false,
        formData: {
          "email": "",
          "password": ""
        },
        currentAPI_details: {
          "id": ""
        },
        apiformData: {
          "ip": ""
        },
        startDate: new Date(),
        password: ''
      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.uploadedImageName : null,
        imageWebURL: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.imageUrl : null,
      }
    }
    else {
      return {
        ...nextProps
      }
    }

  }

  handleClickOpenSurvey = () => {
    console.log("-------------------- 1111111 clicked : ", this.state.newSurveyModal)
    this.setState({ newSurveyModal: true });
  }
  handleCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyModal: false });

  }

  onDisable = (data) => {
    console.log(data);
    this.props.dispatch(companyActions.updateApiKeyStatus({ id: data.id }, this.props));
  }
  onDelete = (data) => {
    console.log(data);
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": this.state.surveypage,
      "size": this.state.surveysize
    }
    confirmAlert({
      title: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.key,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(companyActions.deleteApiKey({ id: data.id }, datatempsurvey))
        },
        {
          label: 'No'
        }
      ]
    });

  }
  handleChangeInputSurvey = (event) => {
    const { surveyformData } = this.state;
    surveyformData[event.target.name] = event.target.value;
    this.setState({ surveyformData });
  }

  handleSubmitSurvey = () => {
    console.log("Working....");

    let reqData = {
      "name": this.state.surveyformData.name,
      "desc": this.state.surveyformData.desc,
      "type": this.state.surveyformData.type,
      "typeName": this.state.surveyformData.typeName,
      "subtypeId": this.state.surveyformData.subtypeId,
      "subtypeName": this.state.surveyformData.subtypeName,
      "category": this.state.surveyformData.category,
      "imageLink": this.state.surveyformData.imageLink,
      "colorCode": this.state.surveyformData.colorCode
    }
    console.log("reqData  ", reqData);

    let datatempsurvey = {
      "keyWord": "",
      "pageNo": this.state.surveypage,
      "size": this.state.surveysize
    }
    console.log("this.props  ", this.props);
    // console.log("this.props  ", this.state.company.surveyTypeList[0].id);

    this.props.dispatch(companyActions.createSurvey(reqData, datatempsurvey));
    this.setState({ newSurveyModal: false });
  }

  surveyhandleClick = (surveyoffset, surveypage) => {
    // console.log(offset, page);

    this.setState({ surveyoffset, surveypage });

    let data = {
      "keyWord": this.state.surveykeyWord,
      "pageNo": surveypage,
      "size": this.state.surveysize
    }
    this.props.dispatch(companyActions.getEmployeeList(data));


  }
  handleChangesurvey = (value) => {

    let data = {
      parameterTypeId: value
    }
    this.props.dispatch(parameterActions.getParameterListbyParameterType(data));

    console.log("value  ", value);
    const { surveyformData, updatesurveyformData } = this.state;

    surveyformData['type'] = value;
    updatesurveyformData['type'] = value;

    this.setState({ surveyformData, updatesurveyformData });
  };

  handleChangesurveyCategory = (value) => {

    const { surveyformData, updatesurveyformData } = this.state;

    surveyformData['category'] = value;
    updatesurveyformData['category'] = value;

    this.setState({ surveyformData, updatesurveyformData });
  };


  handleChangesurveySubType = (value) => {


    const { surveyformData, updatesurveyformData } = this.state;


    surveyformData['subtypeId'] = value;
    updatesurveyformData['subtypeId'] = value;


    this.setState({ surveyformData, updatesurveyformData });

  };



  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getSurveyList(data));
  }


  //survey list
  onUpdateSurvey = (data) => {

    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "type": data.type,
      "typeName": data.typeName,
      "subtypeId": data.subtypeId,
      "subtypeName": data.subtypeName,
      "category": data && data.category ? data.category : "BUCKET",
      "imageLink": data.imageLink,
      "imageLinkV2": data.imageLinkV2,
      "colorCode": data.colorCode

    }
    this.setState({ newSurveyUpdateModal: true, updatesurveyformData: tempdata });
  }

  onCampaign = (data) => {
    let survey = {
      surveyId: data.id
    }
    this.props.history.push(`/app/campaign/${survey.surveyId}`)
  }


  updatehandleChangeInputSurvey = (event) => {
    const { updatesurveyformData } = this.state;
    updatesurveyformData[event.target.name] = event.target.value;
    this.setState({ updatesurveyformData });

  }



  handleUpdateSurveySubmit = () => {

    let reqData = {
      "id": this.state.updatesurveyformData.id,
      "name": this.state.updatesurveyformData.name,
      "desc": this.state.updatesurveyformData.desc,
      "type": this.state.updatesurveyformData.type,
      "typeName": this.state.updatesurveyformData.typeName,
      "subtypeId": (this.state.updatesurveyformData.typeName === 'Happiness') ? '' : this.state.updatesurveyformData.subtypeId,
      "subtypeName": (this.state.updatesurveyformData.typeName === 'Happiness') ? '' : this.state.updatesurveyformData.subtypeName,
      "category": this.state.updatesurveyformData.category,
      "imageLink": this.state.updatesurveyformData.imageLink,
      "colorCode": this.state.updatesurveyformData.colorCode
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    console.log("Final Update: ", reqData)
    this.props.dispatch(companyActions.updateSurvey(reqData, datatempsurvey));
    // this.setState({ newSurveyUpdateModal:false });
  }


  handleUpdateCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyUpdateModal: false });

  }

  onAddQuesClick = (data) => {
    this.props.history.push(`/app/addquestion/${data.id}`)
  }


  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }
  handleUpload = () => {

    const { surveyformData, updatesurveyformData, imageWebLink } = this.state;
    surveyformData['imageLink'] = imageWebLink;
    updatesurveyformData['imageLink'] = imageWebLink;
    this.setState({ surveyformData, updatesurveyformData });

  }



  render() {

    let { classes, company, happiness } = this.props;
    const { } = this.state;



    let { getHappyPlusEmpRespondantsData, getHappyPlusOrgHappinessData, getHappyPlusFilterDMGData, loading } = happiness;
    let respondants = getHappyPlusEmpRespondantsData && getHappyPlusEmpRespondantsData[0] ? getHappyPlusEmpRespondantsData : {}


    let ParticipationObj = {
      total: 0,
      completed: 0,
      mailSent: 0,
      clicked: 0,
      participated: 0,
      performance: 0,
      perfPlusSign: true
    }

    if (respondants && respondants[0]) {
      let apiData = respondants[0]
      ParticipationObj['total'] = apiData['total'] ? apiData['total'] : 0;
      ParticipationObj['active'] = apiData['active'] ? apiData['active'] : 0;
      ParticipationObj['inactive'] = apiData['inactive'] ? apiData['inactive'] : 0;


    }


    let responseOption = {
      "item1": {
        "label": "Total",
        "value": ParticipationObj['total']
      },
      "item2": {
        "label": "Active",
        "value": ParticipationObj['active']
      },
      "item3": {
        "label": "Inactive",
        "value": ParticipationObj['inactive']
      }
    }



    return (
      <>



        <Grid container >


          {/* 
          <Grid item xs={12}>
            <Widget >
            <Grid container >
                <Grid item xs={5} style={{marginLeft:'17%',marginBottom:'5%'}}/>
                
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1} style={{marginTop:'20px'}}>
                  <Button variant="contained"  color="primary" onClick={() => this.handleClickOpenSurvey()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>



            </Widget>
          </Grid> */}



          <Grid item xs={12}>



            <div className="">
              <h1 className='xl:text-2xl text-xl font-medium text-[#3D405B] '>Overview</h1>
              <div className="" >

                <div >

                  <div className="grid gap-4 my-4 lg:grid-cols-3 md:grid-cols-3">
                    <div className="flex items-center h-32 p-6 bg-white border rounded-lg cursor-pointer ">
                      <div className="flex items-center w-full ">
                        <div className="space-y-2">
                          <p className="block text-base text-[#3d405b] w-full uppercase">
                            {responseOption["item1"]["label"]}
                          </p>
                          <h1 className="text-3xl font-medium w-full text-[#3D405B]">
                            {responseOption["item1"]["value"]}
                          </h1>
                        </div>
                      </div>
                      <img src="/img/overview/ic_invited.svg" className="xl:w-20 w-14 " />
                    </div>

                    <div className="flex items-center h-32 p-6 bg-white border rounded-lg cursor-pointer ">
                      <div className="flex items-center w-full ">
                        <div className="space-y-2">
                          <p className="block text-base text-[#3d405b] w-full uppercase">
                            {responseOption["item2"]["label"]}
                          </p>
                          <h1 className="text-3xl font-medium w-full text-[#3D405B]">
                            {responseOption["item2"]["value"]}
                          </h1>

                        </div>
                      </div>

                      <img src="/img/overview/ic_responded.svg" className="xl:w-20 w-14 " />
                    </div>

                    <div className="flex items-center h-32 p-6 bg-white border rounded-lg cursor-pointer ">
                      <div className="flex items-center w-full ">
                        <div className="space-y-2">
                          <p className="block text-base text-[#3d405b] w-full uppercase">
                            {responseOption["item3"]["label"]}
                          </p>
                          <h1 className="text-3xl font-medium w-full text-[#3D405B]">
                            {responseOption["item3"]["value"]}
                          </h1>

                        </div>
                      </div>
                      <img src="/img/overview/ic_response_ratio.svg" className="xl:w-20 w-14 " />
                    </div>
                  </div>

                </div>



                <LoadingOverlay
                  active={loading}
                  // active={false}

                  spinner
                  text='Loading Data...'
                  className="loader"
                >


                  {getHappyPlusOrgHappinessData && getHappyPlusOrgHappinessData.overallScore ?
                    <>
                      <h1 className="py-2 font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Summary</h1>

                      <div className="grid gap-4 lg:grid-cols-1 md:grid-cols-1 lg:gap-6">


                        <div className="p-4 bg-white border rounded-lg cursor-pointer pb-12">
                          <div className="">
                            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap truncate">
                              Overall Happiness Score
                            </h1>
                          </div>

                          <div className="flex justify-center w-full">
                            <div className="h-48 px-2 mt-2 mb-4 md:mt-10"
                            >
                              {getHappyPlusOrgHappinessData && getHappyPlusOrgHappinessData.overallScore ?
                                <ReactSpeedometer className="meter"
                                  value={getHappyPlusOrgHappinessData.overallScore.toFixed(2)}
                                  minValue={0}
                                  maxValue={72}
                                  needleColor="#4db8ff"
                                  segments={4}
                                  segmentColors={[
                                    "#e53935",
                                    "#ffc107",
                                    "#00cd7e",
                                    "#ff4081",
                                  ]}
                                  ringWidth={35}
                                  customSegmentStops={[0, 48, 60, 66, 72]}
                                  needleTransitionDuration={3333}
                                  needleTransition="easeElastic"
                                />
                                : null}

                            </div>

                          </div>

                          <div className="flex items-center justify-center pb-4 text-white md:px-2 ">
                            <span className="px-2 py-2 text-sm bg-red-500 border-r border-white rounded-l-lg md:px-4 lg:text-md text-12">
                              {"Unhappy"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-yellow-400 border-r border-white lg:px-4 lg:text-md text-12">
                              {"Moderate"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-green-400 border-r border-white lg:px-4 lg:text-md text-12">
                              {"Happier"}
                            </span>
                            <span className="px-2 py-2 text-sm bg-pink-500 rounded-r-lg lg:px-4 lg:text-md text-12 ">
                              {"Happiest"}
                            </span>
                          </div>
                        </div>


                      </div>
                    </>
                    :
                    <>
                      <div className='pt-24 text-3xl font-medium text-center text-gray-400'>Data not available!</div>
                    </>}


                </LoadingOverlay>


              </div>

            </div>


          </Grid>






        </Grid>


      </>
    );

  }
}
function mapStateToProps(state) {
  const { company, users, parametertype, parameter, user, happiness } = state;
  return {
    company, users, parametertype, parameter, user, happiness
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(CompanyDetails)));
