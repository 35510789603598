
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const expertcategoryService = {
    disableExpertCategory,
    logout,
    createNewExpertCategory,
    updateExpertCategory,
    getAllExpertCategory,
    getAllExpertCategoryList,
    deleteExpertCategory
};
function logout() {
    localStorage.removeItem('adminexpertcategory');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllExpertCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getExpertCategoryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let expertcategoryObj = {
                listOfExpertCategorys: data.data
            }
            console.log();
            return expertcategoryObj;
        });
}
function getAllExpertCategoryList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllExpertCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let expertcategoryObj = {
                listOfExpertCategory: data.data
            }
            console.log();
            return expertcategoryObj;
        });
}

function createNewExpertCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createExpertCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertcategoryObj = {
                createNewExpertCategory: data.data
            }
            console.log();

            return expertcategoryObj;
        });
}
function updateExpertCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpertCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertcategoryObj = {
                updateNewExpertCategory: data.data
            }
            console.log(expertcategoryObj);

            return expertcategoryObj;
        });
}

function deleteExpertCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteExpertCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertcategoryObj = {
                deleteNewExpertCategory: data.data
            }
            console.log(expertcategoryObj);

            return expertcategoryObj;
        });
}

function disableExpertCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpertCategoryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertcategoryObj = {
                deleteExpertCategory: data.data
            }
            console.log();

            return expertcategoryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}