export const expertcategoryConstants = {

    EXPERTCATEGORY_GETALL_REQUEST: 'EXPERTCATEGORY_GETALL_REQUEST',
    EXPERTCATEGORY_GETALL_SUCCESS: 'EXPERTCATEGORY_GETALL_SUCCESS',
    EXPERTCATEGORY_GETALL_FAILURE: 'EXPERTCATEGORY_GETALL_FAILURE',

    EXPERTCATEGORY_GETALL_LIST_REQUEST: 'EXPERTCATEGORY_GETALL_LIST_REQUEST',
    EXPERTCATEGORY_GETALL_LIST_SUCCESS: 'EXPERTCATEGORY_GETALL_LIST_SUCCESS',
    EXPERTCATEGORY_GETALL_LIST_FAILURE: 'EXPERTCATEGORY_GETALL_LIST_FAILURE',

    ADD_EXPERTCATEGORY_REQUEST: 'ADD_EXPERTCATEGORY_REQUEST',
    ADD_EXPERTCATEGORY_SUCCESS: 'ADD_EXPERTCATEGORY_SUCCESS',
    ADD_EXPERTCATEGORY_FAILURE: 'ADD_EXPERTCATEGORY_FAILURE',
    
    UPDATE_EXPERTCATEGORY_REQUEST: 'UPDATE_EXPERTCATEGORY_REQUEST',
    UPDATE_EXPERTCATEGORY_SUCCESS: 'UPDATE_EXPERTCATEGORY_SUCCESS',
    UPDATE_EXPERTCATEGORY_FAILURE: 'UPDATE_EXPERTCATEGORY_FAILURE',

    DELETE_EXPERTCATEGORY_REQUEST: 'DELETE_EXPERTCATEGORY_REQUEST',
    DELETE_EXPERTCATEGORY_SUCCESS: 'DELETE_EXPERTCATEGORY_SUCCESS',
    DELETE_EXPERTCATEGORY_FAILURE: 'DELETE_EXPERTCATEGORY_FAILURE',

};
