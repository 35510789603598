
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const enquiryService = {
    disableEnquiry,
    logout,
    createNewEnquiry,
    updateEnquiry,
    getAllEnquiry,
    deleteEnquiry
};
function logout() {
    localStorage.removeItem('adminenquiry');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEnqiryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let enquiryObj = {
                listOfEnquirys: data.data
            }
            console.log();
            return enquiryObj;
        });
}

function createNewEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let enquiryObj = {
                createNewEnquiry: data.data
            }
            console.log();

            return enquiryObj;
        });
}
function updateEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let enquiryObj = {
                updateNewEnquiry: data.data
            }
            console.log(enquiryObj);

            return enquiryObj;
        });
}

function deleteEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let enquiryObj = {
                deleteNewEnquiry: data.data
            }
            console.log(enquiryObj);

            return enquiryObj;
        });
}

function disableEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateEnquiryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let enquiryObj = {
                deleteEnquiry: data.data
            }
            console.log();

            return enquiryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}