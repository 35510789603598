import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
// import { Button } from "../../../../components/Wrappers";
import moment from 'moment';


export default function TableComponent(props) {
  //onDeleteonRowClick,
  let { data, offset } = props;
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >NAME</TableCell>
          <TableCell >EMAIL</TableCell>
          <TableCell >MESSAGE</TableCell>
          <TableCell >MOBILE</TableCell>
          <TableCell >CreatedAt</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.email}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.message}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.mobile}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>

            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
