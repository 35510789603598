import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Select,
  Input,
  MenuItem,
  Toolbar,
  IconButton,
  Container,
  TextField
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function AddUser(props) {

  const { classes, surveyformData,submitted, handleCloseSurvey, 
    handleSubmitSurvey, handleChangeInputSurvey,handleChangesurvey, 
    handleChangesurveySubType,parameterTypesList,allParametersListByParameterTypeData, categoryList,
    handleChangesurveyCategory, handleFile, handleUpload, imageWebURL } = props;

  return (
    <div >
         <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleCloseSurvey} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add New Survey
            </Typography>
              <Button color="inherit" onClick={handleCloseSurvey}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Add Survey
              </Typography>

              <ValidatorForm
                // ref="form"
                onSubmit={handleSubmitSurvey}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                    <TextValidator
                      label="Name*"
                      fullWidth
                      onChange={handleChangeInputSurvey}
                      name="name"
                      id="name"
                      value={surveyformData.name}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Description*"
                      id="desc"
                      fullWidth
                      onChange={handleChangeInputSurvey}
                      name="desc"
                      value={surveyformData.desc}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12}>

                  <Select
                    name="category"
                    value={surveyformData.category ? surveyformData.category : categoryList && categoryList.length > 0 ? categoryList[0] : ''}
                    style={{ width: '100%' }}
                    onChange={event => handleChangesurveyCategory(event.target.value)}
                    input={<Input id="category" />}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Survey Category
                    </MenuItem>
                    {
                      categoryList && categoryList.length > 0 ?
                      categoryList.map((tempdata, index) => (
                          <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                        )) : null
                    }

                  </Select>
                  </Grid>


            

                  <Grid item xs={12} sm={12}>

                    <Select
                      name="type"
                      value={surveyformData.type ? surveyformData.type : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                      style={{ width: '100%' }}
                      onChange={event => handleChangesurvey(event.target.value)}
                      input={<Input id="type" />}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>
                        Survey Type
                      </MenuItem>
                      {
                        parameterTypesList && parameterTypesList.length > 0 ?
                        parameterTypesList.map((tempdata, index) => (
                            <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                          )) : null
                      }

                    </Select>
                  </Grid>


{/*                   
                  {!(surveyformData && surveyformData.category && surveyformData.category==="TEST")?
                    <>
         */}
                <Grid item xs={12} sm={12}>

                  <Select
                    name="subtypeId"
                    value={surveyformData.subtypeId ? surveyformData.subtypeId : allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ? allParametersListByParameterTypeData[0].id : ''}
                    style={{ width: '100%' }}
                    onChange={event => handleChangesurveySubType(event.target.value)}
                    input={<Input id="subtypeId" />}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Survey SubType
                    </MenuItem>
                    {
                      allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ?
                      allParametersListByParameterTypeData.map((tempdata, index) => (
                          <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                        )) : null
                    }

                  </Select>

                </Grid>


                {/* </>
                :null} */}



                <Grid item xs={12} sm={12}>

                <TextValidator
                  label="Color Code*"
                  fullWidth
                  onChange={handleChangeInputSurvey}
                  name="colorCode"
                  id="colorCode"
                  value={surveyformData.colorCode}
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                />
                </Grid>



              <Grid item xs={12}>
                <TextField
                  label="Image Link"
                  id="imageLink"
                  name="imageLink"
                  fullWidth
                  rowsMax={4}
                  placeholder="Image Link"
                  value={surveyformData.imageLink}
                  onChange={handleChangeInputSurvey}
                />
              </Grid>

                <Grid item xs={12}>

                  <label htmlFor="image">
                    <Button color="default" variant="contained" component="span" >
                      Browse
                            </Button>
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />

                  <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                    Set
                  </Button>

                </Grid>

                {(imageWebURL) ?
                    <Grid item xs={12} style={{ margin: '4%' }}>
                      <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                    </Grid>
                :null}




  



                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                >
                  Save
                </Button>
              </ValidatorForm>
            </div>
          </Container>


    </div>
  );}