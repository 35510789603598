import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { premiummusicActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddPremiumMusic from './Modal/AddPremiumMusic';
import UpdatePremiumMusic from './Modal/UpdatePremiumMusic';

import { userActions } from '../../_actions/user.actions'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class PremiumMusic extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newPremiumMusicModal: false,
      newUpdatePremiumMusicModal: false,
      submitted: false,
      selectedFile: null,
      selectedFileMusic:null,

      imageWebLink: "",
      imageWebURL: "",

      musicWebLink: "",
      musicWebURL: "",

      categoryList:["premiummusic","podcast"],
      formData: {
        "name": "",
        "desc": "",
        "genere": "",
        "duration": "",
        "imageLink": "",
        "musicLink": "",
        "category":"premiummusic",
        "points":"",
        "composer":""
      },
      updateformData: {
        "name": "",
        "desc": "",
        "genere": "",
        "duration": "",
        "imageLink": "",
        "musicLink": "",
        "category":"premiummusic",
        "points":"",
        "composer":""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    //console.log(data);

    this.props.dispatch(premiummusicActions.getPremiumMusicList(data));
    // this.props.dispatch(premiummusiccategoryActions.getAllPremiumMusicCategoryList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // //console.log('nextProps',nextProps.users)musicUploadSuccess


    if (nextProps.users.musicUploadSuccess) {
      
      return {
        ...nextProps,
     
        musicWebLink: (nextProps.users && nextProps.users.musicAudioDetails && nextProps.users.musicAudioDetails.uploadedImageName) ? nextProps.users.musicAudioDetails.uploadedImageName : null,
        musicWebURL: (nextProps.users && nextProps.users.musicAudioDetails && nextProps.users.musicAudioDetails.uploadedImageName) ? nextProps.users.musicAudioDetails.imageUrl : null,
              
      }
    }
    if (nextProps.premiummusic.addPremiumMusicSuccessTemp) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "genere": "",
          "duration": "",
          "imageLink": "",
          "musicLink": "",
          "category":"premiummusic",
          "points":"",
          "composer":""
        },
        newPremiumMusicModal: false,
      }
    }
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.uploadedImageName : null,
        imageWebURL: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.imageUrl : null,
        

        // musicWebLink: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.uploadedImageName : null,
        // musicWebURL: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.imageUrl : null,
        
      }
    }


    if (nextProps.premiummusic.deletePremiumMusicSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "points":"",
          "genere": "",
          "duration": "",
          "imageLink": "",
          "musicLink": "",
          "category":"premiummusic",
          "composer":""
        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "points":"",
          "genere": "",
          "duration": "",
          "imageLink": "",
          "musicLink": "",
          "category":"premiummusic",
          "composer":""
        }
      }
    }

    if (nextProps.premiummusic.updatePremiumMusicSuccess) {
      return {
        ...nextProps,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "genere": "",
          "duration": "",
          "imageLink": "",
          "musicLink": "",
          "category":"premiummusic",
          "points":"",
          "composer":""
        },
        newUpdatePremiumMusicModal: false,
      }
    }

    if (nextProps.premiummusic.addPremiumMusicSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "genere": "",
          "duration": "",
          "imageLink": "",
          "musicLink": "",
          "category":"premiummusic",
          "points":"",
          "composer":""
        },
        newPremiumMusicModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    //console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(premiummusicActions.getAllPremiumMusic(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(premiummusicActions.getPremiumMusicList(data));
  }
  handleClickOpen = () => {
    this.setState({ newPremiumMusicModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newPremiumMusicModal: false });
    this.props.dispatch(userActions.uploadImageClear());
    this.props.dispatch(userActions.uploadAudioClear());


  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleChangeSwitch = (event) => {
    const { formData,updateformData } = this.state;
    formData["isPremium"] = event.target.checked;
    updateformData["isPremium"] = event.target.checked;
    this.setState({ formData,updateformData });
    //console.log(event.target.checked,"  formData  ",formData);
  }
  handleSubmit = () => {

    let reqData = {
      "name": this.state.formData.name,
      "desc": this.state.formData.desc,
      "genere": this.state.formData.genere,
      "points": this.state.formData.points,
      "isPremium": this.state.formData.isPremium,
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.formData.imageLink,
      "musicLink": (this.state.musicWebLink) ? this.state.musicWebLink : this.state.formData.musicLink,
    }
    //console.log("this.state.formData  ", this.state.formData);
    this.props.dispatch(premiummusicActions.createPremiumMusic(reqData));
    this.setState({ imageWebLink: "" });

  }


  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(premiummusicActions.deletePremiumMusic({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }


  onRowClick = (data) => {
    this.props.history.push(`/app/premiummusicdetails/${data.id}`)
  }
  onDisable = (data) => {
    //console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    //console.log("asdf :: ", tempdata);
    this.props.dispatch(premiummusicActions.disablePremiumMusic(tempdata));
  }
  onUpdate = (data) => {
    //console.log("asdsadadadasd data",data);
    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "genere": data.genere,
      "duration": data.duration,
      "isPremium": data.isPremium,
      "imageLink": data.imageLink,
      "musicLink": data.musicLink,
      "category":data.category,
      "points":data.points?parseInt(data.points):0,
      "composer":data.composer,
      "imageUrl":data.imageUrl,
      "url":data.url
    }


    //console.log("tempdata", tempdata)
    this.setState({ newUpdatePremiumMusicModal: true, updateformData: tempdata });
  }
  addSlot = (index) => {
    // let temp = {
    //   "day": "",
    //   "template": 1,
    //   "slot": [""]
    // }
    const { updateformData } = this.state;
    updateformData['timing'][index]['slot'].push('');
    this.setState({ updateformData });
  }
  addDay = (data) => {
    let temp = {
      "day": "",
      "template": 1,
      "slot": [""]
    }
    const { updateformData } = this.state;
    updateformData['timing'].push(temp);
    this.setState({ updateformData });
  }
  updatehandleChangeInput = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputday = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    // //console.log("event.target  ",event.target);
    let { id } = event.target;
    updateformData['timing'][id]['day'] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputslot = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    let { id, name, value } = event.target;
    // //console.log(event.target);
    updateformData['timing'][id]['slot'][name] = value;
    this.setState({ updateformData });
  }


  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id": this.state.updateformData.id,
      "name": this.state.updateformData.name,
      "desc": this.state.updateformData.desc,
      "genere": this.state.updateformData.genere,
      "points": this.state.updateformData.points,
      "isPremium": this.state.updateformData.isPremium,
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.imageLink,
      "musicLink": (this.state.musicWebLink) ? this.state.musicWebLink : this.state.updateformData.musicLink,


      // "id": this.state.updateformData.id,
      // "name": this.state.updateformData.name,
      // "desc": this.state.updateformData.desc,
      // "category": this.state.updateformData.category,
      // "isPremium": this.state.updateformData.isPremium,
      // "duration": this.state.updateformData.duration,
      // "points": this.state.updateformData.points?parseInt(this.state.updateformData.points):0,
      // "composer": this.state.updateformData.composer,
      // "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.imageLink,
      // "musicLink": (this.state.musicWebLink) ? this.state.musicWebLink : this.state.updateformData.musicLink,

    }
    //console.log("-----musicWebLink-----",this.state.musicWebLink)
    //console.log(reqData)
    this.props.dispatch(premiummusicActions.updatePremiumMusic(reqData, tempdata));

    let temnpupdateformData= {
      "id": "",
      "name": "",
      "desc": "",
      "genere": "",
      "points":"",
      "duration": "",
      "imageLink": "",
      "musicLink": "",
      "category":"premiummusic",
      "composer":""
    }


    this.setState({ imageWebLink: "", updateformData:temnpupdateformData , newUpdatePremiumMusicModal:false});

  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdatePremiumMusicModal: false });
    this.props.dispatch(userActions.uploadImageClear());

  }


  handleFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      //console.log("No File To Upload!")
    }
  }

  handleFileMusic = (event) => {
    this.setState({ selectedFileMusic: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadMusic(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      //console.log("No File To Upload!")
    }
  }

  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['imageLink'] = imageWebLink;
    updateformData['imageLink'] = imageWebLink;
    this.setState({ formData, updateformData });

  }

  handleUploadMusic = () => {

    const { formData, updateformData, musicWebLink } = this.state;
    formData['musicLink'] = musicWebLink;
    updateformData['musicLink'] = musicWebLink;
    this.setState({ formData, updateformData });

  }
  handleCategoryType=(event)=>{
    let {formData} = this.state;
    formData['category']=event.target.value;
    this.setState({formData});
  }
  handleUpdateCategoryType=(event)=>{
        //console.log("listOfPremiumMusicCategory: ",event.target.value)

    let {updateformData} = this.state;
    updateformData['category']=event.target.value;
    this.setState({updateformData});
  }

  // handleParameterTypeName=(event)=>{
  //   const { formData, updateformData } = this.state;
  //   formData[event.target.name] = event.target.value;
  //   updateformData[event.target.name] = event.target.value;
  //   this.setState({formData})
  //   this.setState({updateformData})
  //   // const { listOfPremiumMusicCategory } = this.props;
  //   // //console.log("listOfPremiumMusicCategory: ",listOfPremiumMusicCategory)
  // }


  handleParameterTypeName = (event, val) => {
    //console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.premiummusicCategoryId.push(val.option.id);
      if (updateformData && updateformData.premiummusicCategoryId) {
        updateformData.premiummusicCategoryId.push(val.option.id)
      }
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.premiummusicCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.premiummusicCategoryId.splice(delIndex, 1);
        }
      })

      updateformData.premiummusicCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.premiummusicCategoryId.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }
  }

  render() {

    let { users, premiummusic, classes } = this.props;
    let { items, total } = premiummusic;
    const { formData, updateformData, submitted, listOfPremiumMusicCategory } = this.state;

    if (this.state) {
      //console.log("----updateformdata----", this.state);
    }

    let receivedImage = null;
    if (users.filesDetails) {
      //console.log("filesDetails: ", users.filesDetails)
      //console.log("filesMusicDetails: ", users.filesMusicDetails)

      receivedImage = users.filesDetails;
    }

    //console.log("-->imageweblink: ", this.state.imageWebLink)
    //console.log("-->musicWebLink",this.state.musicWebLink)

    return (
      <>
        <PageTitle title="PremiumMusic List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField style={{ paddingTop: '10px' }}
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newPremiumMusicModal} onClose={this.handleClose} TransitionComponent={Transition}>
          <AddPremiumMusic
            data={this.data}
            classes={classes}
            formData={formData}
            submitted={submitted}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            handleChangeInput={this.handleChangeInput}
            handleParameterTypeName={this.handleParameterTypeName}

            categoryList={this.state.categoryList}
            handleCategoryType={this.handleCategoryType}
            handleChangeSwitch={this.handleChangeSwitch}


            handleFile={this.handleFile}
            handleFileMusic={this.handleFileMusic}
            handleUploadMusic={this.handleUploadMusic}
            handleUpload={this.handleUpload}
            receivedImage={receivedImage}
            listOfPremiumMusicCategory={listOfPremiumMusicCategory}
            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

          />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdatePremiumMusicModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
          <UpdatePremiumMusic
            data={this.data}
            classes={classes}
            updateformData={updateformData}
            handleUpdateClose={this.handleUpdateClose}
            handleUpdateSubmit={this.handleUpdateSubmit}
            handleParameterTypeName={this.handleParameterTypeName}
            updatehandleChangeInput={this.updatehandleChangeInput}
            updatehandleChangeInputday={this.updatehandleChangeInputday}
            updatehandleChangeInputslot={this.updatehandleChangeInputslot}
            handleUpdateCategoryType={this.handleUpdateCategoryType}
            handleChangeSwitch={this.handleChangeSwitch}

            handleFile={this.handleFile}
            handleFileMusic={this.handleFileMusic}
            handleUpload={this.handleUpload}
            handleUploadMusic={this.handleUploadMusic}
            addSlot={this.addSlot}
            addDay={this.addDay}
            receivedImage={receivedImage}
            listOfPremiumMusicCategory={listOfPremiumMusicCategory}

            categoryList={this.state.categoryList}

            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

            musicWebLink={this.state.musicWebLink} 
            musicWebURL={this.state.musicWebURL}

          />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { users, premiummusic } = state;
  return {
    // listOfPremiumMusicCategory: premiummusiccategory.listOfPremiumMusicCategory ? premiummusiccategory.listOfPremiumMusicCategory : [],
    users,
    premiummusic
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(PremiumMusic)));
