import {
  musicConstants
} from '../_constants';

export function music(state = {}, action) {

  switch (action.type) {
    case musicConstants.MUSIC_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case musicConstants.MUSIC_GETALL_SUCCESS:
      return {
        ...state,
        addMusicSuccess: false,
        updateMusicSuccess:false,
        deleteMusicSuccess: false,

          MusicList: action.music.listOfMusics.list,
          items: action.music.listOfMusics.list,
          total: action.music.listOfMusics.total
      };
    case musicConstants.MUSIC_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case musicConstants.ADD_MUSIC_REQUEST:
      return {
        ...state
      };
    case musicConstants.ADD_MUSIC_SUCCESS:
      return {
        ...state,
        addMusicSuccess: true
      };
    case musicConstants.ADD_MUSIC_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case musicConstants.UPDATE_MUSIC_REQUEST:
      return {
        ...state
      };
    case musicConstants.UPDATE_MUSIC_SUCCESS:
      return {
        ...state,
        updateMusicSuccess: true
      };
    case musicConstants.UPDATE_MUSIC_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case musicConstants.DELETE_MUSIC_REQUEST:
        return {
          ...state
        };
      case musicConstants.DELETE_MUSIC_SUCCESS:
        return {
          ...state,
          deleteMusicSuccess: true
        };
      case musicConstants.DELETE_MUSIC_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}