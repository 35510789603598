import {
  expertenqiryConstants
} from '../_constants';

export function expertenqiry(state = {}, action) {

  switch (action.type) {
    case expertenqiryConstants.EXPERTENQIRY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case expertenqiryConstants.EXPERTENQIRY_GETALL_SUCCESS:
      return {
        ...state,
        addExpertEnqirySuccess: false,
        updateExpertEnqirySuccess:false,
        deleteExpertEnqirySuccess: false,

          ExpertEnqiryList: action.expertenqiry.listOfExpertEnqirys.list,
          items: action.expertenqiry.listOfExpertEnqirys.list,
          total: action.expertenqiry.listOfExpertEnqirys.total
      };
    case expertenqiryConstants.EXPERTENQIRY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case expertenqiryConstants.ADD_EXPERTENQIRY_REQUEST:
      return {
        ...state
      };
    case expertenqiryConstants.ADD_EXPERTENQIRY_SUCCESS:
      return {
        ...state,
        addExpertEnqirySuccess: true
      };
    case expertenqiryConstants.ADD_EXPERTENQIRY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case expertenqiryConstants.UPDATE_EXPERTENQIRY_REQUEST:
      return {
        ...state
      };
    case expertenqiryConstants.UPDATE_EXPERTENQIRY_SUCCESS:
      return {
        ...state,
        updateExpertEnqirySuccess: true
      };
    case expertenqiryConstants.UPDATE_EXPERTENQIRY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case expertenqiryConstants.DELETE_EXPERTENQIRY_REQUEST:
        return {
          ...state
        };
      case expertenqiryConstants.DELETE_EXPERTENQIRY_SUCCESS:
        return {
          ...state,
          deleteExpertEnqirySuccess: true
        };
      case expertenqiryConstants.DELETE_EXPERTENQIRY_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}