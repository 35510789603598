import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { musicActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddMusic from './Modal/AddMusic';
import UpdateMusic from './Modal/UpdateMusic';

import { userActions } from '../../_actions/user.actions'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class Music extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newMusicModal: false,
      newUpdateMusicModal: false,
      submitted: false,
      selectedFile: null,
      selectedFileAudio:null,

      imageWebLink: "",
      imageWebURL: "",

      audioWebLink: "",
      audioWebURL: "",

      categoryList:["music","podcast"],
      formData: {
        "name": "",
        "desc": "",
        "duration": "",
        "imageLink": "",
        "audioLink": "",
        "category":"music",
        "points":"",
        "composer":""
      },
      updateformData: {
        "name": "",
        "desc": "",
        "duration": "",
        "imageLink": "",
        "audioLink": "",
        "category":"music",
        "points":"",
        "composer":""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(musicActions.getAllMusic(data));
    // this.props.dispatch(musiccategoryActions.getAllMusicCategoryList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('nextProps',nextProps.users)

    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.uploadedImageName : null,
        imageWebURL: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.imageUrl : null,
        

        // audioWebLink: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.uploadedImageName : null,
        // audioWebURL: (nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName) ? nextProps.users.filesDetails.imageUrl : null,
        
      }
    }
    if (nextProps.users.audioUploadSuccess) {
      
      return {
        ...nextProps,
        audioWebLink: (nextProps.users && nextProps.users.filesAudioDetails && nextProps.users.filesAudioDetails.uploadedImageName) ? nextProps.users.filesAudioDetails.uploadedImageName : null,
        audioWebURL: (nextProps.users && nextProps.users.filesAudioDetails && nextProps.users.filesAudioDetails.uploadedImageName) ? nextProps.users.filesAudioDetails.imageUrl : null,
        
      
      }
    }

    if (nextProps.music.deleteMusicSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "duration": "",
          "imageLink": "",
          "audioLink": "",
          "category":"music",
          "points":"",
          "composer":""
        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "duration": "",
          "imageLink": "",
          "audioLink": "",
          "category":"music",
          "points":"",
          "composer":""
        }
      }
    }

    if (nextProps.music.updateMusicSuccess) {
      return {
        ...nextProps,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "duration": "",
          "imageLink": "",
          "audioLink": "",
          "category":"music",
          "points":"",
          "composer":""
        },
        newUpdateMusicModal: false,
      }
    }

    if (nextProps.music.addMusicSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "duration": "",
          "imageLink": "",
          "audioLink": "",
          "category":"music",
          "points":"",
          "composer":""
        },
        newMusicModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(musicActions.getAllMusic(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(musicActions.getAllMusic(data));
  }
  handleClickOpen = () => {
    this.setState({ newMusicModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newMusicModal: false });
    this.props.dispatch(userActions.uploadImageClear());
    this.props.dispatch(userActions.uploadAudioClear());


  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    let reqData = {
      "name": this.state.formData.name,
      "desc": this.state.formData.desc,
      "duration": this.state.formData.duration,
      "category": this.state.formData.category,
      "points": this.state.formData.points?parseInt(this.state.formData.points):0,
      "composer": this.state.formData.composer,
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.formData.imageLink,
      "audioLink": (this.state.audioWebLink) ? this.state.audioWebLink : this.state.formData.audioLink,
    }
    // console.log("this.state.formData  ", this.state.formData);
    this.props.dispatch(musicActions.createNewMusic(reqData));
    this.setState({ imageWebLink: "" });

  }


  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(musicActions.deleteMusic({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }


  onRowClick = (data) => {
    this.props.history.push(`/app/musicdetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(musicActions.disableMusic(tempdata));
  }
  onUpdate = (data) => {
    console.log("asdsadadadasd data",data);
    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "duration": data.duration,
      "imageLink": data.imageLink,
      "audioLink": data.audioLink,
      "category":data.category,
      "points":data.points?parseInt(data.points):0,
      "composer":data.composer,
      "imageUrl":data.imageUrl,
      "url":data.url
    }


    console.log("tempdata", tempdata)
    this.setState({ newUpdateMusicModal: true, updateformData: tempdata });
  }
  addSlot = (index) => {
    // let temp = {
    //   "day": "",
    //   "template": 1,
    //   "slot": [""]
    // }
    const { updateformData } = this.state;
    updateformData['timing'][index]['slot'].push('');
    this.setState({ updateformData });
  }
  addDay = (data) => {
    let temp = {
      "day": "",
      "template": 1,
      "slot": [""]
    }
    const { updateformData } = this.state;
    updateformData['timing'].push(temp);
    this.setState({ updateformData });
  }
  updatehandleChangeInput = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputday = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    // console.log("event.target  ",event.target);
    let { id } = event.target;
    updateformData['timing'][id]['day'] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputslot = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    let { id, name, value } = event.target;
    // console.log(event.target);
    updateformData['timing'][id]['slot'][name] = value;
    this.setState({ updateformData });
  }


  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id": this.state.updateformData.id,
      "name": this.state.updateformData.name,
      "desc": this.state.updateformData.desc,
      "category": this.state.updateformData.category,
      "duration": this.state.updateformData.duration,
      "points": this.state.updateformData.points?parseInt(this.state.updateformData.points):0,
      "composer": this.state.updateformData.composer,
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.imageLink,
      "audioLink": (this.state.audioWebLink) ? this.state.audioWebLink : this.state.updateformData.audioLink,

    }
    console.log("-----audioWebLink-----",this.state.audioWebLink)
    console.log(reqData)
    this.props.dispatch(musicActions.updateMusic(reqData, tempdata));

    let temnpupdateformData= {
      "id": "",
      "name": "",
      "desc": "",
      "duration": "",
      "imageLink": "",
      "audioLink": "",
      "category":"music",
      "points":"",
      "composer":""
    }


    this.setState({ imageWebLink: "", updateformData:temnpupdateformData , newUpdateMusicModal:false});

  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateMusicModal: false });
    this.props.dispatch(userActions.uploadImageClear());

  }


  handleFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }

  handleFileAudio = (event) => {
    this.setState({ selectedFileAudio: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadAudio(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }

  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['imageLink'] = imageWebLink;
    updateformData['imageLink'] = imageWebLink;
    this.setState({ formData, updateformData });

  }

  handleUploadAudio = () => {

    const { formData, updateformData, audioWebLink } = this.state;
    formData['audioLink'] = audioWebLink;
    updateformData['audioLink'] = audioWebLink;
    this.setState({ formData, updateformData });

  }
  handleCategoryType=(event)=>{
    let {formData} = this.state;
    formData['category']=event.target.value;
    this.setState({formData});
  }
  handleUpdateCategoryType=(event)=>{
        console.log("listOfMusicCategory: ",event.target.value)

    let {updateformData} = this.state;
    updateformData['category']=event.target.value;
    this.setState({updateformData});
  }

  // handleParameterTypeName=(event)=>{
  //   const { formData, updateformData } = this.state;
  //   formData[event.target.name] = event.target.value;
  //   updateformData[event.target.name] = event.target.value;
  //   this.setState({formData})
  //   this.setState({updateformData})
  //   // const { listOfMusicCategory } = this.props;
  //   // console.log("listOfMusicCategory: ",listOfMusicCategory)
  // }


  handleParameterTypeName = (event, val) => {
    console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.musicCategoryId.push(val.option.id);
      if (updateformData && updateformData.musicCategoryId) {
        updateformData.musicCategoryId.push(val.option.id)
      }
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.musicCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.musicCategoryId.splice(delIndex, 1);
        }
      })

      updateformData.musicCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.musicCategoryId.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }
  }

  render() {

    let { users, music, classes } = this.props;
    let { items, total } = music;
    const { formData, updateformData, submitted, listOfMusicCategory } = this.state;

    if (this.state) {
      console.log("----updateformdata----", this.state);
    }

    let receivedImage = null;
    if (users.filesDetails) {
      console.log("filesDetails: ", users.filesDetails)
      console.log("filesAudioDetails: ", users.filesAudioDetails)

      receivedImage = users.filesDetails;
    }

    console.log("-->imageweblink: ", this.state.imageWebLink)
    console.log("-->audioWebLink",this.state.audioWebLink)


    console.log("----updateformdata----", this.state.formData);

    console.log("----updateformdata----", this.state.updateformData);

    return (
      <>
        <PageTitle title="Music List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newMusicModal} onClose={this.handleClose} TransitionComponent={Transition}>
          <AddMusic
            data={this.data}
            classes={classes}
            formData={formData}
            submitted={submitted}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            handleChangeInput={this.handleChangeInput}
            handleParameterTypeName={this.handleParameterTypeName}

            categoryList={this.state.categoryList}
            handleCategoryType={this.handleCategoryType}


            handleFile={this.handleFile}
            handleFileAudio={this.handleFileAudio}
            handleUploadAudio={this.handleUploadAudio}
            handleUpload={this.handleUpload}
            receivedImage={receivedImage}
            listOfMusicCategory={listOfMusicCategory}
            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

            
            audioWebLink={this.state.audioWebLink} 
            audioWebURL={this.state.audioWebURL}

          />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateMusicModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
          <UpdateMusic
            data={this.data}
            classes={classes}
            updateformData={updateformData}
            handleUpdateClose={this.handleUpdateClose}
            handleUpdateSubmit={this.handleUpdateSubmit}
            handleParameterTypeName={this.handleParameterTypeName}
            updatehandleChangeInput={this.updatehandleChangeInput}
            updatehandleChangeInputday={this.updatehandleChangeInputday}
            updatehandleChangeInputslot={this.updatehandleChangeInputslot}
            handleUpdateCategoryType={this.handleUpdateCategoryType}

            handleFile={this.handleFile}
            handleFileAudio={this.handleFileAudio}
            handleUpload={this.handleUpload}
            handleUploadAudio={this.handleUploadAudio}
            addSlot={this.addSlot}
            addDay={this.addDay}
            receivedImage={receivedImage}
            listOfMusicCategory={listOfMusicCategory}

            categoryList={this.state.categoryList}

            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

            audioWebLink={this.state.audioWebLink} 
            audioWebURL={this.state.audioWebURL}

          />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { users, music } = state;
  return {
    // listOfMusicCategory: musiccategory.listOfMusicCategory ? musiccategory.listOfMusicCategory : [],
    users,
    music
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Music)));
