import React, { Component } from 'react';
import {
  Grid,
  // Button,
  Typography,
  CssBaseline,
  // TextField,
  // Dialog,
  // Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { enquiryActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

// import AddEnquiry from './Modal/AddEnquiry';
// import UpdateEnquiry from './Modal/UpdateEnquiry';

import { userActions } from '../../_actions/user.actions'


// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const theme = createMuiTheme();

class Enquiry extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newEnquiryModal: false,
      newUpdateEnquiryModal:false,
      submitted: false,
      selectedFile: null,
      imageWebLink:"",
      formData: {
        "name": "",
        "desc": "",
        "type": "",
        "imageLink": "",
      },
      updateformData:{
        "id":"",
        "name": "",
        "desc": "",
        "type": "",
        "imageLink": "",
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(enquiryActions.getAllEnquiry(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:nextProps.users.filesDetails.uploadedImageName
      
      }
    }

    if (nextProps.enquiry.deleteEnquirySuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "imageLink": "",
        },
        updateformData: {
          "id":"",
          "name": "",
          "desc": "",
          "type": "",
          "imageLink": "",
        }
      }
    }

    if (nextProps.enquiry.updateEnquirySuccess) {
      console.log("nextProps.enquiry.updateEnquirySuccess",nextProps.enquiry.updateEnquirySuccess)
      return {
        ...nextProps,
        updateformData: {
          "id":"",
          "name": "",
          "desc": "",
          "type": "",
          "imageLink": "",
        },
        newUpdateEnquiryModal: false,
      }
    }

    if (nextProps.enquiry.addEnquirySuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "imageLink": "",
        },
        newEnquiryModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(enquiryActions.getAllEnquiry(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(enquiryActions.getAllEnquiry(data));
  }
  handleClickOpen = () => {
    this.setState({ newEnquiryModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newEnquiryModal: false });

  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    let reqData = {
      "name": this.state.formData.name.trim(),
      "desc": this.state.formData.desc,
      "type": this.state.formData.type.trim(),
      "imageLink": (this.state.imageWebLink)?this.state.imageWebLink: this.state.formData.imageLink.trim(),
    }
    this.props.dispatch(enquiryActions.createNewEnquiry(reqData));
    this.setState({ imageWebLink:"" });

  }


  onDelete = (data) => {
    let datatemp = {
    "keyWord": "",
    "pageNo": 1,
    "size": this.state.size
  }

  confirmAlert({
    name: 'Confirm to delete?',
    message: 'Are you sure to delete ' + data.name,
    buttons: [
      {
        label: 'Yes',
        onClick: () => this.props.dispatch(enquiryActions.deleteEnquiry({ id: data.id},datatemp))
      },
      {
        label: 'No'
      }
    ]
  });

}


  onRowClick = (data) => {
    this.props.history.push(`/app/enquirydetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(enquiryActions.disableEnquiry(tempdata));
  }
  onUpdate = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "type": data.type,
      "imageLink": data.imageLink,
    }
    console.log("tempdata",tempdata)
    this.setState({ newUpdateEnquiryModal: true, updateformData: tempdata });
  }
  updatehandleChangeInput= (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
        updateformData[event.target.name] = event.target.value;
        this.setState({ updateformData });
    
  }


  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id":this.state.updateformData.id,
      "name": this.state.updateformData.name.trim(),
      "desc": this.state.updateformData.desc,
      "type": this.state.updateformData.type.trim(),
      "imageLink": (this.state.imageWebLink)?this.state.imageWebLink: this.state.updateformData.imageLink.trim(),
    }
    console.log("-----reqData-----")
    console.log(reqData)
    this.props.dispatch(enquiryActions.updateEnquiry(reqData,tempdata));
    this.setState({ imageWebLink:"" });

  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateEnquiryModal: false });

  }


  handleFile=(event)=>{

    this.setState({ selectedFile: event.target.files[0] }); 

    if(event.target.files[0]){
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length-1]));
      this.setState({ selectedFile: null });
      
     }
     else{
       console.log("No File To Upload!")
     }

  }

  handleUpload=()=>{

    const {formData, updateformData, imageWebLink } = this.state;
    formData['imageLink'] = imageWebLink;
    updateformData['imageLink'] = imageWebLink;
    this.setState({formData, updateformData });

  }



  render() {

    let { users, enquiry } = this.props;
    let { items, total } = enquiry;
    const { updateformData } = this.state;

    if(this.state.updateformData){
      console.log("----updateformdata----",updateformData);
    }

    // let receivedImage=null;
    if(users.filesDetails){
      console.log("filesDetails: ",users.filesDetails)
      // receivedImage = users.filesDetails;
    }

  console.log("imageweblink: ",this.state.imageWebLink)

    return (
      <>
        <PageTitle title="Enquiry List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              {/* <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid> */}
              <Grid item xs={12}><div style={{margin:'2% 0'}}/></Grid>
              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        {/* <Dialog fullScreen open={this.state.newEnquiryModal} onClose={this.handleClose} TransitionComponent={Transition}>
               <AddEnquiry
                  data={this.data}
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}

                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  receivedImage={receivedImage}
                  imageWebLink={this.state.imageWebLink}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateEnquiryModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateEnquiry
                  data={this.data}
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.updatehandleChangeInput}

                  handleFile={this.handleFile}
                  handleUpload={this.handleUpload}
                  receivedImage={receivedImage}
                  imageWebLink={this.state.imageWebLink}

                />
        </Dialog> */}
      </>
    );

  }
}
function mapStateToProps(state) {
  const { users, enquiry } = state;
  return {
    users,
    enquiry
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Enquiry)));
