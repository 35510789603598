import {
  companyConstants
} from '../_constants';

export function company(state = {}, action) {

  switch (action.type) {
    case companyConstants.COMPANY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.COMPANY_GETALL_SUCCESS:
      return {
        ...state,
        addCompanySuccess: false,
        updateCompanySuccess: false,
          items: action.company.listOfCompanys.list,
          total: action.company.listOfCompanys.total
      };
    case companyConstants.COMPANY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case companyConstants.ADD_COMPANY_REQUEST:
      return {
        ...state
      };
    case companyConstants.ADD_COMPANY_SUCCESS:
      return {
        ...state,
        addCompanySuccess: true
      };
    case companyConstants.ADD_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case companyConstants.UPDATE_COMPANY_REQUEST:
      return {
        ...state
      };
    case companyConstants.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        updateCompanySuccess: true
      };
    case companyConstants.UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case companyConstants.GET_COMPANY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.GET_COMPANY_ID_SUCCESS:
      return {
        ...state,
        companydetails: action.users.companydetails
      };
    case companyConstants.GET_COMPANY_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case companyConstants.EMPLOYEE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.EMPLOYEE_GETALL_SUCCESS:
      return {
        ...state,
        addCompanySuccess: false,
          employeeitems: action.company.employeeList.list,
          employeetotal: action.company.employeeList.total,
      };
    case companyConstants.EMPLOYEE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case companyConstants.ALLEMPLOYEE_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case companyConstants.ALLEMPLOYEE_GETALL_SUCCESS:
        return {
          ...state,
          addCompanySuccess: false,
            allEmployeesList: action.company.allEmployeesList.list
        };
      case companyConstants.ALLEMPLOYEE_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };


    case companyConstants.SURVEY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.SURVEY_GETALL_SUCCESS:
      return {
        ...state,
        addSurveySuccess: false,
        surveyitems: action.company.surveyList.list,
        surveytotal: action.company.surveyList.total
      };
    case companyConstants.SURVEY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case companyConstants.SURVEYTYPEALL_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case companyConstants.SURVEYTYPEALL_GETALL_SUCCESS:
      return {
        ...state,
        surveyTypeList: action.company.surveyTypeList
      };
    case companyConstants.SURVEYTYPEALL_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case companyConstants.ADD_SURVEY_REQUEST:
      return {
        ...state
      };
    case companyConstants.ADD_SURVEY_SUCCESS:
      return {
        ...state,
        addSurveySuccess: true
      };
    case companyConstants.ADD_SURVEY_FAILURE:
      return {
        ...state,
        error: action.error
      };

      case companyConstants.UPDATE_SURVEY_REQUEST:
        return {
          ...state
        };
      case companyConstants.UPDATE_SURVEY_SUCCESS:
        return {
          ...state,
          updateSurveySuccess: true
        };
      case companyConstants.UPDATE_SURVEY_FAILURE:
        return {
          ...state,
          error: action.error
        };

      case companyConstants.DELETE_SURVEY_REQUEST:
        return {
          ...state
        };
      case companyConstants.DELETE_SURVEY_SUCCESS:
        return {
          ...state,
          deleteSurveySuccess: true
        };
      case companyConstants.DELETE_SURVEY_FAILURE:
        return {
          ...state,
          error: action.error
        };



        case companyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST:
          return {
            ...state,
            loading: true
          };
        case companyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS:
          return {
            ...state,
            employeeColumnNames: action.company.employeeColumnNames
          };
        case companyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE:
          return {
            ...state,
            error: action.error
          };

          case companyConstants.CREATE_EMPLOYEE_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case companyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS:
            return {
              ...state,
              createEmployeeFilter: true
            };
          case companyConstants.CREATE_EMPLOYEE_FILTER_FAILURE:
            return {
              ...state,
              error: action.error
            };


            case companyConstants.GET_EMPLOYEE_FILTER_REQUEST:
              return {
                ...state,
                loading: true
              };
            case companyConstants.GET_EMPLOYEE_FILTER_SUCCESS:
              return {
                ...state,
                getEmployeeFilter: true,
                employeeFilterList: action.company.employeeFilterList

              };
            case companyConstants.GET_EMPLOYEE_FILTER_FAILURE:
              return {
                ...state,
                error: action.error
              };


              case companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS:
                return {
                  ...state,
                  updateEmployeeFilterStatus: true
  
                };
              case companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE:
                return {
                  ...state,
                  error: action.error
                };



                
                case companyConstants.GET_BENCHMARK_REQUEST:
                  return {
                    ...state
                  };
                case companyConstants.GET_BENCHMARK_SUCCESS:
                  return {
                    ...state,
                    getBenchMarkSuccess: true,
                    addBenchMarkSuccess: false,
                    updateBenchMarkSuccess: false,
                    deleteBenchMarkSuccess: false,

                    getBenchMarkListData:action.company.getBenchMarkListData.list,
                    getBenchMarkListTotal:action.company.getBenchMarkListData.total

                  };
                case companyConstants.GET_BENCHMARK_FAILURE:
                  return {
                    ...state,
                    error: action.error
                  };

                case companyConstants.ADD_BENCHMARK_REQUEST:
                  return {
                    ...state
                  };
                case companyConstants.ADD_BENCHMARK_SUCCESS:
                  return {
                    ...state,
                    addBenchMarkSuccess: true
                  };
                case companyConstants.ADD_BENCHMARK_FAILURE:
                  return {
                    ...state,
                    error: action.error
                  };
            
                  case companyConstants.UPDATE_BENCHMARK_REQUEST:
                    return {
                      ...state
                    };
                  case companyConstants.UPDATE_BENCHMARK_SUCCESS:
                    return {
                      ...state,
                      updateBenchMarkSuccess: true
                    };
                  case companyConstants.UPDATE_BENCHMARK_FAILURE:
                    return {
                      ...state,
                      error: action.error
                    };
            
                  case companyConstants.DELETE_BENCHMARK_REQUEST:
                    return {
                      ...state
                    };
                  case companyConstants.DELETE_BENCHMARK_SUCCESS:
                    return {
                      ...state,
                      deleteBenchMarkSuccess: true
                    };
                  case companyConstants.DELETE_BENCHMARK_FAILURE:
                    return {
                      ...state,
                      error: action.error
                    };

                    case companyConstants.UPDATE_STATUS_BENCHMARK_REQUEST:
                      return {
                        ...state
                      };
                    case companyConstants.UPDATE_STATUS_BENCHMARK_SUCCESS:
                      return {
                        ...state,
                        updateStatusBenchMarkSuccess: true
                      };
                    case companyConstants.UPDATE_STATUS_BENCHMARK_FAILURE:
                      return {
                        ...state,
                        error: action.error
                      };



        
    default:
      return state
  }
}