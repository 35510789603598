
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const premiummusicService = {
    disablePremiumMusic,
    logout,
    createPremiumMusic,
    updatePremiumMusic,
    getPremiumMusicList,
    getAllPremiumMusic,
    deletePremiumMusic
};
function logout() {
    localStorage.removeItem('adminpremiummusic');
    window.location.href = "#/login";
    //window.location.reload();

}
function getPremiumMusicList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPremiumMusicList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let premiummusicObj = {
                listOfPremiumMusics: data.data
            }
            //console.log("i am in service premiummusicObj :::::",premiummusicObj);
            return premiummusicObj;
        });
}

function createPremiumMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPremiumMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiummusicObj = {
                createPremiumMusic: data.data
            }
            //console.log();

            return premiummusicObj;
        });
}
function updatePremiumMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePremiumMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiummusicObj = {
                updateNewPremiumMusic: data.data
            }
            //console.log(premiummusicObj);

            return premiummusicObj;
        });
}

function deletePremiumMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePremiumMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiummusicObj = {
                deleteNewPremiumMusic: data.data
            }
            //console.log(premiummusicObj);

            return premiummusicObj;
        });
}

function disablePremiumMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePremiumMusicStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiummusicObj = {
                deletePremiumMusic: data.data
            }
            //console.log();

            return premiummusicObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getAllPremiumMusic(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPremiumMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiummusicObj = {
                allPremiumMusics: data.data
            }
            // //console.log("getAllPremiumMusicgetAllPremiumMusicgetAllPremiumMusicgetAllPremiumMusic =============> ",premiummusicObj);
            return premiummusicObj;
        });
}