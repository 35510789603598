export const premiumactivityConstants = {

    ADD_PREMIUMACTIVITY_REQUEST: 'ADD_PREMIUMACTIVITY_REQUEST',
    ADD_PREMIUMACTIVITY_SUCCESS: 'ADD_PREMIUMACTIVITY_SUCCESS',
    ADD_PREMIUMACTIVITY_FAILURE: 'ADD_PREMIUMACTIVITY_FAILURE',

    DELETE_PREMIUMACTIVITY_REQUEST: 'DELETE_PREMIUMACTIVITY_REQUEST',
    DELETE_PREMIUMACTIVITY_SUCCESS: 'DELETE_PREMIUMACTIVITY_SUCCESS',
    DELETE_PREMIUMACTIVITY_FAILURE: 'DELETE_PREMIUMACTIVITY_FAILURE',

    UPDATE_PREMIUMACTIVITY_REQUEST: 'UPDATE_PREMIUMACTIVITY_REQUEST',
    UPDATE_PREMIUMACTIVITY_SUCCESS: 'UPDATE_PREMIUMACTIVITY_SUCCESS',
    UPDATE_PREMIUMACTIVITY_FAILURE: 'UPDATE_PREMIUMACTIVITY_FAILURE',

    PREMIUMACTIVITY_GETALL_REQUEST: 'PREMIUMACTIVITY_GETALL_REQUEST',
    PREMIUMACTIVITY_GETALL_SUCCESS: 'PREMIUMACTIVITY_GETALL_SUCCESS',
    PREMIUMACTIVITY_GETALL_FAILURE: 'PREMIUMACTIVITY_GETALL_FAILURE',

    PREMIUMACTIVITYLIST_GETALL_REQUEST: 'PREMIUMACTIVITYLIST_GETALL_REQUEST',
    PREMIUMACTIVITYLIST_GETALL_SUCCESS: 'PREMIUMACTIVITYLIST_GETALL_SUCCESS',
    PREMIUMACTIVITYLIST_GETALL_FAILURE: 'PREMIUMACTIVITYLIST_GETALL_FAILURE',

};
