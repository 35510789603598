export const questiontypeConstants = {

    QUESTIONTYPE_GETALL_REQUEST: 'QUESTIONTYPE_GETALL_REQUEST',
    QUESTIONTYPE_GETALL_SUCCESS: 'QUESTIONTYPE_GETALL_SUCCESS',
    QUESTIONTYPE_GETALL_FAILURE: 'QUESTIONTYPE_GETALL_FAILURE',

    ADD_QUESTIONTYPE_REQUEST: 'ADD_QUESTIONTYPE_REQUEST',
    ADD_QUESTIONTYPE_SUCCESS: 'ADD_QUESTIONTYPE_SUCCESS',
    ADD_QUESTIONTYPE_FAILURE: 'ADD_QUESTIONTYPE_FAILURE',
    
    UPDATE_QUESTIONTYPE_REQUEST: 'UPDATE_QUESTIONTYPE_REQUEST',
    UPDATE_QUESTIONTYPE_SUCCESS: 'UPDATE_QUESTIONTYPE_SUCCESS',
    UPDATE_QUESTIONTYPE_FAILURE: 'UPDATE_QUESTIONTYPE_FAILURE',

};
