import { resourcetypeConstants } from '../_constants';
import { resourcetypeService } from '../_services';
import { alertActions } from '.';
export const resourcetypeActions = {
    disableResourceType,
    createNewResourceType,
    updateResourceType,
    getAllResourceType
};

function getAllResourceType(data) {
    return dispatch => {
        dispatch(request());
        resourcetypeService.getAllResourceType(data)
            .then(
                resourcetype => dispatch(success(resourcetype)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourcetypeConstants.RESOURCETYPE_GETALL_REQUEST } }
    function success(resourcetype) { return { type: resourcetypeConstants.RESOURCETYPE_GETALL_SUCCESS, resourcetype } }
    function failure(error) { return { type: resourcetypeConstants.RESOURCETYPE_GETALL_FAILURE, error } }
}
function createNewResourceType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        resourcetypeService.createNewResourceType(data)
            .then(
                resourcetype => {
                    dispatch(success(resourcetype));
                    dispatch(this.getAllResourceType(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourcetypeConstants.ADD_RESOURCETYPE_REQUEST } }
    function success(resourcetype) { return { type: resourcetypeConstants.ADD_RESOURCETYPE_SUCCESS, resourcetype } }
    function failure(error) { return { type: resourcetypeConstants.ADD_RESOURCETYPE_FAILURE, error } }
}
function updateResourceType(data, tempdata) {

    return dispatch => {
        dispatch(request());
        resourcetypeService.updateResourceType(data)
            .then(
                resourcetype => {
                    dispatch(success(resourcetype));
                    dispatch(this.getAllResourceType(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourcetypeConstants.UPDATE_RESOURCETYPE_REQUEST } }
    function success(resourcetype) { return { type: resourcetypeConstants.UPDATE_RESOURCETYPE_SUCCESS, resourcetype } }
    function failure(error) { return { type: resourcetypeConstants.UPDATE_RESOURCETYPE_FAILURE, error } }
}


function disableResourceType(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        resourcetypeService.disableResourceType({ id: data.id })
            .then(
                resourcetype => {
                    dispatch(success(resourcetype));
                    dispatch(this.getAllResourceType(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourcetypeConstants.ADD_RESOURCETYPE_REQUEST } }
    function success(resourcetype) { return { type: resourcetypeConstants.ADD_RESOURCETYPE_SUCCESS, resourcetype } }
    function failure(error) { return { type: resourcetypeConstants.ADD_RESOURCETYPE_FAILURE, error } }
}