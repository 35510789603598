import { premiummusicConstants } from '../_constants';
import { premiummusicService } from '../_services';
import { alertActions, userActions } from '.';
export const premiummusicActions = {
    createPremiumMusic,
    updatePremiumMusic,
    disablePremiumMusic,
    getPremiumMusicList,
    getAllPremiumMusic,
    deletePremiumMusic
};

function getPremiumMusicList(data) {
    return dispatch => {
        dispatch(request());
        premiummusicService.getPremiumMusicList(data)
            .then(
                premiummusic => dispatch(success(premiummusic)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.PREMIUMMUSIC_GETALL_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.PREMIUMMUSIC_GETALL_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.PREMIUMMUSIC_GETALL_FAILURE, error } }
}
function createPremiumMusic(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        premiummusicService.createPremiumMusic(data)
            .then(
                premiummusic => {
                    dispatch(success(premiummusic));
                    dispatch(userActions.uploadAudioClear());
                    dispatch(this.getPremiumMusicList(tempdata));
                    dispatch(alertActions.success("PremiumMusic created successfully."));
                   

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_FAILURE, error } }
}

function updatePremiumMusic(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        premiummusicService.updatePremiumMusic(data)
            .then(
                premiummusic => {
                    dispatch(success(premiummusic));
                    dispatch(this.getPremiumMusicList(tempdata));
                    dispatch(alertActions.success("PremiumMusic updated successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.UPDATE_PREMIUMMUSIC_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.UPDATE_PREMIUMMUSIC_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.UPDATE_PREMIUMMUSIC_FAILURE, error } }
}

function deletePremiumMusic(data, dataemp) {

    return dispatch => {
        dispatch(request());
        premiummusicService.deletePremiumMusic(data)
            .then(
                premiummusic => {
                    dispatch(success(premiummusic));
                    dispatch(this.getPremiumMusicList(dataemp));
                    dispatch(alertActions.success("PremiumMusic deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.DELETE_PREMIUMMUSIC_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.DELETE_PREMIUMMUSIC_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.DELETE_PREMIUMMUSIC_FAILURE, error } }
}
function disablePremiumMusic(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        premiummusicService.disablePremiumMusic({ id: data.id })
            .then(
                premiummusic => {
                    dispatch(success(premiummusic));
                    dispatch(this.getPremiumMusicList(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.ADD_PREMIUMMUSIC_FAILURE, error } }
}

function getAllPremiumMusic(data) {
    return dispatch => {
        dispatch(request());
        premiummusicService.getAllPremiumMusic(data)
            .then(
                premiummusic => {
                    dispatch(success(premiummusic));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_REQUEST } }
    function success(premiummusic) { return { type: premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_SUCCESS, premiummusic } }
    function failure(error) { return { type: premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_FAILURE, error } }
}
