import { parametertypeConstants } from '../_constants';
import { parametertypeService } from '../_services';
import { alertActions } from '.';
export const parametertypeActions = {
    disableParameterType,
    createNewParameterType,
    updateParameterType,
    getAllParameterType,
    deleteParameterType
};

function getAllParameterType(data) {
    return dispatch => {
        dispatch(request());
        parametertypeService.getAllParameterType(data)
            .then(
                parametertype => dispatch(success(parametertype)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parametertypeConstants.PARAMETERTYPE_GETALL_REQUEST } }
    function success(parametertype) { return { type: parametertypeConstants.PARAMETERTYPE_GETALL_SUCCESS, parametertype } }
    function failure(error) { return { type: parametertypeConstants.PARAMETERTYPE_GETALL_FAILURE, error } }
}
function createNewParameterType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        parametertypeService.createNewParameterType(data)
            .then(
                parametertype => {
                    dispatch(success(parametertype));
                    dispatch(this.getAllParameterType(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parametertypeConstants.ADD_PARAMETERTYPE_REQUEST } }
    function success(parametertype) { return { type: parametertypeConstants.ADD_PARAMETERTYPE_SUCCESS, parametertype } }
    function failure(error) { return { type: parametertypeConstants.ADD_PARAMETERTYPE_FAILURE, error } }
}
function updateParameterType(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        parametertypeService.updateParameterType(data)
            .then(
                parametertype => {
                    dispatch(success(parametertype));
                    dispatch(this.getAllParameterType(tempdata));
                    dispatch(alertActions.success("Updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parametertypeConstants.UPDATE_PARAMETERTYPE_REQUEST } }
    function success(parametertype) { return { type: parametertypeConstants.UPDATE_PARAMETERTYPE_SUCCESS, parametertype } }
    function failure(error) { return { type: parametertypeConstants.UPDATE_PARAMETERTYPE_FAILURE, error } }
}


function disableParameterType(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        parametertypeService.disableParameterType({ id: data.id })
            .then(
                parametertype => {
                    dispatch(success(parametertype));
                    dispatch(this.getAllParameterType(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parametertypeConstants.ADD_PARAMETERTYPE_REQUEST } }
    function success(parametertype) { return { type: parametertypeConstants.ADD_PARAMETERTYPE_SUCCESS, parametertype } }
    function failure(error) { return { type: parametertypeConstants.ADD_PARAMETERTYPE_FAILURE, error } }
}

function deleteParameterType(data, dataemp) {

    return dispatch => {
        dispatch(request());
        parametertypeService.deleteParameterType(data)
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllParameterType(dataemp));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parametertypeConstants.DELETE_PARAMETERTYPE_REQUEST } }
    function success(parametertype) { return { type: parametertypeConstants.DELETE_PARAMETERTYPE_SUCCESS, parametertype } }
    function failure(error) { return { type: parametertypeConstants.DELETE_PARAMETERTYPE_FAILURE, error } }
}