import { parameterConstants } from '../_constants';
import { parameterService } from '../_services';
import { alertActions } from '.';
export const parameterActions = {
    createParameter,
    updateParameter,
    disableParameter,
    getParameterList,
    getAllParameter,
    getParameterListbyParameterType,
    getParameterListBucketbyParameterType,
    getParameterListDimensionbyParameterType
};


function createParameter(data) {
    let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      
}
    return dispatch => {
        dispatch(request());
        parameterService.createParameter(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                    dispatch(this.getParameterList(datatempsurvey));

                    dispatch(alertActions.success("Parameter Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.ADD_PARAMETER_REQUEST } }
    function success(parameter) { return { type: parameterConstants.ADD_PARAMETER_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.ADD_PARAMETER_FAILURE, error } }
}

function updateParameter(data,datatemp) {

    return dispatch => {
        dispatch(request());
        parameterService.updateParameter(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                    dispatch(this.getParameterList(datatemp));

                    dispatch(alertActions.success("Parameter Updated successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.UPDATE_PARAMETER_REQUEST } }
    function success(parameter) { return { type: parameterConstants.UPDATE_PARAMETER_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.UPDATE_PARAMETER_FAILURE, error } }
}

function disableParameter(data) {
        let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
        return dispatch => {
            dispatch(request());
            parameterService.disableParameter(data)
                .then(
                    parameter => {
                        dispatch(success(parameter));
                        dispatch(this.getParameterList(datatempsurvey));
    
                        dispatch(alertActions.success("Parameter Created successfully."));
    
    
                    },
                    error => {
                        dispatch(alertActions.error(error));
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: parameterConstants.ADD_PARAMETER_REQUEST } }
        function success(parameter) { return { type: parameterConstants.ADD_PARAMETER_SUCCESS, parameter } }
        function failure(error) { return { type: parameterConstants.ADD_PARAMETER_FAILURE, error } }
    }


function getAllParameter(data) {
    return dispatch => {
        dispatch(request());
        parameterService.getAllParameter(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.PARAMETER_GETALL_REQUEST } }
    function success(parameter) { return { type: parameterConstants.PARAMETER_GETALL_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.PARAMETER_GETALL_FAILURE, error } }
}

function getParameterList(data) {
    return dispatch => {
        dispatch(request());
        parameterService.getParameterList(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.PARAMETERLIST_BYTYPE_GETALL_REQUEST } }
    function success(parameter) { return { type: parameterConstants.PARAMETERLIST_GETALL_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.PARAMETERLIST_GETALL_FAILURE, error } }
}


function getParameterListbyParameterType(data) {
    return dispatch => {
        dispatch(request());
        parameterService.getParameterListbyParameterType(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.PARAMETERLIST_BYTYPE_GETALL_REQUEST } }
    function success(parameter) { return { type: parameterConstants.PARAMETERLIST_BYTYPE_GETALL_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.PARAMETERLIST_BYTYPE_GETALL_FAILURE, error } }
}



function getParameterListBucketbyParameterType(data) {
    return dispatch => {
        dispatch(request());
        parameterService.getParameterListbyParameterType(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_REQUEST } }
    function success(parameter) { return { type: parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_FAILURE, error } }
}

function getParameterListDimensionbyParameterType(data) {
    return dispatch => {
        dispatch(request());
        parameterService.getParameterListbyParameterType(data)
            .then(
                parameter => {
                    dispatch(success(parameter));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_REQUEST } }
    function success(parameter) { return { type: parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_SUCCESS, parameter } }
    function failure(error) { return { type: parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_FAILURE, error } }
}