import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Select,
  Input,
  MenuItem,
  Toolbar,
  IconButton,
  Container,
  TextField
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function UpdateUser(props) {

  const { classes, updatesurveyformData,submitted, handleUpdateCloseSurvey, handleUpdateSurveySubmit, updatehandleChangeInputSurvey,
    parameterTypesList, allParametersListByParameterTypeData, handleChangesurveySubType, handleChangesurvey, categoryList,
    handleChangesurveyCategory, handleFile, handleUpload, imageWebURL, imageWebLink  } = props;
  

  return (
    <div >
        <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleUpdateCloseSurvey} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Update Survey Details
            </Typography>
              <Button color="inherit" onClick={handleUpdateCloseSurvey}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Update Survey
              </Typography>

              <ValidatorForm
                // ref="form"
                onSubmit={handleUpdateSurveySubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                    <TextValidator
                      label="Name*"
                      fullWidth
                      onChange={updatehandleChangeInputSurvey}
                      name="name"
                      id="name"
                      value={updatesurveyformData.name}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Description*"
                      id="desc"
                      fullWidth
                      onChange={updatehandleChangeInputSurvey}
                      name="desc"
                      value={updatesurveyformData.desc}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                  <Select
                    name="category"
                    value={updatesurveyformData.category ? updatesurveyformData.category : categoryList && categoryList.length > 0 ? categoryList[0] : ''}
                    style={{ width: '100%' }}
                    onChange={event => handleChangesurveyCategory(event.target.value)}
                    input={<Input id="category" />}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Survey Category
                    </MenuItem>
                    {
                      categoryList && categoryList.length > 0 ?
                      categoryList.map((tempdata, index) => (
                          <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                        )) : null
                    }

                  </Select>
                  </Grid>


                  <Grid item xs={12} sm={12}>

                    <Select
                      name="name"
                      value={updatesurveyformData.type ? updatesurveyformData.type : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                      style={{ width: '100%' }}
                      onChange={event => handleChangesurvey(event.target.value)}
                      input={<Input id="name" />}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>
                        Survey Type
                      </MenuItem>
                      {
                        parameterTypesList && parameterTypesList.length > 0 ?
                        parameterTypesList.map((tempdata, index) => (
                            <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                          )) : null
                      }

                    </Select>
                  </Grid>

                <Grid item xs={12} sm={12}>

                <Select
                  name="subtypeId"
                  value={updatesurveyformData.subtypeId ? updatesurveyformData.subtypeId : allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ? allParametersListByParameterTypeData[0].id : ''}
                  style={{ width: '100%' }}
                  onChange={event => handleChangesurveySubType(event.target.value)}
                  input={<Input id="subtypeId" />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Survey Sub Type
                  </MenuItem>
                  {
                    allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ?
                    allParametersListByParameterTypeData.map((tempdata, index) => (
                        <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                      )) : null
                  }

                </Select>
                </Grid>


                <Grid item xs={12} sm={12}>

                <TextValidator
                  label="Color Code*"
                  fullWidth
                  onChange={updatehandleChangeInputSurvey}
                  name="colorCode"
                  id="colorCode"
                  value={updatesurveyformData.colorCode}
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                  label="Image Link"
                  id="imageLink"
                  name="imageLink"
                  fullWidth
                  rowsMax={4}
                  placeholder="Image Link"
                  value={updatesurveyformData.imageLink}
                  onChange={updatehandleChangeInputSurvey}
                />
              </Grid>

                <Grid item xs={12}>

                  <label htmlFor="image">
                    <Button color="default" variant="contained" component="span" >
                      Browse
                            </Button>
                  </label>
                  <input
                    style={{ display: 'none' }}
                    id="image"
                    name="image"
                    type="file"
                    onChange={handleFile}
                  />

                  <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                    Set
                  </Button>

                </Grid>

                {(imageWebURL && (imageWebLink === updatesurveyformData.imageLink)) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : 
                <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={updatesurveyformData.imageLinkV2} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                }





                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                >
                  Save
                </Button>
              </ValidatorForm>
            </div>
          </Container>

    </div>
  );}