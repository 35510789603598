export const enquiryConstants = {

    ENQUIRY_GETALL_REQUEST: 'ENQUIRY_GETALL_REQUEST',
    ENQUIRY_GETALL_SUCCESS: 'ENQUIRY_GETALL_SUCCESS',
    ENQUIRY_GETALL_FAILURE: 'ENQUIRY_GETALL_FAILURE',

    ADD_ENQUIRY_REQUEST: 'ADD_ENQUIRY_REQUEST',
    ADD_ENQUIRY_SUCCESS: 'ADD_ENQUIRY_SUCCESS',
    ADD_ENQUIRY_FAILURE: 'ADD_ENQUIRY_FAILURE',
    
    UPDATE_ENQUIRY_REQUEST: 'UPDATE_ENQUIRY_REQUEST',
    UPDATE_ENQUIRY_SUCCESS: 'UPDATE_ENQUIRY_SUCCESS',
    UPDATE_ENQUIRY_FAILURE: 'UPDATE_ENQUIRY_FAILURE',

    DELETE_ENQUIRY_REQUEST: 'DELETE_ENQUIRY_REQUEST',
    DELETE_ENQUIRY_SUCCESS: 'DELETE_ENQUIRY_SUCCESS',
    DELETE_ENQUIRY_FAILURE: 'DELETE_ENQUIRY_FAILURE',

};
