import React, { Component } from 'react';
import { connect } from 'react-redux';
import { questionsActions, companyActions } from '../../_actions';
import { questiontypeActions } from '../../_actions';
import { parameterActions, parametertypeActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';


class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      size: 10,
      isAdd:false,
      isAddPara:false,
      editId: null,
      QuesName: "",
      OptionName: "",
      dropdown: "Choose",
      radioButton:false,
      checkButton:false,
      starCount:0,
      dropName:null,
      dropQID:null,

      editParaId:null,

      weightage:"",

      parameterId:"",
      parameterTypeId:"",

      parameterType:"",
      paraDrop:"",
      paraSubDrop:"",

      paraUpdateDrop:"",
      parameter:{},


      save: false,
      questionsize: 10,
      editCurrentQuestion: {},
      editCurrentOption:{}
    }
  }
  
  componentDidMount() {
    let survey = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(questiontypeActions.getAllQuestionType());
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(survey));
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getSurveyList(data));

    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 150
    }
    this.props.dispatch(parameterActions.getParameterList(data2));
    this.props.dispatch(parametertypeActions.getAllParameterType(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questions.updateQuestionsSuccess) {
      return {
        ...nextProps
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  


  onEditQues = (currentQuestion) => {




    console.log("currentQuestion: ",currentQuestion)

    this.setState({ editId: currentQuestion.id });
    this.setState({ editCurrentQuestion: currentQuestion.id });
  }

  handleQuestions = (event) => {
    let { questions } = this.props;
    let { questionsList } = questions;
 
    this.props.dispatch(questionsActions.handleNameQuestion(questionsList, this.state.editCurrentQuestion, event.target.value));
   
    console.log(this.state.editCurrentQuestion)
  
  }

  onEditOption = (currentQuestion, currentOption) => {

    this.setState({ editId: currentQuestion.id });
    this.setState({ editCurrentQuestion: currentQuestion.id, editCurrentOption: currentOption.id });
  }

  // handleOptions = (event) => {
  //   let { questions } = this.props;
  //   let { questionsList } = questions;
  
  //   this.props.dispatch(questionsActions.handleNameOption(questionsList, this.state.editCurrentQuestion, this.state.editCurrentOption, event.target.value));
   
  // }

  
  handleOptions = (event,editCurrentQuestion,editCurrentOption) => {
    let { questions } = this.props;
    let { questionsList } = questions;
  
    this.props.dispatch(questionsActions.handleNameOption(questionsList, editCurrentQuestion, editCurrentOption, event.target.value));
   
  }
  
  // handleWeightageOptions = (event) => {
  //   let { questions } = this.props;
  //   let { questionsList } = questions;
  
  //   this.props.dispatch(questionsActions.handleNameWeightage(questionsList, this.state.editCurrentQuestion, this.state.editCurrentOption, event.target.value));
   
  // }

  handleWeightageOptions = (event,editCurrentQuestion,editCurrentOption) => {
    let { questions } = this.props;
    let { questionsList } = questions;
  
    this.props.dispatch(questionsActions.handleNameWeightage(questionsList, editCurrentQuestion, editCurrentOption, event.target.value));
   
  }


  paraUpdateDrop=()=>{
    //no code 
  }


  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }
  onDeleteQues = (Ques) => {
    let data = {
      id: Ques.id,
      surveyId: Ques.surveyId
    }
    this.props.dispatch(questionsActions.deleteQuestion(data));
  }
 
  onSaveQues = (Ques) => {
    console.log('Question to be updated! ',Ques)
    this.props.dispatch(questionsActions.updateQuestion(Ques,false));
    this.setState({ editId: null });
    this.setState({editParaId:null})
  }

  AddQues(){
    if(this.state.isAdd){
      this.setState({ isAddPara: null });
      this.setState({ isAdd: null });
    }
    else{
      this.setState({ isAddPara: true });
    }

    if(this.state.isAddPara){
      this.setState({ isAddPara: null });
      this.setState({ parameter: null });
    }
    
  }

  AddQuesTypeInitial(){
    let { company } = this.props;

    let { surveyitems } = company;

    let currentSurveyId = this.props.match.params.id


    if(surveyitems){
      surveyitems.forEach((survey)=>{
          if(survey.id=== currentSurveyId){
            console.log("survey: ", survey)
            this.setState({
             
                parameterTypeId:survey.subtypeId

              
            });

          }
      })
    }

    this.setState({ isAddPara: false });

    if(this.state.isAdd){
      this.setState({ isAdd: false });

    }
    else{
      this.setState({ isAdd: true });

    }



  }


  AddQuesType=(quesType)=>{


    console.log("this.state.parameterTypeId: ",this.state.parameterTypeId)

    let paraTypeId = ""
    let paraId = "5f5588ddd90eee99b99db44f"

    // if(this.state.parameter.parameterType){
    //   paraType = this.state.parameter.parameterType;
    // }
    if(this.state.parameterTypeId){
      paraTypeId = this.state.parameterTypeId;
    }
   
    let Ques={};

    
    if(quesType.templateId===1||quesType.templateId===2||quesType.templateId===9){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,



        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
                {weightage:1,name:"Option 1"},
                {weightage:1,name:"Option 2"}
            ]
        }

    }
    else if(quesType.templateId===10){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,

        surveyId: this.props.match.params.id,
        name: "This is a statement!",
        options: [{weightage:1,name:"Option"}]
        }

    }
    else if(quesType.templateId===11){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,

        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
                {weightage:1,name:"Yes"},
                {weightage:1,name:"No"}
            ]
        }

    }
    else if(quesType.templateId===5){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,

        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
          {weightage:1,name:"1"},
          {weightage:1,name:"2"},
          {weightage:1,name:"3"},
          {weightage:1,name:"4"},
          {weightage:1,name:"5"}
        ]
        }

    }
    else if(quesType.templateId===6){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,

        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
          {weightage:1,name:"1"},
          {weightage:1,name:"2"},
          {weightage:1,name:"3"},
          {weightage:1,name:"4"},
          {weightage:1,name:"5"},
          {weightage:1,name:"6"},
          {weightage:1,name:"7"},
          {weightage:1,name:"8"},
          {weightage:1,name:"9"},
          {weightage:1,name:"10"}
        ]
        }

    }
    else{
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,

        parameterTypeId: paraTypeId,
        parameterId: paraId,
        
        surveyId: this.props.match.params.id,
        name: "Question",
        options: [{weightage:1,name:"Option"}]
        }

    }
  
    console.log('Ques>.........................',Ques)
  
    
    this.props.dispatch(questionsActions.createQuestion(Ques));
 
   
    this.setState({ isAdd: false });
    this.setState({ weightage: "" });
    this.setState({ parameter: null });
    this.setState({ paraDrop: "" });
  
  }

  deleteOption=(ques,opt)=>{
    let { questions } = this.props;
    let { questionsList } = questions;
    console.log("deleteOption: ",opt)
    this.props.dispatch(questionsActions.deleteOption(questionsList, ques, opt.id));
  }

  addOption=(ques)=>{
    let { questions } = this.props;
    let { questionsList } = questions;
   
    this.props.dispatch(questionsActions.addOption(questionsList, ques));
  
  }

  handleRadio=(radioButtonId)=>{
    if(this.state.radioButton===radioButtonId){
      this.setState({radioButton:null})
    }
    else{
      this.setState({radioButton:radioButtonId._id})
    }
   
    
  }

  handleCheck=(radioButtonId)=>{
    if(this.state.checkButton===radioButtonId){
      this.setState({checkButton:null})
    }
    else{
      this.setState({checkButton:radioButtonId._id})
    }

  }



  handleStars=(data, ques, option)=>{


    if(data==='undo'){
      this.setState({starCount:null})
    }
    else{
      this.setState({starCount:data+1});
      
    }


  }

  handleScale=(data,ques, option)=>{

    if(data==='undo'){
      this.setState({starCount2:null})
    }
    else{
      this.setState({starCount2:data+1});
      
    }
  }


  onDropdown=(Index,ques,currentOption)=>{


    if(this.state.radioButton===currentOption._id){
      this.setState({radioButton:null});
      this.setState({dropName:null})
      this.setState({dropQID:null})
    }
    else{
      this.setState({radioButton:currentOption._id})
      this.setState({dropName:currentOption.name})
      this.setState({dropQID:ques.id})
    }
   
  }

  dropdownInput(){
    //NO CODE
  }

  paraInput(){
      //NO CODE
  }

  paraSubInput(){
    //NO CODE
}





  paraUpdateInput=(para)=>{
    let { questions } = this.props;
    let { questionsList } = questions;

    console.log('para: ',para)


    this.props.dispatch(questionsActions.handleParaInput(questionsList,this.state.editCurrentQuestion,para));
    this.setState({paraUpdateDrop:para.name})

    this.setState({
      parameter:{
        parameterType:para.name
      }
    })
      
}

changeQuestionParameter=(currentQuestion)=>{

        let { parametertype, parameter } = this.props;
        const { parameterTypesList } = parametertype;
        const { items } = parameter;


        console.log("currentQuestion: ",currentQuestion)

        if(currentQuestion.parameterTypeId ){

        let ParameterIndex = items.findIndex(element=>element.id === currentQuestion.parameterTypeId);
        let ParameterTypeId = items[ParameterIndex].parameterTypeId;

        // console.log("items: ",items)
        // console.log("currentQuestion.parameterTypeId: ",currentQuestion.parameterTypeId)

        let ParameterTypeIndex = parameterTypesList.findIndex(element=>element.id === ParameterTypeId.id);

        // console.log("ParameterTypeIndex: ",ParameterTypeIndex)
        let parameterTypeNextId = parameterTypesList[ParameterTypeIndex+1].id
        // console.log("ParameterTypeIndex+1 type: ",parameterTypeNextId)



        if(ParameterTypeIndex>-1){
          let data = {
            parameterTypeId: parameterTypeNextId
          }
          this.props.dispatch(parameterActions.getParameterListbyParameterType(data));
        }
        else{
          console.log("do nothing")
        }

        }
}


handleUpdateWeightage=(value)=>{
  let { questions } = this.props;
  let { questionsList } = questions;
  this.props.dispatch(questionsActions.handleUpdateWeightage(questionsList,this.state.editCurrentQuestion,value));
}
 

  onPreview = () => {
    let survey = {
        surveyId: this.props.match.params.id
      }
    this.props.history.push(`/app/welcomepage/${survey.surveyId}`)
  }

  onPublish = () => {
    let survey = {
        surveyId: this.props.match.params.id
      }
    this.props.history.push(`/app/welcomepagepublish/${survey.surveyId}`)
  }

  // handleWeightage=(value)=>{
  //   this.setState({weightage:value})
  // }

  submitParameter(para){
    if(!para){
      para={parameterId:'000',parameterType:'Null'}
    }
    this.setState({
      parameter:{
        parameterId:para.parameterId,
        parameterType:para.parameterType
      }
    })

    this.setState({paraDrop:para.parameterType})
  }

  
  submitSubParameter(para){

    if(!para){
      para={id:"",name:""}
    }
    this.setState({
      parameter:{
        parameterId:para.id,
        parameterType:this.state.parameter.parameterType
      }
    })
   
    this.setState({paraSubDrop:para.name})
  }

  submitUpdateSubParameter(para){
    let { questions } = this.props;
    let { questionsList } = questions;

    if(!para){
      para={id:"",name:""}
    }
    this.setState({
      parameter:{
        parameterId:para.id,
        parameterType:this.state.parameter.parameterType
      }
    })
   
    this.setState({paraSubDrop:para.name})

    let paraUpdate={
      parameterId:para.id,
      parameterType:this.state.parameter.parameterType
    }

    console.log("_--------------------",paraUpdate)

    this.props.dispatch(questionsActions.handleParaInput(questionsList,this.state.editCurrentQuestion,paraUpdate));

  }



  onEditQuesPara=(ques)=>{
    if(this.state.editParaId){
      this.setState({editParaId:null})
    }
    else{
      this.setState({editParaId:ques.id})
    }
   
  }


  render() {
    let { questions,questiontype, parameter} = this.props;
   
    let { questionsList } = questions;

    let { questionTypeList } = questiontype;

    let {allParametersListByParameterTypeData } = parameter;


    let loading = questions.loading

console.log("questionsList: ",questionsList)

// console.log("parameter: ",this.state.parameter)


// if(this.state.parameter){
//   console.log("parameterId: ", this.state.parameter.parameterId)

// }

// console.log("surveyitems: ",surveyitems)


// console.log("surveyitems: ",surveyitems)


console.log("allParametersListByParameterTypeData: ",allParametersListByParameterTypeData)



console.log("editCurrentQuestion: ",this.state.editCurrentQuestion)
console.log("editCurrentOption: ",this.state.editCurrentOption)



    return (
      <>

<LoadingOverlay
                active={loading}
                // active={false}

                spinner
                text='Loading Data...'
                className="loader"
                >




        <div className="container">

        <div className="row"  style={{ marginTop: '45px',marginLeft: '17px' ,marginBottom: '15px' }} >
         <div className="col"  style={{display:'inline'}}>
            <button
              size="small"
              // onClick={() => this.AddQues()}
              onClick={()=>this.AddQuesTypeInitial()}
              className="px-2 btn btn-primary"
              variant="contained"
              style={{ marginRight: '5px'}} >
              {(!this.state.isAdd) && (!this.state.isAddPara)?'Add Question':null}
              {this.state.isAdd?'Question Type':null}
              {this.state.isAddPara?'Parameter Type':null}
            </button>
          </div>
          <div className="col" style={{display:'inline' ,marginLeft:'70%'}}>
            <button
              size="small"
              onClick={()=>this.onPreview()}
              className="px-2 btn btn-default"
              variant="contained"
              style={{ marginRight: '5px'}}  >
              Preview
            </button>
            <button
              size="small"
              className="px-2 btn btn-default"
              variant="contained"
              style={{ marginRight: '5px' }}
              disabled
              onClick={()=>this.onPublish()}
            >
              Publish
            </button>
          </div>
          </div>



          {/* ADD QUESTION TYPE                       */}
          {(this.state.isAdd)?
                    <div className="container">
                    <div className="card" style={{backgroundColor:'white', padding:'10px', marginBottom:'20px'}}>
                      {
                        questionTypeList && questionTypeList.length > 0 ?
                          questionTypeList.map((QuesType, index) => (
      
                            <button
                              key={index}
                              color="secondary"
                              size="small"
                              className="px-2 btn btn-success"
                              variant="contained"
                              style={{ marginRight: '5px',marginBottom: '5px' }}
                              onClick={()=>this.AddQuesType(QuesType)}
                            >
                              {QuesType.name}
                            </button>
                          )) : null
                      }
                      </div>
                      </div>
           
          
          
          :null}


  


          {/* EDIT QUESTIONS */}
          {
            (questionsList && questionsList.length) > 0 ?
              questionsList.map((question, questionindex) => (
                <div className="container" key={questionindex} >
                  <div className="card" variant="outlined" style={{backgroundColor:'white', marginBottom:'10px'}}>
                    <div className="card-body" style={{paddingBottom:'3%',paddingLeft:'3%'}}>

               
                      <div 
                        style={{marginLeft:'80%'}}>
                        {(this.state.editId === question.id) ?
                        <i className="fa fa-check-circle" aria-hidden="true"
                        title="Save"
                            onClick={() => this.onSaveQues(question)}
                              style={{ margin: '15px' , fontSize:'25px'}}></i>
                          :
                          <i className="fa fa-pencil-square" aria-hidden="true"
                          title="Edit"
                              onClick={() => this.onEditQues(question)}
                                style={{ margin: '15px' ,fontSize:'25px' }}></i>
                    
                        }
                        <i className="fa fa-trash" aria-hidden="true"
                          title="Delete"
                          onClick={() => this.onDeleteQues(question)}
                            style={{ margin: '15px',fontSize:'25px'  }}></i>
                        <i className="fa fa-cube" aria-hidden="true"
                        title="Parameter"
                          onClick={() => this.onEditQuesPara(question)}
                            style={{ margin: '15px',fontSize:'25px' }}></i>

                      </div>
                      <br />
                      {(this.state.editId === question.id) ? 
                      <div className="container" key={questionindex}>
                       
                       <div className="container">
                        <div className="container">

                          <input
                            className="form-control"
                            value={question.name}
                            onChange={this.handleQuestions}
                            variant="outlined"
                            style={{ margin: 8 ,width:'70%'}}
                            placeholder={questionindex + 1 + ') ' + question.name}
                            label={'Question ' + (questionindex + 1)}
                            margin="normal"
                          />
                        </div>


                            {(question.templateId===1||question.templateId===2||question.templateId===9)?
                          <div className="container">
                               <div className="container">
                              
                               <div className="container" style={{display:'inline'}}>
                             
                                    {question.optionsList.map((option, optionindex) =>
                                     <div className="container"  key={optionindex}>
                                      <input                              
                                        value={option.name}
                                        className="px-2 col form-control"
                                        onClick={()=>this.onEditOption(question,option)}
                                        // onChange={this.handleOptions}
                                        onChange={(event)=>this.handleOptions(event,question.id,option.id)}
                                        style={{ margin: 8 , width: '30%', display:'inline'}}
                                        placeholder={optionindex + 1 + ') ' + option.name}
                                        margin="normal"
                                      />

                                      <input 
                                      style={{marginLeft:'14px',marginRight:'10px',width:'100px',display:'inline'}} 
                                      type="number" className="form-control" 
                                      id="exampleInputEmail1" 
                                      value={option.weightage} 
                                      onClick={()=>this.onEditOption(question,option)}
                                      // onChange={this.handleWeightageOptions}
                                      onChange={(event)=>this.handleWeightageOptions(event,question.id,option.id)}
                                      />


                                      <i className="fa fa-trash" aria-hidden="true"
                                      onClick={()=>this.deleteOption(question,option)}
                                      style={{ marginTop: '12px' ,fontSize: '25px' , display:'inline' }}
                                      ></i>
                                      
                                      </div>
                                      )
                                    }
                                  </div>
                                  </div>
                             
                                <div className="container">
                                <div className="container">
                                <i className="fa fa-plus-circle" aria-hidden="true"
                                onClick={()=>this.addOption(question)}
                                style={{ marginTop: '12px',marginLeft: '10px' ,fontSize: '25px' }}></i>
                                        
                                </div>
                                </div>
                              </div>
                         
                        :null
                        }
                          {(question.templateId===11)?
                          <div className="container">
                              <div className="container">
                                <div className="container" >
                                  <div className="container">
                                    {question.optionsList.map((option, optionindex) =>
                                    <div className="container" key={optionindex}>
                                      <input                  
                                        value={option.name}
                                        className="px-2 col form-control"
                                        onClick={()=>this.onEditOption(question,option)}
                                        style={{ margin: 8 ,width:'30%',display:'inline'}}
                                        placeholder={optionindex + 1 + ') ' + option.name}
                                        margin="normal"
                                      />
                                      <input 
                                      style={{marginLeft:'14px',marginRight:'10px',width:'100px',display:'inline'}} 
                                      type="number" className="form-control" 
                                      id="exampleInputEmail1" 
                                      value={option.weightage} 
                                      onClick={()=>this.onEditOption(question,option)}
                                      onChange={this.handleWeightageOptions}
                                      />
                                    </div>
                                )
                              }
                            </div>
                        
                          </div>
                          </div>
                          </div>
                        :null
                        }

                        {(question.templateId===3||question.templateId===4||question.templateId===7||question.templateId===8||question.templateId===10||question.templateId===12)?
                          <div className="container">
                              <div className="container">
                                <div className="container" >
                                  <div className="container">
                                    {question.optionsList.map((option, optionindex) =>
                                    <div className="container" key={optionindex}>
                                      <label>Weightage</label>
                                      <input 
                                      style={{marginLeft:'14px',marginRight:'10px',width:'100px',display:'inline'}} 
                                      type="number" className="form-control" 
                                      id="exampleInputEmail1" 
                                      value={option.weightage} 
                                      onClick={()=>this.onEditOption(question,option)}
                                      onChange={this.handleWeightageOptions}
                                      />
                                    </div>
                                )
                              }
                            </div>
                        
                          </div>
                          </div>
                          </div>
                        :null
                        }

                 
                          {(question.templateId===5||question.templateId===6)?
                          <div className="container">
                              <div className="container">
                                <div className="container">
                                  <div className="container">
                                    {question.optionsList.map((option, optionindex) =>

                                    <div className="container"  key={optionindex}>
                                    <input                              
                                      value={optionindex+1}
                                      className="px-2 col form-control"
                                      onClick={()=>this.onEditOption(question,option)}
                                      onChange={this.handleOptions}
                                      style={{ margin: 8 , width: '30%', display:'inline'}}
                                      placeholder={optionindex + 1 + ') ' + option.name}
                                      margin="normal"
                                    />

                                    <input 
                                    style={{marginLeft:'14px',marginRight:'10px',width:'100px',display:'inline'}} 
                                    type="number" className="form-control" 
                                    id="exampleInputEmail1" 
                                    value={option.weightage} 
                                    onClick={()=>this.onEditOption(question,option)}
                                    onChange={this.handleWeightageOptions}
                                    />


                                    <i className="fa fa-trash" aria-hidden="true"
                                    onClick={()=>this.deleteOption(question,option)}
                                    style={{ marginTop: '12px' ,fontSize: '25px' , display:'inline' }}
                                    ></i>
                                    
                                    </div>

                          
                                      )}
                                  </div>
                              
                                </div>

                                <div className="container">
                                <div className="container">
                                  <i className="fa fa-plus-circle" aria-hidden="true"
                                  onClick={()=>this.addOption(question)}
                                    style={{ marginTop: '12px',marginLeft: '10px' ,fontSize: '25px' }}></i>
                                        
                                </div>
                                </div>

                                </div>
                                </div>
                              :null
                              }

                              </div>

                                            {/* UPDATE PARAMETER TYPE */}
                                    {(this.state.editParaId===question.id)?
                                  <div className="container" style={{display:'inline'}}>
                                  <div className="card" style={{backgroundColor:'white', padding:'10px', marginBottom:'20px',marginLeft:'30px'}}>
                    

                                  {true?
                                    <div className="dropdown show" id="exampleFormControlSelect1" style={{width:'195px'}}>
                                                        <a style={{fontSize:'17px',fontWeight:'normal',textDecoration:'None'}} href="asd" className="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <input className="form-control" placeholder="Parameter"  value={(question.parameterId && question.parameterId.name)?question.parameterId.name:this.state.paraUpdateDrop} onClick={()=>this.changeQuestionParameter(question)} onChange={this.paraUpdateDrop} />
                                                        </a>
                                                        {allParametersListByParameterTypeData && allParametersListByParameterTypeData.length>0?
                                                                  <div   className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{paddingLeft:'50px',paddingRight:'50px'}}>
                                                                   
                                                                  {
                                                                  allParametersListByParameterTypeData.map((para, paraIndex) =>
                                                                  (para.parentId && para.parentId.id && (para.parentId.id === question.parameterTypeId))?
                                                                          <p key={paraIndex} style={{padding:'10px',fontWeight:'bold',fontSize:'17px'}} className=" dropdown-item row" onClick={()=>this.paraUpdateInput(para)}>{para.name}</p>
                                                                    :null
                                                                  )}
                                                              
                                                                </div>
                                                         :null} 

                                        </div>
                                    :null}
                                      
                                    </div>
                                    </div>
                                               
                                      :null}

             
                      
                        <br />
                      </div>
                        :
                        // DISPLAY QUESTIONS------------------------------------------------------------------------
                        <div className="container" key={questionindex}>
                          <div className="container">
                              {question.templateId===10?
                                   <h2 variant="h2"  style={{fontStyle:'italic'}}  >
                                   {`"${question.name}"`}
                                 </h2>:
                                  <h4 variant="h4" >
                                  {questionindex + 1}) {question.name}
                                </h4>
                            }
                           
                          </div>

                          {(question.templateId===1)?
                          <div className="container" >
                                    <div className="container">
                                      <div className="container">
                                        {question.optionsList.map((option, optionindex) =>
                                                  <div className="container" key={optionindex} onClick={()=>this.handleRadio(option)}>

                                                    {(this.state.radioButton === option._id)?
                                                    <input type="radio" checked="false" className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                    :
                                                    <input type="radio"  className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                  }

                                                    
                                     
                                                    <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===2)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container">
                                        {question.optionsList.map((option, optionindex) =>
                                                  <div className="container" key={optionindex} onClick={()=>this.handleCheck(option)}>
                                                                <input type="checkbox" className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                    <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===3)?
                          <div className="container" style={{marginTop:'20px'}}>
                                    <div className="container">
                                      <div className="container">
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8  , width:'50%' }}
                                          variant="outlined"
                                          margin="normal"
                                          placeholder="Text Box"
                                        />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===4)?
                          <div className="container">
                                    <div className="container" >
                                      <div className="container">
                               
                                      <textarea style={{ margin: 8, marginTop: '20px', width:'70%' }} margin="normal" className="px-2 col form-control" variant="outlined" aria-label="minimum height" rows={4} cols={60} placeholder="Multi-line Text" />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===5)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      {question.optionsList.map((option, optionindex) =>
                                        
                                      
                                                    ((this.state.starCount > optionindex) && (this.state.starCount))?
                                                    <i className="text-info fa fa-star" aria-hidden="true" onClick={()=>this.handleStars(optionindex,question, option)} key={optionindex} style={{marginLeft:'10px',marginRight:'10px',fontSize:'40px'}}></i>                       
                                                    :
                                                    <i className="fa fa-star-o" aria-hidden="true" onClick={()=>this.handleStars(optionindex,question, option)} key={optionindex} style={{marginLeft:'10px',marginRight:'10px',fontSize:'40px'}}></i>
                                               
                                      
                                              )}
                                      <i style={{marginLeft:'32px',color:'gray'}} onClick={()=>this.handleStars('undo',question, "")} className="fa fa-undo" aria-hidden="true"></i>
                         
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===6)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                        
                                     <div style={{borderColor:'black',borderWidth:'5px'}} >

                                      {question.optionsList.map((option, optionindex) =>
                                              
                                              ((this.state.starCount2 > optionindex) && (this.state.starCount2))?
                                                <button
                                              key={optionindex}
                                              color="primary"
                                              size="small"
                                              className="px-4 btn btn-info"
                                              variant="contained"
                                              onClick={()=>this.handleScale(optionindex,question, option)}
                                              style={{ marginRight: '5px' }} >
                                              {optionindex+1}
                                          </button>
                                              :
                                                 <button
                                                key={optionindex}
                                              color="secondary"
                                              size="small"
                                              className="px-4 btn btn-secondary"
                                              variant="contained"
                                              onClick={()=>this.handleScale(optionindex,question, option)}
                                              style={{ marginRight: '5px' }} >
                                              {optionindex+1}
                                          </button>
                                  
                                              )}
                                      <i style={{marginLeft:'32px',color:'gray'}} onClick={()=>this.handleScale('undo',question, "")} className="fa fa-undo" aria-hidden="true"></i>
                                       </div>
                                        
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===7)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="email"
                                          placeholder="example@gmail.com"
                                        />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===8)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="number"
                                          placeholder="Number"
                                        />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===9)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container"style={{marginTop:'20px'}}>

                                      <div className="dropdown show">
                                          <a style={{fontSize:'17px',fontWeight:'normal',textDecoration:'None'}} href="asd" className="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <input className="form-control" onChange={this.dropdownInput} value={(this.state.dropName&&this.state.dropQID)?this.state.dropName:'Choose...'}/>
                                          </a>

                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{paddingLeft:'50px'}}>
                                          {question.optionsList.map((option, optionindex) =>
                                             <p style={{padding:'10px',fontWeight:'bold',fontSize:'17px',color:(this.state.radioButton===option._id)?'#1e50bd':null}}  className=" dropdown-item row " key={optionindex} onClick={()=>this.onDropdown(optionindex,question, option)}>{option.name}</p>
                                            )} 
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===11)?
                          <div className="container">
                                    <div className="container" >
                                      <div className="container" style={{marginTop:'20px'}}>
                                      {question.optionsList.map((option, optionindex) =>
                                                          <div className="container" key={optionindex} onClick={()=>this.handleRadio(option)}>
                                                            {(this.state.radioButton === option._id)?
                                                    <input type="radio" checked="false" className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                    :
                                                    <input type="radio"  className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                  }

                                                     <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===12)?
                          <div className="container" >
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="date"
                                          placeholder="Date"
                                        />
                                      </div>
                                    </div>
                          </div>:null }


                          <br />
                        </div>}
                    </div>

                  </div>
                
                </div>



              )) :null
          }




        </div>


        </LoadingOverlay>


      </>
    );
  }
}
AddQuestion.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, questiontype, users,company, parameter, parametertype } = state;
  return {
    loggingIn,
    questions,
    questiontype,
    users,
    company,
    parameter,
    parametertype

  };
}

export default connect(mapStateToProps)(AddQuestion);
