import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  TextFields as TextFieldsIcon,
  Business as BusinessIcon,
  CheckBoxOutlined as CheckBoxIcon,
  AcUnit as AcUnitIcon,
  AccessibilityNew as AccessibilityNewIcon,
  People as PeopleAltIcon,
  NotificationsActive as NotificationsActiveIcon,
  BeachAccess as BeachAccessIcon,
  ContactPhone as ContactPhoneIcon,
  LiveHelp as LiveHelpIcon,
  BorderAll as BorderAllIcon,
  LabelImportant as LabelImportantIcon,
  Category as CategoryIcon,
  MusicNote as MusicIcon

} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
//import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

const structure = [
  {
    id: 0,
    label: "Dashboard",
    link: "/app/dashboard",
    icon: <HomeIcon />
  },
  {
    id: 1,
    label: "Company Mgmt.",
    link: "/app/company",
    icon: <BusinessIcon />
  },
  {
    id: 2,
    label: "Survey Mgmt.",
    link: "/app/survey/",
    icon: <CheckBoxIcon />
  },
  {
    id: 3,
    label: "Tracker Mgmt.",
    link: "/app/bucket",
    icon: <AccessibilityNewIcon />,
    children: [
      { label: "Tracker", link: "/app/tracker" },
      { label: "Activity", link: "/app/activity" },
      { label: "Resource", link: "/app/resource" },
    ],
  },
  {
    id: 5,
    label: "Category Mgmt.",
    link: "/app/questiontype",
    icon: <TextFieldsIcon />,
    children: [
      { label: "QuestionType", link: "/app/questiontype" },
      { label: "ResourceType", link: "/app/resourcetype"},
      { label: "ParameterType", link: "/app/parametertype"},

    ],
  },
  {
    id: 5,
    label: "Expert Mgmt.",
    link: "/app/questiontype",
    icon: <PeopleAltIcon />,
    children: [
      { label: "Expert", link: "/app/expert" },
      { label: "Expert Category", link: "/app/expertcategory"},
      { label: "Expert Enqiry", link: "/app/expertenqiry"},
      // { label: "ExpertCategoryEnqiry", link: "/app/expertcategoryenqiry"},

    ],
  },
  {
    id: 6,
    label: "InsStory",
    link: "/app/insstory/",
    icon: <AcUnitIcon />
  },
  {
    id: 6,
    label: "Benchmark",
    link: "/app/benchmark/",
    icon: <BorderAllIcon />
  },
  {
    id: 6,
    label: "Music",
    link: "/app/music",
    icon: <MusicIcon/>
  },
  {
    id: 7,
    label: "Badge",
    link: "/app/badge/",
    icon: <LabelImportantIcon />
  },
  {
    id: 8,
    label: "Notification",
    link: "/app/notification/",
    icon: <NotificationsActiveIcon />
  },
  {
    id: 9,
    label: "Parameter",
    link: "/app/parameter/",
    icon: <BeachAccessIcon />
  },
  {
    id: 10,
    label: "Enquiry",
    link: "/app/enquiry/",
    icon: <ContactPhoneIcon />
  },
  {
    id: 11,
    label: "FAQ",
    link: "/app/faq/",
    icon: <LiveHelpIcon />
  },
  {
    id: 12,
    label: "HabitCategory",
    link: "/app/habitcategory/",
    icon: <CategoryIcon />
  },
  {
    id: 12,
    label: "PremiumMusic",
    link: "/app/premiummusic/",
    icon: <CategoryIcon />
  },
  {
    id: 12,
    label: "PremiumActivity",
    link: "/app/premiumactivity/",
    icon: <CategoryIcon />
  },
  // {
  //   id: 13,
  //   label: "ExpertCategory",
  //   link: "/app/expertcategory/",
  //   icon: <LiveHelpIcon />
  // },
  
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();

  // var  isSidebarOpened  = true;

  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
