import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Select,
  MenuItem,
  Input
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function UpdateParameter(props) {

  const {data, classes, updateformData,submitted, handleClose, handleParameterName,handleParameterTypeName,
  parameterTypesList, submitUpdateParameterDetails} = props;


    console.log("parameterTypesList: ",parameterTypesList)


    console.log("updateformDataX: ",updateformData)



  return (
    <div >

<AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Update New Parameter
            </Typography>
              <Button color="inherit" onClick={handleClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
              Update Parameter
              </Typography>

          
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                        <TextField
                          label="Name*"
                          id="name"
                          name="name"
                            fullWidth
                            rowsMax={4}
                            placeholder="Name"
                            value={updateformData.name}
                            validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <TextField
                          label="Desc"
                          id="desc"
                          name="desc"
                            fullWidth
                            rowsMax={4}
                            placeholder="Desc"
                            value={updateformData.desc}
                            // validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <TextField
                          label="Tagline"
                          id="tagline"
                          name="tagline"
                            fullWidth
                            rowsMax={4}
                            placeholder="Tagline"
                            value={updateformData.tagline}
                            // validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12}>
                                              <Select
                                                name="parameterTypeId"
                                                value={updateformData.parameterTypeId ? updateformData.parameterTypeId : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Parameter Type"
                                                onChange={handleParameterTypeName}
                                                input={<Input id="parameterTypeId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Parameter Type
                                                </MenuItem>
                                                {
                                                  parameterTypesList && parameterTypesList.length > 0 ?
                                                  parameterTypesList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Select
                                                name="parentId"
                                                value={updateformData.parentId ? updateformData.parentId : data && data.length > 0 ? data[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Parent"
                                                onChange={handleParameterName}
                                                input={<Input id="parentId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Parent
                                                </MenuItem>
                                                {
                                                  data && data.length > 0 ?
                                                  data.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
                                        </Grid>

                </Grid>
                <Grid item xs={12} sm={12}>
                        <TextField
                            label="Icon"
                            id="icon"
                            name="icon"
                            fullWidth
                            rowsMax={4}
                            placeholder="Icon"
                            value={updateformData.icon}
                            // validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <TextField
                            label="Color Code"
                            id="colorCode"
                            name="colorCode"
                            fullWidth
                            rowsMax={4}
                            placeholder="Color Code"
                            value={updateformData.colorCode}
                            // validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                        <TextField
                            label="Progress Bar Color"
                            id="barColor"
                            name="barColor"
                            fullWidth
                            rowsMax={4}
                            placeholder="Progress Bar Color"
                            value={updateformData.barColor}
                            // validators={['required']}
                            onChange={handleParameterName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                  onClick={()=>submitUpdateParameterDetails()}
                  style={{marginTop:"5%"}}
                >
                  Save
                </Button>
       
            </div>
          </Container>



</div>
  );
}
