import React from 'react';
import Widget from "../../../components/Widget/Widget";
import {createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  TextField,
  IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';

import EmployeeDetailTable from "../components/Employee/EmployeeDetailTable";

const theme = createMuiTheme();

export default function AddUser(props) {

  const { classes,  handleEDClose,handleSearch, employeeoffset, onRowClick,
    onDeleteClick, onDelete, onDisable, onUpdate, viewDetails , employeesize, employeeitems,employeetotal,employeehandleClick ,companydetails  } = props;

  return (
    <div >

<AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleEDClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
              {companydetails && companydetails.name ? companydetails.name : "NA"}
            </Typography>
              <Button color="inherit" onClick={handleEDClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={3}>
                  <h3>Employee List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  {/* <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button> */}
                </Grid>
              </Grid>

              {
                employeeitems && employeeitems.length > 0 ?
                  <>
                    <EmployeeDetailTable data={employeeitems} offset={employeeoffset} onRowClick={onRowClick}
                      onDeleteClick={onDeleteClick}
                      onDelete={onDelete}
                      isShowMoreButton={true}
                      onDisable={onDisable} 
                      onUpdate={onUpdate}
                      viewDetails={viewDetails}/>

                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                employeetotal && employeetotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={employeesize}
                      offset={employeeoffset}
                      total={employeetotal}
                      onClick={(e, employeeoffset, employeepage) => employeehandleClick(employeeoffset, employeepage)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
          </Grid>


    </div>
  );}