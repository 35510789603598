import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Select,
  Input,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
// import Select2 from 'react-select';


export default function AddMusic(props) {

  const { classes, formData, handleFileAudio,submitted, handleClose, handleSubmit, handleChangeInput, listOfMusicCategory,
    handleFile, handleUpload, handleUploadAudio,handleCategoryType, imageWebURL ,categoryList, audioWebURL } = props;
  let colourOptions = []
  if (listOfMusicCategory && listOfMusicCategory.length > 0) {
    listOfMusicCategory.forEach((emp) => {
      colourOptions.push(
        {
          value: emp.name,
          label: emp.name,
          id: emp.id,
        }
      )
    })
  }
  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Music
            </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Music
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="name"
                  id="name"
                  value={formData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Desc*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="desc"
                  id="desc"
                  value={formData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="duration*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="duration"
                  id="duration"
                  value={formData.duration}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="category*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="category"
                  id="category"
                  value={formData.category}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              <Grid item xs={12}>
                                              <Select
                                                name="category"
                                                value={formData.category ? formData.category : categoryList && categoryList.length > 0 ? categoryList[0] : ''}
                                                style={{ width: '100%' }}
                                                label="Category Type"
                                                onChange={handleCategoryType}
                                                input={<Input id="category" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Category Type
                                                </MenuItem>
                                                {
                                                  categoryList && categoryList.length > 0 ?
                                                  categoryList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="points*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="points"
                  id="points"
                  value={formData.points}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="composer*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="composer"
                  id="composer"
                  value={formData.composer}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  disabled={true}
                  onChange={handleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={formData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="image">
                  <Button color="default" variant="contained" component="span" >
                    Browse Image
                    </Button>
                </label>
                <input
                  style={{ display: 'none' }}
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleFile}
                />
                <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                  Set
                  </Button>
                {(imageWebURL) ?
                  <Grid item xs={12} style={{ margin: '4%' }}>
                    <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                  </Grid>
                  : null}
              </Grid>




              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Audio Link*"
                  fullWidth
                  disabled={true}
                  onChange={handleChangeInput}
                  name="audioLink"
                  id="audioLink"
                  value={formData.audioLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="audio">
                  <Button color="default" variant="contained" component="span" >
                    Browse Audio
                    </Button>
                </label>
                <input
                  style={{ display: 'none' }}
                  id="audio"
                  name="audio"
                  type="file"
                  onChange={handleFileAudio}
                />
                <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUploadAudio()}>
                  Set
                  </Button>
                {/* {(audioWebURL) ?
                  <Grid item xs={12} style={{ margin: '4%' }}>
                    <img src={audioWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                  </Grid>
                  : null} */}
              </Grid>

            </Grid>

        <div style={{margin:'20px'}}></div>



            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}