import {
  questionsConstants
} from '../_constants';

export function questions(state = {}, action) {

  switch (action.type) {


      case questionsConstants.QUESTIONS_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case questionsConstants.QUESTIONS_GETALL_SUCCESS:
        return {
          ...state,
          questionsList: action.questions.questionsList,
          answersList: action.questions.answersList,
          loading:false
        };
      case questionsConstants.QUESTIONS_GETALL_FAILURE:
        return {
          ...state,
          error: action.error,
          loading:false

        };



    case questionsConstants.ADD_QUESTIONS_REQUEST:
      return {
        ...state,
        loading:true
      };
    case questionsConstants.ADD_QUESTIONS_SUCCESS:
      return {
        ...state,
        addQuestionsSuccess: true,
        loading:false,
        createQuestion:action.questions.createQuestion
      };
    case questionsConstants.ADD_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading:false
      };

      case questionsConstants.DELETE_QUESTIONS_REQUEST:
        return {
          ...state,
          loading:true
        };
      case questionsConstants.DELETE_QUESTIONS_SUCCESS:
        return {
          ...state,
          deleteQuestionsSuccess: true,
          loading:false
        };
      case questionsConstants.DELETE_QUESTIONS_FAILURE:
        return {
          ...state,
          error: action.error,
          loading:false
        };

    case questionsConstants.UPDATE_QUESTIONS_REQUEST:
      return {
        ...state,
        loading:true
      };
    case questionsConstants.UPDATE_QUESTIONS_SUCCESS:
      return {
        ...state,
        updateQuestionsSuccess: false,
        // questionsList: action.questions.questionsList,
        // answersList: action.questions.answersList
        loading:false

      };
    case questionsConstants.UPDATE_QUESTIONS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading:false
      };

      case questionsConstants.DELETE_QUESTIONOPTION_REQUEST:
        return {
          ...state,
          loading:true
        };
      case questionsConstants.DELETE_QUESTIONOPTION_SUCCESS:
        return {
          ...state,
          deleteQuestionOption: true,
          loading:false
        };
      case questionsConstants.DELETE_QUESTIONOPTION_FAILURE:
        return {
          ...state,
          error: action.error,
          loading:false
        };

      case questionsConstants.ANSWERS_SUBMIT_REQUEST:
        return {
          ...state,
          loading:true
        };
      case questionsConstants.ANSWERS_SUBMIT_SUCCESS:
        return {
          ...state,
          answerSubmitSuccess: true,
          loading:false
        };
      case questionsConstants.ANSWERS_SUBMIT_FAILURE:
        return {
          ...state,
          error: action.error,
          loading:false
        };



    default:
      return state
  }
}