import React, { Component } from 'react';

import { connect } from 'react-redux';



import { questionsActions } from '../../../_actions';



class QuestionPublish extends Component {
  constructor(props) {
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      isAdd:false,
      editId: null,
      QuesName: "",
      OptionName: "",
      dropdown: "Choose",
      radioButton:false,
      radioButton2:false,
      checkButton:false,
      starCount:0,
      starCount2:0,
      dropName:null,
      dropQID:null,

      radioButtonArray:[],


      save: false,
      questionsize: 10,
      editCurrentQuestion: {},
      editCurrentOption:{},

      ansTextBox:"",

      currQues:null
    }
  }
  
  componentDidMount() {
    let survey = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(survey));


  }


  // componentDidUpdate (){
  
  // }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log(" nextProps  addSurveySuccess", nextProps);
  // }

  


  onEditQues = (currentQuestion) => {

    this.setState({ editId: currentQuestion.id });
    this.setState({ Ques: currentQuestion, editCurrentQuestion: currentQuestion});
  }

  handleQuestions = (event) => {
    let { questions } = this.props;
    let { questionsList } = questions;
 
    this.props.dispatch(questionsActions.handleNameQuestion(questionsList, this.state.editCurrentQuestion, event.target.value));
   
    console.log(this.state.editCurrentQuestion)
  
  }

  onEditOption = (currentQuestion, currentOption) => {

    this.setState({ editId: currentQuestion.id });
    this.setState({ Ques: currentQuestion, editCurrentQuestion: currentQuestion, editCurrentOption: currentOption });
  }

  handleOptions = (event,option) => {
    let { questions } = this.props;
    let { questionsList } = questions;
  
    this.props.dispatch(questionsActions.handleNameOption(questionsList, this.state.editCurrentQuestion, this.state.editCurrentOption, event.target.value));
   
  }



  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }
  onDeleteQues = (Ques) => {
    let data = {
      id: Ques.id,
      surveyId: Ques.surveyId
    }
    this.props.dispatch(questionsActions.deleteQuestion(data));
  }
 
  onSaveQues = (Ques) => {
    this.props.dispatch(questionsActions.updateQuestion(Ques,false));
    this.setState({ editId: null });
  }

  AddQues(){
    if(this.state.isAdd){
      this.setState({ isAdd: null });
    }
    else{
      this.setState({ isAdd: true });
    }
    
  }



  AddQuesType=(quesType)=>{

    let Ques={};

    if(quesType.templateId===1||quesType.templateId===2||quesType.templateId===9){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,
        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
                {name:"Option 1"},
                {name:"Option 2"}
            ]
        }

    }
    else if(quesType.templateId===10){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,
        surveyId: this.props.match.params.id,
        name: "This is a statement!",
        options: []
        }

    }
    else if(quesType.templateId===11){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,
        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
                {name:"Yes"},
                {name:"No"}
            ]
        }

    }
    else if(quesType.templateId===5||quesType.templateId===6){
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,
        surveyId: this.props.match.params.id,
        name: "Question",
        options: [
          {name:"1"},
          {name:"2"},
          {name:"3"},
          {name:"4"},
          {name:"5"},
          {name:"6"},
          {name:"7"},
          {name:"8"},
          {name:"9"},
          {name:"10"}
        ]
        }

    }
    else{
      Ques ={
        questionTypeId: quesType.id,
        templateId: quesType.templateId,
        surveyId: this.props.match.params.id,
        name: "Question",
        options: []
        }

    }
  

    
    this.props.dispatch(questionsActions.createQuestion(Ques));
 
    this.setState({ isAdd: false });
  
  }

  deleteOption=(ques,opt)=>{
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.deleteOption(questionsList, ques, opt));
  }

  addOption=(ques)=>{
    let { questions } = this.props;
    let { questionsList } = questions;
   
    this.props.dispatch(questionsActions.addOption(questionsList, ques));
  
  }

  handleRadio=(Index,ques,currentOption)=>{

    let { questions } = this.props;
    let { questionsList, answersList } = questions;


    if(this.state.radioButton===currentOption._id){

      this.setState({radioButton:null});
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, "",0));
    }
    else{

      this.setState({radioButton:currentOption._id})
      console.log("currentOption::",currentOption)
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, currentOption,currentOption.weightage));
    }
   
  }

  handleRadioX=(e)=>{
    console.log("handleRadioX: ",e.target.value )
    
  }


  handleRadio2=(Index,ques,currentOption)=>{

    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    if(this.state.radioButton2===currentOption._id){
      this.setState({radioButton2:null});
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, "",1));
    }
    else{
      this.setState({radioButton2:currentOption._id})
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, currentOption,currentOption.weightage));
    }
   
  }
  onDropdown=(Index,ques,currentOption)=>{


    if(this.state.radioButton===currentOption._id){
      this.setState({radioButton:null});
      this.setState({dropName:null})
      this.setState({dropQID:null})
    }
    else{
      this.setState({radioButton:currentOption._id})
      this.setState({dropName:currentOption.name})
      this.setState({dropQID:ques.id})
    }
   
  }
 

  handleCheck=(optionindex,ques, currentOption)=>{

    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, currentOption, currentOption.weightage ,optionindex));
   
  }



  handleStars=(data, ques, option)=>{



    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    if(data==='undo'){
      this.setState({starCount:null});
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, "",1));
    }
    else{
      this.setState({starCount:data+1});
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, option, option.weightage));
      
    }

   


  


  }

  handleScale=(data,ques, option)=>{
    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    if(data==='undo'){
      this.setState({starCount2:null})
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, "",1));
    }
    else{
      this.setState({starCount2:data+1});
      this.props.dispatch(questionsActions.handleSubmitPreChange(ques, answersList,questionsList, option,option.weightage));
      
    }
  }

 

  // surveySubmit(){

  //   let survey = {
  //     surveyId: this.props.match.params.id,
  //     employeeId: this.props.match.params.idc,
  //   }
  //   let { questions } = this.props;
  //   let { answersList } = questions;

  //   this.props.dispatch(questionsActions.surveySubmit(answersList,survey,this.props ));

  //   console.log("Final Answer: ",answersList)
  // }

  goToThankyouPage(){
    let survey = {
      surveyId: this.props.match.params.id
    }
     this.props.history.push(`/app/thankyoupage/${survey.surveyId}`)
  }

  ansTextBoxClick(ques){
    this.setState({currQues:ques});
  }

  ansTextBoxChange=(e)=>{

    let { questions } = this.props;
    let { questionsList, answersList } = questions;

    let ansData ={
      _id:"",
      name:e.target.value
    }

    this.props.dispatch(questionsActions.handleSubmitPreChange(this.state.currQues, answersList,questionsList, ansData,this.state.currQues.options[0].weightage));
  }



  render() {
    let { questions } = this.props;
       
    let { questionsList, answersList } = questions;

    console.log("answersList:---------------------------------> ",answersList)

    console.log("radio Aarry :---------------------------------> ",this.state.radioButtonArray)

    
 
    return (
      <>
        <div className="container" style={{marginTop:'0px'}}>

      
          {
            (questionsList && questionsList.length) > 0 ?
              questionsList.map((question, questionindex) => (
                <div className="container" key={questionindex} >
                  <div className="card" variant="outlined" style={{backgroundColor:'white', marginBottom:'10px'}}>
                    <div className="card-body" style={{paddingTop:'3%',paddingBottom:'3%',paddingLeft:'3%'}}>

               
                       <br />
                  
                        <div className="container" key={questionindex}>
                          <div className="container">
                              {question.templateId===10?
                                   <h2 variant="h2"  style={{fontStyle:'italic'}}  >
                                   {`"${question.name}"`}
                                 </h2>:
                                  <h4 variant="h4" >
                                  {questionindex + 1}) {question.name}
                                </h4>
                            }
                           
                          </div>

                          {(question.templateId===1)?
                          <div className="container" >
                                    <div className="container">
                                      <div className="container">
                                        {question.options.map((option, optionindex) =>
                                                    <div className="container" key={optionindex} >   

                                                    <input type="radio" 
                                                    checked={
                                                       answersList.some(item => option._id === item.selected)
                                                    } 
                                                    value={option._id} onChange={this.handleRadioX} onClick={()=>this.handleRadio(optionindex,question, option)} className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                      
                                                    <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===2)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container">
                                        {question.options.map((option, optionindex) =>
                                                  <div className="container" key={optionindex} onClick={()=>this.handleCheck(optionindex, question, option)}>

                                                                <input type="checkbox" className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                     
                                                    <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {/* TextBox */}
                          {(question.templateId===3)?
                          <div className="container" style={{marginTop:'20px'}}>
                                    <div className="container">
                                      <div className="container">
                                          <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8  , width:'50%' }}
                                          variant="outlined"
                                          margin="normal"
                                          placeholder="Text Box"
                                          onClick={()=>this.ansTextBoxClick(question)}
                                          onChange={this.ansTextBoxChange}
                                      />
                                    
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===4)?
                          <div className="container">
                                    <div className="container" >
                                      <div className="container">
                               
                                      <textarea style={{ margin: 8, marginTop: '20px', width:'70%' }} margin="normal" 
                                      className="px-2 col form-control" variant="outlined" aria-label="minimum height" 
                                      rows={4} cols={60} placeholder="Multi-line Text" 
                                      onClick={()=>this.ansTextBoxClick(question)}
                                      onChange={this.ansTextBoxChange}/>
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===5)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      {question.options.map((option, optionindex) =>
                                        
                                      
                                                    ((this.state.starCount > optionindex) && (this.state.starCount))?
                                                    <i className="text-info fa fa-star" aria-hidden="true" onClick={()=>this.handleStars(optionindex,question, option)} key={optionindex} style={{marginLeft:'10px',marginRight:'10px',fontSize:'40px'}}></i>                       
                                                    :
                                                    <i className="fa fa-star-o" aria-hidden="true" onClick={()=>this.handleStars(optionindex,question, option)} key={optionindex} style={{marginLeft:'10px',marginRight:'10px',fontSize:'40px'}}></i>
                                               
                                      
                                              )}
                                      <i style={{marginLeft:'32px',color:'gray'}} onClick={()=>this.handleStars('undo',question, "")} className="fa fa-undo" aria-hidden="true"></i>
                         
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===6)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                        
                                     <div style={{borderColor:'black',borderWidth:'5px'}} >

                                      {question.options.map((option, optionindex) =>
                                              
                                              ((this.state.starCount2 > optionindex) && (this.state.starCount2))?
                                                <button
                                              key={optionindex}
                                              color="primary"
                                              size="small"
                                              className="px-4 btn btn-info"
                                              variant="contained"
                                              onClick={()=>this.handleScale(optionindex,question, option)}
                                              style={{ marginRight: '5px' }} >
                                              {optionindex+1}
                                          </button>
                                              :
                                                 <button
                                                key={optionindex}
                                              color="secondary"
                                              size="small"
                                              className="px-4 btn btn-secondary"
                                              variant="contained"
                                              onClick={()=>this.handleScale(optionindex,question, option)}
                                              style={{ marginRight: '5px' }} >
                                              {optionindex+1}
                                          </button>
                                  
                                              )}
                                      <i style={{marginLeft:'32px',color:'gray'}} onClick={()=>this.handleScale('undo',question, "")} className="fa fa-undo" aria-hidden="true"></i>
                                       </div>
                                        
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===7)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="email"
                                          placeholder="example@gmail.com"
                                          onClick={()=>this.ansTextBoxClick(question)}
                                          onChange={this.ansTextBoxChange}
                                        />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===8)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="number"
                                          placeholder="Number"
                                          onClick={()=>this.ansTextBoxClick(question)}
                                          onChange={this.ansTextBoxChange}
                                        />
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===9)?
                          <div className="container">
                                    <div className="container">
                                      <div className="container"style={{marginTop:'20px'}}>

                                      <div className="dropdown show">
                                          <a style={{fontSize:'17px',fontWeight:'normal',textDecoration:'None'}} href="asd" className="btn btn-secondary dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          <input className="form-control" value={(this.state.dropName&&this.state.dropQID)?this.state.dropName:'Choose...'}/>
                                          </a>

                                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink" style={{paddingLeft:'50px'}}>
                                          {question.options.map((option, optionindex) =>
                                             <p style={{padding:'10px',fontWeight:'bold',fontSize:'17px',color:(this.state.radioButton===option._id)?'#1e50bd':null}}  className=" dropdown-item row " key={optionindex} onClick={()=>this.onDropdown(optionindex,question, option)}>{option.name}</p>
                                            )} 
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===11)?
                          <div className="container">
                                    <div className="container" >
                                      <div className="container" style={{marginTop:'20px'}}>
                                      {question.options.map((option, optionindex) =>
                                                          <div className="container" key={optionindex} onClick={()=>this.handleRadio2(optionindex,question, option)}>
                                                            {(this.state.radioButton2 === option._id)?
                                                    <input type="radio" checked="false" className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                    :
                                                    <input type="radio"  className="form-check" style={{marginTop:'15px',fontSize:'25px'}}/>
                                                  }

                                                     <h4 variant="h4"  style={{marginLeft:'15px',display:'inline'}}  >
                                                      {option.name}
                                                    </h4>
                                                  </div> )}
                                      </div>
                                    </div>
                          </div>:null }
                          {(question.templateId===12)?
                          <div className="container" >
                                    <div className="container">
                                      <div className="container" style={{marginTop:'20px'}}>
                                      <input                
                                          className="px-2 col form-control"
                                          style={{ margin: 8 , width:'50%'}}
                                          variant="outlined"
                                          margin="normal"
                                          type="date"
                                          placeholder="Date"
                                          onClick={()=>this.ansTextBoxClick(question)}
                                          onChange={this.ansTextBoxChange}
                                        />
                                      </div>
                                    </div>
                          </div>:null }


                          <br />
                        </div>
                    </div>

                  </div>
                
                </div>



              )) :null
          }

{/* {
            (questionsList && questionsList.length) > 0 ? */}

             <div className="container">
             <div className="card" style={{backgroundColor:'white'}}>
             <div className="card-body" style={{margin:"20px", padding:'3%'}}>
           
                    <div className="container">
                    <button
                        color="secondary"
                        size="small"
                        onClick={() => this.goToThankyouPage()}
                        className="px-2 btn btn-primary"
                        variant="contained"
                        style={{marginLeft:'39%'}}
                     >
                        Submit
                      </button>
                      </div>
                
                    </div>

                  </div>
                </div>
                
                
                 {/* :null} */}


        </div>
      </>
    );
  }
}
QuestionPublish.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(QuestionPublish);
