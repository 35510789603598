
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const expertenqiryService = {
    disableExpertEnqiry,
    logout,
    createNewExpertEnqiry,
    updateExpertEnqiry,
    getAllExpertEnqiry,
    deleteExpertEnqiry
};
function logout() {
    localStorage.removeItem('adminexpertenqiry');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllExpertEnqiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getExpertCategoryEnquiryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let expertenqiryObj = {
                listOfExpertEnqirys: data.data
            }
            // console.log("gffjghk",expertenqiryObj);
            return expertenqiryObj;
        });
}

function createNewExpertEnqiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createExpertEnqiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertenqiryObj = {
                createNewExpertEnqiry: data.data
            }
            console.log();

            return expertenqiryObj;
        });
}
function updateExpertEnqiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpertEnqiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertenqiryObj = {
                updateNewExpertEnqiry: data.data
            }
            console.log(expertenqiryObj);

            return expertenqiryObj;
        });
}

function deleteExpertEnqiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteExpertEnqiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertenqiryObj = {
                deleteNewExpertEnqiry: data.data
            }
            console.log(expertenqiryObj);

            return expertenqiryObj;
        });
}

function disableExpertEnqiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpertEnqiryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertenqiryObj = {
                deleteExpertEnqiry: data.data
            }
            console.log();

            return expertenqiryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}