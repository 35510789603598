import { expertConstants } from '../_constants';
import { expertService } from '../_services';
import { alertActions, userActions } from '.';
export const expertActions = {
    disableExpert,
    createNewExpert,
    updateExpert,
    getAllExpert,
    deleteExpert
};

function getAllExpert(data) {
    return dispatch => {
        dispatch(request());
        expertService.getAllExpert(data)
            .then(
                expert => dispatch(success(expert)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertConstants.EXPERT_GETALL_REQUEST } }
    function success(expert) { return { type: expertConstants.EXPERT_GETALL_SUCCESS, expert } }
    function failure(error) { return { type: expertConstants.EXPERT_GETALL_FAILURE, error } }
}
function createNewExpert(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        expertService.createNewExpert(data)
            .then(
                expert => {
                    dispatch(success(expert));
                    dispatch(this.getAllExpert(tempdata));
                    dispatch(alertActions.success("Expert created successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertConstants.ADD_EXPERT_REQUEST } }
    function success(expert) { return { type: expertConstants.ADD_EXPERT_SUCCESS, expert } }
    function failure(error) { return { type: expertConstants.ADD_EXPERT_FAILURE, error } }
}

function updateExpert(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        expertService.updateExpert(data)
            .then(
                expert => {
                    dispatch(success(expert));
                    dispatch(this.getAllExpert(tempdata));
                    dispatch(alertActions.success("Expert updated successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertConstants.UPDATE_EXPERT_REQUEST } }
    function success(expert) { return { type: expertConstants.UPDATE_EXPERT_SUCCESS, expert } }
    function failure(error) { return { type: expertConstants.UPDATE_EXPERT_FAILURE, error } }
}

function deleteExpert(data, dataemp) {

    return dispatch => {
        dispatch(request());
        expertService.deleteExpert(data)
            .then(
                expert => {
                    dispatch(success(expert));
                    dispatch(this.getAllExpert(dataemp));
                    dispatch(alertActions.success("Expert deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertConstants.DELETE_EXPERT_REQUEST } }
    function success(expert) { return { type: expertConstants.DELETE_EXPERT_SUCCESS, expert } }
    function failure(error) { return { type: expertConstants.DELETE_EXPERT_FAILURE, error } }
}
function disableExpert(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        expertService.disableExpert({ id: data.id })
            .then(
                expert => {
                    dispatch(success(expert));
                    dispatch(this.getAllExpert(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertConstants.ADD_EXPERT_REQUEST } }
    function success(expert) { return { type: expertConstants.ADD_EXPERT_SUCCESS, expert } }
    function failure(error) { return { type: expertConstants.ADD_EXPERT_FAILURE, error } }
}