import React, { Component } from 'react';
import {
  Grid,
  // Button,
  Typography,
  // CssBaseline,
  TextField,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Select,
  MenuItem,
  Input
  // Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
// import Widget from "../../components/Widget/Widget";
// import Table from "./components/Table/Table";
import { companyActions, notificationActions } from '../../_actions';
// import { confirmAlert } from 'react-confirm-alert'; // Import
import {  withStyles } from "@material-ui/core/styles";
// import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// const theme = createMuiTheme();

class Notification extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newNotificationModal: false,
      newUpdateNotificationModal:false,
      submitted: false,
      formData: {
        "message": "",
        "title": "",
        "companyId": null,
        "emp_id":null,
        "type":null
      },
      employeesize: 10,
      employeeoffset: 0,
      employeepage: 0,
      companyName:'',
      EmployeeName:'',
      showCompanyTable:true,
      showEmployeeTable:false,
      employees:[]
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": 1000
    }
    this.props.dispatch(companyActions.getAllCompany(data));
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.notification.addNotificationSuccess) {
      return {
        ...nextProps,
        formData: {
          "message": "",
          "title": "",
          "companyId": "",
          "emp_id":"",
          "type":null

        },
        newNotificationModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  handleChangeInput=(event)=>{
    let { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({formData})
  }

  handleCompanyInput=(event)=>{
    let { formData } = this.state;
    formData["companyId"] = event.target.value;

    let dataemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": event.target.value
    }
  
    this.props.dispatch(companyActions.getAllEmployees(dataemployee));

    this.setState({formData})
  }
  onSelectCompany=(value)=>{
    let { formData } = this.state;
    if(formData.companyId===value.id){
      formData["companyId"] = null;
      this.setState({companyName:""})
    }
    else{
      formData["companyId"] = value.id;
      this.setState({companyName:value.name})

    let dataemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": value.id
    }
  
    this.props.dispatch(companyActions.getAllEmployees(dataemployee));
    this.onClickEmployee();
  }
  this.setState({formData})
  }
  
  // onSelectEmployee=(value)=>{
  //   let { formData } = this.state;
  //   if(formData.emp_id===value.id){
  //     formData["emp_id"] = null;
  //     this.setState({EmployeeName:""})
  //   }
  //   else{
  //     formData["emp_id"] = value.id;
  //     this.setState({EmployeeName:value.name})
  //   }
  //   this.setState({formData})
  // }


  onSelectEmployee = (emp) => {


    if (true) {

      console.log("--------------EMP: ", emp.email, this.state.employees.length);
      const { formData } = this.state;


      if (this.state.employees.length > 0) {
        var matchFlag = null;
        this.state.employees.forEach((selectedEmp, empIndex) => {

          if (emp.id === selectedEmp) {
            matchFlag = 1
            var data = this.state.employees;
            data.splice(empIndex, 1);
            if(data.length===0){
              formData['emp_id'] = null;
            }
            else{
              formData['emp_id'] = data;
            }
            this.setState({ formData });

          }

        });

        if (matchFlag === 1) {
          console.log("matchFlag = 1:---->", this.state.employees)
        }
        else {
          var data = this.state.employees;
          data.push(emp.id)


          formData['emp_id'] = data;
          this.setState({ formData });

          console.log("matchFlag = 0:---->", this.state.employees)
        }



      }
      else {

        var datax = this.state.employees;

        datax.push(emp.id)

        console.log("setZero:---->", this.state.employees)
        formData['emp_id'] = datax;
        this.setState({ formData });
      }


    }


  }




  handleEmployeeInput=(event)=>{
    let { formData } = this.state;
    formData["emp_id"] = event.target.value;
    this.setState({formData})
  }


  submitNotification=()=>{
    let { formData } = this.state;
    console.log('formData: ',formData)
    this.props.dispatch(notificationActions.saveEmployeeNotificationList(formData));

  }
  handleEmplty=()=>{
    //empty code
  }

  onClickCompany=()=>{
    this.setState({showCompanyTable:true, showEmployeeTable:false});
  }

  onClickEmployee=()=>{
    this.setState({showEmployeeTable:true, showCompanyTable:false});
  }

  handleSelectCompany=()=>{
    let { formData } = this.state;
    formData["companyId"] = null;
    formData["emp_id"] = null;
    this.setState({formData, companyName:'All Companies'})
  }


  handleSelectEmployee=()=>{
    let { formData } = this.state;
    formData["emp_id"] = null;
    this.setState({formData, EmployeeName:'All Employees',employees:[]})
  }


  handleNotififcationTypeName=(e)=>{
    let { formData } = this.state;
    if(e.target.value==="none"){
      formData["type"] = null;
    }else{
      formData["type"] = e.target.value;
    }
    this.setState({formData})
  }

  render() {

    let {  company } = this.props;
    let {  allEmployeesList,  items } = company;
    const { formData,  companyName ,employees} = this.state;
    console.log('formData: ',formData);

    let NotificationTypeList = ["none","quotes","music","stories","resources"];

    return (
      <>
        <PageTitle title="Notification" />

      <Grid container spacing={2} style={{ margin:'2% 0 0 2%' }} >
     

          <Grid item xs={4} >
            <div className="card" style={{ backgroundColor: 'white'}}>
              <div style={{ padding: '5%', paddingTop: '5%' }}>
                <div style={{ backgroundColor: '#ededed', padding: '5%', marginBottom: '5%', 
                fontSize: '25px', paddingLeft: '30%' }}>Push Notification</div>
                <form>
                  <div className="form-group"  >
                    <label htmlFor="exampleFormControlInput1">Title</label>
                    <TextField
                      id="standard-multiline-flexible"
                      fullWidth
                      rowsMax={4}
                      name="title"
                      placeholder="Title"
                      value={formData.title}
                      onChange={this.handleChangeInput}
                    />
                  </div>
                  <div className="form-group"  >
                  <label htmlFor="exampleFormControlInput1">Message</label>
                  <TextField
                      fullWidth
                      multiline
                      onChange={this.handleChangeInput}
                      name="message"
                      id="message"
                      placeholder="Message"
                      rowsMax={4}
                      value={formData.message}
                    />
                    </div>
                  {/* <div className="form-group"  >
                    <label htmlFor="exampleFormControlInput1">Email Message</label>
                    <ReactQuill theme="snow" value={formData.message} onChange={handleCampaignEmail} />
                  </div> */}
                  <div className="form-group" onClick={()=>this.onClickCompany} >
                    <label htmlFor="exampleFormControlInput1" >Company</label>
                    <TextField
                      id="standard-multiline-flexible"
                      fullWidth
                      name="companyId"
                      placeholder="Company"
                      value={companyName}
                      onChange={this.handleEmplty}
                      onClick={()=>this.onClickCompany()}

                    />
                  </div>
                  {formData.companyId?
                         <div className="form-group"  >
                          <label htmlFor="exampleFormControlInput1" >Employee</label>
                          <TextField
                            id="standard-multiline-flexible"
                             fullWidth
                             name="emp_id"
                              placeholder="Employee"
                              value={(employees && employees.length)?employees.length:'All Employees'}
                              onChange={this.handleEmplty}
                             onClick={()=>this.onClickEmployee()}
                          />
                                  </div>
                    :null}


                  <div className="form-group">
                    <label htmlFor="exampleFormControlInput1" >Notififcation Type</label>
                    <Select
                      name="type"
                      value={formData.type ? formData.type : NotificationTypeList && NotificationTypeList.length > 0 ? NotificationTypeList[0] : ''}
                      style={{ width: '100%' }}
                      label="Notififcation Type"
                      onChange={this.handleNotififcationTypeName}
                      input={<Input id="type" />}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      <MenuItem value="" disabled>
                      Notififcation Type
                      </MenuItem>
                      {
                        NotificationTypeList && NotificationTypeList.length > 0 ?
                        NotificationTypeList.map((tempdata, index) => (
                            <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                          )) : null
                      }

                    </Select>
                  </div>



                </form>
              </div>
              <div className="form-group">
                <button className="btn btn-success" 
                style={{ marginLeft: '33%', marginBottom: '20px', display: 'inline', width: '175px' }} 
                onClick={() => this.submitNotification()}>Submit</button>
              </div>


            </div>



          </Grid>


          {this.state.showCompanyTable?
          <Grid item xs={6}>


            <div className="card" style={{ backgroundColor: 'white',height:'650px',overflow:'auto'  }}>
              <div style={{ padding: '2%'}}>

              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3 style={{color:'red'}}>Company List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  {/* <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  /> */}
                </Grid>
                <Grid item xs={1}>
                  {/* <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button> */}
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                          <Table className="mb-0">
                          <TableHead>
                                <TableRow>
                                  <TableCell >S. NO.</TableCell>
                                  <TableCell >Name</TableCell>
                                  <TableCell >
                                    <input type="checkbox" onClick={()=>this.handleSelectCompany()}
                                    checked={(formData && formData.companyId)?false:true}
                                    id="defaultsCsheck1x"  
                                    style={{marginLeft:'25px'}}/>
                                  </TableCell> 
                                </TableRow>
                              </TableHead>
                            <TableBody>
                                      {
                                        items && items.length > 0 ?
                                        items.map((tempdata, index) => (
                                          <TableRow>
                                            <TableCell ><h4> {this.state.offset + index + 1}</h4></TableCell>
                                            <TableCell ><h4> {tempdata.name}</h4></TableCell>
                                            <TableCell >
                                              <input type="checkbox" 
                                              onClick={()=>this.onSelectCompany(tempdata)} 
                                              checked={(tempdata.id===formData.companyId)?true:false}
                                              id="defaultsCsheck1"  style={{marginLeft:'25px'}}/>
                                              </TableCell>
                                          </TableRow>
                                          )) : null
                                      }
                            </TableBody>
                          </Table>
                  </>
                  : <Typography >Data not found.</Typography>
              }



              </div>
            </div>




          </Grid>   
          :null}

          {this.state.showEmployeeTable?
          <Grid item xs={6}>


            <div className="card" style={{ backgroundColor: 'white',height:'650px',overflow:'auto' }}>
              <div style={{ padding: '2%'}}>

              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3 style={{color:'blue'}}>Employee List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={1}>
                  {/* <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button> */}
                </Grid>
              </Grid>

              {
                allEmployeesList && allEmployeesList.length > 0 ?
                  <>
                          <Table className="mb-0">
                          <TableHead>
                                <TableRow>
                                  <TableCell >S. NO.</TableCell>
                                  <TableCell >Name</TableCell>
                                  <TableCell >Email</TableCell>
                                  <TableCell >
                                    <input type="checkbox" onClick={()=>this.handleSelectEmployee()}  id="defaultsCsheck1x"  
                                    checked={(formData && formData.emp_id && formData.emp_id.length>0)?false:true}
                                    style={{marginLeft:'25px'}}/>
                                  </TableCell>                                
                                  </TableRow>
                              </TableHead>
                            <TableBody>
                                      {
                                        allEmployeesList && allEmployeesList.length > 0 ?
                                        allEmployeesList.map((tempdata, index) => (
                                          <TableRow>
                                            <TableCell ><h4> {this.state.offset + index + 1}</h4></TableCell>
                                            <TableCell ><h4> {tempdata.name}</h4></TableCell>
                                            <TableCell ><h4> {tempdata.email}</h4></TableCell>
                                            <TableCell >

                                              {/* <input type="checkbox" 
                                              onClick={()=>this.onSelectEmployee(tempdata)}  
                                              checked={(tempdata.id===formData.emp_id)?true:false}
                                              id="defaultsCsheck1"  style={{marginLeft:'25px'}}/> */}

                                              <input type="checkbox" value={tempdata.id} onClick={() => this.onSelectEmployee(tempdata)} onChange={this.handleEmplty} 
                                              checked={employees && employees.length>0 && employees.some(item => tempdata.id === item)?true:false} 
                                              id="defaultsCsheck1" style={{ marginLeft: '25px' }} />
                                            

                                              </TableCell>
                                          </TableRow>
                                          )) : null
                                      }
                            </TableBody>
                          </Table>
                  </>
                  : <Typography ></Typography>
              }



              </div>
            </div>




          </Grid>   
          :null}






          </Grid>


      </>
    );

  }
}
function mapStateToProps(state) {
  const { notification,company } = state;
  return {
    notification,
    company
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Notification)));
