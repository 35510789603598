import React from "react";
import {
  Route,
  Switch,
  //Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Company from "../../pages/Company";
import CompanyDetails from "../../pages/CompanyDetails";

import Campaign from "../../pages/Campaign/Campaign";


import AddQuestion from "../../pages/AddSurveyQuestion/AddQuestion";

import QuestionPreview from "../../pages/AddSurveyQuestion/Preview/questions";
import WelcomePage from "../../pages/AddSurveyQuestion/Preview/welcome";
import ThankyouPage from "../../pages/AddSurveyQuestion/Preview/thankyou";


import User from "../../pages/User";
import { useLayoutState } from "../../context/LayoutContext";

// import Welcome from '../../pages/Surveylink/welcome';
// import Thankyou from '../../pages/Surveylink/thankyou';
// import Questions from '../../pages/Surveylink/questions';

import QuestionType from "../../pages/QuestionType";

import ResourceType from "../../pages/ResourceType/ResourceType";

import Survey from "../../pages/Survey/Survey";

import Resource from "../../pages/Resource/Resource";

import Activity from "../../pages/Activity/Activity";

import Tracker from "../../pages/Tracker/Tracker";

import InsStory from "../../pages/InsStory/InsStory";

import Expert from "../../pages/Expert/Expert";

import Notification from "../../pages/Notification/Notification";

import ParameterType from "../../pages/ParameterType/ParameterType";

import Parameter from "../../pages/Parameter/Parameter";

import Enquiry from "../../pages/Enquiry/Enquiry";

import ExpertEnqiry from "../../pages/ExpertEnqiry/ExpertEnqiry";
import ExpertCategoryEnquiry from "../../pages/ExpertCategoryEnqiry/ExpertCategoryEnquiry";

import Faq from '../../pages/Faq/Faq';

import ExpertCategory from '../../pages/ExpertCategory/ExpertCategory'

import BenchMark from '../../pages/BenchMark/BenchMark';

import Badge from '../../pages/Badge/Badge';

import HabitCategory from '../../pages/HabitCategory';

import Music from '../../pages/Music';
import PremiumMusic from '../../pages/PremiumMusic';
import PremiumActivity from '../../pages/PremiumActivity';

import Happiness from '../../pages/Happiness/Happiness';


function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
            <Route path="/app/dashboard" component={withRouter(Dashboard)} />
            <Route path="/app/user" exact component={withRouter(User)} />
            <Route path="/app/company" exact component={withRouter(Company)} />
            <Route path="/app/companydetails/:id" exact component={withRouter(CompanyDetails)} />

            <Route path="/app/survey/" exact component={withRouter(Survey)} />

            <Route path="/app/resource/" exact component={withRouter(Resource)} />
            <Route path="/app/activity/" exact component={withRouter(Activity)} />
            <Route path="/app/tracker/" exact component={withRouter(Tracker)} />

            <Route path="/app/insstory/" exact component={withRouter(InsStory)} />
            <Route path="/app/expert/" exact component={withRouter(Expert)} />
            <Route path="/app/notification" exact component={withRouter(Notification)} />

            <Route path="/app/parametertype" exact component={withRouter(ParameterType)} />

            <Route path="/app/parameter" exact component={withRouter(Parameter)} />


            <Route path="/app/questiontype" exact component={withRouter(QuestionType)} />
            <Route path="/app/resourcetype" exact component={withRouter(ResourceType)} />


            <Route path="/app/campaign/:id/:idc" exact component={withRouter(Campaign)} />


            <Route path="/app/addquestion/:id" exact component={withRouter(AddQuestion)} />
            <Route path="/app/welcomepage/:id" exact component={withRouter(WelcomePage)} />
            <Route path="/app/questionpreview/:id" exact component={withRouter(QuestionPreview)} />
            <Route path="/app/thankyoupage/:id" exact component={withRouter(ThankyouPage)} />

            <Route path="/app/enquiry" exact component={withRouter(Enquiry)} />

            <Route path="/app/faq" exact component={withRouter(Faq)} />
            <Route path="/app/expertcategory" exact component={withRouter(ExpertCategory)} />
            <Route path="/app/expertenqiry" exact component={withRouter(ExpertEnqiry)} />
            {/* <Route path="/app/expertcategoryenquiry" exact component={withRouter(ExpertCategoryEnquiry)} /> */}
            

            <Route path="/app/benchmark" exact component={withRouter(BenchMark)} />

            <Route path="/app/badge" exact component={withRouter(Badge)} />

            <Route path="/app/habitcategory" exact component={withRouter(HabitCategory)} />

            <Route path="/app/music" exact component={withRouter(Music)} />
            <Route path="/app/premiummusic" exact component={withRouter(PremiumMusic)} />
            <Route path="/app/premiumactivity" exact component={withRouter(PremiumActivity)} />

            <Route path="/app/happiness/:id" exact component={withRouter(Happiness)} />

            {/* <Route path="/app/questionpublish/:id" exact component={withRouter(Questions)} />
            <Route path="/app/welcomepagepublish/:id" exact component={withRouter(Welcome)} />
            <Route path="/app/thankyoupagepublish/:id" exact component={withRouter(Thankyou)} /> */}
            
          </Switch>
          </div>
        </>
    </div>
  );
}

export default withRouter(Layout);
