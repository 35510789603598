import { questiontypeConstants } from '../_constants';
import { questiontypeService } from '../_services';
import { alertActions } from '.';
export const questiontypeActions = {
    disableQuestionType,
    createNewQuestionType,
    updateQuestionType,
    getAllQuestionType
};

function getAllQuestionType(data) {
    return dispatch => {
        dispatch(request());
        questiontypeService.getAllQuestionType(data)
            .then(
                questiontype => dispatch(success(questiontype)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questiontypeConstants.QUESTIONTYPE_GETALL_REQUEST } }
    function success(questiontype) { return { type: questiontypeConstants.QUESTIONTYPE_GETALL_SUCCESS, questiontype } }
    function failure(error) { return { type: questiontypeConstants.QUESTIONTYPE_GETALL_FAILURE, error } }
}
function createNewQuestionType(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        questiontypeService.createNewQuestionType(data)
            .then(
                questiontype => {
                    dispatch(success(questiontype));
                    dispatch(this.getAllQuestionType(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questiontypeConstants.ADD_QUESTIONTYPE_REQUEST } }
    function success(questiontype) { return { type: questiontypeConstants.ADD_QUESTIONTYPE_SUCCESS, questiontype } }
    function failure(error) { return { type: questiontypeConstants.ADD_QUESTIONTYPE_FAILURE, error } }
}
function updateQuestionType(data,tempdata) {
    
    return dispatch => {
        dispatch(request());
        questiontypeService.updateQuestionType(data)
            .then(
                questiontype => {
                    dispatch(success(questiontype));
                    dispatch(this.getAllQuestionType(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questiontypeConstants.UPDATE_QUESTIONTYPE_REQUEST } }
    function success(questiontype) { return { type: questiontypeConstants.UPDATE_QUESTIONTYPE_SUCCESS, questiontype } }
    function failure(error) { return { type: questiontypeConstants.UPDATE_QUESTIONTYPE_FAILURE, error } }
}
function disableQuestionType(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        questiontypeService.disableQuestionType({ id: data.id })
            .then(
                questiontype => {
                    dispatch(success(questiontype));
                    dispatch(this.getAllQuestionType(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questiontypeConstants.ADD_QUESTIONTYPE_REQUEST } }
    function success(questiontype) { return { type: questiontypeConstants.ADD_QUESTIONTYPE_SUCCESS, questiontype } }
    function failure(error) { return { type: questiontypeConstants.ADD_QUESTIONTYPE_FAILURE, error } }
}