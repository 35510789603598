import React, { Component } from 'react';
import {
  Grid
} from "@material-ui/core";
import { connect } from 'react-redux';
import Widget from "../../components/Widget";
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";

import { dashboardActions } from '../../_actions';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      markers: [],
      places: [],
    }
  }
  componentDidMount() {


    this.props.dispatch(dashboardActions.getStatics());

  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }

  render() {
    let classes = {};
    let {  dashboard } = this.props;
    let { dashboardStates} = dashboard;

console.log("dashboardStates",dashboardStates)


    return (
      <>
        <PageTitle title="Dashboard"/>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Company"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                  {dashboardStates?dashboardStates.company:0}
              </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="Survey"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                {dashboardStates?dashboardStates.survey:0}
              </Typography>
              </div>
            </Widget>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Widget
              title="User"
              upperTitle
              disableWidgetMenu={false}
              bodyClass={classes.fullHeightBody}
              className={classes.card}
            >
              <div className={classes.visitsNumberContainer}>
                <Typography size="xl" weight="medium">
                {dashboardStates?dashboardStates.user:0}
              </Typography>
              </div>
            </Widget>
          </Grid>
        </Grid>
        
      </>
    );
  }
}
Dashboard.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users,dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dashboard);
