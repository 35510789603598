import {
  resourcetypeConstants
} from '../_constants';

export function resourcetype(state = {}, action) {

  switch (action.type) {
    case resourcetypeConstants.RESOURCETYPE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case resourcetypeConstants.RESOURCETYPE_GETALL_SUCCESS:
      return {
        ...state,
        addResourceTypeSuccess: false,
        updateResourceTypeSuccess:false,
        resourceTypesList: action.resourcetype.listOfResourceTypes.list,
        items: action.resourcetype.listOfResourceTypes.list,
        total: action.resourcetype.listOfResourceTypes.total
      };
    case resourcetypeConstants.RESOURCETYPE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case resourcetypeConstants.ADD_RESOURCETYPE_REQUEST:
      return {
        ...state
      };
    case resourcetypeConstants.ADD_RESOURCETYPE_SUCCESS:
      return {
        ...state,
        addResourceTypeSuccess: true
      };
    case resourcetypeConstants.ADD_RESOURCETYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case resourcetypeConstants.UPDATE_RESOURCETYPE_REQUEST:
      return {
        ...state
      };
    case resourcetypeConstants.UPDATE_RESOURCETYPE_SUCCESS:
      return {
        ...state,
        updateResourceTypeSuccess: true
      };
    case resourcetypeConstants.UPDATE_RESOURCETYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}