import React from 'react';
import Widget from "../../../components/Widget/Widget";
import {
  Grid,
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Select,
  Input,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Checkbox
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';



export default function AddUser(props) {

  const { classes,  handleEDClose, submitted, formDataFilter,
    handleFilterInput, employeeColumnNames, handleFilterSubmit, employeeFilterList, handleFilterStatus } = props;

console.log("employeeFilterList: ",employeeFilterList)

  return (
    <div >

<AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleEDClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Employee Filter
            </Typography>
              <Button color="inherit" onClick={handleEDClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Grid container style={{marginTop:'100px'}}>
          <Grid item xs={6}>
            <Widget >
              <Grid container >
                <Grid item xs={3}>
                  <h3>Filter List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={12}>
                <Table className="mb-0">
                        <TableHead>
                          <TableRow>
                            <TableCell >S. NO.</TableCell>
                            <TableCell >NAME</TableCell>
                            <TableCell >LABEL</TableCell>
                            <TableCell >STATUS</TableCell>

                            {/* <TableCell >ACTION</TableCell> */}

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            employeeFilterList && employeeFilterList.length > 0 ? employeeFilterList.map((tempdata, index) => (
                              <TableRow key={index}>
                                <TableCell className="pl-3 fw-normal">{index+1}</TableCell>
                                <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
                                <TableCell className="pl-3 fw-normal">{tempdata.label}</TableCell>
                                {/* <TableCell className="pl-3 fw-normal">{tempdata.isDisable?'Active':'InActive'}</TableCell> */}
                                <TableCell className="pl-3 fw-normal">
                                <Checkbox
                                      checked={tempdata.isDisable}
                                      onClick={()=>handleFilterStatus(tempdata.id)}
                                      inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </TableCell>

                              </TableRow>
                            )) : null
                          }
                        </TableBody>
                      </Table>
                </Grid>
                <Grid item xs={1}>
                  {/* <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button> */}
                </Grid>
              </Grid>


            </Widget>
          </Grid>
          <Grid item xs={6}>
            <Widget >
                      <Grid container >
                      <Grid item xs={3}>
                        <h3>Create Filter</h3>
                      </Grid>
                      <br/>
                           <Grid item xs={12} sm={12}>
                             <br/>

                                <Select
                                  name="name"
                                  value={formDataFilter.name ? formDataFilter.name : employeeColumnNames && employeeColumnNames.length > 0 ? employeeColumnNames[0].name : ''}
                                  style={{ width: '100%' }}
                                  onChange={handleFilterInput}
                                  input={<Input id="name" />}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                >
                                  <MenuItem value="" disabled>
                                    Select Filter
                                  </MenuItem>
                                  {
                                    employeeColumnNames && employeeColumnNames.length > 0 ?
                                    employeeColumnNames.map((tempdata, index) => (
                                        <MenuItem key={index} value={tempdata.name}>{tempdata.name}</MenuItem>
                                      )) : null
                                  }

                                </Select>
                               </Grid>
                                <br/>
                                <Grid item xs={12} sm={12}>
                                      <br/>

                                      <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        disabled={submitted}
                                        className={classes.submit}
                                        onClick={handleFilterSubmit}
                                      >
                                        Save
                                      </Button>
                                </Grid>
                      </Grid>

            </Widget>
          </Grid>
          </Grid>


    </div>
  );}