import { badgeConstants } from '../_constants';
import { badgeService } from '../_services';
import { alertActions } from '.';
export const badgeActions = {
    disableBadge,
    createNewBadge,
    updateBadge,
    getAllBadge,
    deleteBadge
};

function getAllBadge(data) {
    return dispatch => {
        dispatch(request());
        badgeService.getAllBadge(data)
            .then(
                badge => dispatch(success(badge)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: badgeConstants.BADGE_GETALL_REQUEST } }
    function success(badge) { return { type: badgeConstants.BADGE_GETALL_SUCCESS, badge } }
    function failure(error) { return { type: badgeConstants.BADGE_GETALL_FAILURE, error } }
}
function createNewBadge(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        badgeService.createNewBadge(data)
            .then(
                badge => {
                    dispatch(success(badge));
                    dispatch(this.getAllBadge(tempdata));
                    dispatch(alertActions.success("Badge created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: badgeConstants.ADD_BADGE_REQUEST } }
    function success(badge) { return { type: badgeConstants.ADD_BADGE_SUCCESS, badge } }
    function failure(error) { return { type: badgeConstants.ADD_BADGE_FAILURE, error } }
}

function updateBadge(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        badgeService.updateBadge(data)
            .then(
                badge => {
                    dispatch(success(badge));
                    dispatch(this.getAllBadge(tempdata));
                    dispatch(alertActions.success("Badge updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: badgeConstants.UPDATE_BADGE_REQUEST } }
    function success(badge) { return { type: badgeConstants.UPDATE_BADGE_SUCCESS, badge } }
    function failure(error) { return { type: badgeConstants.UPDATE_BADGE_FAILURE, error } }
}

function deleteBadge(data, dataemp) {

    return dispatch => {
        dispatch(request());
        badgeService.deleteBadge(data)
            .then(
                badge => {
                    dispatch(success(badge));
                    dispatch(this.getAllBadge(dataemp));
                    dispatch(alertActions.success("Badge deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: badgeConstants.DELETE_BADGE_REQUEST } }
    function success(badge) { return { type: badgeConstants.DELETE_BADGE_SUCCESS, badge } }
    function failure(error) { return { type: badgeConstants.DELETE_BADGE_FAILURE, error } }
}
function disableBadge(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        badgeService.disableBadge({ id: data.id })
            .then(
                badge => {
                    dispatch(success(badge));
                    dispatch(this.getAllBadge(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: badgeConstants.ADD_BADGE_REQUEST } }
    function success(badge) { return { type: badgeConstants.ADD_BADGE_SUCCESS, badge } }
    function failure(error) { return { type: badgeConstants.ADD_BADGE_FAILURE, error } }
}