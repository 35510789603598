import { habitcategoryConstants } from '../_constants';
import { habitcategoryService } from '../_services';
import { alertActions } from '.';
export const habitcategoryActions = {
    disableHabitCategory,
    createNewHabitCategory,
    updateHabitCategory,
    getAllHabitCategory,
    deleteHabitCategory
};

function getAllHabitCategory(data) {
    return dispatch => {
        dispatch(request());
        habitcategoryService.getAllHabitCategory(data)
            .then(
                habitcategory => dispatch(success(habitcategory)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: habitcategoryConstants.HABITCATEGORY_GETALL_REQUEST } }
    function success(habitcategory) { return { type: habitcategoryConstants.HABITCATEGORY_GETALL_SUCCESS, habitcategory } }
    function failure(error) { return { type: habitcategoryConstants.HABITCATEGORY_GETALL_FAILURE, error } }
}
function createNewHabitCategory(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        habitcategoryService.createNewHabitCategory(data)
            .then(
                habitcategory => {
                    dispatch(success(habitcategory));
                    dispatch(this.getAllHabitCategory(tempdata));
                    dispatch(alertActions.success("HabitCategory created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: habitcategoryConstants.ADD_HABITCATEGORY_REQUEST } }
    function success(habitcategory) { return { type: habitcategoryConstants.ADD_HABITCATEGORY_SUCCESS, habitcategory } }
    function failure(error) { return { type: habitcategoryConstants.ADD_HABITCATEGORY_FAILURE, error } }
}

function updateHabitCategory(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        habitcategoryService.updateHabitCategory(data)
            .then(
                habitcategory => {
                    dispatch(success(habitcategory));
                    dispatch(this.getAllHabitCategory(tempdata));
                    dispatch(alertActions.success("HabitCategory updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: habitcategoryConstants.UPDATE_HABITCATEGORY_REQUEST } }
    function success(habitcategory) { return { type: habitcategoryConstants.UPDATE_HABITCATEGORY_SUCCESS, habitcategory } }
    function failure(error) { return { type: habitcategoryConstants.UPDATE_HABITCATEGORY_FAILURE, error } }
}

function deleteHabitCategory(data, dataemp) {

    return dispatch => {
        dispatch(request());
        habitcategoryService.deleteHabitCategory(data)
            .then(
                habitcategory => {
                    dispatch(success(habitcategory));
                    dispatch(this.getAllHabitCategory(dataemp));
                    dispatch(alertActions.success("HabitCategory deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: habitcategoryConstants.DELETE_HABITCATEGORY_REQUEST } }
    function success(habitcategory) { return { type: habitcategoryConstants.DELETE_HABITCATEGORY_SUCCESS, habitcategory } }
    function failure(error) { return { type: habitcategoryConstants.DELETE_HABITCATEGORY_FAILURE, error } }
}
function disableHabitCategory(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        habitcategoryService.disableHabitCategory({ id: data.id })
            .then(
                habitcategory => {
                    dispatch(success(habitcategory));
                    dispatch(this.getAllHabitCategory(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: habitcategoryConstants.ADD_HABITCATEGORY_REQUEST } }
    function success(habitcategory) { return { type: habitcategoryConstants.ADD_HABITCATEGORY_SUCCESS, habitcategory } }
    function failure(error) { return { type: habitcategoryConstants.ADD_HABITCATEGORY_FAILURE, error } }
}