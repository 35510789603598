export const companyConstants = {

    COMPANY_GETALL_REQUEST: 'COMPANY_GETALL_REQUEST',
    COMPANY_GETALL_SUCCESS: 'COMPANY_GETALL_SUCCESS',
    COMPANY_GETALL_FAILURE: 'COMPANY_GETALL_FAILURE',

    ADD_COMPANY_REQUEST: 'ADD_COMPANY_REQUEST',
    ADD_COMPANY_SUCCESS: 'ADD_COMPANY_SUCCESS',
    ADD_COMPANY_FAILURE: 'ADD_COMPANY_FAILURE',

    UPDATE_COMPANY_REQUEST: 'UPDATE_COMPANY_REQUEST',
    UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
    UPDATE_COMPANY_FAILURE: 'UPDATE_COMPANY_FAILURE',

    GET_COMPANY_ID_REQUEST: 'GET_COMPANY_ID_REQUEST',
    GET_COMPANY_ID_SUCCESS: 'GET_COMPANY_ID_SUCCESS',
    GET_COMPANY_ID_FAILURE: 'GET_COMPANY_ID_FAILURE',

    EMPLOYEE_GETALL_REQUEST: 'EMPLOYEE_GETALL_REQUEST',
    EMPLOYEE_GETALL_SUCCESS: 'EMPLOYEE_GETALL_SUCCESS',
    EMPLOYEE_GETALL_FAILURE: 'EMPLOYEE_GETALL_FAILURE',

    ALLEMPLOYEE_GETALL_REQUEST: 'ALLEMPLOYEE_GETALL_REQUEST',
    ALLEMPLOYEE_GETALL_SUCCESS: 'ALLEMPLOYEE_GETALL_SUCCESS',
    ALLEMPLOYEE_GETALL_FAILURE: 'ALLEMPLOYEE_GETALL_FAILURE',

    SURVEY_GETALL_REQUEST: 'SURVEY_GETALL_REQUEST',
    SURVEY_GETALL_SUCCESS: 'SURVEY_GETALL_SUCCESS',
    SURVEY_GETALL_FAILURE: 'SURVEY_GETALL_FAILURE',

    SURVEYTYPEALL_GETALL_REQUEST: 'SURVEYTYPEALL_GETALL_REQUEST',
    SURVEYTYPEALL_GETALL_SUCCESS: 'SURVEYTYPEALL_GETALL_SUCCESS',
    SURVEYTYPEALL_GETALL_FAILURE: 'SURVEYTYPEALL_GETALL_FAILURE',

    ADD_SURVEY_REQUEST: 'ADD_SURVEY_REQUEST',
    ADD_SURVEY_SUCCESS: 'ADD_SURVEY_SUCCESS',
    ADD_SURVEY_FAILURE: 'ADD_SURVEY_FAILURE',

    DELETE_SURVEY_REQUEST: 'DELETE_SURVEY_REQUEST',
    DELETE_SURVEY_SUCCESS: 'DELETE_SURVEY_SUCCESS',
    DELETE_SURVEY_FAILURE: 'DELETE_SURVEY_FAILURE',

    
    UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST',
    UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
    UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

    GET_EMPLOYEE_COLUMN_NAME_REQUEST:'GET_EMPLOYEE_COLUMN_NAME_REQUEST',
    GET_EMPLOYEE_COLUMN_NAME_SUCCESS:'GET_EMPLOYEE_COLUMN_NAME_SUCCESS',
    GET_EMPLOYEE_COLUMN_NAME_FAILURE:'GET_EMPLOYEE_COLUMN_NAME_FAILURE',

    CREATE_EMPLOYEE_FILTER_REQUEST:'CREATE_EMPLOYEE_FILTER_REQUEST',
    CREATE_EMPLOYEE_FILTER_SUCCESS:'CREATE_EMPLOYEE_FILTER_SUCCESS',
    CREATE_EMPLOYEE_FILTER_FAILURE:'CREATE_EMPLOYEE_FILTER_FAILURE',

    
    GET_EMPLOYEE_FILTER_REQUEST:'GET_EMPLOYEE_FILTER_REQUEST',
    GET_EMPLOYEE_FILTER_SUCCESS:'GET_EMPLOYEE_FILTER_SUCCESS',
    GET_EMPLOYEE_FILTER_FAILURE:'GET_EMPLOYEE_FILTER_FAILURE',

    UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST:'UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST',
    UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS:'UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE:'UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE',


    GET_BENCHMARK_REQUEST:'GET_BENCHMARK_REQUEST',
    GET_BENCHMARK_SUCCESS:'GET_BENCHMARK_SUCCESS',
    GET_BENCHMARK_FAILURE:'GET_BENCHMARK_FAILURE',

    ADD_BENCHMARK_REQUEST:'ADD_BENCHMARK_REQUEST',
    ADD_BENCHMARK_SUCCESS:'ADD_BENCHMARK_SUCCESS',
    ADD_BENCHMARK_FAILURE:'ADD_BENCHMARK_FAILURE',
    
    UPDATE_BENCHMARK_REQUEST:'UPDATE_BENCHMARK_REQUEST',
    UPDATE_BENCHMARK_SUCCESS:'UPDATE_BENCHMARK_SUCCESS',
    UPDATE_BENCHMARK_FAILURE:'UPDATE_BENCHMARK_FAILURE',

    DELETE_BENCHMARK_REQUEST:'DELETE_BENCHMARK_REQUEST',
    DELETE_BENCHMARK_SUCCESS:'DELETE_BENCHMARK_SUCCESS',
    DELETE_BENCHMARK_FAILURE:'DELETE_BENCHMARK_FAILURE',

    UPDATE_STATUS_BENCHMARK_REQUEST:'UPDATE_STATUS_BENCHMARK_REQUEST',
    UPDATE_STATUS_BENCHMARK_SUCCESS:'UPDATE_STATUS_BENCHMARK_SUCCESS',
    UPDATE_STATUS_BENCHMARK_FAILURE:'UPDATE_STATUS_BENCHMARK_FAILURE',

};
