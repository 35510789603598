import React from 'react';
import Widget from "../../../../components/Widget/Widget";
import {
  Grid,
  Button,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';



export default function TableEmployee(props) {

  const {classes, handleClose, campaignEmployeesList } = props;

  console.log("x ----- x----- campaignEmployeesList: -ssssssss---x : ",campaignEmployeesList)
  return (
    <div >

<AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
              Campaign Employee List
            </Typography>
              <Button color="inherit" onClick={handleClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={5} />
                <Grid item xs={1}>
                </Grid>
              </Grid>

              { campaignEmployeesList && campaignEmployeesList.length > 0 ?
              <Grid item xs={6} style={{margin:'5%'}}>
                  <Table className="mb-0" style={{padding:'5%'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell >S. NO.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { campaignEmployeesList.map((tempdata, index) => (
                          <TableRow key={index}>
                            <TableCell className="pl-3 fw-normal">{tempdata.employeeId.email}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
              </Grid>
             :null}
     
         
            </Widget>
          </Grid>
          </Grid>


    </div>
  );}