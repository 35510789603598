
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const dashboardService = {
    getStatics,
    logout
};

function logout() {
    localStorage.removeItem('admincompany');
    window.location.href = "#/login";
    //window.location.reload();

}

function getStatics() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getStatics`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let bucketObj = {
                dashboardStates: data.data
            }
            console.log();
            return bucketObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}