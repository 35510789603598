import {
  badgeConstants
} from '../_constants';

export function badge(state = {}, action) {

  switch (action.type) {
    case badgeConstants.BADGE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case badgeConstants.BADGE_GETALL_SUCCESS:
      return {
        ...state,
        addBadgeSuccess: false,
        updateBadgeSuccess:false,
        deleteBadgeSuccess: false,

          BadgeList: action.badge.listOfBadges.list,
          items: action.badge.listOfBadges.list,
          total: action.badge.listOfBadges.total
      };
    case badgeConstants.BADGE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case badgeConstants.ADD_BADGE_REQUEST:
      return {
        ...state
      };
    case badgeConstants.ADD_BADGE_SUCCESS:
      return {
        ...state,
        addBadgeSuccess: true
      };
    case badgeConstants.ADD_BADGE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case badgeConstants.UPDATE_BADGE_REQUEST:
      return {
        ...state
      };
    case badgeConstants.UPDATE_BADGE_SUCCESS:
      return {
        ...state,
        updateBadgeSuccess: true
      };
    case badgeConstants.UPDATE_BADGE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case badgeConstants.DELETE_BADGE_REQUEST:
        return {
          ...state
        };
      case badgeConstants.DELETE_BADGE_SUCCESS:
        return {
          ...state,
          deleteBadgeSuccess: true
        };
      case badgeConstants.DELETE_BADGE_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}