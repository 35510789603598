import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { toast } from 'react-toastify';
export const userActions = {
    login,
    createNewApiKey,
    disableUser,
    updateApiKeyStatus,
    deleteApiKey,
    logout,
    deleteUser,
    updatePasswordUser,
    createNewUser,
    uploadImage,
    uploadImageClear,
    uploadAudio,
    uploadAudioClear,
    useroverView,
    updateApiKeyVelidity,
    getAllUser,
    uploadMusic,
    // sendFrom,
    // updateNotificationStatus,
    // getAllNotification,
    // addMenu,
    // addItem,
    // onChangeImageFile,
    // onChangeImageFileLogo,
    // addOrRemoveImageInRestaurant,
    // deleteItem,
    // statics,
    // deleteRestaurant,
    // disableItem,
    // disableRestaurant,
    // disableCategory,
    // updateRestaurant,
    // deleteCategory,
    // updateCategory,
    // updateItem,
};
function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/dashboard' });
                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}
function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

function uploadMusic(data) {
    return dispatch => {
        userService.uploadAudio(data)
            .then(
                uploadMusic => {
                    toast("Music Uploaded successfully.")
                    dispatch(success(uploadMusic));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadMusic) { return { type: userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS, uploadMusic } }
    function failure(error) { return { type: userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE, error } }
}


function getAllUser(data) {
    return dispatch => {
        dispatch(request());
        userService.getAllUser(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}
function useroverView(data) {
    return dispatch => {
        dispatch(request());
        userService.useroverView(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.GET_OVERVIEW_REQUEST } }
    function success(users) { return { type: userConstants.GET_OVERVIEW_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GET_OVERVIEW_FAILURE, error } }
}
function updatePasswordUser(data) {
    let tempdata = {
        id: data.id
    }
    return dispatch => {
        dispatch(request());
        userService.updatePasswordUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.useroverView(tempdata));
                    dispatch(alertActions.success("Password updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
}
function createNewUser(data) {
    let tempdata = {
        "keyWord": "",
        "companyId": data.companyId,
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        userService.createNewUser(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}

function deleteUser(data,dataemp) {
    let tempdata = {
        "keyWord": dataemp.keyWord,
        "pageNo": dataemp.pageNo,
        "companyId":dataemp.companyId,
        "size": dataemp.size
    }
    return dispatch => {
        dispatch(request());
        userService.deleteUser({ id: data.id })
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("Deleted successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function disableUser(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        userService.disableUser({ id: data.id })
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getAllUser(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.ADD_RESTAURANT_USER_REQUEST } }
    function success(users) { return { type: userConstants.ADD_RESTAURANT_USER_SUCCESS, users } }
    function failure(error) { return { type: userConstants.ADD_RESTAURANT_USER_FAILURE, error } }
}
function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    toast("Image Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}

function createNewApiKey(data) {
    let tempdata = {
        id: data.id
    }
    let tempdata1 = {
        userId: data.id
    }
    return dispatch => {
        dispatch(request());
        userService.createNewApiKey(tempdata1)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.useroverView(tempdata));
                    dispatch(alertActions.success("Created successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
}
function updateApiKeyStatus(data) {
    let tempdata = {
        id: data.id
    }
    let tempdata1 = {
        id: data.userId
    }
    return dispatch => {
        dispatch(request());
        userService.updateApiKeyStatus(tempdata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.useroverView(tempdata1));
                    dispatch(alertActions.success("Updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
}
function deleteApiKey(data) {
    let tempdata = {
        id: data.id
    }
    let tempdata1 = {
        id: data.userId
    }
    return dispatch => {
        dispatch(request());
        userService.deleteApiKey(tempdata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.useroverView(tempdata1));
                    dispatch(alertActions.success("Updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
}
function updateApiKeyVelidity(data) {
    let tempdata = {
        "id": data.id,
        "ip": data.ip,
        "validity": data.validity
    }
    let tempdata1 = {
        id: data.userId
    }
    return dispatch => {
        dispatch(request());
        userService.updateApiKeyVelidity(tempdata)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.useroverView(tempdata1));
                    dispatch(alertActions.success("Updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: userConstants.UPDATE_USER_NOTIFY_REQUEST } }
    function success(users) { return { type: userConstants.UPDATE_USER_NOTIFY_SUCCESS, users } }
    function failure(error) { return { type: userConstants.UPDATE_USER_NOTIFY_FAILURE, error } }
}


function uploadAudio(data) {
    return dispatch => {
        userService.uploadAudio(data)
            .then(
                uploadImage => {
                    toast("Audio Uploaded successfully.")
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_AUDIO_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_AUDIO_STATUS_FAILURE, error } }
}

function uploadAudioClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_CLEAR_AUDIO_SUCCESS, uploadImage } }
}