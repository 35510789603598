import {
  resourceConstants
} from '../_constants';

export function resource(state = {}, action) {

  switch (action.type) {

      case resourceConstants.ADD_RESOURCE_REQUEST:
        return {
          ...state
        };
      case resourceConstants.ADD_RESOURCE_SUCCESS:
        return {
          ...state,
          addResourceSuccess: true,
          createResource: action.resource.createResource,
        };
      case resourceConstants.ADD_RESOURCE_FAILURE:
        return {
          ...state,
          error: action.error
        };

        case resourceConstants.UPDATE_RESOURCE_REQUEST:
          return {
            ...state
          };
        case resourceConstants.UPDATE_RESOURCE_SUCCESS:
          return {
            ...state,
            updateResourceSuccess: true,
            updateResource: action.resource.updateResource,
          };
        case resourceConstants.UPDATE_RESOURCE_FAILURE:
          return {
            ...state,
            error: action.error
          };
  

      case resourceConstants.RESOURCE_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case resourceConstants.RESOURCE_GETALL_SUCCESS:
        return {
          ...state,    
          addResourceSuccess: false,   
          updateResourceSuccess: false,
             
          allResources: action.resource.allResources
        };
      case resourceConstants.RESOURCE_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
        case resourceConstants.RESOURCELIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case resourceConstants.RESOURCELIST_GETALL_SUCCESS:
          return {
            ...state,  
            addResourceSuccess: false,      
            updateResourceSuccess: false,
   
            items: action.resource.allResourcesList.list,
            total: action.resource.allResourcesList.total
          };
        case resourceConstants.RESOURCELIST_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };



    default:
      return state
  }
}