import { insstoryConstants } from '../_constants';
import { insstoryService } from '../_services';
import { alertActions, userActions } from '.'; 

export const insstoryActions = {
    disableInsStory,
    createNewInsStory,
    updateInsStory,
    getAllInsStory,
    deleteInsStory
};

function getAllInsStory(data) {
    return dispatch => {
        dispatch(request());
        insstoryService.getAllInsStory(data)
            .then(
                insstory => dispatch(success(insstory)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: insstoryConstants.INSSTORY_GETALL_REQUEST } }
    function success(insstory) { return { type: insstoryConstants.INSSTORY_GETALL_SUCCESS, insstory } }
    function failure(error) { return { type: insstoryConstants.INSSTORY_GETALL_FAILURE, error } }
}
function createNewInsStory(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        insstoryService.createNewInsStory(data)
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllInsStory(tempdata));
                    dispatch(alertActions.success("InsStory created successfully."));
                    dispatch(userActions.uploadImageClear());
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: insstoryConstants.ADD_INSSTORY_REQUEST } }
    function success(insstory) { return { type: insstoryConstants.ADD_INSSTORY_SUCCESS, insstory } }
    function failure(error) { return { type: insstoryConstants.ADD_INSSTORY_FAILURE, error } }
}

function updateInsStory(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        insstoryService.updateInsStory(data)
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllInsStory(tempdata));
                    dispatch(alertActions.success("InsStory updated successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: insstoryConstants.UPDATE_INSSTORY_REQUEST } }
    function success(insstory) { return { type: insstoryConstants.UPDATE_INSSTORY_SUCCESS, insstory } }
    function failure(error) { return { type: insstoryConstants.UPDATE_INSSTORY_FAILURE, error } }
}

function deleteInsStory(data, dataemp) {

    return dispatch => {
        dispatch(request());
        insstoryService.deleteInsStory(data)
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllInsStory(dataemp));
                    dispatch(alertActions.success("InsStory deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: insstoryConstants.DELETE_INSSTORY_REQUEST } }
    function success(insstory) { return { type: insstoryConstants.DELETE_INSSTORY_SUCCESS, insstory } }
    function failure(error) { return { type: insstoryConstants.DELETE_INSSTORY_FAILURE, error } }
}
function disableInsStory(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        insstoryService.disableInsStory({ id: data.id })
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllInsStory(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: insstoryConstants.ADD_INSSTORY_REQUEST } }
    function success(insstory) { return { type: insstoryConstants.ADD_INSSTORY_SUCCESS, insstory } }
    function failure(error) { return { type: insstoryConstants.ADD_INSSTORY_FAILURE, error } }
}