import { faqConstants } from '../_constants';
import { faqService } from '../_services';
import { alertActions } from '.';
export const faqActions = {
    disableFaq,
    createNewFaq,
    updateFaq,
    getAllFaq,
    deleteFaq
};

function getAllFaq(data) {
    return dispatch => {
        dispatch(request());
        faqService.getAllFaq(data)
            .then(
                faq => dispatch(success(faq)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: faqConstants.FAQ_GETALL_REQUEST } }
    function success(faq) { return { type: faqConstants.FAQ_GETALL_SUCCESS, faq } }
    function failure(error) { return { type: faqConstants.FAQ_GETALL_FAILURE, error } }
}
function createNewFaq(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        faqService.createNewFaq(data)
            .then(
                faq => {
                    dispatch(success(faq));
                    dispatch(this.getAllFaq(tempdata));
                    dispatch(alertActions.success("Faq created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: faqConstants.ADD_FAQ_REQUEST } }
    function success(faq) { return { type: faqConstants.ADD_FAQ_SUCCESS, faq } }
    function failure(error) { return { type: faqConstants.ADD_FAQ_FAILURE, error } }
}

function updateFaq(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        faqService.updateFaq(data)
            .then(
                faq => {
                    dispatch(success(faq));
                    dispatch(this.getAllFaq(tempdata));
                    dispatch(alertActions.success("Faq updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: faqConstants.UPDATE_FAQ_REQUEST } }
    function success(faq) { return { type: faqConstants.UPDATE_FAQ_SUCCESS, faq } }
    function failure(error) { return { type: faqConstants.UPDATE_FAQ_FAILURE, error } }
}

function deleteFaq(data, dataemp) {

    return dispatch => {
        dispatch(request());
        faqService.deleteFaq(data)
            .then(
                faq => {
                    dispatch(success(faq));
                    dispatch(this.getAllFaq(dataemp));
                    dispatch(alertActions.success("Faq deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: faqConstants.DELETE_FAQ_REQUEST } }
    function success(faq) { return { type: faqConstants.DELETE_FAQ_SUCCESS, faq } }
    function failure(error) { return { type: faqConstants.DELETE_FAQ_FAILURE, error } }
}
function disableFaq(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        faqService.disableFaq({ id: data.id })
            .then(
                faq => {
                    dispatch(success(faq));
                    dispatch(this.getAllFaq(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: faqConstants.ADD_FAQ_REQUEST } }
    function success(faq) { return { type: faqConstants.ADD_FAQ_SUCCESS, faq } }
    function failure(error) { return { type: faqConstants.ADD_FAQ_FAILURE, error } }
}