import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { company } from './company.reducer';
import { questiontype } from './questiontype.reducer';
import { questions } from './questions.reducer';
import { campaign } from './campaign.reducer';
import { dashboard } from './dashboard.reducer';
import { resourcetype } from './resourcetype.reducer';

import { resource } from './resource.reducer';
import { activity } from './activity.reducer';
import { tracker } from './tracker.reducer';
import { insstory } from './insstory.reducer';
import { expert } from './expert.reducer';
import { notification } from './notification.reducer';

import { parametertype } from './parametertype.reducer';

import { parameter } from './parameter.reducer';

import { enquiry } from './enquiry.reducer';

import { expertenqiry } from './expertenqiry.reducer';

import { faq } from './faq.reducer';
import { expertcategory } from './expertcategory.reducer';
import { expertCategoryEnquiry } from './expertCategoryEnquiry.reducer';

import { benchmark } from './benchmark.reducer';

import { badge } from './badge.reducer';

import { habitcategory } from './habitcategory.reducer';

import { music } from './music.reducer';
import { premiumactivity } from './premiumactivity.reducer';
import { premiummusic } from './premiummusic.reducer';
import { happiness } from './happiness.reducer';

const rootReducer = combineReducers({
  authentication,
  users,
  alert,
  company,
  questiontype,
  questions,
  campaign,
  dashboard,
  resourcetype,
  resource,
  activity,
  tracker,
  insstory,
  expert,
  notification,
  parametertype,
  parameter,
  enquiry,
  faq,
  expertcategory,
  expertenqiry,
  benchmark,
  badge,
  habitcategory,
  premiumactivity,
  music,
  premiummusic,
  happiness
  
});

export default rootReducer;
