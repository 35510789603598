export const activityConstants = {

    ADD_ACTIVITY_REQUEST: 'ADD_ACTIVITY_REQUEST',
    ADD_ACTIVITY_SUCCESS: 'ADD_ACTIVITY_SUCCESS',
    ADD_ACTIVITY_FAILURE: 'ADD_ACTIVITY_FAILURE',

    DELETE_ACTIVITY_REQUEST: 'DELETE_ACTIVITY_REQUEST',
    DELETE_ACTIVITY_SUCCESS: 'DELETE_ACTIVITY_SUCCESS',
    DELETE_ACTIVITY_FAILURE: 'DELETE_ACTIVITY_FAILURE',

    UPDATE_ACTIVITY_REQUEST: 'UPDATE_ACTIVITY_REQUEST',
    UPDATE_ACTIVITY_SUCCESS: 'UPDATE_ACTIVITY_SUCCESS',
    UPDATE_ACTIVITY_FAILURE: 'UPDATE_ACTIVITY_FAILURE',

    ACTIVITY_GETALL_REQUEST: 'ACTIVITY_GETALL_REQUEST',
    ACTIVITY_GETALL_SUCCESS: 'ACTIVITY_GETALL_SUCCESS',
    ACTIVITY_GETALL_FAILURE: 'ACTIVITY_GETALL_FAILURE',

    ACTIVITYLIST_GETALL_REQUEST: 'ACTIVITYLIST_GETALL_REQUEST',
    ACTIVITYLIST_GETALL_SUCCESS: 'ACTIVITYLIST_GETALL_SUCCESS',
    ACTIVITYLIST_GETALL_FAILURE: 'ACTIVITYLIST_GETALL_FAILURE',

};
