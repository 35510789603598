import {
  campaignConstants
} from '../_constants';

export function campaign(state = {}, action) {

  switch (action.type) {

      case campaignConstants.ADD_CAMPAIGN_REQUEST:
        return {
          ...state
        };
      case campaignConstants.ADD_CAMPAIGN_SUCCESS:
        return {
          ...state,
          addCampaignSuccess: true,
          createNewCampaign: action.campaign.createNewCampaign,
        };
      case campaignConstants.ADD_CAMPAIGN_FAILURE:
        return {
          ...state,
          error: action.error
        };
  

      case campaignConstants.CAMPAIGN_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case campaignConstants.CAMPAIGN_GETALL_SUCCESS:
        return {
          ...state,           
          campaignList: action.campaign.campaignList
        };
      case campaignConstants.CAMPAIGN_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };


        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_SUCCESS:
          return {
            ...state,
            getCampaignEmployeeSuccess:true,
            campaignEmployeesList: action.campaign.campaignEmployeesList
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };
  


    default:
      return state
  }
}