export const parameterConstants = {

    ADD_PARAMETER_REQUEST: 'ADD_PARAMETER_REQUEST',
    ADD_PARAMETER_SUCCESS: 'ADD_PARAMETER_SUCCESS',
    ADD_PARAMETER_FAILURE: 'ADD_PARAMETER_FAILURE',

    UPDATE_PARAMETER_REQUEST: 'UPDATE_PARAMETER_REQUEST',
    UPDATE_PARAMETER_SUCCESS: 'UPDATE_PARAMETER_SUCCESS',
    UPDATE_PARAMETER_FAILURE: 'UPDATE_PARAMETER_FAILURE',

    PARAMETER_GETALL_REQUEST: 'PARAMETER_GETALL_REQUEST',
    PARAMETER_GETALL_SUCCESS: 'PARAMETER_GETALL_SUCCESS',
    PARAMETER_GETALL_FAILURE: 'PARAMETER_GETALL_FAILURE',

    PARAMETERLIST_GETALL_REQUEST: 'PARAMETERLIST_GETALL_REQUEST',
    PARAMETERLIST_GETALL_SUCCESS: 'PARAMETERLIST_GETALL_SUCCESS',
    PARAMETERLIST_GETALL_FAILURE: 'PARAMETERLIST_GETALL_FAILURE',
    
    PARAMETERLIST_BYTYPE_GETALL_REQUEST: 'PARAMETERLIST_BYTYPE_GETALL_REQUEST',
    PARAMETERLIST_BYTYPE_GETALL_SUCCESS: 'PARAMETERLIST_BYTYPE_GETALL_SUCCESS',
    PARAMETERLIST_BYTYPE_GETALL_FAILURE: 'PARAMETERLIST_BYTYPE_GETALL_FAILURE',

    PARAMETERLIST_BUCKET_BYTYPE_GETALL_REQUEST: 'PARAMETERLIST_BUCKET_BYTYPE_GETALL_REQUEST',
    PARAMETERLIST_BUCKET_BYTYPE_GETALL_SUCCESS: 'PARAMETERLIST_BUCKET_BYTYPE_GETALL_SUCCESS',
    PARAMETERLIST_BUCKET_BYTYPE_GETALL_FAILURE: 'PARAMETERLIST_BUCKET_BYTYPE_GETALL_FAILURE',

    PARAMETERLIST_DIMENSION_BYTYPE_GETALL_REQUEST: 'PARAMETERLIST_DIMENSION_BYTYPE_GETALL_REQUEST',
    PARAMETERLIST_DIMENSION_BYTYPE_GETALL_SUCCESS: 'PARAMETERLIST_DIMENSION_BYTYPE_GETALL_SUCCESS',
    PARAMETERLIST_DIMENSION_BYTYPE_GETALL_FAILURE: 'PARAMETERLIST_DIMENSION_BYTYPE_GETALL_FAILURE',

};
