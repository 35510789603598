import { dashboardConstants } from '../_constants';
import { dashboardService } from '../_services';
import { alertActions } from '.';

export const dashboardActions = {
    getStatics
};

function getStatics() {
    return dispatch => {
        dispatch(request());
        dashboardService.getStatics()
            .then(
                dashboard => dispatch(success(dashboard)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: dashboardConstants.DASHBOARD_GETALL_REQUEST } }
    function success(dashboard) { return { type: dashboardConstants.DASHBOARD_GETALL_SUCCESS, dashboard } }
    function failure(error) { return { type: dashboardConstants.DASHBOARD_GETALL_FAILURE, error } }
}