import { resourceConstants } from '../_constants';
import { resourceService } from '../_services';
import { alertActions,userActions } from '.';
export const resourceActions = {
    createResource,
    updateResource,
    disableResource,
    getResourceList,
    getAllResource
};


function createResource(data) {
    let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      
}
    return dispatch => {
        dispatch(request());
        resourceService.createResource(data)
            .then(
                resource => {
                    dispatch(success(resource));
                    dispatch(this.getResourceList(datatempsurvey));
                    dispatch(alertActions.success("Resource Created successfully."));
                    dispatch(userActions.uploadImageClear());

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourceConstants.ADD_RESOURCE_REQUEST } }
    function success(resource) { return { type: resourceConstants.ADD_RESOURCE_SUCCESS, resource } }
    function failure(error) { return { type: resourceConstants.ADD_RESOURCE_FAILURE, error } }
}

function updateResource(data) {
    let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      
}
    return dispatch => {
        dispatch(request());
        resourceService.updateResource(data)
            .then(
                resource => {
                    dispatch(success(resource));
                    dispatch(this.getResourceList(datatempsurvey));
                    dispatch(alertActions.success("Resource Updated successfully."));
                    dispatch(userActions.uploadImageClear());


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourceConstants.UPDATE_RESOURCE_REQUEST } }
    function success(resource) { return { type: resourceConstants.UPDATE_RESOURCE_SUCCESS, resource } }
    function failure(error) { return { type: resourceConstants.UPDATE_RESOURCE_FAILURE, error } }
}

function disableResource(data) {
        let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
        return dispatch => {
            dispatch(request());
            resourceService.disableResource(data)
                .then(
                    resource => {
                        dispatch(success(resource));
                        dispatch(this.getResourceList(datatempsurvey));
    
                        dispatch(alertActions.success("Resource Created successfully."));
    
    
                    },
                    error => {
                        dispatch(alertActions.error(error));
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: resourceConstants.ADD_RESOURCE_REQUEST } }
        function success(resource) { return { type: resourceConstants.ADD_RESOURCE_SUCCESS, resource } }
        function failure(error) { return { type: resourceConstants.ADD_RESOURCE_FAILURE, error } }
    }


function getAllResource(data) {
    return dispatch => {
        dispatch(request());
        resourceService.getAllResource(data)
            .then(
                resource => {
                    dispatch(success(resource));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourceConstants.RESOURCE_GETALL_REQUEST } }
    function success(resource) { return { type: resourceConstants.RESOURCE_GETALL_SUCCESS, resource } }
    function failure(error) { return { type: resourceConstants.RESOURCE_GETALL_FAILURE, error } }
}

function getResourceList(data) {
    return dispatch => {
        dispatch(request());
        resourceService.getResourceList(data)
            .then(
                resource => {
                    dispatch(success(resource));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: resourceConstants.RESOURCELIST_GETALL_REQUEST } }
    function success(resource) { return { type: resourceConstants.RESOURCELIST_GETALL_SUCCESS, resource } }
    function failure(error) { return { type: resourceConstants.RESOURCELIST_GETALL_FAILURE, error } }
}