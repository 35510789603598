
import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function UpdateInsStory(props) {

  const { classes, updateformData, submitted, handleUpdateClose, handleUpdateSubmit, updatehandleChangeInput,
    handleFile, handleUpload,updatehandleChangeInputDesc, imageWebLink, imageWebURL } = props;

  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update InsStory
            </Typography>
          <Button color="inherit" onClick={handleUpdateClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update InsStory
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleUpdateSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Title*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="title"
                  id="title"
                  value={updateformData.title}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {/* <TextValidator
                  label="Desc*"
                  fullWidth
                  multiline
                  onChange={updatehandleChangeInput}
                  name="desc"
                  id="desc"
                  value={updateformData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                /> */}
                  <p>Desc</p>
                  <ReactQuill 
                  theme="snow" 
                  name="desc"
                  placeholder="Desc"
                  value={updateformData.desc} 
                  onChange={updatehandleChangeInputDesc} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Video Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="videoLink"
                  id="videoLink"
                  value={updateformData.videoLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={updateformData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>

              <Grid item xs={12}>

                <label htmlFor="image">
                  <Button color="default" variant="contained" component="span" >
                    Browse
                        </Button>
                </label>
                <input
                  style={{ display: 'none' }}
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleFile}
                />

                <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                  Set
                      </Button>


                {/* {(updateformData.imageLink)?CONST.IMAGE_URL + updateformData.imageLink:CONST.IMAGE_URL + imageWebLink  } */}

              </Grid>

              {(imageWebURL && (imageWebLink === updateformData.imageLink)) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : 
                <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={updateformData.imageLinkV2} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                }

              <Grid item xs={12}></Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}