const styles = theme => ({
  dashedBorder: {
    border: "1px dashed",
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
  },
  text: {
    marginBottom: theme.spacing(2),
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  title1: {
    marginTop: theme.spacing(2),
    flex: 1,
  },
  heading_cs:{
    display: 'flex',
    padding: '24px',
    'align-items': 'center',
    'padding-bottom': '8px',
    'justify-content': 'space-between'
  }
});
export default styles;