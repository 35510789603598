import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";

import { parameterActions } from '../../_actions/parameter.actions'
import { parametertypeActions } from '../../_actions/parametertype.actions'

import { withRouter } from "react-router";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './styles';

import UpdateParameter from './Modal/UpdateParameter';
import AddParameter from './Modal/AddParameter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();



class Parameter extends Component {
  constructor(props) {
    super(props);
    this.state = {

      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',

      newParameterModal:false,
      newUpdateParameterModal:false,

      title:"",
      desc:"",
      name:"",
      link:"",
      parameterTypeName:"",
      parameterTypeId:"",

      rating:"",
      author:"",
      idmb:"",
      subjectIndex:"",

      selectedFile:null,
      imageWebLink:"",


      submitted: false,
      formData: {
        "name": "",
        "desc": "",
        "tagline":"",
        "parameterTypeId":"",
        "parentId":"",
        "icon":"",
        "colorCode":"",
        "barColor":""



      },
      updateformData:{
        "id":"",
        "name": "",
        "desc": "",
        "tagline":"",
        "parameterTypeId":"",
        "parentId":"",
        "icon":"",
        "colorCode":"",
        "barColor":""

      }

    }
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(parameterActions.getParameterList(data));

    this.props.dispatch(parametertypeActions.getAllParameterType(data));
    
 


  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);

    if (nextProps.parameter.updateParameterSuccess) {

      return {
        ...nextProps,
        updateformData: {
          "id":"",
          "name": "",
          "desc": "",
          "tagline":"",
          "parameterTypeId":"",
          "parentId":"",
          "icon":"",
          "colorCode":"",
          "barColor":""

        },
        formData: {
          "name": "",
          "desc": "",
          "tagline":"",
          "parameterTypeId":"",
          "parentId":"",
          "icon":"",
          "colorCode":"",
          "barColor":""

        },
        newUpdateParameterModal:false
      
      }
    }

    if (nextProps.parameter.addParameterSuccess) {

      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "tagline":"",
          "parameterTypeId":"",
          "parentId":"",
          "icon":"",
          "colorCode":"",
          "barColor":""

        },
        updateformData: {
          "id":"",
          "name": "",
          "desc": "",
          "tagline":"",
          "parameterTypeId":"",
          "parentId":"",
          "icon":"",
          "colorCode":"",
          "barColor":""

        },
        newParameterModal:false
      
      }
    } 
    
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:nextProps.users.filesDetails.imageUrl
      
      }
    }

    else {
      return {
        ...nextProps
      }
    }

  }

  onDisable = (data) => {
    
    let tempdata = {
      "id": data.id
    }
    this.props.dispatch(parameterActions.disableParameter(tempdata));
  }


  onUpdate = (data) => {
  
    console.log("data:::",data)
    let tempdata = {
      "id": data.id,
      "name":data.name,
      "desc":data.desc,
      "tagline":data.tagline,
      "parameterTypeId":data.parameterTypeId.id,
      "parentId":data.parentId.id,
      "icon":(data.icon)?data.icon:'',
      "colorCode":(data.colorCode)?data.colorCode:'',
      "barColor":(data.barColor)?data.barColor:'',

    }
    this.setState({ newUpdateParameterModal: true, updateformData: tempdata });
  }

  handleClickOpen = () => {
    this.setState({ newParameterModal: true });
  }
  handleClose = () => {
    let data = {
      "name": "",
      "desc": "",
      "tagline":"",
      "parameterTypeId":"",
      "parentId":"",
      "icon":"",
      "colorCode":"",
      "barColor":""

    }
    this.setState({formData:data, newParameterModal: false });


  }

  handleUpdateClose = () => {
    let data = {
      "id":"",
      "name": "",
      "desc": "",
      "tagline":"",
      "parameterTypeId":"",
      "parentId":"",
      "icon":"",
      "colorCode":"",
      "barColor":""

    }
    this.setState({ updateformData:data, newUpdateParameterModal: false });

  }

  handleParameterName=(event)=>{
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;

    this.setState({ formData, updateformData });
  }

  
  handleParameterTypeName=(event)=>{
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;


    let { parametertype } = this.props;
    const { parameterTypesList } = parametertype;
    console.log("parameterTypesList: ",parameterTypesList)

    let ParameterTypeIndex = parameterTypesList.findIndex(element=>element.id === event.target.value);

    if(ParameterTypeIndex>0){
      console.log("ParameterTypeIndex-------------",ParameterTypeIndex-1)
      // console.log("parameterTypesList-------------",parameterTypesList[ParameterTypeIndex-1])
  
      let data = {
        parameterTypeId: parameterTypesList[ParameterTypeIndex-1].id
      }
      console.log("parameterTypeId-------------",data.parameterTypeId)
  
      this.props.dispatch(parameterActions.getParameterListbyParameterType(data));
    }
    else{
      //Do Nothing
    }


  }
 
  
  submitParameterDetails=()=>{


    let data  = {
      name: this.state.formData.name.trim(),
      desc: this.state.formData.desc,
      tagline: this.state.formData.tagline,
      parameterTypeId: this.state.formData.parameterTypeId,
      parentId: this.state.formData.parentId,
      icon: this.state.formData.icon,
      colorCode: this.state.formData.colorCode,
      barColor: this.state.formData.barColor


    }

    this.props.dispatch(parameterActions.createParameter(data));

  }

  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(parameterActions.getParameterList(data));
  }

  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:",value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(parameterActions.getParameterList(data));
  }
  
  submitUpdateParameterDetails=()=>{


    let data = {
          id: this.state.updateformData.id,
          name: this.state.updateformData.name.trim(),
          desc: this.state.updateformData.desc,
          tagline: this.state.updateformData.tagline,
          parameterTypeId: this.state.updateformData.parameterTypeId,
          parentId: this.state.updateformData.parentId,
          icon: this.state.updateformData.icon,
          colorCode: this.state.updateformData.colorCode,
          barColor: this.state.updateformData.barColor


       }    

       let datatemp = {
        "keyWord": this.state.keyWord,
        "pageNo": this.state.page,
        "size": this.state.size
      }

    this.props.dispatch(parameterActions.updateParameter(data,datatemp));




  }


  render() {

    let { classes, parameter, parametertype } = this.props;
    let { items, total, allParametersListByParameterTypeData } = parameter;
    const { parameterTypesList  } = parametertype;
    const { formData, updateformData, submitted } = this.state;



      console.log("formData---->",this.state.formData)
      console.log("updateformData---->",this.state.updateformData)

      console.log("allParametersListByParameterTypeData: ",allParametersListByParameterTypeData)


    return (
      <>


<PageTitle title="Parameter List" />

<Grid container spacing={2} style={{marginTop:'5%'}}>



        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable} 
                      onUpdate={this.onUpdate}/>
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}s
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
       
        </Grid>
        <Dialog fullScreen open={this.state.newParameterModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddParameter
                  data={items}
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  parameterTypesList={parameterTypesList}
                  handleParameterName={this.handleParameterName}
                  handleParameterTypeName={this.handleParameterTypeName}
                  allParametersListByParameterTypeData={allParametersListByParameterTypeData}
                  submitParameterDetails={this.submitParameterDetails}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateParameterModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateParameter
                  data={items}
                  classes={classes}
                  updateformData ={updateformData}
                  submitted={submitted}
                  handleClose={this.handleUpdateClose}
                  parameterTypesList={parameterTypesList}
                  handleParameterName={this.handleParameterName}
                  handleParameterTypeName={this.handleParameterTypeName}
                  allParametersListByParameterTypeData={allParametersListByParameterTypeData}
                  submitUpdateParameterDetails={this.submitUpdateParameterDetails}

                />
        </Dialog>
              
            
    </Grid>

  
      </>
    );
  }
}
Parameter.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users,parameter, parametertype } = state;
  return {
    loggingIn,
    users,
    parameter,
    parametertype
    
    
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Parameter)));
