import {
  premiummusicConstants
} from '../_constants';

export function premiummusic(state = {}, action) {

  switch (action.type) {
    case premiummusicConstants.PREMIUMMUSIC_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case premiummusicConstants.PREMIUMMUSIC_GETALL_SUCCESS:
      return {
        ...state,
        addPremiumMusicSuccess: false,
        addPremiumMusicSuccessTemp: false,
        updatePremiumMusicSuccess: false,
        deletePremiumMusicSuccess: false,
        // premiumMusicList: action.premiummusic.listOfPremiumMusics,
        items: action.premiummusic.listOfPremiumMusics.list,
        total: action.premiummusic.listOfPremiumMusics.total
      };
    case premiummusicConstants.PREMIUMMUSIC_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_SUCCESS:
      return {
        ...state,
        addPremiumMusicSuccess: false,
        addPremiumMusicSuccessTemp: false,
        updatePremiumMusicSuccess: false,
        deletePremiumMusicSuccess: false,
        allPremiumMusics: action.premiummusic.allPremiumMusics,
        // items: action.premiummusic.listOfPremiumMusics.list,
        // total: action.premiummusic.listOfPremiumMusics.total
      };
    case premiummusicConstants.ALL_PREMIUMMUSIC_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case premiummusicConstants.ADD_PREMIUMMUSIC_REQUEST:
      return {
        ...state
      };
    case premiummusicConstants.ADD_PREMIUMMUSIC_SUCCESS:
      return {
        ...state,
        addPremiumMusicSuccessTemp: true,
        addPremiumMusicSuccess: true
      };
    case premiummusicConstants.ADD_PREMIUMMUSIC_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case premiummusicConstants.UPDATE_PREMIUMMUSIC_REQUEST:
      return {
        ...state
      };
    case premiummusicConstants.UPDATE_PREMIUMMUSIC_SUCCESS:
      return {
        ...state,
        updatePremiumMusicSuccess: true
      };
    case premiummusicConstants.UPDATE_PREMIUMMUSIC_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case premiummusicConstants.DELETE_PREMIUMMUSIC_REQUEST:
      return {
        ...state
      };
    case premiummusicConstants.DELETE_PREMIUMMUSIC_SUCCESS:
      return {
        ...state,
        deletePremiumMusicSuccess: true
      };
    case premiummusicConstants.DELETE_PREMIUMMUSIC_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}