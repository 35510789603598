import { expertCategoryEnquiryConstants } from '../_constants';
import { expertCategoryEnquiryService } from '../_services';
import { alertActions } from '.';
export const expertCategoryEnquiryActions = {
    disableexpertCategoryEnquiry,
    createNewexpertCategoryEnquiry,
    updateexpertCategoryEnquiry,
    getAllexpertCategoryEnquiry,
    deleteexpertCategoryEnquiry
};

function getAllexpertCategoryEnquiry(data) {
    return dispatch => {
        dispatch(request());
        expertCategoryEnquiryService.getAllexpertCategoryEnquiry(data)
            .then(
                expertCategoryEnquiry => dispatch(success(expertCategoryEnquiry)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertCategoryEnquiryConstants.EXPERTCATEGORYENQUIRY_GETALL_REQUEST } }
    function success(expertCategoryEnquiry) { return { type: expertCategoryEnquiryConstants.EXPERTCATEGORYENQUIRY_GETALL_SUCCESS, expertCategoryEnquiry } }
    function failure(error) { return { type: expertCategoryEnquiryConstants.EXPERTCATEGORYENQUIRY_GETALL_FAILURE, error } }
}
function createNewexpertCategoryEnquiry(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        expertCategoryEnquiryService.createNewexpertCategoryEnquiry(data)
            .then(
                expertCategoryEnquiry => {
                    dispatch(success(expertCategoryEnquiry));
                    dispatch(this.getAllexpertCategoryEnquiry(tempdata));
                    dispatch(alertActions.success("expertCategoryEnquiry created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_REQUEST } }
    function success(expertCategoryEnquiry) { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_SUCCESS, expertCategoryEnquiry } }
    function failure(error) { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_FAILURE, error } }
}

function updateexpertCategoryEnquiry(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        expertCategoryEnquiryService.updateexpertCategoryEnquiry(data)
            .then(
                expertCategoryEnquiry => {
                    dispatch(success(expertCategoryEnquiry));
                    dispatch(this.getAllexpertCategoryEnquiry(tempdata));
                    dispatch(alertActions.success("expertCategoryEnquiry updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertCategoryEnquiryConstants.UPDATE_expertCategoryEnquiry_REQUEST } }
    function success(expertCategoryEnquiry) { return { type: expertCategoryEnquiryConstants.UPDATE_expertCategoryEnquiry_SUCCESS, expertCategoryEnquiry } }
    function failure(error) { return { type: expertCategoryEnquiryConstants.UPDATE_expertCategoryEnquiry_FAILURE, error } }
}

function deleteexpertCategoryEnquiry(data, dataemp) {

    return dispatch => {
        dispatch(request());
        expertCategoryEnquiryService.deleteexpertCategoryEnquiry(data)
            .then(
                expertCategoryEnquiry => {
                    dispatch(success(expertCategoryEnquiry));
                    dispatch(this.getAllexpertCategoryEnquiry(dataemp));
                    dispatch(alertActions.success("expertCategoryEnquiry deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertCategoryEnquiryConstants.DELETE_expertCategoryEnquiry_REQUEST } }
    function success(expertCategoryEnquiry) { return { type: expertCategoryEnquiryConstants.DELETE_expertCategoryEnquiry_SUCCESS, expertCategoryEnquiry } }
    function failure(error) { return { type: expertCategoryEnquiryConstants.DELETE_expertCategoryEnquiry_FAILURE, error } }
}
function disableexpertCategoryEnquiry(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        expertCategoryEnquiryService.disableexpertCategoryEnquiry({ id: data.id })
            .then(
                expertCategoryEnquiry => {
                    dispatch(success(expertCategoryEnquiry));
                    dispatch(this.getAllexpertCategoryEnquiry(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_REQUEST } }
    function success(expertCategoryEnquiry) { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_SUCCESS, expertCategoryEnquiry } }
    function failure(error) { return { type: expertCategoryEnquiryConstants.ADD_expertCategoryEnquiry_FAILURE, error } }
}