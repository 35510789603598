import { expertenqiryConstants } from '../_constants';
import { expertenqiryService } from '../_services';
import { alertActions } from '.';
export const expertenqiryActions = {
    disableExpertEnqiry,
    createNewExpertEnqiry,
    updateExpertEnqiry,
    getAllExpertEnqiry,
    deleteExpertEnqiry
};

function getAllExpertEnqiry(data) {
    return dispatch => {
        dispatch(request());
        expertenqiryService.getAllExpertEnqiry(data)
            .then(
                expertenqiry => dispatch(success(expertenqiry)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertenqiryConstants.EXPERTENQIRY_GETALL_REQUEST } }
    function success(expertenqiry) { return { type: expertenqiryConstants.EXPERTENQIRY_GETALL_SUCCESS, expertenqiry } }
    function failure(error) { return { type: expertenqiryConstants.EXPERTENQIRY_GETALL_FAILURE, error } }
}
function createNewExpertEnqiry(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        expertenqiryService.createNewExpertEnqiry(data)
            .then(
                expertenqiry => {
                    dispatch(success(expertenqiry));
                    dispatch(this.getAllExpertEnqiry(tempdata));
                    dispatch(alertActions.success("ExpertEnqiry created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_REQUEST } }
    function success(expertenqiry) { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_SUCCESS, expertenqiry } }
    function failure(error) { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_FAILURE, error } }
}

function updateExpertEnqiry(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        expertenqiryService.updateExpertEnqiry(data)
            .then(
                expertenqiry => {
                    dispatch(success(expertenqiry));
                    dispatch(this.getAllExpertEnqiry(tempdata));
                    dispatch(alertActions.success("ExpertEnqiry updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertenqiryConstants.UPDATE_EXPERTENQIRY_REQUEST } }
    function success(expertenqiry) { return { type: expertenqiryConstants.UPDATE_EXPERTENQIRY_SUCCESS, expertenqiry } }
    function failure(error) { return { type: expertenqiryConstants.UPDATE_EXPERTENQIRY_FAILURE, error } }
}

function deleteExpertEnqiry(data, dataemp) {

    return dispatch => {
        dispatch(request());
        expertenqiryService.deleteExpertEnqiry(data)
            .then(
                expertenqiry => {
                    dispatch(success(expertenqiry));
                    dispatch(this.getAllExpertEnqiry(dataemp));
                    dispatch(alertActions.success("ExpertEnqiry deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertenqiryConstants.DELETE_EXPERTENQIRY_REQUEST } }
    function success(expertenqiry) { return { type: expertenqiryConstants.DELETE_EXPERTENQIRY_SUCCESS, expertenqiry } }
    function failure(error) { return { type: expertenqiryConstants.DELETE_EXPERTENQIRY_FAILURE, error } }
}
function disableExpertEnqiry(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        expertenqiryService.disableExpertEnqiry({ id: data.id })
            .then(
                expertenqiry => {
                    dispatch(success(expertenqiry));
                    dispatch(this.getAllExpertEnqiry(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_REQUEST } }
    function success(expertenqiry) { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_SUCCESS, expertenqiry } }
    function failure(error) { return { type: expertenqiryConstants.ADD_EXPERTENQIRY_FAILURE, error } }
}