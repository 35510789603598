import { notificationConstants } from '../_constants';
import { notificationService } from '../_services';
import { alertActions } from '.';
export const notificationActions = {
    disableNotification,
    saveEmployeeNotificationList,
    updateNotification,
    getAllNotification,
    deleteNotification
};

function getAllNotification(data) {
    return dispatch => {
        dispatch(request());
        notificationService.getAllNotification(data)
            .then(
                notification => dispatch(success(notification)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: notificationConstants.NOTIFICATION_GETALL_REQUEST } }
    function success(notification) { return { type: notificationConstants.NOTIFICATION_GETALL_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.NOTIFICATION_GETALL_FAILURE, error } }
}
function saveEmployeeNotificationList(data) {
    // let tempdata = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        notificationService.saveEmployeeNotificationList(data)
            .then(
                notification => {
                    dispatch(success(notification));
                    // dispatch(this.getAllNotification(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: notificationConstants.ADD_NOTIFICATION_REQUEST } }
    function success(notification) { return { type: notificationConstants.ADD_NOTIFICATION_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.ADD_NOTIFICATION_FAILURE, error } }
}
function updateNotification(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        notificationService.updateNotification(data)
            .then(
                notification => {
                    dispatch(success(notification));
                    dispatch(this.getAllNotification(tempdata));
                    dispatch(alertActions.success("Updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: notificationConstants.UPDATE_NOTIFICATION_REQUEST } }
    function success(notification) { return { type: notificationConstants.UPDATE_NOTIFICATION_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.UPDATE_NOTIFICATION_FAILURE, error } }
}


function disableNotification(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        notificationService.disableNotification({ id: data.id })
            .then(
                notification => {
                    dispatch(success(notification));
                    dispatch(this.getAllNotification(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: notificationConstants.ADD_NOTIFICATION_REQUEST } }
    function success(notification) { return { type: notificationConstants.ADD_NOTIFICATION_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.ADD_NOTIFICATION_FAILURE, error } }
}

function deleteNotification(data, dataemp) {

    return dispatch => {
        dispatch(request());
        notificationService.deleteNotification(data)
            .then(
                insstory => {
                    dispatch(success(insstory));
                    dispatch(this.getAllNotification(dataemp));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: notificationConstants.DELETE_NOTIFICATION_REQUEST } }
    function success(notification) { return { type: notificationConstants.DELETE_NOTIFICATION_SUCCESS, notification } }
    function failure(error) { return { type: notificationConstants.DELETE_NOTIFICATION_FAILURE, error } }
}