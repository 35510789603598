export const CONST = {
  // BACKEND_URL: 'http://localhost:4000/api/dadmin',
  // IMAGE_URL: 'http://localhost:4000/upload/',

  BACKEND_URL: 'https://api.happbit.com/api/dadmin',
  IMAGE_URL: 'https://api.happbit.com/upload/',


  // BACKEND_URL: 'http://192.46.208.143/api/dadmin',
  // IMAGE_URL: 'http://192.46.208.143/upload/',
  
  // BACKEND_URL: 'http://45.79.127.205:4547/api/dadmin',
  // IMAGE_URL: 'http://45.79.127.205:4547/upload/',

  APP_NAME: 'HappyPlus',
  FOOTER_TEXT: '© HappyPlus. All rights reserved.',

};
