import { activityConstants } from '../_constants';
import { activityService } from '../_services';
import { alertActions } from '.';
export const activityActions = {
    createActivity,
    getAllActivity,
    disableActivity,
    getActivityList,
    updateActivity,
    deleteActivity
};


function createActivity(data) {

    let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
 
    return dispatch => {
        dispatch(request());
        console.log("Create Activity Data:",data)
        activityService.createActivity(data)
            .then(
                activity => {
                    dispatch(success(activity));
                    dispatch(this.getActivityList(datatempsurvey));
                    dispatch(alertActions.success("Activity Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.ADD_ACTIVITY_REQUEST } }
    function success(activity) { return { type: activityConstants.ADD_ACTIVITY_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.ADD_ACTIVITY_FAILURE, error } }
}

function deleteActivity(data,datatempsurvey) {
 
    return dispatch => {
        dispatch(request());
        activityService.deleteActivity(data)
            .then(
                activity => {
                    dispatch(success(activity));
                    dispatch(this.getActivityList(datatempsurvey));
                    dispatch(alertActions.success("Activity Deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.DELETE_ACTIVITY_REQUEST } }
    function success(activity) { return { type: activityConstants.DELETE_ACTIVITY_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.DELETE_ACTIVITY_FAILURE, error } }
}

function updateActivity(data,tempdata) {

 
    return dispatch => {
        dispatch(request());
        console.log("data controllerUpdate: ",data)
        activityService.updateActivity(data)
            .then(
                activity => {
                    dispatch(success(activity));
                    dispatch(this.getActivityList(tempdata));
                    dispatch(alertActions.success("Activity Updated successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.UPDATE_ACTIVITY_REQUEST } }
    function success(activity) { return { type: activityConstants.UPDATE_ACTIVITY_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.UPDATE_ACTIVITY_FAILURE, error } }
}


function disableActivity(data) {

    let datatempsurvey = {
            "keyWord": "",
            "pageNo": 1,
            "size": 10
          
    }
 
    return dispatch => {
        dispatch(request());
        activityService.disableActivity(data)
            .then(
                activity => {
                    dispatch(success(activity));
                    dispatch(this.getActivityList(datatempsurvey));
                    dispatch(alertActions.success("Activity Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.UPDATE_ACTIVITY_REQUEST } }
    function success(activity) { return { type: activityConstants.UPDATE_ACTIVITY_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.UPDATE_ACTIVITY_FAILURE, error } }
}

function getAllActivity(data) {
    return dispatch => {
        dispatch(request());
        activityService.getAllActivity(data)
            .then(
                activity => {
                    dispatch(success(activity));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.ACTIVITY_GETALL_REQUEST } }
    function success(activity) { return { type: activityConstants.ACTIVITY_GETALL_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.ACTIVITY_GETALL_FAILURE, error } }
}

function getActivityList(data) {
    return dispatch => {
        dispatch(request());
        activityService.getActivityList(data)
            .then(
                activity => {
                    dispatch(success(activity));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: activityConstants.ACTIVITYLIST_GETALL_REQUEST } }
    function success(activity) { return { type: activityConstants.ACTIVITYLIST_GETALL_SUCCESS, activity } }
    function failure(error) { return { type: activityConstants.ACTIVITYLIST_GETALL_FAILURE, error } }
}
