import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  FormControlLabel,
  Toolbar,
  IconButton,
  Container,
  // Select,
  // Input,
  // MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
// import Select2 from 'react-select';
import Switch from '@material-ui/core/Switch';


export default function UpdatePremiumMusic(props) {

  const { classes, updateformData, handleFileMusic, submitted, handleUpdateClose, handleUpdateSubmit, updatehandleChangeInput,
    handleFile, handleUpload, imageWebLink, imageWebURL, musicWebLink,handleChangeSwitch, musicWebURL, handleUploadMusic } = props;

  // const { classes, updateformData, handleFileMusic, submitted, handleUpdateClose, handleUpdateSubmit, updatehandleChangeInput,
  //   handleFile, handleUpload, imageWebLink, imageWebURL, handleUpdateCategoryType, categoryList,
  //   musicWebLink, musicWebURL, handleUploadMusic } = props;


  // console.log('imageWebURL: ', imageWebURL)
  // console.log('imageWebLink: ', imageWebLink)
  // console.log('updateformData.imageUrl: ', updateformData.imageUrl)



  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update New PremiumMusic
          </Typography>
          <Button color="inherit" onClick={handleUpdateClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update PremiumMusic
          </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleUpdateSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="name"
                  id="name"
                  value={updateformData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Desc*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="desc"
                  id="desc"
                  value={updateformData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Genere"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="genere"
                  id="genere"
                  value={updateformData.genere}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControlLabel style={{}}
                  value="start"
                  control={<Switch color="primary" onChange={handleChangeSwitch}  />}
                  label="Premium content"
                  checked={updateformData.isPremium}
                  labelPlacement="start"
                  // classes={classess}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="duration*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="duration"
                  id="duration"
                  value={updateformData.duration}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="category*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="category"
                  id="category"
                  value={updateformData.category}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <Select
                  name="category"
                  value={updateformData.category ? updateformData.category : categoryList && categoryList.length > 0 ? categoryList[0] : ''}
                  style={{ width: '100%' }}
                  label="Category Type"
                  onChange={handleUpdateCategoryType}
                  input={<Input id="category" />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Category Type
                  </MenuItem>
                  {
                    categoryList && categoryList.length > 0 ?
                      categoryList.map((tempdata, index) => (
                        <MenuItem key={index} value={tempdata}>{tempdata}</MenuItem>
                      )) : null
                  }

                </Select>
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="points*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="points"
                  id="points"
                  value={updateformData.points}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12}>
                <TextValidator
                  label="composer*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="composer"
                  id="composer"
                  value={updateformData.composer}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid> */}


              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={updateformData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>

            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse Image
                </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
              </Button>



            </Grid>



            {(imageWebURL && (imageWebLink === updateformData.imageLink)) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={imageWebURL} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              :
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={updateformData.imageUrl} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
            }



            <Grid item xs={12}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Music Link*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="musicLink"
                  id="musicLink"
                  value={updateformData.musicLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <div style={{ margin: '20px' }}></div>
            <Grid item xs={12}>

              <label htmlFor="music">
                <Button color="default" variant="contained" component="span" >
                  Browse Music
                </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="music"
                name="music"
                type="file"
                onChange={handleFileMusic}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUploadMusic()}>
                Set
              </Button>
            </Grid>



            {(musicWebURL && (musicWebLink === updateformData.url)) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={musicWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              :
              <Grid item xs={12} style={{ margin: '4%' }}>
                <music
                  // ref="music_tag"
                  src={updateformData.url}
                  autoPlay={false}
                  controls={true} >
                  {/* <source type="music/mp3" src={tempdata.url} /> */}
                </music>
              </Grid>
            }


            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
            </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}