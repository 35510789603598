import {
  expertcategoryConstants
} from '../_constants';

export function expertcategory(state = {}, action) {

  switch (action.type) {
    case expertcategoryConstants.EXPERTCATEGORY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case expertcategoryConstants.EXPERTCATEGORY_GETALL_SUCCESS:
      return {
        ...state,
        addExpertCategorySuccess: false,
        updateExpertCategorySuccess:false,
        deleteExpertCategorySuccess: false,

          ExpertCategoryList: action.expertcategory.listOfExpertCategorys.list,
          items: action.expertcategory.listOfExpertCategorys.list,
          total: action.expertcategory.listOfExpertCategorys.total
      };
    case expertcategoryConstants.EXPERTCATEGORY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_SUCCESS:
      return {
        ...state,
        listOfExpertCategory: action.expertcategory.listOfExpertCategory
      };
    case expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case expertcategoryConstants.ADD_EXPERTCATEGORY_REQUEST:
      return {
        ...state
      };
    case expertcategoryConstants.ADD_EXPERTCATEGORY_SUCCESS:
      return {
        ...state,
        addExpertCategorySuccess: true
      };
    case expertcategoryConstants.ADD_EXPERTCATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case expertcategoryConstants.UPDATE_EXPERTCATEGORY_REQUEST:
      return {
        ...state
      };
    case expertcategoryConstants.UPDATE_EXPERTCATEGORY_SUCCESS:
      return {
        ...state,
        updateExpertCategorySuccess: true
      };
    case expertcategoryConstants.UPDATE_EXPERTCATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case expertcategoryConstants.DELETE_EXPERTCATEGORY_REQUEST:
        return {
          ...state
        };
      case expertcategoryConstants.DELETE_EXPERTCATEGORY_SUCCESS:
        return {
          ...state,
          deleteExpertCategorySuccess: true
        };
      case expertcategoryConstants.DELETE_EXPERTCATEGORY_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}