import {
  activityConstants
} from '../_constants';

export function activity(state = {}, action) {

  switch (action.type) {

      case activityConstants.ADD_ACTIVITY_REQUEST:
        return {
          ...state
        };
      case activityConstants.ADD_ACTIVITY_SUCCESS:
        return {
          ...state,
          addActivitySuccess: true,
          createActivity: action.activity.createActivity,
        };
      case activityConstants.ADD_ACTIVITY_FAILURE:
        return {
          ...state,
          error: action.error
        };

        
        case activityConstants.DELETE_ACTIVITY_REQUEST:
          return {
            ...state
          };
        case activityConstants.DELETE_ACTIVITY_SUCCESS:
          return {
            ...state,
            deleteActivitySuccess: true
          };
        case activityConstants.DELETE_ACTIVITY_FAILURE:
          return {
            ...state,
            error: action.error
          };
    

        case activityConstants.UPDATE_ACTIVITY_REQUEST:
          return {
            ...state
          };
        case activityConstants.UPDATE_ACTIVITY_SUCCESS:
          return {
            ...state,
            updateActivitySuccess: true,
            updateActivity: action.activity.updateActivity,
          };
        case activityConstants.UPDATE_ACTIVITY_FAILURE:
          return {
            ...state,
            error: action.error
          };
  

      case activityConstants.ACTIVITY_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case activityConstants.ACTIVITY_GETALL_SUCCESS:
        return {
          ...state,           
          addActivitySuccess: false,   
          updateActivitySuccess: false,

          allActivities: action.activity.allActivities
        };
      case activityConstants.ACTIVITY_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };


        case activityConstants.ACTIVITYLIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case activityConstants.ACTIVITYLIST_GETALL_SUCCESS:
          return {
            ...state,       
            addActivitySuccess: false,   
            updateActivitySuccess: false,

            items: action.activity.allActivitiesList.list,
            total:action.activity.allActivitiesList.total,
          };
        case activityConstants.ACTIVITYLIST_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };
  


    default:
      return state
  }
}