import {
  expertConstants
} from '../_constants';

export function expert(state = {}, action) {

  switch (action.type) {
    case expertConstants.EXPERT_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case expertConstants.EXPERT_GETALL_SUCCESS:
      return {
        ...state,
        addExpertSuccess: false,
        updateExpertSuccess:false,
        deleteExpertSuccess: false,

          ExpertList: action.expert.listOfExperts.list,
          items: action.expert.listOfExperts.list,
          total: action.expert.listOfExperts.total
      };
    case expertConstants.EXPERT_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case expertConstants.ADD_EXPERT_REQUEST:
      return {
        ...state
      };
    case expertConstants.ADD_EXPERT_SUCCESS:
      return {
        ...state,
        addExpertSuccess: true
      };
    case expertConstants.ADD_EXPERT_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case expertConstants.UPDATE_EXPERT_REQUEST:
      return {
        ...state
      };
    case expertConstants.UPDATE_EXPERT_SUCCESS:
      return {
        ...state,
        updateExpertSuccess: true
      };
    case expertConstants.UPDATE_EXPERT_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case expertConstants.DELETE_EXPERT_REQUEST:
        return {
          ...state
        };
      case expertConstants.DELETE_EXPERT_SUCCESS:
        return {
          ...state,
          deleteExpertSuccess: true
        };
      case expertConstants.DELETE_EXPERT_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}