
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const activityService = {
    logout,
    createActivity,
    updateActivity,
    disableActivity,
    getAllActivity,
    getActivityList,
    deleteActivity
};
function logout() {
    localStorage.removeItem('adminactivity');
    window.location.href = "#/login";
}

function createActivity(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                createActivity: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}

function deleteActivity(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                deleteActivity: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}

function updateActivity(data) {
    console.log("data serviceUpdate: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                updateActivity: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}

function disableActivity(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateActivityStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                updateActivity: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}

function getAllActivity(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                allActivities: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}


function getActivityList(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getActivityList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let activityObj = {
                allActivitiesList: data.data
            }
            // console.log("After API: ",data.data);

            return activityObj;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}