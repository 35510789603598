import {
  questiontypeConstants
} from '../_constants';

export function questiontype(state = {}, action) {

  switch (action.type) {
    case questiontypeConstants.QUESTIONTYPE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case questiontypeConstants.QUESTIONTYPE_GETALL_SUCCESS:
      return {
        ...state,
        addQuestionTypeSuccess: false,
        updateQuestionTypeSuccess:false,
          questionTypeList: action.questiontype.listOfQuestionTypes.list,
          items: action.questiontype.listOfQuestionTypes.list,
          total: action.questiontype.listOfQuestionTypes.total
      };
    case questiontypeConstants.QUESTIONTYPE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case questiontypeConstants.ADD_QUESTIONTYPE_REQUEST:
      return {
        ...state
      };
    case questiontypeConstants.ADD_QUESTIONTYPE_SUCCESS:
      return {
        ...state,
        addQuestionTypeSuccess: true
      };
    case questiontypeConstants.ADD_QUESTIONTYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case questiontypeConstants.UPDATE_QUESTIONTYPE_REQUEST:
      return {
        ...state
      };
    case questiontypeConstants.UPDATE_QUESTIONTYPE_SUCCESS:
      return {
        ...state,
        updateQuestionTypeSuccess: true
      };
    case questiontypeConstants.UPDATE_QUESTIONTYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}