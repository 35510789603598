import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  // Select,
  // MenuItem,
  // Input
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select2 from 'react-select';
import { CONST } from '../../../_config/index';


export default function UpdatePremiumActivity(props) {

  const { classes, updateformData, submitted, handleClose, handlePremiumActivityNameUpdate,handleActivityResourceUpdate,
    allResources, submitUpdatePremiumActivityDetails,
    allPremiumMusics,  handleFile, handleUpload, } = props;


  let colourOptions=[]
  allPremiumMusics.forEach((emp)=>{
    colourOptions.push(
      {
        value:emp.name,
        label:emp.name,
        id:emp.id,
      }
    )
  })
  //console.log("colourOptionscolourOptionscolourOptions", colourOptions);
  //console.log("updateformData   ",updateformData);
  //console.log("allPremiumMusics   ",allPremiumMusics);

  let colourDefault=[]
  if(updateformData.musicList){
        //console.log("updateformData   ",updateformData);
         updateformData.musicList.forEach((upd)=>{
          allPremiumMusics.forEach((emp)=>{
           if(emp.id === upd)
           colourDefault.push(
             {
               value:emp.name,
               label:emp.name,
               id:emp.id,
             }
           )
         })
       })
       //console.log("colourDefaultcolourDefaultcolourDefault  ",colourDefault);
  }







  //console.log("allResources: ", allResources)

  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update New PremiumActivity
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update PremiumActivity
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>

              <TextField
                label="Name*"
                id="name"
                name="name"
                fullWidth
                rowsMax={4}
                placeholder="Name"
                value={updateformData.name}
                validators={['required']}
                onChange={handlePremiumActivityNameUpdate}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Color Code"
                id="colorCode"
                name="colorCode"
                fullWidth
                rowsMax={4}
                placeholder="Color Code"
                value={updateformData.colorCode}
                onChange={handlePremiumActivityNameUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Desc"
                id="desc"
                name="desc"
                fullWidth
                rowsMax={4}
                placeholder="Description"
                value={updateformData.desc}
                onChange={handlePremiumActivityNameUpdate}
              />
            </Grid>

            <Grid item xs={12} style={{ margin: "0px" }}>
              <label htmlFor="musicList" style={{ color: 'grey' }}>Premium Music List</label>
              {/* {JSON.stringify(colourDefault)} */}
              <Select2
                id="musicList"
                isMulti
                defaultValue={colourDefault}
                // defaultValue={(colourOptions) ? colourOptions.map((val) => { return val }) : ""}
                name="musicList"
                onChange={handleActivityResourceUpdate}
                options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="Resources"
                value={updateformData.resourceList}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Icon"
                id="icon"
                name="icon"
                fullWidth
                rowsMax={4}
                placeholder="Icon"
                value={updateformData.icon}
                onChange={handlePremiumActivityNameUpdate}
              />
            </Grid>
            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
              </Button>
            </Grid>


            {(updateformData.icon) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={CONST.IMAGE_URL + updateformData.icon} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              : null}
            <div style={{ margin: "7px" }}></div>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
            className={classes.submit}
            onClick={() => submitUpdatePremiumActivityDetails()}
            style={{ marginTop: "5%" }}
          >
            Save
          </Button>

        </div>
      </Container>



    </div>
  );
}
