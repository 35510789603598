import {
  parameterConstants
} from '../_constants';

export function parameter(state = {}, action) {

  switch (action.type) {

      case parameterConstants.ADD_PARAMETER_REQUEST:
        return {
          ...state
        };
      case parameterConstants.ADD_PARAMETER_SUCCESS:
        return {
          ...state,
          addParameterSuccess: true,
          createParameter: action.parameter.createParameter,
        };
      case parameterConstants.ADD_PARAMETER_FAILURE:
        return {
          ...state,
          error: action.error
        };

        case parameterConstants.UPDATE_PARAMETER_REQUEST:
          return {
            ...state
          };
        case parameterConstants.UPDATE_PARAMETER_SUCCESS:
          return {
            ...state,
            updateParameterSuccess: true,
            updateParameter: action.parameter.updateParameter,
          };
        case parameterConstants.UPDATE_PARAMETER_FAILURE:
          return {
            ...state,
            error: action.error
          };
  

      case parameterConstants.PARAMETER_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case parameterConstants.PARAMETER_GETALL_SUCCESS:
        return {
          ...state,    
          addParameterSuccess: false,   
          updateParameterSuccess: false,
             
          allParameters: action.parameter.allParameters
        };
      case parameterConstants.PARAMETER_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };
  
        case parameterConstants.PARAMETERLIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case parameterConstants.PARAMETERLIST_GETALL_SUCCESS:
          return {
            ...state,  
            addParameterSuccess: false,      
            updateParameterSuccess: false,
   
            items: action.parameter.allParametersList.list,
            parameterList: action.parameter.allParametersList.list,
            total: action.parameter.allParametersList.total
          };
        case parameterConstants.PARAMETERLIST_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };

          case parameterConstants.PARAMETERLIST_BYTYPE_GETALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case parameterConstants.PARAMETERLIST_BYTYPE_GETALL_SUCCESS:
            return {
              ...state,  
              addParameterSuccess: false,      
              updateParameterSuccess: false,
     
              allParametersListByParameterTypeData: action.parameter.getParameterListbyParameterType
            };
          case parameterConstants.PARAMETERLIST_BYTYPE_GETALL_FAILURE:
            return {
              ...state,
              error: action.error
            };



            case parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_REQUEST:
              return {
                ...state,
                loading: true
              };
            case parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_SUCCESS:
              return {
                ...state,  
                addParameterSuccess: false,      
                updateParameterSuccess: false,
       
                allParametersListBucketByParameterTypeData: action.parameter.getParameterListbyParameterType
              };
            case parameterConstants.PARAMETERLIST_BUCKET_BYTYPE_GETALL_FAILURE:
              return {
                ...state,
                error: action.error
              };



              case parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_SUCCESS:
                return {
                  ...state,  
                  addParameterSuccess: false,      
                  updateParameterSuccess: false,
         
                  allParametersListDimensionByParameterTypeData: action.parameter.getParameterListbyParameterType
                };
              case parameterConstants.PARAMETERLIST_DIMENSION_BYTYPE_GETALL_FAILURE:
                return {
                  ...state,
                  error: action.error
                };


    default:
      return state
  }
}