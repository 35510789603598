import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function AddResourceType(props) {

  const { classes, formData,submitted, handleClose, handleSubmit, handleChangeInput } = props;

  return (
    <div >
 <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Add New ResourceType
            </Typography>
              <Button color="inherit" onClick={handleClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Add ResourceType
              </Typography>

              <ValidatorForm
                // ref="form"
                onSubmit={handleSubmit}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                    <TextValidator
                      label="Name*"
                      fullWidth
                      onChange={handleChangeInput}
                      name="name"
                      id="name"
                      value={formData.name}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextValidator
                      label="Template*"
                      fullWidth
                      onChange={handleChangeInput}
                      name="template"
                      id="template"
                      value={formData.template}
                      validators={['required']}
                      errorMessages={['this field is required']}
                    />
                    </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                >
                  Save
                </Button>
              </ValidatorForm>
            </div>
          </Container>

    </div>
  );
}