export const benchmarkConstants = {

    BENCHMARK_GETALL_REQUEST: 'BENCHMARK_GETALL_REQUEST',
    BENCHMARK_GETALL_SUCCESS: 'BENCHMARK_GETALL_SUCCESS',
    BENCHMARK_GETALL_FAILURE: 'BENCHMARK_GETALL_FAILURE',

    ADD_BENCHMARK_REQUEST: 'ADD_BENCHMARK_REQUEST',
    ADD_BENCHMARK_SUCCESS: 'ADD_BENCHMARK_SUCCESS',
    ADD_BENCHMARK_FAILURE: 'ADD_BENCHMARK_FAILURE',
    
    UPDATE_BENCHMARK_REQUEST: 'UPDATE_BENCHMARK_REQUEST',
    UPDATE_BENCHMARK_SUCCESS: 'UPDATE_BENCHMARK_SUCCESS',
    UPDATE_BENCHMARK_FAILURE: 'UPDATE_BENCHMARK_FAILURE',

    DELETE_BENCHMARK_REQUEST: 'DELETE_BENCHMARK_REQUEST',
    DELETE_BENCHMARK_SUCCESS: 'DELETE_BENCHMARK_SUCCESS',
    DELETE_BENCHMARK_FAILURE: 'DELETE_BENCHMARK_FAILURE',

};
