import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { expertActions, expertcategoryActions } from '../../_actions';
import { confirmAlert } from 'react-confirm-alert'; // Import
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddExpert from './Modal/AddExpert';
import UpdateExpert from './Modal/UpdateExpert';

import { userActions } from '../../_actions/user.actions'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class Expert extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newExpertModal: false,
      newUpdateExpertModal: false,
      submitted: false,
      selectedFile: null,
      imageWebLink: "",
      imageWebURL:"",
      formData: {
        "name": "",
        "desc": "",
        "type": "",
        "timing": [],
        "imageLink": "",
        "expertCategoryId": [],
      },
      updateformData: {
        "id": "",
        "name": "",
        "desc": "",
        "type": "",
        "timing": [],
        "imageLink": "",
        "imageLinkV2": "",
        "expertCategoryId": [],

      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(expertActions.getAllExpert(data));
    this.props.dispatch(expertcategoryActions.getAllExpertCategoryList(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }

    if (nextProps.expert.deleteExpertSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "timing": [],
          "imageLink": "",
        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "timing": [],
          "imageLink": "",
          "imageLinkV2": "",
        }
      }
    }

    if (nextProps.expert.updateExpertSuccess) {
      console.log("nextProps.expert.updateExpertSuccess", nextProps.expert.updateExpertSuccess)
      return {
        ...nextProps,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "timing": [],
          "type": "",
          "imageLink": "",
          "imageLinkV2": "",
        },
        newUpdateExpertModal: false,
      }
    }

    if (nextProps.expert.addExpertSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "timing": [],
          "type": "",
          "imageLink": "",
        },
        newExpertModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(expertActions.getAllExpert(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(expertActions.getAllExpert(data));
  }
  handleClickOpen = () => {
    this.setState({ newExpertModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newExpertModal: false });
    this.props.dispatch(userActions.uploadImageClear());


  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    let reqData = {
      "name": this.state.formData.name.trim(),
      "desc": this.state.formData.desc,
      "expertCategoryId": this.state.formData.expertCategoryId,
      "type": this.state.formData.type.trim(),
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.formData.imageLink.trim(),
    }
    console.log("this.state.formData  ", this.state.formData);
    this.props.dispatch(expertActions.createNewExpert(reqData));
    this.setState({ imageWebLink: "" });

  }


  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(expertActions.deleteExpert({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }


  onRowClick = (data) => {
    this.props.history.push(`/app/expertdetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(expertActions.disableExpert(tempdata));
  }
  onUpdate = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "type": data.type,
      "timing": data.timing,
      "imageLink": data.imageLink,
      "imageLinkV2":data.imageLinkV2,
      "expertCategoryId": data.expertCategoryId
    }

    let tempEmpId = tempdata.expertCategoryId;
    tempdata.expertCategoryId = []
    tempEmpId.forEach((res) => {
      tempdata.expertCategoryId.push(res.id)
    })

    console.log("tempdata", tempdata)
    this.setState({ newUpdateExpertModal: true, updateformData: tempdata });
  }
  addSlot = (index) => {
    // let temp = {
    //   "day": "",
    //   "template": 1,
    //   "slot": [""]
    // }
    const { updateformData } = this.state;
    updateformData['timing'][index]['slot'].push('');
    this.setState({ updateformData });
  }
  addDay = (data) => {
    let temp = {
      "day": "",
      "template": 1,
      "slot": [""]
    }
    const { updateformData } = this.state;
    updateformData['timing'].push(temp);
    this.setState({ updateformData });
  }
  updatehandleChangeInput = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputday = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    // console.log("event.target  ",event.target);
    let {id}=event.target;
    updateformData['timing'][id]['day'] = event.target.value;
    this.setState({ updateformData });
  }
  updatehandleChangeInputslot = (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
    let {id,name,value}=event.target;
    // console.log(event.target);
    updateformData['timing'][id]['slot'][name] = value;
    this.setState({ updateformData });
  }


  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id": this.state.updateformData.id,
      "name": this.state.updateformData.name.trim(),
      "desc": this.state.updateformData.desc,
      "expertCategoryId": this.state.updateformData.expertCategoryId,
      "timing": this.state.updateformData.timing,
      "type": this.state.updateformData.type.trim(),
      "imageLink": (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.imageLink.trim(),
    }
    console.log("-----reqData-----")
    console.log(reqData)
    this.props.dispatch(expertActions.updateExpert(reqData, tempdata));
    this.setState({ imageWebLink: "" });

  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateExpertModal: false });
    this.props.dispatch(userActions.uploadImageClear());

  }


  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });

    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });

    }
    else {
      console.log("No File To Upload!")
    }

  }

  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['imageLink'] = imageWebLink;
    updateformData['imageLink'] = imageWebLink;
    this.setState({ formData, updateformData });

  }

  // handleParameterTypeName=(event)=>{
  //   const { formData, updateformData } = this.state;
  //   formData[event.target.name] = event.target.value;
  //   updateformData[event.target.name] = event.target.value;
  //   this.setState({formData})
  //   this.setState({updateformData})
  //   // const { listOfExpertCategory } = this.props;
  //   // console.log("listOfExpertCategory: ",listOfExpertCategory)
  // }


  handleParameterTypeName = (event, val) => {
    console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.expertCategoryId.push(val.option.id);
      if (updateformData && updateformData.expertCategoryId) {
        updateformData.expertCategoryId.push(val.option.id)
      }
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.expertCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.expertCategoryId.splice(delIndex, 1);
        }
      })

      updateformData.expertCategoryId.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.expertCategoryId.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }
  }

  render() {

    let { users, expert, classes } = this.props;
    let { items, total } = expert;
    const { formData, updateformData, submitted, listOfExpertCategory } = this.state;

    if (this.state) {
      console.log("----updateformdata----", this.state);
    }

    let receivedImage = null;
    if (users.filesDetails) {
      console.log("filesDetails: ", users.filesDetails)
      receivedImage = users.filesDetails;
    }

    console.log("imageweblink: ", this.state.imageWebLink)

    return (
      <>
        <PageTitle title="Expert List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate}
                      onDelete={this.onDelete} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newExpertModal} onClose={this.handleClose} TransitionComponent={Transition}>
          <AddExpert
            data={this.data}
            classes={classes}
            formData={formData}
            submitted={submitted}
            handleClose={this.handleClose}
            handleSubmit={this.handleSubmit}
            handleChangeInput={this.handleChangeInput}
            handleParameterTypeName={this.handleParameterTypeName}

            handleFile={this.handleFile}
            handleUpload={this.handleUpload}
            receivedImage={receivedImage}
            listOfExpertCategory={listOfExpertCategory}
            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

          />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateExpertModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
          <UpdateExpert
            data={this.data}
            classes={classes}
            updateformData={updateformData}
            handleUpdateClose={this.handleUpdateClose}
            handleUpdateSubmit={this.handleUpdateSubmit}
            handleParameterTypeName={this.handleParameterTypeName}
            updatehandleChangeInput={this.updatehandleChangeInput}
            updatehandleChangeInputday={this.updatehandleChangeInputday}
            updatehandleChangeInputslot={this.updatehandleChangeInputslot}

            handleFile={this.handleFile}
            handleUpload={this.handleUpload}
            addSlot={this.addSlot}
            addDay={this.addDay}
            receivedImage={receivedImage}
            listOfExpertCategory={listOfExpertCategory}
            imageWebLink={this.state.imageWebLink}
            imageWebURL={this.state.imageWebURL}

          />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { users, expert, expertcategory } = state;
  return {
    listOfExpertCategory: expertcategory.listOfExpertCategory ? expertcategory.listOfExpertCategory : [],
    users,
    expert
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Expert)));
