export const expertConstants = {

    EXPERT_GETALL_REQUEST: 'EXPERT_GETALL_REQUEST',
    EXPERT_GETALL_SUCCESS: 'EXPERT_GETALL_SUCCESS',
    EXPERT_GETALL_FAILURE: 'EXPERT_GETALL_FAILURE',

    ADD_EXPERT_REQUEST: 'ADD_EXPERT_REQUEST',
    ADD_EXPERT_SUCCESS: 'ADD_EXPERT_SUCCESS',
    ADD_EXPERT_FAILURE: 'ADD_EXPERT_FAILURE',
    
    UPDATE_EXPERT_REQUEST: 'UPDATE_EXPERT_REQUEST',
    UPDATE_EXPERT_SUCCESS: 'UPDATE_EXPERT_SUCCESS',
    UPDATE_EXPERT_FAILURE: 'UPDATE_EXPERT_FAILURE',

    DELETE_EXPERT_REQUEST: 'DELETE_EXPERT_REQUEST',
    DELETE_EXPERT_SUCCESS: 'DELETE_EXPERT_SUCCESS',
    DELETE_EXPERT_FAILURE: 'DELETE_EXPERT_FAILURE',

};
