export const expertCategoryEnquiryConstants = {

    EXPERTCATEGORYENQUIRY_GETALL_REQUEST: 'EXPERTCATEGORYENQUIRY_GETALL_REQUEST',
    EXPERTCATEGORYENQUIRY_GETALL_SUCCESS: 'EXPERTCATEGORYENQUIRY_GETALL_SUCCESS',
    EXPERTCATEGORYENQUIRY_GETALL_FAILURE: 'EXPERTCATEGORYENQUIRY_GETALL_FAILURE',

    ADD_EXPERTCATEGORYENQUIRY_REQUEST: 'ADD_EXPERTCATEGORYENQUIRY_REQUEST',
    ADD_EXPERTCATEGORYENQUIRY_SUCCESS: 'ADD_EXPERTCATEGORYENQUIRY_SUCCESS',
    ADD_EXPERTCATEGORYENQUIRY_FAILURE: 'ADD_EXPERTCATEGORYENQUIRY_FAILURE',
    
    UPDATE_EXPERTCATEGORYENQUIRY_REQUEST: 'UPDATE_EXPERTCATEGORYENQUIRY_REQUEST',
    UPDATE_EXPERTCATEGORYENQUIRY_SUCCESS: 'UPDATE_EXPERTCATEGORYENQUIRY_SUCCESS',
    UPDATE_EXPERTCATEGORYENQUIRY_FAILURE: 'UPDATE_EXPERTCATEGORYENQUIRY_FAILURE',

    DELETE_EXPERTCATEGORYENQUIRY_REQUEST: 'DELETE_EXPERTCATEGORYENQUIRY_REQUEST',
    DELETE_EXPERTCATEGORYENQUIRY_SUCCESS: 'DELETE_EXPERTCATEGORYENQUIRY_SUCCESS',
    DELETE_EXPERTCATEGORYENQUIRY_FAILURE: 'DELETE_EXPERTCATEGORYENQUIRY_FAILURE',

};
