import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import moment from 'moment';

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };

export default function TableComponent(props) {
  let { data, offset, onCampaign} = props;



  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Name</TableCell>
          <TableCell >Desc</TableCell>
          <TableCell >CreatedAt</TableCell>
          {/* <TableCell >RATING</TableCell> */}
          <TableCell >ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data && data.length > 0 ? data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.desc}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>

              <TableCell>
           
                <Button
                  color="primary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onCampaign(tempdata)}
                >
                 Publish
                </Button>
              </TableCell> 
            </TableRow>
          )) : null
        }
      </TableBody>
    </Table>
  );
}
