export const premiummusicConstants = {
    
    PREMIUMMUSIC_GETALL_REQUEST: 'PREMIUMMUSIC_GETALL_REQUEST',
    PREMIUMMUSIC_GETALL_SUCCESS: 'PREMIUMMUSIC_GETALL_SUCCESS',
    PREMIUMMUSIC_GETALL_FAILURE: 'PREMIUMMUSIC_GETALL_FAILURE',

    ADD_PREMIUMMUSIC_REQUEST: 'ADD_PREMIUMMUSIC_REQUEST',
    ADD_PREMIUMMUSIC_SUCCESS: 'ADD_PREMIUMMUSIC_SUCCESS',
    ADD_PREMIUMMUSIC_FAILURE: 'ADD_PREMIUMMUSIC_FAILURE',

    // PREMIUMMUSIC_GETALL_REQUEST: 'PREMIUMMUSIC_GETALL_REQUEST',
    // PREMIUMMUSIC_GETALL_SUCCESS: 'PREMIUMMUSIC_GETALL_SUCCESS',
    // PREMIUMMUSIC_GETALL_FAILURE: 'PREMIUMMUSIC_GETALL_FAILURE',
    
    UPDATE_PREMIUMMUSIC_REQUEST: 'UPDATE_PREMIUMMUSIC_REQUEST',
    UPDATE_PREMIUMMUSIC_SUCCESS: 'UPDATE_PREMIUMMUSIC_SUCCESS',
    UPDATE_PREMIUMMUSIC_FAILURE: 'UPDATE_PREMIUMMUSIC_FAILURE',

    DELETE_PREMIUMMUSIC_REQUEST: 'DELETE_PREMIUMMUSIC_REQUEST',
    DELETE_PREMIUMMUSIC_SUCCESS: 'DELETE_PREMIUMMUSIC_SUCCESS',
    DELETE_PREMIUMMUSIC_FAILURE: 'DELETE_PREMIUMMUSIC_FAILURE',



        
    ALL_PREMIUMMUSIC_GETALL_REQUEST: 'ALL_PREMIUMMUSIC_GETALL_REQUEST',
    ALL_PREMIUMMUSIC_GETALL_SUCCESS: 'ALL_PREMIUMMUSIC_GETALL_SUCCESS',
    ALL_PREMIUMMUSIC_GETALL_FAILURE: 'ALL_PREMIUMMUSIC_GETALL_FAILURE',

};
