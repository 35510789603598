
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const expertCategoryEnquiryService = {
    disableexpertCategoryEnquiry,
    logout,
    createNewexpertCategoryEnquiry,
    updateexpertCategoryEnquiry,
    getAllexpertCategoryEnquiry,
    deleteexpertCategoryEnquiry
};
function logout() {
    localStorage.removeItem('adminexpertCategoryEnquiry');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllexpertCategoryEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getexpertCategoryEnquiryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let expertCategoryEnquiryObj = {
                listOfexpertCategoryEnquirys: data.data
            }
            console.log();
            return expertCategoryEnquiryObj;
        });
}

function createNewexpertCategoryEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createexpertCategoryEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertCategoryEnquiryObj = {
                createNewexpertCategoryEnquiry: data.data
            }
            console.log();

            return expertCategoryEnquiryObj;
        });
}
function updateexpertCategoryEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateexpertCategoryEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertCategoryEnquiryObj = {
                updateNewexpertCategoryEnquiry: data.data
            }
            console.log(expertCategoryEnquiryObj);

            return expertCategoryEnquiryObj;
        });
}

function deleteexpertCategoryEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteexpertCategoryEnquiry`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertCategoryEnquiryObj = {
                deleteNewexpertCategoryEnquiry: data.data
            }
            console.log(expertCategoryEnquiryObj);

            return expertCategoryEnquiryObj;
        });
}

function disableexpertCategoryEnquiry(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateexpertCategoryEnquiryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertCategoryEnquiryObj = {
                deleteexpertCategoryEnquiry: data.data
            }
            console.log();

            return expertCategoryEnquiryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}