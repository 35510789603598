import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { companyActions } from '../../_actions';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import UpdateCompany from './Modal/UpdateCompany';
import AddCompany from './Modal/AddCompany';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class Company extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      newCompanyModal: false,
      newUpdateCompanyModal: false,
      submitted: false,
      keyWord: '',
      formData: {
        "name": ""
      },
      updateformData:{
        "id":"",
        "name":""
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }


    this.props.dispatch(companyActions.getAllCompany(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    
    if (nextProps.company.updateCompanySuccess) {
      return {
        ...nextProps,
        updateformData:{
          "id":"",
          "name":""
        },
        newUpdateCompanyModal: false,
      }
    }
    if (nextProps.company.addCompanySuccess) {
      return {
        ...nextProps,
        formData: {
          "name": ""
        },
        newCompanyModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getAllCompany(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:",value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getAllCompany(data));
  }
  handleClickOpen = () => {
    this.setState({ newCompanyModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newCompanyModal: false });

  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    //console.log();

    //let { company } = this.props;
    //let { filesDetails } = company;
    let reqData = {
      "name": this.state.formData.name
    }
    this.props.dispatch(companyActions.createNewCompany(reqData));
  }
  onRowClick = (data) => {
    this.props.history.push(`/app/companydetails/${data.id}`)
  }


  onDisable = (data) => {
    
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(companyActions.disableCompany(tempdata));
  }
  onUpdate = (data) => {
  
    let tempdata = {
      "id": data.id,
      "name":data.name
    }
    this.setState({ newUpdateCompanyModal: true, updateformData: tempdata });
  }
  updatehandleChangeInput= (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
        updateformData[event.target.name] = event.target.value;
        this.setState({ updateformData });
   
  }

  handleUpdateSubmit = () => {

    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }

    let reqData = {
      "id":this.state.updateformData.id,
      "name": this.state.updateformData.name
    }
    this.props.dispatch(companyActions.updateNewCompany(reqData,tempdata));
  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateCompanyModal: false });

  }

  onDashboard = (data) => {
    this.props.history.push(`/app/happiness/${data.id}`)
  }



  render() {

    let { company, classes } = this.props;
    let { items, total } = company;
    const { formData,updateformData, submitted } = this.state;

    return (
      <>
        <PageTitle title="Company List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable} 
                      onUpdate={this.onUpdate}
                      onDashboard={this.onDashboard}
                      />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newCompanyModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddCompany
                  data={this.data}
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateCompanyModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateCompany
                  data={this.data}
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.updatehandleChangeInput}
                />
        </Dialog>
       
      </>
    );

  }
}
function mapStateToProps(state) {
  const { company } = state;
  return {
    company
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Company)));
