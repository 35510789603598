import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { questiontypeActions } from '../../_actions';
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import { withRouter } from "react-router";
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './styles';

import AddQuestionType from './Modal/AddQuestionType';
import UpdateQuestionType from './Modal/UpdateQuestionType';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();

class QuestionType extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',
      newQuestionTypeModal: false,
      newUpdateQuestionTypeModal:false,
      submitted: false,
      formData: {
        "name": "",
        "templateId":null
      },
      updateformData:{
        "name":"",
        "templateId":null
      }
    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(questiontypeActions.getAllQuestionType(data));
  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.questiontype.updateQuestionTypeSuccess) {
      return {
        ...nextProps,
        updateformData: {
          "name": "",
          "templateId":null
        },
        newUpdateQuestionTypeModal: false,
      }
    }

    if (nextProps.questiontype.addQuestionTypeSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "templateId":null
        },
        newQuestionTypeModal: false,
      }
    } else {
      return {
        ...nextProps
      }
    }

  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.props.dispatch(questiontypeActions.getAllQuestionType(data));
  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(questiontypeActions.getAllQuestionType(data));
  }
  handleClickOpen = () => {
    this.setState({ newQuestionTypeModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newQuestionTypeModal: false });

  }
  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    formData[event.target.templateId] = event.target.value;
    this.setState({ formData });
  }
  handleSubmit = () => {

    //console.log();

    //let { questiontype } = this.props;
    //let { filesDetails } = questiontype;
    let reqData = {
      "name": this.state.formData.name.trim(),
      "templateId":this.state.formData.templateId
    }
    this.props.dispatch(questiontypeActions.createNewQuestionType(reqData));
  }
  onRowClick = (data) => {
    this.props.history.push(`/app/questiontypedetails/${data.id}`)
  }
  onDisable = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size

    }
    console.log("asdf :: ", tempdata);
    this.props.dispatch(questiontypeActions.disableQuestionType(tempdata));
  }
  onUpdate = (data) => {
    console.log(data);
    let tempdata = {
      "id": data.id,
      "name":data.name,
      "templateId":data.templateId
    }
    this.setState({ newUpdateQuestionTypeModal: true, updateformData: tempdata });
  }
  updatehandleChangeInput= (event) => {
    event.preventDefault();
    const { updateformData } = this.state;
        updateformData[event.target.name] = event.target.value;
        updateformData[event.target.templateId] = event.target.value;
        this.setState({ updateformData });
        if(this.state.updateformData){
          console.log("----updateformdata----");
          console.log(updateformData);
        }
  }

  handleUpdateSubmit = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    let reqData = {
      "id":this.state.updateformData.id,
      "name": this.state.updateformData.name.trim(),
      "templateId": this.state.updateformData.templateId
    }
    console.log("-----reqData-----")
    console.log(reqData)
    this.props.dispatch(questiontypeActions.updateQuestionType(reqData,tempdata));
  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ newUpdateQuestionTypeModal: false });

  }
  render() {

    let { questiontype, classes } = this.props;
    let { items, total } = questiontype;
    const { formData,updateformData, submitted } = this.state;

    return (
      <>
        <PageTitle title="QuestionType List" />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={8} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate} />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>
        </Grid>
        <Dialog fullScreen open={this.state.newQuestionTypeModal} onClose={this.handleClose} TransitionComponent={Transition}>
               <AddQuestionType
                  data={this.data}
                  classes={classes}
                  formData ={formData}
                  submitted={submitted}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.newUpdateQuestionTypeModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
                <UpdateQuestionType
                  data={this.data}
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.updatehandleChangeInput}
                />
        </Dialog>
      </>
    );

  }
}
function mapStateToProps(state) {
  const { questiontype } = state;
  return {
    questiontype
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(QuestionType)));
