import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import moment from 'moment';


export default function TableComponent(props) {
  //onDeleteonRowClick,
  let { data, offset, onDisable, onUpdate, onDelete } = props;
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Question</TableCell>
          <TableCell >Answer</TableCell>
          <TableCell >CreatedAt</TableCell>
          <TableCell >ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.answer}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>
              <TableCell>

                <Button
                  color={tempdata.isDisable ? "warning" : "success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDisable(tempdata)}
                >
                  {tempdata.isDisable ? 'Disable' : 'Enable'}
                </Button>
                <Button
                  color="danger"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDelete(tempdata)}
                >
                  Delete
                </Button>
                <Button
                  color="info"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onUpdate(tempdata)}
                >
                  Update
                </Button>

              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
