import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { confirmAlert } from 'react-confirm-alert'; // Import

import { trackerActions } from '../../_actions/tracker.actions';
import { activityActions } from '../../_actions/activity.actions';
import { habitcategoryActions } from '../../_actions/habitcategory.actions';

import { parameterActions, parametertypeActions } from '../../_actions';

import { withRouter } from "react-router";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './styles';

import AddTracker from './Modal/AddTracker';
import UpdateTracker from './Modal/UpdateTracker';
import { userActions } from '../../_actions/user.actions'


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();



class Tracker extends Component {
  constructor(props) {
    super(props);
    this.state = {


      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',

      title: "",
      desc: "",
      name: "",
      activityList: [],
      activityTypeName: "",
      activityTypeId: "",

      allDimensionIndex: "",

      rating: "",
      author: "",
      idmb: "",

      submitted: false,
      newTrackerModal: false,
      newUpdateTrackerModal: false,
      formData: {
        "name": "",
        "desc": "",
        "parameterId": null,
        "parameterTypeId":  null,
        "activityList": [],
        "habitCategoryId":""


      },
      updateformData: {
        "id": "",
        "name": "",
        "desc": "",
        "parameterId": null,
        "parameterTypeId":  null,
        "activityList": [],
        "habitCategoryId":""

      },
      finalData: "",
      imageWebLink:"",
      imageWebURL:""

    }
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(trackerActions.getTrackerList(data));
    this.props.dispatch(activityActions.getAllActivity(data));


    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }

    this.props.dispatch(parameterActions.getParameterList(data3));
    this.props.dispatch(parametertypeActions.getAllParameterType(data3));



    this.props.dispatch(habitcategoryActions.getAllHabitCategory(data3));

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(" prevState  addSurveySuccess", prevState);
    // console.log(" nextProps  addSurveySuccess", nextProps.company);
    if (nextProps.tracker.updateTrackerSuccess) {
      return {
        ...nextProps,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "colorCode": "",
          "icon": "",
          "parameterId": null,
          "parameterTypeId":  null,
          "activityList": [],
          "habitCategoryId":""

        },
        formData: {
          "name": "",
          "desc": "",
          "colorCode": "",
          "icon": "",
          "parameterId": null,
          "parameterTypeId":  null,
          "activityList": [],
          "habitCategoryId":""

        },
        newUpdateTrackerModal: false

      }
    }
    if (nextProps.tracker.addTrackerSuccess) {
      return {
        ...nextProps,
        formData: {
          "name": "",
          "desc": "",
          "parameterId": null,
          "parameterTypeId":  null,
          "activityList": [],
          "habitCategoryId":""

        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "parameterId": null,
          "parameterTypeId":  null,
          "activityList": [],
          "habitCategoryId":""

        },
        newTrackerModal: false

      }
    } 
    if (nextProps.users.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.uploadedImageName:null,
        imageWebURL:(nextProps.users && nextProps.users.filesDetails && nextProps.users.filesDetails.uploadedImageName)?nextProps.users.filesDetails.imageUrl:null,
      }
    }
    else {
      return {
        ...nextProps
      }
    }

  }
  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(trackerActions.getTrackerList(data));
  }
  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": "",
      "pageNo": page,
      "size": this.state.size
    }
    console.log(data);

    this.props.dispatch(trackerActions.getTrackerList(data));
  }

  handleClickOpen = () => {
    this.setState({ newTrackerModal: true });
  }
  handleClose = () => {
    let data = {
      "name": "",
      "desc": "",
      "parameterId": null,
      "parameterTypeId":  null,
      "activityList": [],
      "habitCategoryId":""

    }
    let data2 = {
      "id": "",
      "name": "",
      "desc": "",
      "parameterId": null,
      "parameterTypeId":  null,
      "activityList": [],
      "habitCategoryId":""

    }
    this.setState({ newTrackerModal: false, formData: data, updateformData: data2 });

  }

  handleUpdateClose = () => {
    let data1 = {
      "name": "",
      "desc": "",
      "parameterId": null,
      "parameterTypeId":  null,
      "activityList": [],
      "habitCategoryId":""

    }
    let data = {
      "id": "",
      "name": "",
      "desc": "",
      "parameterId": null,
      "parameterTypeId":  null,
      "activityList": [],
      "habitCategoryId":""

    }

    this.setState({ newUpdateTrackerModal: false, updateformData: data, formData: data1 });

  }






  onUpdate = (data) => {

    console.log("data:::", data)



    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "icon": data.icon,
      "parameterId": data.parameterId,
      "parameterTypeId": data.parameterTypeId,
      "activityList": data.activityList,
      "habitCategoryId":data.habitCategoryId,
      "imageLinkV2":data.imageLinkV2,
    }
    if(data && data.parameterTypeId){
      this.props.dispatch(parameterActions.getParameterListbyParameterType({ parameterTypeId: data.parameterTypeId }));
    }

    let tempEmpId = tempdata.activityList;
    tempdata.activityList = []
    tempEmpId.forEach((res) => {
      tempdata.activityList.push(res.id)
    })

    this.setState({ newUpdateTrackerModal: true, updateformData: tempdata });
  }



  onDisable = (data) => {

    let tempdata = {
      "id": data.id
    }
    this.props.dispatch(trackerActions.disableTracker(tempdata));
  }



  handleTrackerName = (event) => {
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;

    this.setState({ formData, updateformData });
  }

  handleTrackerParaType = (event) => {
    const { formData, updateformData } = this.state;
    formData[event.target.name] = event.target.value;
    updateformData[event.target.name] = event.target.value;

    this.props.dispatch(parameterActions.getParameterListbyParameterType({ parameterTypeId: event.target.value }));

    this.setState({ formData, updateformData });
  }



  handleHabitCategoryInput=(event)=>{
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  updatehandleHabitCategoryInput=(event)=>{
    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }



  handleTrackerActivities = (event, val) => {
    console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.activityList.push(val.option.id);
      updateformData.activityList.push(val.option.id)
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.activityList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.activityList.splice(delIndex, 1);
        }
      })

      updateformData.activityList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.activityList.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }
  }



  submitTrackerDetails = () => {

    const { formData } = this.state;


    this.props.dispatch(trackerActions.createTracker(formData));

  }

  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(trackerActions.deleteTracker({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  submitUpdateTrackerDetails = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }

    let data ={
      "id": this.state.updateformData.id,
      "name": this.state.updateformData.name,
      "desc": this.state.updateformData.desc,
      "icon": this.state.updateformData.icon,
      "habitCategoryId": this.state.updateformData.habitCategoryId,
      "parameterId": this.state.updateformData.parameterId,
      "parameterTypeId": this.state.updateformData.parameterTypeId,
      "activityList": this.state.updateformData.activityList,
    }

    this.props.dispatch(trackerActions.updateTracker(data, tempdata));

  }
  handleFile = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      console.log("No File To Upload!")
    }
  }
  handleUpload = () => {
    console.log("sssssssssssssss  ");
    const { users } = this.props;
    const { filesDetails } = users;
    console.log("filesDetails  ",filesDetails);
    const { formData, updateformData } = this.state;
    formData['icon'] = filesDetails && filesDetails.uploadedImageName?filesDetails.uploadedImageName:null;
    updateformData['icon'] = filesDetails && filesDetails.uploadedImageName?filesDetails.uploadedImageName:null;
    console.log("filesDetails  ",filesDetails);
    this.setState({ formData, updateformData });

    // const { formData, updateformData, imageWebLink } = this.state;
    // formData['icon'] = imageWebLink;
    // updateformData['icon'] = imageWebLink;
    // this.setState({ formData, updateformData });
  }
  render() {

    let { classes, activity, tracker } = this.props;
    const { items, total } = tracker;
    const { allActivities } = activity;

    
    let getHabitCategoryList = this.props.habitcategory.items;

    const { formData, updateformData, submitted } = this.state;

    let { parameter, parametertype } = this.props;
    let { allParametersListByParameterTypeData } = parameter;
    const { parameterTypesList } = parametertype;


    console.log("getHabitCategoryList---->", getHabitCategoryList);
    console.log("this.state.updateformData---->", this.state.updateformData);
    console.log("this.state.formData---->", this.state.formData);


    console.log("this.state.imageWebLink---->", this.state.imageWebLink)
    console.log("this.state.imageWebURL---->", this.state.imageWebURL)
    return (
      <>

        <PageTitle title="Tracker List" />

        <Grid container spacing={2} style={{ marginTop: '5%' }}>



          <Grid container >
            <Grid item xs={12}>
              <Widget >
                <Grid container >
                  <Grid item xs={8} />
                  <Grid item xs={3}>
                    <TextField
                      id="standard-search"
                      label="Search field"
                      type="search"
                      name="keyWord"
                      onChange={this.handleSearch}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                      Add
                  </Button>
                  </Grid>
                </Grid>

                {
                  items && items.length > 0 ?
                    <>
                      <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                        onDisable={this.onDisable}
                        onUpdate={this.onUpdate}
                        onDelete={this.onDelete} />
                    </>
                    : <Typography >Data not found.</Typography>
                }
                {
                  total && total > 10 ?
                    <MuiThemeProvider theme={theme}>
                      <CssBaseline />
                      <Pagination
                        limit={this.state.size}
                        offset={this.state.offset}
                        total={total}
                        onClick={(e, offset, page) => this.handleClick(offset, page)}
                      />
                    </MuiThemeProvider>
                    : null}
              </Widget>
            </Grid>

          </Grid>
          <Dialog fullScreen open={this.state.newTrackerModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddTracker
              data={this.data}
              classes={classes}
              formData={formData}
              submitted={submitted}
              handleClose={this.handleClose}
              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              allActivities={allActivities}
              parameterTypesList={parameterTypesList}
              allParametersListByParameterTypeData={allParametersListByParameterTypeData}
              handleTrackerParaType={this.handleTrackerParaType}

              handleTrackerActivities={this.handleTrackerActivities}
              handleTrackerName={this.handleTrackerName}
              submitTrackerDetails={this.submitTrackerDetails}
              getHabitCategoryList={getHabitCategoryList}
              handleHabitCategoryInput={this.handleHabitCategoryInput}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}
            />
          </Dialog>
          <Dialog fullScreen open={this.state.newUpdateTrackerModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
            <UpdateTracker
              data={this.data}
              classes={classes}
              updateformData={updateformData}
              submitted={submitted}
              handleClose={this.handleUpdateClose}

              allActivities={allActivities}
              parameterTypesList={parameterTypesList}
              allParametersListByParameterTypeData={allParametersListByParameterTypeData}
              handleTrackerParaType={this.handleTrackerParaType}
              
              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              handleTrackerActivities={this.handleTrackerActivities}
              handleTrackerName={this.handleTrackerName}
              submitUpdateTrackerDetails={this.submitUpdateTrackerDetails}
              getHabitCategoryList={getHabitCategoryList}
              updatehandleHabitCategoryInput={this.updatehandleHabitCategoryInput}
              imageWebLink={this.state.imageWebLink}
              imageWebURL={this.state.imageWebURL}
            />
          </Dialog>


        </Grid>


      </>
    );
  }
}
Tracker.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, tracker, activity, parameter, parametertype, habitcategory } = state;
  return {
    loggingIn,
    users,
    tracker,
    activity,
    parameter,
    parametertype,
    habitcategory

  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Tracker)));
