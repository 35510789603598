export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    
    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',
    

    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    FILE_UPLOAD_STATUS_AUDIO_REQUEST: 'FILE_UPLOAD_STATUS_AUDIO_REQUEST',
    FILE_UPLOAD_AUDIO_STATUS_SUCCESS: 'FILE_UPLOAD_AUDIO_STATUS_SUCCESS',
    FILE_UPLOAD_CLEAR_AUDIO_SUCCESS: 'FILE_UPLOAD_CLEAR_AUDIO_SUCCESS',
    FILE_UPLOAD_AUDIO_STATUS_FAILURE: 'FILE_UPLOAD_AUDIO_STATUS_FAILURE',

    AUDIO_UPLOAD_STATUS_AUDIO_REQUEST: 'AUDIO_UPLOAD_STATUS_AUDIO_REQUEST',
    AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS: 'AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS',
    AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS: 'AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS',
    AUDIO_UPLOAD_AUDIO_STATUS_FAILURE: 'AUDIO_UPLOAD_AUDIO_STATUS_FAILURE',

    GET_OVERVIEW_REQUEST: 'GET_OVERVIEW_REQUEST',
    GET_OVERVIEW_SUCCESS: 'GET_OVERVIEW_SUCCESS',
    GET_OVERVIEW_FAILURE: 'GET_OVERVIEW_FAILURE',
    
    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',

};