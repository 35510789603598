import { happinessConstants } from '../_constants';
import { happinessService } from '../_services';

export const happinessActions = {
    getHappyPlusEmpRespondants,
    getHappyPlusOrgHappiness,
    getHappyPlusOrgHappinessDMGWise,
    getHappyPlusFilterDMG
};


function getHappyPlusEmpRespondants(data) {
    return dispatch => {
        dispatch(request());
        happinessService.getHappyPlusEmpRespondants(data)
            .then(
                happiness => dispatch(success(happiness)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_REQUEST } }
    function success(happiness) { return { type: happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_SUCCESS, happiness } }
    function failure(error) { return { type: happinessConstants.GET_HAPPINESS_EMP_RESPONDANTS_FAILURE, error } }
}



function getHappyPlusOrgHappiness(data) {
    let obj = {
        getHappyPlusOrgHappinessData:[]
    }

    return dispatch => {
        dispatch(success2(obj))
        dispatch(request());
        happinessService.getHappyPlusOrgHappiness(data)
            .then(
                happiness => dispatch(success(happiness)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_REQUEST } }
    function success(happiness) { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_SUCCESS, happiness } }
    function success2(happiness) { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_EMPTY, happiness } }
    function failure(error) { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_FAILURE, error } }
}



function getHappyPlusOrgHappinessDMGWise(data) {
    return dispatch => {
        dispatch(request());
        happinessService.getHappyPlusOrgHappinessDMGWise(data)
            .then(
                happiness => dispatch(success(happiness)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_REQUEST } }
    function success(happiness) { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_SUCCESS, happiness } }
    function failure(error) { return { type: happinessConstants.GET_HAPPINESS_ORG_HAPPINESS_DMG_WISE_FAILURE, error } }
}



function getHappyPlusFilterDMG(data) {
    return dispatch => {
        dispatch(request());
        happinessService.getHappyPlusFilterDMG(data)
            .then(
                happiness => dispatch(success(happiness)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: happinessConstants.GET_HAPPYPLUS_FILTER_DMG_REQUEST } }
    function success(happiness) { return { type: happinessConstants.GET_HAPPYPLUS_FILTER_DMG_SUCCESS, happiness } }
    function failure(error) { return { type: happinessConstants.GET_HAPPYPLUS_FILTER_DMG_FAILURE, error } }
}
