
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const musicService = {
    disableMusic,
    logout,
    createNewMusic,
    updateMusic,
    getAllMusic,
    deleteMusic
};
function logout() {
    localStorage.removeItem('adminmusic');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getMusicList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let musicObj = {
                listOfMusics: data.data
            }
            console.log("i am in service musicObj :::::",musicObj);
            return musicObj;
        });
}

function createNewMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let musicObj = {
                createNewMusic: data.data
            }
            console.log();

            return musicObj;
        });
}
function updateMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let musicObj = {
                updateNewMusic: data.data
            }
            console.log(musicObj);

            return musicObj;
        });
}

function deleteMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteMusic`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let musicObj = {
                deleteNewMusic: data.data
            }
            console.log(musicObj);

            return musicObj;
        });
}

function disableMusic(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateMusicStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let musicObj = {
                deleteMusic: data.data
            }
            console.log();

            return musicObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}