import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {

  switch (action.type) {
    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        imageUploadSuccess:false,

        items: action.users.listOfUsers.list,
        total: action.users.listOfUsers.total
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.GETALL_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        listOfNotification: action.users.listOfNotification.list,
        listOfNotificationtotal: action.users.listOfNotification.total
      };
    case userConstants.GETALL_USER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.UPDATE_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        passwordUpdated: true
      };
    case userConstants.UPDATE_USER_NOTIFY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.GET_OVERVIEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        addMenuSuccess: false,
        addItemSuccess: false,
        updateitem: false,
        passwordUpdated: false,
        updateCategory: false,
        overviewdetails: action.users.overviewdetails
      };
    case userConstants.GET_OVERVIEW_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.ADD_RESTAURANT_USER_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_RESTAURANT_USER_SUCCESS:
      return {
        ...state,
        addUserSuccess: true
      };
    case userConstants.ADD_RESTAURANT_USER_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case userConstants.ADD_MENU_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_MENU_SUCCESS:
      return {
        ...state,
        addMenuSuccess: true
      };
    case userConstants.ADD_MENU_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.ADD_ITEM_REQUEST:
      return {
        ...state
      };
    case userConstants.ADD_ITEM_SUCCESS:
      return {
        ...state,
        addItemSuccess: true
      };
    case userConstants.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.UPDATE_CATEGORY_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        updateCategory: true
      };
    case userConstants.UPDATE_CATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.UPDATE_ITEM_REQUEST:
      return {
        ...state
      };
    case userConstants.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        updateitem: true
      };
    case userConstants.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state, 
        imageUploadSuccess:true,
        filesDetails: action.uploadImage.filesDetails
      };

      case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
        return {
          ...state, 
          imageUploadClearSuccess:true,
          filesDetails: null
        };

    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state
      };


      case userConstants.FILE_UPLOAD_AUDIO_STATUS_SUCCESS:
        return {
          ...state, 
          audioUploadSuccess:true,
          filesAudioDetails: action.uploadImage.filesDetails
        };
  
        case userConstants.FILE_UPLOAD_CLEAR_AUDIO_SUCCESS:
          return {
            ...state, 
            audioUploadClearSuccess:true,
            filesDetails: null
          };
  
      case userConstants.FILE_UPLOAD_AUDIO_STATUS_FAILURE:
        return {
          ...state
        };




        case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
          return {
            ...state,
            musicUploadSuccess: true,
            musicAudioDetails: action.uploadMusic.filesDetails
          };
    
        case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
          return {
            ...state,
            musicUploadClearSuccess: true,
            filesDetails: null
          };
    
        case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
          return {
            ...state
          }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
          return {
            ...state,
            musicUploadSuccess: true,
            musicAudioDetails: action.uploadMusic.filesDetails
          };
    
        case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
          return {
            ...state,
            musicUploadClearSuccess: true,
            filesDetails: null
          };
    
        case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
          return {
            ...state
          }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };

    case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_FAILURE:
      return {
        ...state
      }; case userConstants.AUDIO_UPLOAD_AUDIO_STATUS_SUCCESS:
      return {
        ...state,
        musicUploadSuccess: true,
        musicAudioDetails: action.uploadMusic.filesDetails
      };

    case userConstants.AUDIO_UPLOAD_CLEAR_AUDIO_SUCCESS:
      return {
        ...state,
        musicUploadClearSuccess: true,
        filesDetails: null
      };




        

    case userConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.STATS_SUCCESS:
      return {
        ...state,
        statics: action.users.statics
      };
    case userConstants.STATS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}