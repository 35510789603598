import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Select,
  MenuItem,
  Input

} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONST } from '../../../_config/index';


import Select2 from 'react-select';


export default function AddCompany(props) {

  const { classes, formData, submitted, handleClose, handleActivityName,
    allResources, handleActivityResource, submitActivityDetails,imageWebURL,

    activityType, addSubactivityField, handleSubActivityName, onSubActivityName, deleteSubactivityField, handleFile, handleUpload } = props;

  // console.log("allResources: ",allResources)

  let colourOptions = []
  allResources.forEach((emp) => {
    colourOptions.push(
      {
        value: emp.name,
        label: emp.name,
        id: emp.id,
      }
    )
  })

console.log("imageWebURL: ", imageWebURL);

  // const colourOptions = [
  //   { value: 'chocolate', label: 'Chocolate' },
  //   { value: 'strawberry', label: 'Strawberry' },
  //   { value: 'vanilla', label: 'Vanilla' }
  // ]


  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Activity
            </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Activity
              </Typography>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>

              <TextField
                label="Name*"
                id="name"
                name="name"
                fullWidth
                rowsMax={4}
                placeholder="Name"
                value={formData.name}
                // validators={['required']}
                onChange={handleActivityName}
              // errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Desc"
                id="desc"
                name="desc"
                fullWidth
                rowsMax={4}
                placeholder="Description"
                value={formData.desc}
                onChange={handleActivityName}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Select
                name="type"
                value={formData.type ? formData.type : ''}
                style={{ width: '100%' }}
                onChange={handleActivityName}
                input={<Input id="type" />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  Type
                    </MenuItem>
                {
                  activityType && activityType.length > 0 ?
                    activityType.map((tempdata, index) => (
                      <MenuItem key={index} value={tempdata.value}>{tempdata.name}</MenuItem>
                    )) : null
                }

              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Color Code"
                id="colorCode"
                name="colorCode"
                fullWidth
                rowsMax={4}
                placeholder="Color Code"
                value={formData.colorCode}
                onChange={handleActivityName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Icon"
                id="icon"
                name="icon"
                fullWidth
                rowsMax={4}
                placeholder="Icon"
                value={formData.icon}
                onChange={handleActivityName}
              />
            </Grid>
            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                        </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
                      </Button>



            </Grid>


            {(imageWebURL) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
            : null}


            <div style={{ margin: "7px" }}></div>
            
              <Grid item xs={12} style={{ margin: "0px" }}>
                <label htmlFor="resourceList" style={{ color: 'grey' }}>Resources</label>
                <Select2
                  id="resourceList"
                  isMulti
                  name="resourceList"
                  onChange={handleActivityResource}
                  options={colourOptions}
                  className="basic-multi-select"
                  classNamePrefix="Resources"
                />
              </Grid>
              

            <Grid item xs={12} style={{ margin: "0px" }}>
              <label htmlFor="Subactivity" style={{ color: 'grey' }}>Subactivity</label>
              <br />
              <Button id="Subactivity" variant="contained" color="primary" onClick={() => addSubactivityField()}>Add Fields</Button>



              {
                formData.subactivityList.map((item, index) =>
                  <Grid key={index} item xs={12} sm={12}>
                    <hr />

                    <Typography variant="h6">
                      Sub Activity: {index + 1}
                    </Typography>

                    <TextField
                      label="Name*"
                      id="name"
                      name="name"
                      fullWidth
                      rowsMax={4}
                      placeholder="Name"
                      value={formData.subactivityList[index].name}
                      onChange={handleSubActivityName}
                      onClick={() => onSubActivityName(index)}
                    />

                    <TextField
                      label="Desc*"
                      id="desc"
                      name="desc"
                      fullWidth
                      rowsMax={4}
                      placeholder="Desc"
                      value={formData.subactivityList[index].desc}
                      onChange={handleSubActivityName}
                      onClick={() => onSubActivityName(index)}
                    />
                    <TextField
                      label="Desc2*"
                      id="desc2"
                      name="desc2"
                      fullWidth
                      rowsMax={4}
                      placeholder="Desc"
                      value={formData.subactivityList[index].desc2}
                      onChange={handleSubActivityName}
                      onClick={() => onSubActivityName(index)}
                    />
                    <TextField
                      label="Rank*"
                      id="rank"
                      name="rank"
                      fullWidth
                      rowsMax={4}
                      placeholder="Rank"
                      value={formData.subactivityList[index].rank}
                      onChange={handleSubActivityName}
                      onClick={() => onSubActivityName(index)}
                    />
                    <Button style={{ marginTop: '10px' }} id="Subactivity" variant="contained" color="secondary" onClick={() => deleteSubactivityField(index)}>Delete</Button>

                  </Grid>
                )
              }


            </Grid>


            <div style={{ margin: "7px" }}></div>





          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
            className={classes.submit}
            onClick={() => submitActivityDetails()}
            style={{ marginTop: "5%" }}
          >
            Save
                </Button>

        </div>
      </Container>



    </div>
  );
}
