
import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';


export default function UpdateCompany(props) {

  const { classes, updateformData,submitted, handleUpdateClose, handleUpdateSubmit, updatehandleChangeInput } = props;

  return (
    <div >
      <AppBar className={classes.appBar}>
      <Toolbar>
        <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" className={classes.title}>
          Update Company
      </Typography>
        <Button color="inherit" onClick={handleUpdateClose}>
          Cancel
      </Button>
      </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        Update Company
        </Typography>

        <ValidatorForm
          // ref="form"
          onSubmit={handleUpdateSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>

              <TextValidator
                label="Name*"
                fullWidth
                onChange={updatehandleChangeInput}
                name="name"
                id="name"
                value={updateformData.name}
                validators={['required']}
                errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
            className={classes.submit}
          >
            Save
          </Button>
        </ValidatorForm>
      </div>
      </Container>

</div>
  );
}
