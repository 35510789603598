export const musicConstants = {

    MUSIC_GETALL_REQUEST: 'MUSIC_GETALL_REQUEST',
    MUSIC_GETALL_SUCCESS: 'MUSIC_GETALL_SUCCESS',
    MUSIC_GETALL_FAILURE: 'MUSIC_GETALL_FAILURE',

    ADD_MUSIC_REQUEST: 'ADD_MUSIC_REQUEST',
    ADD_MUSIC_SUCCESS: 'ADD_MUSIC_SUCCESS',
    ADD_MUSIC_FAILURE: 'ADD_MUSIC_FAILURE',
    
    UPDATE_MUSIC_REQUEST: 'UPDATE_MUSIC_REQUEST',
    UPDATE_MUSIC_SUCCESS: 'UPDATE_MUSIC_SUCCESS',
    UPDATE_MUSIC_FAILURE: 'UPDATE_MUSIC_FAILURE',

    DELETE_MUSIC_REQUEST: 'DELETE_MUSIC_REQUEST',
    DELETE_MUSIC_SUCCESS: 'DELETE_MUSIC_SUCCESS',
    DELETE_MUSIC_FAILURE: 'DELETE_MUSIC_FAILURE',

};
