
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const campaignService = {
    disableCampaign,
    getCampaignById,
    deleteCampaign,
    logout,
    createNewCampaign,
    updateNewCampaign,
    getAllCampaign,
    getEmployeeList,
    saveEmployeeList,
    getAllCampaignType,
    getCampaignListBySurveyID,
    getCampaignEmployeesByID
};
function logout() {
    localStorage.removeItem('admincampaign');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllCampaign(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCampaignList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                listOfCampaigns: data.data
            }
            console.log();
            return campaignObj;
        });
}
function createNewCampaign(data) {
    console.log("------createNewCampaign------------ ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            console.log("After API: ",data.data);

            return campaignObj;
        });
}
function updateNewCampaign(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                updateNewCampaign: data.data
            }
            console.log(campaignObj);

            return campaignObj;
        });
}
function deleteCampaign(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteCampaign`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
function disableCampaign(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCampaignStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                deleteCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
function getCampaignById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCampaignById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                campaigndetails: data.data
            }
            console.log();

            return userObj;
        });
}
function saveEmployeeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                createNewCampaign: data.data
            }
            console.log();

            return campaignObj;
        });
}
function getEmployeeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                employeeList: data.data
            }
            console.log();

            return campaignObj;
        });
}


function getAllCampaignType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllCampaignType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let campaignObj = {
                campaignTypeList: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

function getCampaignListBySurveyID(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCampaignListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                campaignList: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

function getCampaignEmployeesByID(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCampaignEmployeesByID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let campaignObj = {
                campaignEmployeesList: data.data
            }
            console.log("campaignObj  ", campaignObj);

            return campaignObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}