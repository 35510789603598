
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const parametertypeService = {
    disableParameterType,
    logout,
    createNewParameterType,
    updateParameterType,
    getAllParameterType,
    deleteParameterType
};
function logout() {
    localStorage.removeItem('adminparametertype');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllParameterType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getParameterTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let parametertypeObj = {
                listOfParameterTypes: data.data
            }
            return parametertypeObj;
        });
}
function createNewParameterType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createParameterType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parametertypeObj = {
                createNewParameterType: data.data
            }
            console.log();

            return parametertypeObj;
        });
}
function updateParameterType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateParameterType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parametertypeObj = {
                updateNewParameterType: data.data
            }
            console.log(parametertypeObj);

            return parametertypeObj;
        });
}
function disableParameterType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateParameterTypeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parametertypeObj = {
                deleteParameterType: data.data
            }
            console.log();

            return parametertypeObj;
        });
}

function deleteParameterType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteParameterType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parametertypeObj = {
                deleteNewdeleteParameterType: data.data
            }
            console.log(parametertypeObj);

            return parametertypeObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}