export const habitcategoryConstants = {

    HABITCATEGORY_GETALL_REQUEST: 'HABITCATEGORY_GETALL_REQUEST',
    HABITCATEGORY_GETALL_SUCCESS: 'HABITCATEGORY_GETALL_SUCCESS',
    HABITCATEGORY_GETALL_FAILURE: 'HABITCATEGORY_GETALL_FAILURE',

    ADD_HABITCATEGORY_REQUEST: 'ADD_HABITCATEGORY_REQUEST',
    ADD_HABITCATEGORY_SUCCESS: 'ADD_HABITCATEGORY_SUCCESS',
    ADD_HABITCATEGORY_FAILURE: 'ADD_HABITCATEGORY_FAILURE',
    
    UPDATE_HABITCATEGORY_REQUEST: 'UPDATE_HABITCATEGORY_REQUEST',
    UPDATE_HABITCATEGORY_SUCCESS: 'UPDATE_HABITCATEGORY_SUCCESS',
    UPDATE_HABITCATEGORY_FAILURE: 'UPDATE_HABITCATEGORY_FAILURE',

    DELETE_HABITCATEGORY_REQUEST: 'DELETE_HABITCATEGORY_REQUEST',
    DELETE_HABITCATEGORY_SUCCESS: 'DELETE_HABITCATEGORY_SUCCESS',
    DELETE_HABITCATEGORY_FAILURE: 'DELETE_HABITCATEGORY_FAILURE',

};
