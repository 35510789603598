export const parametertypeConstants = {

    PARAMETERTYPE_GETALL_REQUEST: 'PARAMETERTYPE_GETALL_REQUEST',
    PARAMETERTYPE_GETALL_SUCCESS: 'PARAMETERTYPE_GETALL_SUCCESS',
    PARAMETERTYPE_GETALL_FAILURE: 'PARAMETERTYPE_GETALL_FAILURE',

    ADD_PARAMETERTYPE_REQUEST: 'ADD_PARAMETERTYPE_REQUEST',
    ADD_PARAMETERTYPE_SUCCESS: 'ADD_PARAMETERTYPE_SUCCESS',
    ADD_PARAMETERTYPE_FAILURE: 'ADD_PARAMETERTYPE_FAILURE',
    
    UPDATE_PARAMETERTYPE_REQUEST: 'UPDATE_PARAMETERTYPE_REQUEST',
    UPDATE_PARAMETERTYPE_SUCCESS: 'UPDATE_PARAMETERTYPE_SUCCESS',
    UPDATE_PARAMETERTYPE_FAILURE: 'UPDATE_PARAMETERTYPE_FAILURE',

    DELETE_PARAMETERTYPE_REQUEST: 'DELETE_PARAMETERTYPE_REQUEST',
    DELETE_PARAMETERTYPE_SUCCESS: 'DELETE_PARAMETERTYPE_SUCCESS',
    DELETE_PARAMETERTYPE_FAILURE: 'DELETE_PARAMETERTYPE_FAILURE',

};
