import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
// import { Button } from "../../../../components/Wrappers";
import moment from 'moment';


export default function TableComponent(props) {
  //onDeleteonRowClick,
  let { data, offset } = props;
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Status</TableCell>
          <TableCell >Expert</TableCell>
          <TableCell >Category</TableCell>
          <TableCell >User name</TableCell>
          <TableCell >User email</TableCell>
          <TableCell >Mobile</TableCell>
          <TableCell >DAY</TableCell>
          <TableCell >SLOT</TableCell>
          <TableCell >MESSAGE</TableCell>
          <TableCell >Amount</TableCell>
          <TableCell >DATE / TIME</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.status}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.expertId?tempdata.expertId.name:"NA"}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.expertCategoryId.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.employeeId.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.employeeId.email}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.mobile}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.day}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.slotId?tempdata.slotId.date+" "+tempdata.slotId.time12+tempdata.slotId.time12AMPM:tempdata.slot }</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.message}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.amountPaid}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("DD-MM-YYYY, HH:mm")}</TableCell>

            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
