import { companyConstants } from '../_constants';
import { companyService } from '../_services';
import { alertActions } from '.';
export const companyActions = {
    disableCompany,
    getCompanyById,
    createNewCompany,
    updateNewCompany,
    getAllCompany,
    getEmployeeList,

    getAllEmployees,

    saveEmployeeList,
    getSurveyList,
    getAllSurveyType,
    deleteApiKey,

    createSurvey,
    updateSurvey,

    getEmployeeColumnName,
    createEmployeeFilter,
    getEmployeeFilterListByCompanyId,
    updateEmployeeFilterStatus,

    getBenchMarkList,
    createBenchMark,
    updateBenchMark,
    deleteBenchMark,
    updateBenchMarkStatus
};

function getAllCompany(data) {
    return dispatch => {
        dispatch(request());
        companyService.getAllCompany(data)
            .then(
                company => dispatch(success(company)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.COMPANY_GETALL_REQUEST } }
    function success(company) { return { type: companyConstants.COMPANY_GETALL_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.COMPANY_GETALL_FAILURE, error } }
}
function createNewCompany(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        companyService.createNewCompany(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getAllCompany(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ADD_COMPANY_REQUEST } }
    function success(company) { return { type: companyConstants.ADD_COMPANY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ADD_COMPANY_FAILURE, error } }
}
function updateNewCompany(data,tempdata) {
    return dispatch => {
        dispatch(request());
        companyService.updateNewCompany(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getAllCompany(tempdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.UPDATE_COMPANY_REQUEST } }
    function success(company) { return { type: companyConstants.UPDATE_COMPANY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.UPDATE_COMPANY_FAILURE, error } }
}

function disableCompany(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        companyService.disableCompany({ id: data.id })
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getAllCompany(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ADD_COMPANY_REQUEST } }
    function success(company) { return { type: companyConstants.ADD_COMPANY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ADD_COMPANY_FAILURE, error } }
}

function getCompanyById(data) {
    return dispatch => {
        dispatch(request());
        companyService.getCompanyById(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.GET_COMPANY_ID_REQUEST } }
    function success(users) { return { type: companyConstants.GET_COMPANY_ID_SUCCESS, users } }
    function failure(error) { return { type: companyConstants.GET_COMPANY_ID_FAILURE, error } }
}

function saveEmployeeList(data, showdata) {

    return dispatch => {
        dispatch(request());
        companyService.saveEmployeeList(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getEmployeeList(showdata));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ADD_COMPANY_REQUEST } }
    function success(company) { return { type: companyConstants.ADD_COMPANY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ADD_COMPANY_FAILURE, error } }
}
function getEmployeeList(data) {
    return dispatch => {
        dispatch(request());
        companyService.getEmployeeList(data)
            .then(
                company => {
                    dispatch(success(company));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.EMPLOYEE_GETALL_REQUEST } }
    function success(company) { return { type: companyConstants.EMPLOYEE_GETALL_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.EMPLOYEE_GETALL_FAILURE, error } }
}

function getAllEmployees(data) {
    return dispatch => {
        dispatch(request());
        companyService.getAllEmployees(data)
            .then(
                company => {
                    dispatch(success(company));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ALLEMPLOYEE_GETALL_REQUEST } }
    function success(company) { return { type: companyConstants.ALLEMPLOYEE_GETALL_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ALLEMPLOYEE_GETALL_FAILURE, error } }
}


function getSurveyList(data) {
    return dispatch => {
        dispatch(request());
        companyService.getSurveyList(data)
            .then(
                company => {
                    console.log(company)
                    dispatch(success(company));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.SURVEY_GETALL_REQUEST } }
    function success(company) { return { type: companyConstants.SURVEY_GETALL_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.SURVEY_GETALL_FAILURE, error } }
}

function getAllSurveyType(data) {
 
    return dispatch => {
        dispatch(request());
        companyService.getAllSurveyType(data)
            .then(
                company => {
                    console.log(company)
                    dispatch(success(company));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.SURVEYTYPEALL_GETALL_REQUEST } }
    function success(company) { return { type: companyConstants.SURVEYTYPEALL_GETALL_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.SURVEYTYPEALL_GETALL_FAILURE, error } }
}

function createSurvey(data, datatempsurvey) {

    return dispatch => {
        dispatch(request());
        companyService.createSurvey(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getSurveyList(datatempsurvey));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ADD_SURVEY_REQUEST } }
    function success(company) { return { type: companyConstants.ADD_SURVEY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ADD_SURVEY_FAILURE, error } }
}
function updateSurvey(data, datatempsurvey) {

    return dispatch => {
        dispatch(request());
        companyService.updateSurvey(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getSurveyList(datatempsurvey));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.UPDATE_SURVEY_REQUEST } }
    function success(company) { return { type: companyConstants.UPDATE_SURVEY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.UPDATE_SURVEY_FAILURE, error } }
}

function deleteApiKey(data,datatempsurvey) {

    return dispatch => {
        dispatch(request());
        companyService.deleteApiKey(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getSurveyList(datatempsurvey));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.DELETE_SURVEY_REQUEST } }
    function success(company) { return { type: companyConstants.DELETE_SURVEY_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.DELETE_SURVEY_FAILURE, error } }

}

function getEmployeeColumnName(data) {
    return dispatch => {
        dispatch(request());
        companyService.getEmployeeColumnName(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.GET_EMPLOYEE_COLUMN_NAME_REQUEST } }
    function success(company) { return { type: companyConstants.GET_EMPLOYEE_COLUMN_NAME_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.GET_EMPLOYEE_COLUMN_NAME_FAILURE, error } }
}




function createEmployeeFilter(data) {
    return dispatch => {
        dispatch(request());
        companyService.createEmployeeFilter(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.CREATE_EMPLOYEE_FILTER_REQUEST } }
    function success(company) { return { type: companyConstants.CREATE_EMPLOYEE_FILTER_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.CREATE_EMPLOYEE_FILTER_FAILURE, error } }
}

function getEmployeeFilterListByCompanyId(data) {
    return dispatch => {
        dispatch(request());
        companyService.getEmployeeFilterListByCompanyId(data)
            .then(
                users => dispatch(success(users)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.GET_EMPLOYEE_FILTER_REQUEST } }
    function success(company) { return { type: companyConstants.GET_EMPLOYEE_FILTER_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.GET_EMPLOYEE_FILTER_FAILURE, error } }
}


function updateEmployeeFilterStatus (data,data2) {
    return dispatch => {
        dispatch(request());
        companyService.updateEmployeeFilterStatus(data)
            .then(
                users => {
                    dispatch(success(users));
                    dispatch(this.getEmployeeFilterListByCompanyId(data2))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST } }
    function success(company) { return { type: companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE, error } }
}



function getBenchMarkList(data) {

    return dispatch => {
        dispatch(request());
        companyService.getBenchMarkList(data)
            .then(
                company => {
                    dispatch(success(company));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.GET_BENCHMARK_REQUEST } }
    function success(company) { return { type: companyConstants.GET_BENCHMARK_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.GET_BENCHMARK_FAILURE, error } }
}


function createBenchMark(data) {

    return dispatch => {
        dispatch(request());
        companyService.createBenchMark(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getBenchMarkList({companyId: data.companyId}));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.ADD_BENCHMARK_REQUEST } }
    function success(company) { return { type: companyConstants.ADD_BENCHMARK_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.ADD_BENCHMARK_FAILURE, error } }
}


function updateBenchMark(data) {

    return dispatch => {
        dispatch(request());
        companyService.updateBenchMark(data)
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getBenchMarkList({companyId: data.companyId}));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.UPDATE_BENCHMARK_REQUEST } }
    function success(company) { return { type: companyConstants.UPDATE_BENCHMARK_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.UPDATE_BENCHMARK_FAILURE, error } }
}

function deleteBenchMark(data) {

    return dispatch => {
        dispatch(request());
        companyService.deleteBenchMark({id:data.id})
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getBenchMarkList({companyId: data.companyId}));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.DELETE_BENCHMARK_REQUEST } }
    function success(company) { return { type: companyConstants.DELETE_BENCHMARK_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.DELETE_BENCHMARK_FAILURE, error } }
}


function updateBenchMarkStatus(data) {

    return dispatch => {
        dispatch(request());
        companyService.updateBenchMarkStatus({id:data.id})
            .then(
                company => {
                    dispatch(success(company));
                    dispatch(this.getBenchMarkList({companyId: data.companyId}));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: companyConstants.UPDATE_STATUS_BENCHMARK_REQUEST } }
    function success(company) { return { type: companyConstants.UPDATE_STATUS_BENCHMARK_SUCCESS, company } }
    function failure(error) { return { type: companyConstants.UPDATE_STATUS_BENCHMARK_FAILURE, error } }
}