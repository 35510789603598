export const badgeConstants = {

    BADGE_GETALL_REQUEST: 'BADGE_GETALL_REQUEST',
    BADGE_GETALL_SUCCESS: 'BADGE_GETALL_SUCCESS',
    BADGE_GETALL_FAILURE: 'BADGE_GETALL_FAILURE',

    ADD_BADGE_REQUEST: 'ADD_BADGE_REQUEST',
    ADD_BADGE_SUCCESS: 'ADD_BADGE_SUCCESS',
    ADD_BADGE_FAILURE: 'ADD_BADGE_FAILURE',
    
    UPDATE_BADGE_REQUEST: 'UPDATE_BADGE_REQUEST',
    UPDATE_BADGE_SUCCESS: 'UPDATE_BADGE_SUCCESS',
    UPDATE_BADGE_FAILURE: 'UPDATE_BADGE_FAILURE',

    DELETE_BADGE_REQUEST: 'DELETE_BADGE_REQUEST',
    DELETE_BADGE_SUCCESS: 'DELETE_BADGE_SUCCESS',
    DELETE_BADGE_FAILURE: 'DELETE_BADGE_FAILURE',

};
