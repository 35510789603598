import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  // Select,
  // Input,
  // MenuItem,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
import Select2 from 'react-select';


export default function AddExpert(props) {

  const { classes, formData, submitted, handleClose, handleSubmit, handleChangeInput, listOfExpertCategory,
    handleFile, handleUpload, handleParameterTypeName, imageWebURL} = props;
    let colourOptions = []
    if (listOfExpertCategory && listOfExpertCategory.length > 0) {
      listOfExpertCategory.forEach((emp) => {
        colourOptions.push(
          {
            value: emp.name,
            label: emp.name,
            id: emp.id,
          }
        )
      })
    }
  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Expert
            </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Expert
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="name"
                  id="name"
                  value={formData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Desc*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="desc"
                  id="desc"
                  value={formData.desc}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Type*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="type"
                  id="type"
                  value={formData.type}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}>
              <div style={{ margin: "7px" }}></div>
            <Grid item xs={12} style={{ margin: "0px" }}>
              <label htmlFor="resourceList" style={{ color: 'grey' }}>Expert Category</label>
              <Select2
                id="expertCategoryId"
                isMulti
                name="expertCategoryId"
                onChange={handleParameterTypeName}
                options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="Activities"
              />
            </Grid>
            <div style={{ margin: "7px" }}></div>
                {/* <Select
                  name="expertCategoryId"
                  value={formData.expertCategoryId ? formData.expertCategoryId : listOfExpertCategory && listOfExpertCategory.length > 0 ? listOfExpertCategory[0].id : ''}
                  style={{ width: '100%' }}
                  label="Parameter Type"
                  onChange={handleParameterTypeName}
                  input={<Input id="expertCategoryId" />}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="" disabled>
                    Parameter Type
                                                </MenuItem>
                  {
                    listOfExpertCategory && listOfExpertCategory.length > 0 ?
                      listOfExpertCategory.map((tempdata, index) => (
                        <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                      )) : null
                  }

                </Select> */}
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Image Link*"
                  fullWidth
                  onChange={handleChangeInput}
                  name="imageLink"
                  id="imageLink"
                  value={formData.imageLink}
                // validators={['required']}
                // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                    </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />
              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
                  </Button>
            </Grid>
            
            {(imageWebURL) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : null}


            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}