import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import moment from 'moment';

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };

export default function TableComponent(props) {
  let { data, offset, onDelete, onUpdate } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Name</TableCell>
          <TableCell >Value</TableCell>
          <TableCell >CreatedAt</TableCell>
          <TableCell >ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data && data.length > 0 ? data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.value}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>

              <TableCell>

{/* 
                <Button
                  color={tempdata.isDisableByAdmin ? "warning" : "success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDisable(tempdata)}
                >
                  {tempdata.isDisableByAdmin ? 'Disable' : 'Enable'}
                </Button> */}
                <Button
                  color="danger"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDelete(tempdata)}
                >
                  Delete
              </Button>
              <Button
                  color="info"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onUpdate(tempdata)}
                >
                  Update
              </Button>
              </TableCell>
            </TableRow>
          )) : null
        }
      </TableBody>
    </Table>
  );
}
