import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  // Select,
  // MenuItem,
  // Input

} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONST } from '../../../_config/index';
import Select2 from 'react-select';

export default function AddCompany(props) {

  const { classes, formData, submitted, handleClose, handlePremiumActivityName, allPremiumMusics,
    handleActivityResource, submitPremiumActivityDetails,
    handleFile, handleUpload } = props;


  let colourOptions = []
  allPremiumMusics.forEach((emp) => {
    colourOptions.push(
      {
        value: emp.name,
        label: emp.name,
        id: emp.id,
      }
    )
  })

  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New PremiumActivity
          </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add PremiumActivity
          </Typography>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>

              <TextField
                label="Name*"
                id="name"
                name="name"
                fullWidth
                rowsMax={4}
                placeholder="Name"
                value={formData.name}
                // validators={['required']}
                onChange={handlePremiumActivityName}
              // errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Color Code"
                id="colorCode"
                name="colorCode"
                fullWidth
                rowsMax={4}
                placeholder="Color Code"
                value={formData.colorCode}
                onChange={handlePremiumActivityName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Desc"
                id="desc"
                name="desc"
                fullWidth
                rowsMax={4}
                placeholder="Description"
                value={formData.desc}
                onChange={handlePremiumActivityName}
              />
            </Grid>

            <Grid item xs={12} style={{ margin: "0px" }}>
              <label htmlFor="musicList" style={{ color: 'grey' }}>Premium Music List</label>
              <Select2
                id="musicList"
                isMulti
                name="musicList"
                onChange={handleActivityResource}
                options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="Resources"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Icon"
                id="icon"
                name="icon"
                fullWidth
                rowsMax={4}
                placeholder="Icon"
                value={formData.icon}
                onChange={handlePremiumActivityName}
              />
            </Grid>
            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
              </Button>
            </Grid>

            {(formData.icon) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={CONST.IMAGE_URL + formData.icon} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              : null}
            <div style={{ margin: "7px" }}></div>

          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
            className={classes.submit}
            onClick={() => submitPremiumActivityDetails()}
            style={{ marginTop: "5%" }}
          >
            Save
          </Button>

        </div>
      </Container>

    </div>
  );
}
