import React, { Component } from 'react';
import {
  Grid,
  Select,
  MenuItem,
  Input,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Slide,
  Dialog,
  Button,
  CssBaseline
} from "@material-ui/core";
import { connect } from 'react-redux';
import { campaignActions } from '../../_actions/campaign.actions'
import { companyActions } from '../../_actions/company.actions'
import {  parametertypeActions, parameterActions } from '../../_actions'
import { withRouter } from "react-router";
import Pagination from "material-ui-flat-pagination";


import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";

import styles from './styles';


import TableEmployee from "./components/Table/TableEmployee";



const theme = createMuiTheme();


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.markerClicked = this.markerClicked.bind(this);
    this.state = {
      size: 10,
      offset: 0,
      markers: [],
      places: [],
      employeesize:10,

      title:"",
      subject:"",
      message:"",
      campaignTypeName:"Happiness",
      subjectIndex:"",
      campaignTypeId:0, //for HAPPINESS
      messageIndex:"",
      employees:[],
      parameter:'',
      finalData:{},

      selectAll:false,

      selectedEmployeeModal:false,

      formData:{
        "title":"",
        "subject":"",
        "message":"",
        "surveyId":this.props.match.params.id,
        "companyId":this.props.match.params.idc,
        "parameterId":"",
        "parameterTypeId":"",
        "employees":[]
      }

    }
  }
  componentDidMount() {

    
    this.props.dispatch(campaignActions.getCampaignListBySurveyID({
      surveyId:this.props.match.params.id,
      companyId:this.props.match.params.idc,
    }));

    let datatempemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.idc,
      "size": this.state.employeesize
    }

    let allemployeesdata = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.idc
    }

    this.props.dispatch(companyActions.getEmployeeList(datatempemployee));

    this.props.dispatch(companyActions.getAllEmployees(allemployeesdata));

    let data3 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(parametertypeActions.getAllParameterType(data3));


    
  }

  markerClicked(marker) {
    console.log("The marker that was clicked is", marker);
  }


  handleEmployeeSize=(e)=>{
    this.setState({
      employeesize:e.target.value
    });
  }

  employeesizeUpdate(val){
    let datatempemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.idc,
      "size": parseInt(val)
    }
    console.log("xYYY size; ",this.state.employeesize,datatempemployee)
    this.props.dispatch(companyActions.getEmployeeList(datatempemployee));
  }

  handleCampaignName=(event)=>{
    const {formData}=this.state;
    formData[event.target.name]=event.target.value
    this.setState({ formData });
  }

  handleParameterType=(event)=>{

    const {formData}=this.state;
 

    formData[event.target.name]=event.target.value
    this.setState({ formData });

    let data = {
      parameterTypeId: event.target.value
    }
    this.props.dispatch(parameterActions.getParameterListbyParameterType(data));




  }

  handleCampaignEmployees=(emp)=>{
    

    if(!this.state.selectAll){

      console.log("--------------EMP: ",emp.email,this.state.employees.length);
      const { formData } = this.state;


      if(this.state.employees.length > 0){
        var matchFlag = null;
        this.state.employees.forEach((selectedEmp,empIndex)=>{
     
          if(emp.id===selectedEmp.id){
            matchFlag = 1
            var data = this.state.employees;
            data.splice(empIndex, 1);

            formData['employees']= data;
            this.setState({ formData });
            
          }

        });

          if(matchFlag===1){
            console.log("matchFlag = 1:---->",this.state.employees)
          }
          else{
            var data =  this.state.employees;
            data.push({id:emp.id})

          
            formData['employees']= data;
            this.setState({ formData });

            console.log("matchFlag = 0:---->",this.state.employees)
          }
        


      }
      else{
         
        var datax =  this.state.employees;

          datax.push({id:emp.id})
      
        console.log("setZero:---->",this.state.employees)
        formData['employees']= datax;
        this.setState({ formData });
      }
    
       
    }


  }

  submitCampaignDetails(){

    const { formData } = this.state;
    console.log("formData: ",formData)

  this.props.dispatch(campaignActions.createNewCampaign(formData));

  }


  handleEmployeeOpen = (campaignId) => {
    this.setState({ selectedEmployeeModal: true });

    let campaignObj = {
      "surveyId":this.props.match.params.id,
      "campaignId":campaignId
    }
    this.props.dispatch(campaignActions.getCampaignEmployeesByID(campaignObj));
  }

  handleClose = () => {
    this.setState({ selectedEmployeeModal: false });
  }

  handleClick = (offset, page) => {
    console.log(offset, page);

    this.setState({ offset, page });

    let datatempemployee = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "companyId": this.props.match.params.idc,
      "size": this.state.size
    }
    this.props.dispatch(companyActions.getEmployeeList(datatempemployee));
  }
  
  handleSelectAll=()=>{

    let { company } = this.props;
    let {  allEmployeesList } = company;
    let { formData } = this.state;

    let allEmployeeIdList = []

    allEmployeesList.forEach((emp)=>{
      allEmployeeIdList.push({id:emp.id})
    })

    if(this.state.selectAll){
      this.setState({selectAll:false})
      formData['employees']=[]
      this.setState({formData})
    }
    else{
      this.setState({selectAll:true})
      formData['employees']=allEmployeeIdList
      this.setState({formData})
    }

  }

  render() {

    let { classes, campaign, company  } = this.props;
    const {  campaignList, campaignEmployeesList } = campaign;
    let {  employeeitems, employeetotal  } = company;


    console.log("finalData: ------------", this.state.finalData)

    let {formData} = this.state;

    console.log("formData: ", formData)

    let {  parameter, parametertype } = this.props;
    let {  allParametersListByParameterTypeData } = parameter;
    const { parameterTypesList  } = parametertype;


    return (
      <>

<Grid container spacing={2} style={{marginTop:'5%'}}>
          <Grid item xs={4} >
          
              
              <div className="card" style={{backgroundColor:'white'}}>
              <div style={{padding:'5%',paddingTop:'5%'}}>
                <div style={{backgroundColor:'#ededed',padding:'5%',marginBottom:'5%',fontSize:'25px',paddingLeft:'30%'}}>Campaign Form</div>
                        <form>
                        <div className="form-group"  >
                          <label htmlFor="exampleFormControlInput1">Campaign Title</label>
                          <TextField
                            id="standard-multiline-flexible"
                            fullWidth
                            rowsMax={4}
                            name="title"
                            placeholder="Campaign Title"
                            value={formData.title}
                            onChange={this.handleCampaignName}
                          />
                        </div>
                        <div className="form-group"  >
                          <label htmlFor="exampleFormControlInput1">Subject</label>
                          <TextField
                            id="standard-multiline-flexible"
                            fullWidth
                            rowsMax={4}
                            name="subject"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={this.handleCampaignName}
                          />
                        </div>
                        <div className="form-group"  >
                          <label htmlFor="exampleFormControlInput1">Message</label>
                          <TextField
                            id="standard-multiline-flexible"
                            fullWidth
                            rowsMax={4}
                            name="message"
                            placeholder="Message"
                            value={formData.message}
                            onChange={this.handleCampaignName}
                          />
                        </div>
                        <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Parameter Type</label>


                                        <Grid item xs={12} sm={12}>

                                              <Select
                                                name="parameterTypeId"
                                                value={formData.parameterTypeId ? formData.parameterTypeId : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Parameter Type"
                                                onChange={this.handleParameterType}
                                                input={<Input id="parameterTypeId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                  Campaign Type
                                                </MenuItem>
                                                {
                                                  parameterTypesList && parameterTypesList.length > 0 ?
                                                  parameterTypesList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>

                                            </Grid>

                        </div>
                                                <div className="form-group">
                                        <label htmlFor="exampleFormControlSelect1">Parameter</label>


                                        <Grid item xs={12} sm={12}>

                                              <Select
                                                name="parameterId"
                                                value={formData.parameterId? formData.parameterId: allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ? allParametersListByParameterTypeData[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Parameter"
                                                onChange={this.handleCampaignName}
                                                input={<Input id="parameterId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Parameter
                                                </MenuItem>
                                                {
                                                  allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ?
                                                  allParametersListByParameterTypeData.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>

                                            </Grid>
                                            
                        </div>
                        <div className="form-group"  >
                          <label htmlFor="exampleFormControlInput1">No. of Users Selected!</label>
                          <TextField
                            id="standard-multiline-flexible"
                            fullWidth
                            rowsMax={4}
                            placeholder="Users"
                            value={(formData && formData.employees)?formData.employees.length:0}
                          />
                        </div>
        
                         
                  </form>
                </div>
                <div className="form-group">
                      <button className="btn btn-success" style={{marginLeft:'33%',marginBottom:'20px',display:'inline',width:'175px'}} onClick={()=>this.submitCampaignDetails(this.state.employeesize)}>Submit</button>
                      {/* <h4 style={{marginLeft:'45%',marginBottom:'20px', display:'inline'}} className="text-success">{(addCampaignSuccess)?'Success!':null}</h4> */}
                </div>
                <div className="form-group">
                {
                  
                              employeeitems && employeeitems.length > 0 ?
                              employeeitems.map((tempdata, index) => (
                                  <span key={index}>
                                 
                                        {this.state.employees.map((emp,index2)=>
                                          (tempdata.id === emp.id)?
                                              <button key={index2} className="btn btn-secondary">{tempdata.email}</button>
                                          :null
                                        )}
                                  </span>

                                )) : null
                            }

                </div>

            </div>


       
          </Grid>
          <Grid item xs={8}>
        

            <div className="card" style={{backgroundColor:'white'}}>
                <div style={{padding:'5%',paddingTop:'5%'}}>
                          <label htmlFor="exampleFormControlInput1" style={{marginLeft:'10px'}}>Select Employee Size</label>

                          {/* <div className="form-group">
                        
                            <Grid item xs={4}>
                            <input  type="number" className="form-control" id="exampleFormControlInput1" placeholder={this.state.employeesize}  onChange={this.handleEmployeeSize} defaultValue={this.state.employeesize}/>
                            </Grid>
                            <Grid item xs={4}>
                            <button   className="btn btn-info" style={{width:'175px',marginTop:'10px'}} onClick={()=>this.employeesizeUpdate(this.state.employeesize)}>Set Size</button>
                            </Grid>
                          </div> */}
                          <Table className="mb-0">
                          <TableHead>
                                <TableRow>
                                  <TableCell >S. NO.</TableCell>
                                  <TableCell >Name</TableCell>
                                  <TableCell ><input type="checkbox" onClick={()=>this.handleSelectAll()} checked={this.state.selectAll}  id="defaultsCsheck1x"  style={{marginLeft:'25px'}}/></TableCell>
                                </TableRow>
                              </TableHead>
                            <TableBody>
                                      {
                                        employeeitems && employeeitems.length > 0 ?
                                        employeeitems.map((tempdata, index) => (
                                          <TableRow>
                                            <TableCell ><h4> {this.state.offset + index + 1}</h4></TableCell>
                                            <TableCell ><h4> {tempdata.email}</h4></TableCell>
                                            <TableCell >
                                              {this.state.selectAll?
                                              <input type="checkbox" onClick={()=>this.handleCampaignEmployees(tempdata)} checked={this.state.selectAll}  id="defaultsCsheck1"  style={{marginLeft:'25px'}}/>
                                              :
                                              <input type="checkbox" onClick={()=>this.handleCampaignEmployees(tempdata)}  id="defaultsCsheck1"  style={{marginLeft:'25px'}}/>
                                              }
                                              </TableCell>
                                          </TableRow>
                                          )) : null
                                      }
                            </TableBody>
                          </Table>

                          {
                employeetotal && employeetotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={employeetotal}
                      onClick={(e, offset, page) => this.handleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}

            
                  </div>
              </div>
        



          </Grid>


       
  
                  <Grid item xs={12}>
               
                    {
                     
                                      campaignList && campaignList.length > 0  ?
                                      campaignList.map((tempdata, index) => (
                                        <div className="card" style={{backgroundColor:'white',margin:'10px',padding:'20px'}}>
                                        <span style={{margin:'20px'}}>
                                        <span>
                                          <Typography style={{margin:'12px'}}>
                                            <h3 ><span style={{fontWeight:'bold'}}>Title:</span> {tempdata.title}</h3> 
                                          </Typography>
                                          {/* <Typography style={{margin:'12px',marginLeft:'20px'}}>
                                           <h4 ><span style={{fontWeight:'bold'}}>Subject:</span> {tempdata.campaignType}</h4> 
                                         </Typography>
                                         <Typography style={{margin:'12px',marginLeft:'20px'}}>
                                           <h4 ><span style={{fontWeight:'bold'}}>Message:</span> {tempdata.message}</h4> 
                                         </Typography> */}
                                         <Typography style={{margin:'12px',marginLeft:'20px'}}>
                                            <h4 ><span style={{fontWeight:'bold'}}>Campaign Type: </span> {tempdata.campaignTypeName}</h4> 
                                          </Typography>
                                          <Typography style={{margin:'12px',marginLeft:'20px'}}>
                                            <h4 ><span style={{fontWeight:'bold'}}>Parameter: </span> {tempdata.parameter}</h4> 
                                          </Typography>


                                          <Button  
                                          variant="contained"
                                          color="primary"  
                                          style={{margin:'10px'}}  
                                          onClick={()=>this.handleEmployeeOpen(tempdata.id)}>
                                            See Employees
                                            </Button>


                                        </span>
                                        </span>
                     </div>


                                        )) : null
                                    }
         
                    </Grid>
                
                
   

    </Grid>

    <Dialog fullScreen open={this.state.selectedEmployeeModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <TableEmployee
              campaignEmployeesList={campaignEmployeesList}
              handleClose={this.handleClose}
              classes={classes}

                />
    </Dialog>
      </>
    );
  }
}
Campaign.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users,campaign,company, parameter,  parametertype} = state;
  return {
    loggingIn,
    users,
    campaign,
    company,
    parameter,
    parametertype
    
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(Campaign)));
