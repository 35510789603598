export const insstoryConstants = {

    INSSTORY_GETALL_REQUEST: 'INSSTORY_GETALL_REQUEST',
    INSSTORY_GETALL_SUCCESS: 'INSSTORY_GETALL_SUCCESS',
    INSSTORY_GETALL_FAILURE: 'INSSTORY_GETALL_FAILURE',

    ADD_INSSTORY_REQUEST: 'ADD_INSSTORY_REQUEST',
    ADD_INSSTORY_SUCCESS: 'ADD_INSSTORY_SUCCESS',
    ADD_INSSTORY_FAILURE: 'ADD_INSSTORY_FAILURE',
    
    UPDATE_INSSTORY_REQUEST: 'UPDATE_INSSTORY_REQUEST',
    UPDATE_INSSTORY_SUCCESS: 'UPDATE_INSSTORY_SUCCESS',
    UPDATE_INSSTORY_FAILURE: 'UPDATE_INSSTORY_FAILURE',

    DELETE_INSSTORY_REQUEST: 'DELETE_INSSTORY_REQUEST',
    DELETE_INSSTORY_SUCCESS: 'DELETE_INSSTORY_SUCCESS',
    DELETE_INSSTORY_FAILURE: 'DELETE_INSSTORY_FAILURE',

};
