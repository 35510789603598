import {
  parametertypeConstants
} from '../_constants';

export function parametertype(state = {}, action) {

  switch (action.type) {
    case parametertypeConstants.PARAMETERTYPE_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case parametertypeConstants.PARAMETERTYPE_GETALL_SUCCESS:
      return {
        ...state,
        addParameterTypeSuccess: false,
        updateParameterTypeSuccess:false,
        parameterTypesList: action.parametertype.listOfParameterTypes.list,
        total: action.parametertype.listOfParameterTypes.total
      };
    case parametertypeConstants.PARAMETERTYPE_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case parametertypeConstants.ADD_PARAMETERTYPE_REQUEST:
      return {
        ...state
      };
    case parametertypeConstants.ADD_PARAMETERTYPE_SUCCESS:
      return {
        ...state,
        addParameterTypeSuccess: true
      };
    case parametertypeConstants.ADD_PARAMETERTYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case parametertypeConstants.UPDATE_PARAMETERTYPE_REQUEST:
      return {
        ...state
      };
    case parametertypeConstants.UPDATE_PARAMETERTYPE_SUCCESS:
      return {
        ...state,
        updateParameterTypeSuccess: true
      };
    case parametertypeConstants.UPDATE_PARAMETERTYPE_FAILURE:
      return {
        ...state,
        error: action.error
      };

      case parametertypeConstants.DELETE_PARAMETERTYPE_REQUEST:
        return {
          ...state
        };
      case parametertypeConstants.DELETE_PARAMETERTYPE_SUCCESS:
        return {
          ...state,
          deleteParameterTypeSuccess: true
        };
      case parametertypeConstants.DELETE_PARAMETERTYPE_FAILURE:
        return {
          ...state,
          error: action.error
        };

        
    default:
      return state
  }
}