export const faqConstants = {

    FAQ_GETALL_REQUEST: 'FAQ_GETALL_REQUEST',
    FAQ_GETALL_SUCCESS: 'FAQ_GETALL_SUCCESS',
    FAQ_GETALL_FAILURE: 'FAQ_GETALL_FAILURE',

    ADD_FAQ_REQUEST: 'ADD_FAQ_REQUEST',
    ADD_FAQ_SUCCESS: 'ADD_FAQ_SUCCESS',
    ADD_FAQ_FAILURE: 'ADD_FAQ_FAILURE',
    
    UPDATE_FAQ_REQUEST: 'UPDATE_FAQ_REQUEST',
    UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
    UPDATE_FAQ_FAILURE: 'UPDATE_FAQ_FAILURE',

    DELETE_FAQ_REQUEST: 'DELETE_FAQ_REQUEST',
    DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
    DELETE_FAQ_FAILURE: 'DELETE_FAQ_FAILURE',

};
