import {
  notificationConstants
} from '../_constants';

export function notification(state = {}, action) {

  switch (action.type) {
    case notificationConstants.NOTIFICATION_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case notificationConstants.NOTIFICATION_GETALL_SUCCESS:
      return {
        ...state,
        addNotificationSuccess: false,
        updateNotificationSuccess:false,
          compaignTypeList: action.notification.listOfNotifications.list,
          total: action.notification.listOfNotifications.total
      };
    case notificationConstants.NOTIFICATION_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case notificationConstants.ADD_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case notificationConstants.ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addNotificationSuccess: true
      };
    case notificationConstants.ADD_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case notificationConstants.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state
      };
    case notificationConstants.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        updateNotificationSuccess: true
      };
    case notificationConstants.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.error
      };

      case notificationConstants.DELETE_NOTIFICATION_REQUEST:
        return {
          ...state
        };
      case notificationConstants.DELETE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          deleteNotificationSuccess: true
        };
      case notificationConstants.DELETE_NOTIFICATION_FAILURE:
        return {
          ...state,
          error: action.error
        };

        
    default:
      return state
  }
}