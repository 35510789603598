
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const habitcategoryService = {
    disableHabitCategory,
    logout,
    createNewHabitCategory,
    updateHabitCategory,
    getAllHabitCategory,
    deleteHabitCategory
};
function logout() {
    localStorage.removeItem('adminhabitcategory');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllHabitCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getHabitCategoryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let habitcategoryObj = {
                listOfHabitCategorys: data.data
            }
            console.log();
            return habitcategoryObj;
        });
}

function createNewHabitCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createHabitCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let habitcategoryObj = {
                createNewHabitCategory: data.data
            }
            console.log();

            return habitcategoryObj;
        });
}
function updateHabitCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateHabitCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let habitcategoryObj = {
                updateNewHabitCategory: data.data
            }
            console.log(habitcategoryObj);

            return habitcategoryObj;
        });
}

function deleteHabitCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteHabitCategory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let habitcategoryObj = {
                deleteNewHabitCategory: data.data
            }
            console.log(habitcategoryObj);

            return habitcategoryObj;
        });
}

function disableHabitCategory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateHabitCategoryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let habitcategoryObj = {
                deleteHabitCategory: data.data
            }
            console.log();

            return habitcategoryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}