import { trackerConstants } from '../_constants';
import { trackerService } from '../_services';
import { alertActions } from '.';
export const trackerActions = {
    createTracker,
    updateTracker,
    disableTracker,
    getTrackerTypeList,
    getTrackerList,
    deleteTracker
};


function createTracker(data) {
    let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      
}
    return dispatch => {
        dispatch(request());
        trackerService.createTracker(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                    dispatch(this.getTrackerList(datatempsurvey));

                    dispatch(alertActions.success("Tracker Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.ADD_TRACKER_REQUEST } }
    function success(tracker) { return { type: trackerConstants.ADD_TRACKER_SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.ADD_TRACKER_FAILURE, error } }
}

function deleteTracker(data, datatempsurvey) {
    return dispatch => {
        dispatch(request());
        trackerService.deleteTracker(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                    dispatch(this.getTrackerList(datatempsurvey));
                    dispatch(alertActions.success("Tracker Delete successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.DELETE_TRACKER_REQUEST } }
    function success(tracker) { return { type: trackerConstants.DELETE_TRACKER_SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.DELETE_TRACKER_FAILURE, error } }
}

function updateTracker(data,tempdata) {
//     let datatempsurvey = {
//         "keyWord": "",
//         "pageNo": 1,
//         "size": 10
      
// }
    return dispatch => {
        dispatch(request());
        trackerService.updateTracker(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                    dispatch(this.getTrackerList(tempdata));

                    dispatch(alertActions.success("Tracker Updated successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.UPDATE_TRACKER_REQUEST } }
    function success(tracker) { return { type: trackerConstants.UPDATE_TRACKER_SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.UPDATE_TRACKER_FAILURE, error } }
}

function disableTracker(data) {
    let datatempsurvey = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
      
}
    return dispatch => {
        dispatch(request());
        trackerService.disableTracker(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                    dispatch(this.getTrackerList(datatempsurvey));

                    dispatch(alertActions.success("Tracker Created successfully."));


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.UPDATE_TRACKER_REQUEST } }
    function success(tracker) { return { type: trackerConstants.UPDATE_TRACKER_SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.UPDATE_TRACKER_FAILURE, error } }
}

function getTrackerTypeList(data) {
    return dispatch => {
        dispatch(request());
        trackerService.getTrackerTypeList(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.TRACKER_GETALL_REQUEST } }
    function success(tracker) { return { type: trackerConstants.TRACKER_GETALL_SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.TRACKER_GETALL_FAILURE, error } }
}


function getTrackerList(data) {
    return dispatch => {
        dispatch(request());
        trackerService.getTrackerList(data)
            .then(
                tracker => {
                    dispatch(success(tracker));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: trackerConstants.TRACKERLIST_GETALL_REQUEST } }
    function success(tracker) { return { type: trackerConstants.TRACKERLIST_GETALL__SUCCESS, tracker } }
    function failure(error) { return { type: trackerConstants.TRACKERLIST_GETALL__FAILURE, error } }
}
