import {
  habitcategoryConstants
} from '../_constants';

export function habitcategory(state = {}, action) {

  switch (action.type) {
    case habitcategoryConstants.HABITCATEGORY_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case habitcategoryConstants.HABITCATEGORY_GETALL_SUCCESS:
      return {
        ...state,
        addHabitCategorySuccess: false,
        updateHabitCategorySuccess:false,
        deleteHabitCategorySuccess: false,

          HabitCategoryList: action.habitcategory.listOfHabitCategorys.list,
          items: action.habitcategory.listOfHabitCategorys.list,
          total: action.habitcategory.listOfHabitCategorys.total
      };
    case habitcategoryConstants.HABITCATEGORY_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case habitcategoryConstants.ADD_HABITCATEGORY_REQUEST:
      return {
        ...state
      };
    case habitcategoryConstants.ADD_HABITCATEGORY_SUCCESS:
      return {
        ...state,
        addHabitCategorySuccess: true
      };
    case habitcategoryConstants.ADD_HABITCATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case habitcategoryConstants.UPDATE_HABITCATEGORY_REQUEST:
      return {
        ...state
      };
    case habitcategoryConstants.UPDATE_HABITCATEGORY_SUCCESS:
      return {
        ...state,
        updateHabitCategorySuccess: true
      };
    case habitcategoryConstants.UPDATE_HABITCATEGORY_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case habitcategoryConstants.DELETE_HABITCATEGORY_REQUEST:
        return {
          ...state
        };
      case habitcategoryConstants.DELETE_HABITCATEGORY_SUCCESS:
        return {
          ...state,
          deleteHabitCategorySuccess: true
        };
      case habitcategoryConstants.DELETE_HABITCATEGORY_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}