
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const expertService = {
    disableExpert,
    logout,
    createNewExpert,
    updateExpert,
    getAllExpert,
    deleteExpert
};
function logout() {
    localStorage.removeItem('adminexpert');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllExpert(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getExpertList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let expertObj = {
                listOfExperts: data.data
            }
            console.log();
            return expertObj;
        });
}

function createNewExpert(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createExpert`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertObj = {
                createNewExpert: data.data
            }
            console.log();

            return expertObj;
        });
}
function updateExpert(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpert`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertObj = {
                updateNewExpert: data.data
            }
            console.log(expertObj);

            return expertObj;
        });
}

function deleteExpert(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteExpert`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertObj = {
                deleteNewExpert: data.data
            }
            console.log(expertObj);

            return expertObj;
        });
}

function disableExpert(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateExpertStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let expertObj = {
                deleteExpert: data.data
            }
            console.log();

            return expertObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}