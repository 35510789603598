
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const userService = {
    login,
    createNewApiKey,
    updateApiKeyVelidity,
    disableUser,
    deleteApiKey,
    updateApiKeyStatus,
    deleteUser,
    logout,
    getAllNotification,
    updateNotificationStatus,
    createNewUser,
    uploadImage,
    uploadAudio,
    useroverView,
    updatePasswordUser,
    statics,
    getAllUser
};
function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
    //window.location.reload();

}
function login(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/login`, requestOptions)
        .then(handleResponse)
        .then(user => {
            let userObj = {
                userinfo: user.data
            }
            if (user.data) {
                localStorage.setItem('adminuser', JSON.stringify(user.data));
            }

            return userObj;
        });
}

function getAllUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getUserList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                listOfUsers: data.data
            }
            console.log();
            return userObj;
        });
}
function useroverView(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/useroverView`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                overviewdetails: data.data
            }
            console.log();

            return userObj;
        });
}
function getAllNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                listOfNotification: data.data
            }
            console.log();

            return userObj;
        });
}
function updateNotificationStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNotificationStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                listOfNotification: data.data
            }
            console.log();

            return userObj;
        });
}
function createNewUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createNewUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                createNewUser: data.data
            }
            console.log();

            return userObj;
        });
}
function createNewApiKey(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createApiKey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNewApiKey: data.data
            }
            return userObj;
        });
}
function updateApiKeyStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateApiKeyStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNewApiKey: data.data
            }
            return userObj;
        });
}
function deleteApiKey(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteApiKey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNewApiKey: data.data
            }
            return userObj;
        });
}
function updateApiKeyVelidity(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateApiKeyVelidity`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                createNewApiKey: data.data
            }
            return userObj;
        });
}
function deleteUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteUser: data.data
            }
            console.log();

            return userObj;
        });
}
function disableUser(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/disableUser`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let userObj = {
                deleteUser: data.data
            }
            console.log();

            return userObj;
        });
}

function updatePasswordUser(data) {
    console.log(data);
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePasswordUser`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                addItem: data.data
            }
            return userObj;
        });
}
function uploadImage(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            return userObj;
        });
}

function uploadAudio(filedata) {

    let header = new Headers({
        "Authorization": authHeader().Authorization
    });
    var data = new FormData();
    data.append('image', filedata);

    const requestOptions = {
        method: "POST",
        headers: header,
        body: data
    }
    return fetch(CONST.BACKEND_URL + `/uploadFile`, requestOptions)
        .then(handleResponse)
        .then(res => {
            let userObj = {
                filesDetails: res.data
            }
            //console.log("servive_uploadMusicImage",userObj);
            return userObj;
        });
}


function statics() {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/adminoverView`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                statics: data.data
            }
            return userObj;
        });
}
function handleResponse(response) {
    // console.log("response22222   ", response);

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                //location.reload(true);
            }
            console.log("datadatadata ", response);

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        //console.log("datadatadatadatadata   ", data.error);
        if (data.error) {
            console.log("datadatadatadatadata   ", data);
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}