import { expertcategoryConstants } from '../_constants';
import { expertcategoryService } from '../_services';
import { alertActions } from '.';
export const expertcategoryActions = {
    getAllExpertCategoryList,
    disableExpertCategory,
    createNewExpertCategory,
    updateExpertCategory,
    getAllExpertCategory,
    deleteExpertCategory
};

function getAllExpertCategory(data) {
    return dispatch => {
        dispatch(request());
        expertcategoryService.getAllExpertCategory(data)
            .then(
                expertcategory => dispatch(success(expertcategory)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_FAILURE, error } }
}
function getAllExpertCategoryList(data) {
    return dispatch => {
        dispatch(request());
        expertcategoryService.getAllExpertCategoryList(data)
            .then(
                expertcategory => dispatch(success(expertcategory)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.EXPERTCATEGORY_GETALL_LIST_FAILURE, error } }
}
function createNewExpertCategory(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        expertcategoryService.createNewExpertCategory(data)
            .then(
                expertcategory => {
                    dispatch(success(expertcategory));
                    dispatch(this.getAllExpertCategory(tempdata));
                    dispatch(alertActions.success("ExpertCategory created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_FAILURE, error } }
}

function updateExpertCategory(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        expertcategoryService.updateExpertCategory(data)
            .then(
                expertcategory => {
                    dispatch(success(expertcategory));
                    dispatch(this.getAllExpertCategory(tempdata));
                    dispatch(alertActions.success("ExpertCategory updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.UPDATE_EXPERTCATEGORY_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.UPDATE_EXPERTCATEGORY_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.UPDATE_EXPERTCATEGORY_FAILURE, error } }
}

function deleteExpertCategory(data, dataemp) {

    return dispatch => {
        dispatch(request());
        expertcategoryService.deleteExpertCategory(data)
            .then(
                expertcategory => {
                    dispatch(success(expertcategory));
                    dispatch(this.getAllExpertCategory(dataemp));
                    dispatch(alertActions.success("ExpertCategory deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.DELETE_EXPERTCATEGORY_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.DELETE_EXPERTCATEGORY_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.DELETE_EXPERTCATEGORY_FAILURE, error } }
}
function disableExpertCategory(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        expertcategoryService.disableExpertCategory({ id: data.id })
            .then(
                expertcategory => {
                    dispatch(success(expertcategory));
                    dispatch(this.getAllExpertCategory(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_REQUEST } }
    function success(expertcategory) { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_SUCCESS, expertcategory } }
    function failure(error) { return { type: expertcategoryConstants.ADD_EXPERTCATEGORY_FAILURE, error } }
}