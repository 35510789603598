
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const benchmarkService = {
    disableBenchMark,
    logout,
    createNewBenchMark,
    updateBenchMark,
    getAllBenchMark,
    deleteBenchMark
};
function logout() {
    localStorage.removeItem('adminbenchmark');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBenchMarkList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let benchmarkObj = {
                listOfBenchMarks: data.data
            }
            console.log();
            return benchmarkObj;
        });
}

function createNewBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let benchmarkObj = {
                createNewBenchMark: data.data
            }
            console.log();

            return benchmarkObj;
        });
}
function updateBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let benchmarkObj = {
                updateNewBenchMark: data.data
            }
            console.log(benchmarkObj);

            return benchmarkObj;
        });
}

function deleteBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let benchmarkObj = {
                deleteNewBenchMark: data.data
            }
            console.log(benchmarkObj);

            return benchmarkObj;
        });
}

function disableBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBenchMarkStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let benchmarkObj = {
                deleteBenchMark: data.data
            }
            console.log();

            return benchmarkObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}