
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const faqService = {
    disableFaq,
    logout,
    createNewFaq,
    updateFaq,
    getAllFaq,
    deleteFaq
};
function logout() {
    localStorage.removeItem('adminfaq');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllFaq(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getFaqList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let faqObj = {
                listOfFaqs: data.data
            }
            console.log();
            return faqObj;
        });
}

function createNewFaq(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createFaq`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let faqObj = {
                createNewFaq: data.data
            }
            console.log();

            return faqObj;
        });
}
function updateFaq(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateFaq`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let faqObj = {
                updateNewFaq: data.data
            }
            console.log(faqObj);

            return faqObj;
        });
}

function deleteFaq(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteFaq`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let faqObj = {
                deleteNewFaq: data.data
            }
            console.log(faqObj);

            return faqObj;
        });
}

function disableFaq(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateFaqStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let faqObj = {
                deleteFaq: data.data
            }
            console.log();

            return faqObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}