
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const parameterService = {
    logout,
    createParameter,
    updateParameter,
    disableParameter,
    getAllParameter,
    getParameterList,
    getParameterListbyParameterType
};
function logout() {
    localStorage.removeItem('adminparameter');
    window.location.href = "#/login";
}

function createParameter(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                createParameter: data.data
            }
            // console.log("After API: ",data.data);

            return parameterObj;
        });
}



function updateParameter(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                updateParameter: data.data
            }
            // console.log("After API: ",data.data);

            return parameterObj;
        });
}


function disableParameter(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateParameterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                updateParameter: data.data
            }
            // console.log("After API: ",data.data);

            return parameterObj;
        });
}

function getAllParameter(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllParameter`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                allParameters: data.data
            }

            return parameterObj;
        });
}

function getParameterList(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                allParametersList: data.data
            }

            return parameterObj;
        });
}
function getParameterListbyParameterType(data) {
    console.log("Data Ready For API: ",data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getParameterListbyParameterType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let parameterObj = {
                getParameterListbyParameterType: data.data
            }

            return parameterObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}