
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const questionsService = {
    disableQuestions,
    deleteQuestion,
    logout,
    createQuestion,
    updateQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    surveySubmit,
    deleteQuestionOption
    
};
function logout() {
    localStorage.removeItem('adminquestions');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllQuestions(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getQuestionsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                listOfQuestionss: data.data
            }
            console.log();
            return questionsObj;
        });
}
function createQuestion(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                createQuestion: data.data
            }
            console.log();

            return questionsObj;
        });
}
function updateQuestion(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                questionsList: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}

function deleteQuestion(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });


    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestion: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}
function disableQuestions(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateQuestionsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestions: data.data
            }
            console.log();

            return questionsObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function getQuestionListBySurveyID(data,employeeId) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getQuestionListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            var QuesAns=[];
            data.data.map((quesData,quesDataIndex)=>{
                if(quesData.templateId===2){
                    QuesAns.push({
                        questionId:quesData.id,
                        answer:"",
                        weightage:0,
                        parameterType:quesData.parameterType,
                        // parameter:(quesData.parameterType==="Happiness")?'':quesData.parameterId.name,
                        parameter:quesData.parameter,
                        selected:""
                    });

                    // quesData.options.map((ansData)=>{
                    //     return QuesAns[quesDataIndex].answers.push({
                    //         questionId:quesData.id,
                    //         answer:"",
                    //         weightage:0,
                    //         parameterType:quesData.parameterType,
                    //         parameter:quesData.parameterId.name

                    //     });
                    // });

                    // console.log("X 2 X ",QuesAns[quesDataIndex] );
                    return QuesAns;
                }
                else{
                    return QuesAns.push({
                        questionId:quesData.id,
                        answer:"",
                        weightage:0,
                        parameterType:quesData.parameterType,
                        // parameter:(quesData.parameterType==="Happiness")?'':quesData.parameterId.name,
                        parameter:quesData.parameter,
                        selected:""
                    })
            }
                
            })
            let questionObj = {
                questionsList: data.data,
                answersList:QuesAns
            }
            console.log("questionObj  ", questionObj);

            return questionObj;
        });
}


function surveySubmit(data,quesList) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveAnswers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}


function deleteQuestionOption(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteQuestionOption`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}