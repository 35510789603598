export const expertenqiryConstants = {

    EXPERTENQIRY_GETALL_REQUEST: 'EXPERTENQIRY_GETALL_REQUEST',
    EXPERTENQIRY_GETALL_SUCCESS: 'EXPERTENQIRY_GETALL_SUCCESS',
    EXPERTENQIRY_GETALL_FAILURE: 'EXPERTENQIRY_GETALL_FAILURE',

    ADD_EXPERTENQIRY_REQUEST: 'ADD_EXPERTENQIRY_REQUEST',
    ADD_EXPERTENQIRY_SUCCESS: 'ADD_EXPERTENQIRY_SUCCESS',
    ADD_EXPERTENQIRY_FAILURE: 'ADD_EXPERTENQIRY_FAILURE',
    
    UPDATE_EXPERTENQIRY_REQUEST: 'UPDATE_EXPERTENQIRY_REQUEST',
    UPDATE_EXPERTENQIRY_SUCCESS: 'UPDATE_EXPERTENQIRY_SUCCESS',
    UPDATE_EXPERTENQIRY_FAILURE: 'UPDATE_EXPERTENQIRY_FAILURE',

    DELETE_EXPERTENQIRY_REQUEST: 'DELETE_EXPERTENQIRY_REQUEST',
    DELETE_EXPERTENQIRY_SUCCESS: 'DELETE_EXPERTENQIRY_SUCCESS',
    DELETE_EXPERTENQIRY_FAILURE: 'DELETE_EXPERTENQIRY_FAILURE',

};
