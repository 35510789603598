
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import "react-datepicker/dist/react-datepicker.css";
import { companyActions, userActions } from '../../_actions';
import { withRouter } from "react-router";
import Pagination from "material-ui-flat-pagination";
import {
  Grid,
  TextField,
  Dialog,
  Slide,
  CssBaseline,
  Button
} from "@material-ui/core";

import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import Typography from '@material-ui/core/Typography';
import Table from "./components/Table/Table";

import UserTable from "./components/UserTable/UserTable";


import SurveyTable from "./components/SurveyTable/SurveyTable";

import BenchMarkTable from "./components/BenchMark/BenchMarkTable";

import { SheetJSFT } from './types';
import styles from './styles';

import AddUser from './UserModal/AddUser';
import UpdateUser from './UserModal/UpdateUser';

import AddSurvey from './SurveyModal/AddSurvey';
import UpdateSurvey from './SurveyModal/UpdateSurvey';

import ViewEmployeeModal from './EmployeeModal/ViewEmployeeModal';
import EmployeeFilter from './EmployeeModal/EmployeeFilter';

import AddBenchMark from './BenchMarkModal/AddBenchMark';
import UpdateBenchMark from './BenchMarkModal/UpdateBenchMark';


const theme = createMuiTheme();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class CompanyDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      newUserModal: false,
      updateUserModal: false,
      newSurveyModal: false,
      openFilterModal:false,
      newSurveyUpdateModal: false,
      viewEmployeeDetailsModel: false,
      password: "",
      formData: {
        "email": "",
        "password": ""
      },
      updateformData:{
        "password": ""
      },
      surveyformData: {
        "name": "",
        "desc": "",
        "type": ""
      },
      updatesurveyformData:{
        "name": "",
        "desc": "",
        "type": ""
      },

      benchmarkformData: {
        "name": "",
        "value": ""
      },
      updatebenchmarkformData:{
        "id":"",
        "name": "",
        "value": ""
      },

      formDataFilter:{
        "name":"",
        "label":""
      },
      amount: 0,
      size: 10,
      page: 1,

      employeesize: 10,
      employeeoffset: 0,
      employeepage: 0,

      surveysize: 10,
      surveyoffset: 0,
      surveypage: 0,


      benchmarksize: 10,
      benchmarkoffset: 0,
      benchmarkpage: 0,


      file: {},
      data: [],
      cols: []
    }
  }
  componentDidMount() {
    let data = {
      id: this.props.match.params.id
    }
    this.props.dispatch(companyActions.getCompanyById(data));
    let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }
  

    this.props.dispatch(userActions.getAllUser(datatemp));

    let datatempemployee = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize
    }
  

    this.props.dispatch(companyActions.getEmployeeList(datatempemployee));

    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    let allemployeesdata = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id
    }

    this.props.dispatch(companyActions.getAllEmployees(allemployeesdata));

    this.props.dispatch(companyActions.getSurveyList(datatempsurvey));

    this.props.dispatch(companyActions.getBenchMarkList({companyId:this.props.match.params.id}));

    // this.props.dispatch(companyActions.getAllSurveyType());
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log(" nextProps  addSurveySuccess", nextProps.companys);
    if (nextProps.users.addUserSuccess) {
      return {
        ...nextProps,
        formData: {
          "email": "",
          "password": ""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.addSurveySuccess) {
      return {
        ...nextProps,
        surveyformData: {
          "name": "",
          "desc": "",
          "type": "",
          "companyId": ""
        },
        newUserModal: false,
        newSurveyModal: false,

      }
    }
    if (nextProps.company.addBenchMarkSuccess) {
      return {
        ...nextProps,
        benchmarkformData: {
          "name": "",
          "value": ""
        },
        newBenchMarkModal: false,

      }
    }
    if (nextProps.company.updateBenchMarkSuccess) {
      return {
        ...nextProps,
        updatebenchmarkformData: {
          "id":"",
          "name": "",
          "value": ""
        },
        newBenchMarkUpdateModal: false,

      }
    }
    if (nextProps.company.passwordUpdated) {

      return {
        ...nextProps,
        apikeyModal: false,
        formData: {
          "email": "",
          "password": ""
        },
        currentAPI_details: {
          "id": ""
        },
        apiformData: {
          "ip": ""
        },
        startDate: new Date(),
        password: ''
      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  handleOpenFilter=()=>{

    let data ={
      companyId:this.props.match.params.id
    }

    this.props.dispatch(companyActions.getEmployeeColumnName(data));
    this.props.dispatch(companyActions.getEmployeeFilterListByCompanyId(data));

    
    this.setState({ openFilterModal: true });

  }

  handleFilterInput=(event)=>{
    let { formDataFilter } = this.state;
    formDataFilter[event.target.name]=event.target.value;
    formDataFilter['label']=event.target.value;
    formDataFilter['companyId']=this.props.match.params.id;
    this.setState({ formDataFilter });
  }

  handleFilterSubmit=()=>{
    let { formDataFilter } = this.state;
    console.log("formDataFilter final",formDataFilter)
    this.props.dispatch(companyActions.createEmployeeFilter(formDataFilter));

  }

  handleFilterStatus=(value)=>{
    console.log("handleFilterStatus::",value)
    let data ={
      companyId:this.props.match.params.id
    }
    this.props.dispatch(companyActions.updateEmployeeFilterStatus({id:value},data));
  }



  handleCloseFilter=()=>{
    this.setState({ openFilterModal: false });
  }





  handleClickOpen = () => {
    this.setState({ newUserModal: true });
  }
  handleClose = () => {
    //setOpen(false);
    this.setState({ newUserModal: false });

  }
  handleClickOpenSurvey = () => {
    this.setState({ newSurveyModal: true });
  }


  handleCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyModal: false });
  }



  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  updateSubmit = (e) => {
    e.preventDefault();
    let { password } = this.state;
    this.props.dispatch(companyActions.updatePasswordUser({ id: this.props.match.params.id, password }, this.props));
  }
  onDisable = (data) => {
  
    this.props.dispatch(companyActions.updateApiKeyStatus({ id: data.id, companyId: this.props.match.params.id }, this.props));
  }

  onDeleteUser = (data) => {
      let datatemp = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }

    confirmAlert({
      title: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.key,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteUser({ id: data.id},datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  DownloadEmployees =(employeesItem)=>{

    // console.log('employeesItem: ',employeesItem)

    let list = [];
    let keyList = []
    let keyNewList = []


    if(employeesItem && employeesItem.length>0 && employeesItem[0]){
      keyList = Object.keys(employeesItem[0])
    }

    if(keyList && keyList.length>0){
      keyList.forEach((key,index)=>{
        if(key !== 'id' && key !== 'role' 
        && key !== 'companyId' && key !== 'currentStage' 
        && key !== 'isDisable' && key !== 'createdAt'
        && key !== 'deviceToken' && key !== 'imageLinkV2' 
        && key !== 'isHappySurvey' && key !== 'isNotificationActive' 
        && key !== 'profile'

        ){
          keyNewList.push(key);
        }
      })
    }

    if(employeesItem && employeesItem.length>0){

      employeesItem.forEach((emp,index)=>{

        let data = {}

        if(keyNewList && keyNewList.length>0){
          keyNewList.forEach((keY,indey)=>{
            data[keY] = emp[keY]
          })
        }

        list.push(data)

      })



      // console.log('keyList: ',keyList)

      // console.log('DownloadEmployees: ',list)
      // console.log('employeesItem: ',employeesItem)

    // array of objects to save in Excel
    let binary_univers = list;
  
    let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 
  
    // Create a new Workbook
    var wb = XLSX.utils.book_new() 
  
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 
  
    // export your excel
    XLSX.writeFile(wb, 'employees.xlsx');


    }

  }

  onRowClick=()=>{
    
  }

 

  handleChangeInput = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }
  handleChangeInputSurvey = (event) => {
    const { surveyformData } = this.state;
    surveyformData[event.target.name] = event.target.value;
    this.setState({ surveyformData });
  }
  handleSubmit = () => {
    let reqData = {
      "email": this.state.formData.email,
      "password": this.state.formData.password,
      "companyId": this.props.match.params.id
    }

    this.props.dispatch(userActions.createNewUser(reqData));
  }
  handleSubmitSurvey = () => {

    let reqData = {
      "name": this.state.surveyformData.name,
      "desc": this.state.surveyformData.desc,
      "type": this.state.surveyformData.type ? this.state.surveyformData.type : this.state.company.surveyTypeList[0].id
    }


    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(companyActions.createSurvey(reqData, datatempsurvey));
  }
  handleChange = (e) => {
    const files = e.target.files;
    if (files && files[0]) this.setState({ file: files[0] });
  };
  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v, "companyId": this.props.match.params.id }))

      /* Update state */
      let datatemp0 = {
        "keyWord": this.state.keyWord,
        "pageNo": 1,
        "companyId": this.props.match.params.id,
        "size": this.state.employeesize
      }
      this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
        this.props.dispatch(companyActions.saveEmployeeList(this.state.data, datatemp0));
      });
    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }
  userhandleClick = (offset, page) => {
    // console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "companyId": this.props.match.params.id,
      "size": this.state.size
    }
    this.props.dispatch(userActions.getAllUser(data));
  }
  employeehandleClick = (employeeoffset, employeepage) => {
    // console.log(offset, page);

    this.setState({ employeeoffset, employeepage });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": employeepage,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize
    }
    this.props.dispatch(companyActions.getEmployeeList(data));

  }

  surveyhandleClick = (surveyoffset, surveypage) => {
    // console.log(offset, page);

    this.setState({ surveyoffset, surveypage });

    let data = {
      "keyWord": this.state.surveykeyWord,
      "pageNo": surveypage,
      "companyId": this.props.match.params.id,
      "size": this.state.surveysize
    }
    this.props.dispatch(companyActions.getEmployeeList(data));


  }
  handleChangesurvey = (value) => {
    console.log("value  ", value);
    const { surveyformData } = this.state;
    surveyformData['type'] = value;
    this.setState({ surveyformData });

    // const files = e.target.files;
    // if (files && files[0]) this.setState({ file: files[0] });
  };

  onUpdate = (data) => {

    let tempdata = {
      "id": data.id,
      "password":data.password
    }
    this.setState({ updateUserModal: true, updateformData: tempdata });
  }
  updatehandleChangeInput= (event) => {
    const { updateformData } = this.state;
        updateformData[event.target.name] = event.target.value;
        this.setState({ updateformData });
  }

  handleUpdateSubmit = () => {

    let reqData = {
      "id":this.props.match.params.id,
      "password": this.state.updateformData.password
    }
  
    this.props.dispatch(userActions.updatePasswordUser(reqData));
  }

  handleUpdateClose = () => {
    //setOpen(false);
    this.setState({ updateUserModal: false });

  }

  //survey list
  onUpdateSurvey = (data) => {
 
    let tempdata = {
      "id": data.id,
      "name":data.name,
      "desc":data.desc,
      "type":data.type
    }
    this.setState({ newSurveyUpdateModal: true, updatesurveyformData: tempdata });
  }

  onCampaign=(data)=>{

    let survey = {
      surveyId: this.props.match.params.id,
      companyId: data.id
    }
    this.props.history.push(`/app/campaign/${survey.companyId}/${survey.surveyId}`)
  }


  updatehandleChangeInputSurvey= (event) => {
    const { updatesurveyformData } = this.state;
    updatesurveyformData[event.target.name] = event.target.value;
        this.setState({ updatesurveyformData });
 
  }

  updatehandleChangesurvey = (value) => {
   
    const { updatesurveyformData } = this.state;
    updatesurveyformData['type'] = value;
    this.setState({ updatesurveyformData });

  };

  handleUpdateSurveySubmit = () => {

    let reqData = {
      "id":this.state.updatesurveyformData.id,
      "name": this.state.updatesurveyformData.name,
      "desc":this.state.updatesurveyformData.desc,
      "type":this.state.updatesurveyformData.type
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(companyActions.updateSurvey(reqData, datatempsurvey));
  }


  handleUpdateCloseSurvey = () => {
    //setOpen(false);
    this.setState({ newSurveyUpdateModal: false });

  }

  viewDetails = (data) => {
    this.setState({ viewEmployeeDetailsModel: true });
  }

  handleEDClose = () => {
    this.setState({ viewEmployeeDetailsModel: false });
  }

  onAddQuesClick = (data) => {
    this.props.history.push(`/app/addquestion/${data.id}`)
  }



  handleClickOpenSurvey = () => {
    this.setState({ newBenchMarkModal: true });
  }

  handleCloseBenchMark=()=>{
    this.setState({ newBenchMarkModal: false });

  }
  handleUpdateCloseBenchMark=()=>{
    this.setState({ newBenchMarkUpdateModal: false });

  }


  handleChangeInputBenchMark=(event)=>{
    const { benchmarkformData } = this.state;
    benchmarkformData[event.target.name] = event.target.value;
    this.setState({ benchmarkformData });
  }

  updatehandleChangeInputBenchMark=(event)=>{
    const { updatebenchmarkformData } = this.state;
    updatebenchmarkformData[event.target.name] = event.target.value;
        this.setState({ updatebenchmarkformData });
  }


  handleChangebenchmark=()=>{
    const { benchmarkformData } = this.state;
    // benchmarkformData['type'] = value;
    this.setState({ benchmarkformData });
  }
  updatehandleChangebenchmark=()=>{
    const { updatesurveyformData } = this.state;
    // updatesurveyformData['type'] = value;
    this.setState({ updatesurveyformData });
  }


  handleSubmitBenchMark=()=>{
    let reqData = {
      "name": this.state.benchmarkformData.name,
      "value": this.state.benchmarkformData.value,
      "companyId": this.props.match.params.id,
    }

    this.props.dispatch(companyActions.createBenchMark(reqData));
  }


  handleUpdateBenchMarkSubmit=()=>{
    let reqData = {
      "id":this.state.updatebenchmarkformData.id,
      "name": this.state.updatebenchmarkformData.name,
      "value":this.state.updatebenchmarkformData.value,
      "companyId": this.props.match.params.id,
    }

    this.props.dispatch(companyActions.updateBenchMark(reqData));
  }


  handleClickOpenBenchMark=()=>{
    this.setState({ newBenchMarkModal: true });
  }



  onUpdateBenchMark = (data) => {
 
    let tempdata = {
      "id": data.id,
      "name":data.name,
      "value":data.value
    }
    this.setState({ newBenchMarkUpdateModal: true, updatebenchmarkformData: tempdata });


  }

  onDeleteBenchMark=(data)=>{
    let tempdata = {
      "id":data.id,
      "companyId": this.props.match.params.id,

    }
    confirmAlert({
      title: 'Confirm to delete?',
      message: 'Are you sure to delete '+data.name+' ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(companyActions.deleteBenchMark(tempdata))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  onDisableBenchMark=(data)=>{
    let tempdata={
      "id":data.id,
      "companyId": this.props.match.params.id,

    }
    this.props.dispatch(companyActions.updateBenchMarkStatus(tempdata))
  }

  // benchmarkhandleClick = (benchmarkoffset, benchmarkpage) => {
  //   this.setState({ benchmarkoffset, benchmarkpage });
  //   let data = {
  //     "keyWord": this.state.benchmarkkeyWord,
  //     "pageNo": benchmarkpage,
  //     "companyId": this.props.match.params.id,
  //     "size": this.state.benchmarksize
  //   }
  //   // this.props.dispatch(companyActions.getBenchMarkList(data));

  // }

  render() {

    let { classes, company, users } = this.props;
    let { companydetails, employeeitems, employeetotal, surveyitems, surveytotal, surveyTypeList, employeeColumnNames, employeeFilterList,
    
      getBenchMarkListData,allEmployeesList} = company;

    let { items, total } = users;
    const { formData, updateformData, surveyformData, updatesurveyformData, submitted, formDataFilter,
    
    benchmarkformData, updatebenchmarkformData} = this.state;

    console.log("employeeColumnNames: ",employeeColumnNames)

    return (
      <>
        <PageTitle title={companydetails && companydetails.name ? companydetails.name : "NA"} />
        <Grid container >
          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3>User List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                items && items.length > 0 ?
                  <>
                    <UserTable data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                      onDeleteClick={this.onDeleteClick}
                      onDelete={this.onDeleteUser}
                      isShowMoreButton={true}
                      isShowViewButton={true}
                      onDisable={this.onDisable}
                      onUpdate={this.onUpdate} 
                      viewDetails={this.viewDetails}/>
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                total && total > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.size}
                      offset={this.state.offset}
                      total={total}
                      onClick={(e, offset, page) => this.userhandleClick(offset, page)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>


          <Grid item xs={6} style={{marginTop:'22px'}}>
            <div>
              <Typography variant="h6">
              Upload an excel sheet of employees
              </Typography>
              {/* <label htmlFor="file">Upload an excel to Process Triggers</label> */}
              <br />
              <Button
                  color="info"
                  size="small"
                  className="px-2"
                  variant="contained"
                >
                <input color="primary" type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
              </Button>
              <br />
              {/* <input type='submit'
                value="Upload Employees"
                className = "btn btn-primary"
                onClick={this.handleFile} /> */}
                  <Button
                  color="primary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginTop: '10px' }}
                  onClick={this.handleFile}
                >
                  Upload Employees
              </Button>
              <br />
            </div>
        
          </Grid>



          
          <Grid item xs={6} style={{marginTop:'22px'}}>


          <div style={{float:'right',marginLeft:'2%'}}>
              {/* <Typography variant="h6">
              Employee Filter
              </Typography> */}
              <br />
                  <Button
                  color="primary"
                  size="small"
                  className="px-2"
                  variant="contained"
                  onClick={()=>this.handleOpenFilter()}
                  style={{ marginTop: '80px'}}
                >
                  Employees Filter
              </Button>
              <br />
            </div>

            <div style={{float:'right'}}>
              <br />
              <Button
                color="info"
                size="small"
                className="px-2"
                variant="contained"
                style={{ marginTop: '80px'}}
                onClick={()=>this.DownloadEmployees(allEmployeesList)}
              >
                Download Employees
              </Button>
              <br />
            </div>
        
          </Grid>




          <Grid item xs={12} style={{marginTop:'22px'}}>
            <Widget >
              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3>Employee List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                </Grid>
              </Grid>

              {
                employeeitems && employeeitems.length > 0 ?
                  <>
                    <Table data={employeeitems} offset={this.state.employeeoffset} onRowClick={this.onRowClick}
                      onDeleteClick={this.onDeleteClick}
                      onDelete={this.onDelete}
                      isShowUpdateButton={true}
                      isShowMoreButton={true}
                      onDisable={this.onDisable} 
                      onUpdate={this.onUpdate}
                      viewDetails={this.viewDetails}/>
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                employeetotal && employeetotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.employeesize}
                      offset={this.state.employeeoffset}
                      total={employeetotal}
                      onClick={(e, employeeoffset, employeepage) => this.employeehandleClick(employeeoffset, employeepage)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>


          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3>Survey List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpenSurvey()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                surveyitems && surveyitems.length > 0 ?
                  <>
                    <SurveyTable data={surveyitems} offset={this.state.surveyoffset} onRowClick={this.onRowClick}
                      onDeleteClick={this.onDeleteClick}
                      onDelete={this.onDelete}
                      isShowMoreButton={true}
                      onDisable={this.onDisable}
                      onAddQuesClick ={this.onAddQuesClick}
                      onUpdateSurvey={this.onUpdateSurvey}
                      onCampaign={this.onCampaign}/>
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {
                surveytotal && surveytotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.surveysize}
                      offset={this.state.surveyoffset}
                      total={surveytotal}
                      onClick={(e, surveyoffset, surveypage) => this.surveyhandleClick(surveyoffset, surveypage)}
                    />
                  </MuiThemeProvider>
                  : null}
            </Widget>
          </Grid>



          <Grid item xs={12}>
            <Widget >
              <Grid container >
                <Grid item xs={3} style={{marginTop:'12px'}}>
                  <h3>BenchMark List</h3>
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={3}>
                  {/* <TextField
                    id="standard-search"
                    label="Search field"
                    type="search"
                    name="keyWord"
                    onChange={this.handleSearch}
                    className={classes.textField}
                    margin="normal"
                  /> */}
                </Grid>
                <Grid item xs={1}>
                  <Button variant="contained" color="primary" onClick={() => this.handleClickOpenBenchMark()} className={classes.button}>
                    Add
                  </Button>
                </Grid>
              </Grid>

              {
                getBenchMarkListData && getBenchMarkListData.length > 0 ?
                  <>
                    <BenchMarkTable data={getBenchMarkListData} offset={this.state.benchmarkoffset}
                      onDelete={this.onDeleteBenchMark}
                      onDisable={this.onDisableBenchMark}
                      onUpdate={this.onUpdateBenchMark}
                      />
                  </>
                  : <Typography >Data not found.</Typography>
              }
              {/* {
                getBenchMarkListTotal && getBenchMarkListTotal > 10 ?
                  <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Pagination
                      limit={this.state.benchmarksize}
                      offset={this.state.benchmarkoffset}
                      total={getBenchMarkListTotal}
                      onClick={(e, benchmarkoffset, benchmarkpage) => this.benchmarkhandleClick(benchmarkoffset, benchmarkpage)}
                    />
                  </MuiThemeProvider>
                  : null} */}
            </Widget>
          </Grid>



                



        </Grid>
        <Dialog fullScreen open={this.state.newUserModal} onClose={this.handleClose} TransitionComponent={Transition}>
               <AddUser
                  data={this.data}
                  classes={classes}
                  formData ={formData}
                  handleClose={this.handleClose}
                  handleSubmit={this.handleSubmit}
                  handleChangeInput={this.handleChangeInput}
                />
        </Dialog>
        <Dialog fullScreen open={this.state.updateUserModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
             <UpdateUser
                  data={this.data}
                  classes={classes}
                  updateformData ={updateformData}
                  handleUpdateClose={this.handleUpdateClose}
                  handleUpdateSubmit={this.handleUpdateSubmit}
                  updatehandleChangeInput={this.updatehandleChangeInput}
                />
        </Dialog>

        <Dialog fullScreen open={this.state.newSurveyModal} onClose={this.handleCloseSurvey} TransitionComponent={Transition}>
              <AddSurvey
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  surveyTypeList={surveyTypeList}
                  surveyformData ={surveyformData}
                  handleCloseSurvey={this.handleCloseSurvey}
                  handleSubmitSurvey={this.handleSubmitSurvey}
                  handleChangeInputSurvey={this.handleChangeInputSurvey}
                  handleChangesurvey={this.handleChangesurvey}
                />
        </Dialog>

        <Dialog fullScreen open={this.state.newSurveyUpdateModal} onClose={this.handleUpdateCloseSurvey} TransitionComponent={Transition}>
        <UpdateSurvey
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  surveyTypeList={surveyTypeList}
                  updatesurveyformData ={updatesurveyformData}
                  handleUpdateCloseSurvey={this.handleUpdateCloseSurvey}
                  handleUpdateSurveySubmit={this.handleUpdateSurveySubmit}
                  updatehandleChangeInputSurvey={this.updatehandleChangeInputSurvey}
                  updatehandleChangesurvey={this.updatehandleChangesurvey}
                />
        </Dialog>

        <Dialog fullScreen open={this.state.viewEmployeeDetailsModel} onClose={this.handleEDClose} TransitionComponent={Transition}>
        <ViewEmployeeModal
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  surveyTypeList={surveyTypeList}
                  updatesurveyformData ={updatesurveyformData}

                  handleEDClose={this.handleEDClose}
                  handleSearch={this.handleSearch}
                  employeeoffset={this.state.employeeoffset}
                  employeesize={this.state.employeesize}

                  onDeleteClick={this.onDeleteClick}
                  onDelete={this.onDelete}
                  onDisable={this.onDisable}
                  onUpdate={this.onUpdate}
                  onRowClick={this.onRowClick}
                  viewDetails={this.viewDetails}
                  employeehandleClick={this.employeehandleClick}

                  employeeitems={employeeitems}
                  employeetotal={employeetotal}
                  companydetails={companydetails}
                  
                />
        </Dialog>
        <Dialog fullScreen open={this.state.openFilterModal} onClose={this.handleCloseFilter} TransitionComponent={Transition}>
        <EmployeeFilter
                  classes={classes}
                  submitted={submitted}
                  formDataFilter={formDataFilter}
                  handleFilterInput={this.handleFilterInput}
                  handleEDClose={this.handleCloseFilter}
                  handleSearch={this.handleSearch}

                  employeeColumnNames={employeeColumnNames}
                  handleFilterSubmit={this.handleFilterSubmit}
                  employeeFilterList={employeeFilterList}
                  handleFilterStatus={this.handleFilterStatus}

        />
        </Dialog>




        <Dialog fullScreen open={this.state.newBenchMarkModal} onClose={this.handleCloseBenchMark} TransitionComponent={Transition}>
              <AddBenchMark
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  benchmarkformData ={benchmarkformData}
                  handleCloseBenchMark={this.handleCloseBenchMark}
                  handleSubmitBenchMark={this.handleSubmitBenchMark}
                  handleChangeInputBenchMark={this.handleChangeInputBenchMark}
                  handleChangebenchmark={this.handleChangebenchmark}
                />
        </Dialog>

        <Dialog fullScreen open={this.state.newBenchMarkUpdateModal} onClose={this.handleUpdateCloseBenchMark} TransitionComponent={Transition}>
        <UpdateBenchMark
                  data={this.data}
                  classes={classes}
                  submitted={submitted}
                  updatebenchmarkformData ={updatebenchmarkformData}
                  handleUpdateCloseBenchMark={this.handleUpdateCloseBenchMark}
                  handleUpdateBenchMarkSubmit={this.handleUpdateBenchMarkSubmit}
                  updatehandleChangeInputBenchMark={this.updatehandleChangeInputBenchMark}
                  updatehandleChangebenchmark={this.updatehandleChangebenchmark}
                />
        </Dialog>



      </>
    );

  }
}
function mapStateToProps(state) {
  const { company, users } = state;
  return {
    company, users
  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(CompanyDetails)));
