import React, { Component } from 'react';

import { connect } from 'react-redux';


import { questionsActions } from '../../../_actions';



class ThankyouPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isAdd:false,
      editId: null,
      QuesName: "",
      OptionName: "",
      dropdown: "Choose",
      radioButton:false,
      starCount:0,


      save: false,
      questionsize: 10,
      editCurrentQuestion: {},
      editCurrentOption:{},

      view:'desktop',
      setViewValue:100
    }
  }
  
  componentDidMount() {
    let survey = {
      surveyId: this.props.match.params.id,
      employeeId: this.props.match.params.idc,
    }
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(survey));
  }
  //   static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log(" nextProps  addSurveySuccess", nextProps);
  // }

  
  onDashboard = () => {
    // let survey = {
    //     surveyId: this.props.match.params.id
    //   }
    this.props.history.push(`/app/dashboard`)
  }

  handleView(view){
    if(view==='mobile'){
      this.setState({view:'mobile',setViewValue:30})
    }
    else if(view==='tablet'){
      this.setState({view:'tablet',setViewValue:50})
    }
    else if(view==='desktop'){
      this.setState({view:'desktop',setViewValue:100})
    }
    else {
      this.setState({view:'desktop',setViewValue:100})
    }
  }

  

  render() {

    

    return (
      <>
       {/* <PageTitle title="Add Question" /> */}
       <div className="container">

         

     
<div className="container">
          <div className="card" variant="outlined" style={{backgroundColor:'white',padding:'10%', marginTop:'0%'}}> 
            <div className="card-body">

          
                    <div className="container" 
                    style={{marginTop:'15%',marginBottom:'23%'}}
                    >
                    <div className="container"
                    container
                    direction="row"
                    justify="center"
                    style={{margin:'20px'}}
                    >
              



                    
        
                                <div className="container">
                                  
                                    <div className="container" 
                                    container
                                    direction="row"
                                    justify="center"
                                    >
                                            <h1 className="display-1" variant="h1"  style={{marginLeft:'27%'}}>
                                                Thank You
                                            </h1>
                                        </div>
                             
                                </div>
                      
                    
                   
                
                        </div>
                                <div className="container"
                                container
                                direction="row"
                                justify="center"
                                style={{margin:'20px'}}
                                >
                                        <button
                                        
                                        color="secondary"
                                        size="small"
                                        className="px-2 btn btn-secondary" 
                                        variant="contained"
                                        style={{ marginLeft:'32%',marginTop:'3%' ,color:'white',backgroundColor:'#545059'}}
                                        onClick={()=>this.onDashboard()}
                                        >
                                        Go To Home
                                        </button>
                                </div>
                        </div>
            </div>
          </div>
        </div>
      




</div>
</>
    );
  }
}
ThankyouPage.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { questions, users } = state;
  return {
    loggingIn,
    questions,
    users

  };
}

export default connect(mapStateToProps)(ThankyouPage);
