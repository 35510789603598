import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Select,
  Input,
  MenuItem
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { CONST } from '../../../_config/index';

import Select2 from 'react-select';


export default function AddTracker(props) {

  const { getHabitCategoryList,handleHabitCategoryInput, classes, formData, submitted, handleClose, handleTrackerName,
    allActivities, 
    handleFile, 
    handleUpload ,imageWebURL,
    handleTrackerActivities, submitTrackerDetails,
    // parameterTypesList, handleTrackerParaType, allParametersListByParameterTypeData
   } = props;

  let colourOptions = []
  if (allActivities && allActivities.length > 0) {
    allActivities.forEach((emp) => {
      colourOptions.push(
        {
          value: emp.name,
          label: emp.name,
          id: emp.id,
        }
      )
    })
  }


  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Tracker
            </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Add Tracker
              </Typography>


          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>

              <TextField
                label="Name*"
                id="name"
                name="name"
                fullWidth
                rowsMax={4}
                placeholder="Name"
                value={formData.name}
                // validators={['required']}
                onChange={handleTrackerName}
              // errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Desc"
                id="desc"
                name="desc"
                fullWidth
                rowsMax={4}
                placeholder="Description"
                value={formData.desc}
                onChange={handleTrackerName}
              />
            </Grid>

            <Grid item xs={12}>
                                              <Select
                                                name="habitCategoryId"
                                                value={formData.habitCategoryId ? formData.habitCategoryId : getHabitCategoryList && getHabitCategoryList.length > 0 ? getHabitCategoryList[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Habit Category"
                                                onChange={handleHabitCategoryInput}
                                                input={<Input id="habitCategoryId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Habit Category
                                                </MenuItem>
                                                {
                                                  getHabitCategoryList && getHabitCategoryList.length > 0 ?
                                                  getHabitCategoryList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
              </Grid>



            <Grid item xs={12}>
              <TextField
                label="colorCode"
                id="colorCode"
                name="colorCode"
                fullWidth
                rowsMax={4}
                placeholder="colorCode"
                value={formData.colorCode}
                onChange={handleTrackerName}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="icon"
                id="icon"
                name="icon"
                fullWidth
                rowsMax={4}
                placeholder="icon"
                value={formData.desc}
                onChange={handleTrackerName}
              />
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                label="Icon"
                id="icon"
                disabled={true}
                name="icon"
                fullWidth
                rowsMax={4}
                placeholder="Icon"
                value={formData.icon}
              />
            </Grid>
            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" 
              onClick={() => handleUpload()}>
                Set
                      </Button>



            </Grid>


            {(imageWebURL) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '150px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : null}
            {/* <Grid item xs={12} sm={12}>
              <Select
                name="parameterTypeId"
                value={formData.parameterTypeId ? formData.parameterTypeId : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                style={{ width: '100%' }}
                onChange={handleTrackerParaType}
                input={<Input id="parameterTypeId" />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  Parameter Type List
                    </MenuItem>
                {
                  parameterTypesList && parameterTypesList.length > 0 ?
                    parameterTypesList.map((tempdata, index) => (
                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                    )) : null
                }

              </Select>
            </Grid> */}
            {/* <Grid item xs={12} sm={12}>
              <Select
                name="parameterId"
                value={formData.parameterId ? formData.parameterId : allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ? allParametersListByParameterTypeData[0].id : ''}
                style={{ width: '100%' }}
                onChange={handleTrackerName}
                input={<Input id="parameterId" />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  Subdimension List
                    </MenuItem>
                {
                  allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ?
                    allParametersListByParameterTypeData.map((tempdata, index) => (
                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                    )) : null
                }

              </Select>
            </Grid> */}

            <div style={{ margin: "7px" }}></div>
            <Grid item xs={12} style={{ margin: "0px" }}>
              <label htmlFor="resourceList" style={{ color: 'grey' }}>Activities</label>
              <Select2
                id="activityList"
                isMulti
                name="activityList"
                onChange={handleTrackerActivities}
                options={colourOptions}
                className="basic-multi-select"
                classNamePrefix="Activities"
              />
            </Grid>
            <div style={{ margin: "7px" }}></div>



          </Grid>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={submitted}
            className={classes.submit}
            onClick={() => submitTrackerDetails()}
            style={{ marginTop: "5%" }}
          >
            Save
                </Button>
        </div>
      </Container>



    </div>
  );
}
