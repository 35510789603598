export const trackerConstants = {

    ADD_TRACKER_REQUEST: 'ADD_TRACKER_REQUEST',
    ADD_TRACKER_SUCCESS: 'ADD_TRACKER_SUCCESS',
    ADD_TRACKER_FAILURE: 'ADD_TRACKER_FAILURE',

    DELETE_TRACKER_REQUEST: 'DELETE_TRACKER_REQUEST',
    DELETE_TRACKER_SUCCESS: 'DELETE_TRACKER_SUCCESS',
    DELETE_TRACKER_FAILURE: 'DELETE_TRACKER_FAILURE',

    UPDATE_TRACKER_REQUEST: 'UPDATE_TRACKER_REQUEST',
    UPDATE_TRACKER_SUCCESS: 'UPDATE_TRACKER_SUCCESS',
    UPDATE_TRACKER_FAILURE: 'UPDATE_TRACKER_FAILURE',

    TRACKER_GETALL_REQUEST: 'TRACKER_GETALL_REQUEST',
    TRACKER_GETALL_SUCCESS: 'TRACKER_GETALL_SUCCESS',
    TRACKER_GETALL_FAILURE: 'TRACKER_GETALL_FAILURE',

    TRACKERLIST_GETALL_REQUEST: 'TRACKERLIST_GETALL_REQUEST',
    TRACKERLIST_GETALL__SUCCESS: 'TRACKERLIST_GETALL__SUCCESS',
    TRACKERLIST_GETALL__FAILURE: 'TRACKERLIST_GETALL__FAILURE',

};
