import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Select,
  Input,
  MenuItem
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select2 from 'react-select';


export default function UpdateTracker(props) {

  const { classes, updateformData,getHabitCategoryList,updatehandleHabitCategoryInput,submitted, handleClose, handleTrackerName, 
    allActivities,handleTrackerActivities, submitUpdateTrackerDetails,
    parameterTypesList, allParametersListByParameterTypeData, imageWebLink, imageWebURL,handleFile,handleUpload,
    handleTrackerParaType } = props;

    let colourOptions=[]
    allActivities.forEach((emp)=>{
      colourOptions.push(
        {
          value:emp.name,
          label:emp.name,
          id:emp.id,
        }
      )
    })
    let colourDefault=[]
    if(updateformData.activityList){
          
           updateformData.activityList.forEach((upd)=>{
            allActivities.forEach((emp)=>{
             if(emp.id === upd)
             colourDefault.push(
               {
                 value:emp.name,
                 label:emp.name,
                 id:emp.id,
               }
             )
           })
         })
    }

    // console.log("allActivities: ",allActivities)

    console.log("updateformData: ",updateformData)
  return (
    <div >

<AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Update New Tracker
            </Typography>
              <Button color="inherit" onClick={handleClose}>
                Cancel
            </Button>
            </Toolbar>
          </AppBar>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Update Tracker
              </Typography>

       
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>

                        <TextField
                          label="Name*"
                          id="name"
                          name="name"
                            fullWidth
                            rowsMax={4}
                            placeholder="Name"
                            value={updateformData.name}
                            // validators={['required']}
                            onChange={handleTrackerName}
                            // errorMessages={['this field is required']}
                          />
                  </Grid>
                  <Grid item xs={12}>
                        <TextField
                          label="Desc"
                            id="desc"
                            name="desc"
                            fullWidth
                            rowsMax={4}
                            placeholder="Description"
                            value={updateformData.desc}
                            onChange={handleTrackerName}
                          />
                  </Grid>

                  
            <Grid item xs={12}>
                                              <Select
                                                name="habitCategoryId"
                                                value={updateformData.habitCategoryId ? updateformData.habitCategoryId : getHabitCategoryList && getHabitCategoryList.length > 0 ? getHabitCategoryList[0].id : ''}
                                                style={{ width: '100%' }}
                                                label="Habit Category"
                                                onChange={updatehandleHabitCategoryInput}
                                                input={<Input id="habitCategoryId" />}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                              >
                                                <MenuItem value="" disabled>
                                                Habit Category
                                                </MenuItem>
                                                {
                                                  getHabitCategoryList && getHabitCategoryList.length > 0 ?
                                                  getHabitCategoryList.map((tempdata, index) => (
                                                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                                                    )) : null
                                                }

                                              </Select>
              </Grid>



                  <Grid item xs={12} sm={12}>
                  <Select
                    name="parameterTypeId"
                    value={updateformData.parameterTypeId ? updateformData.parameterTypeId  : parameterTypesList && parameterTypesList.length > 0 ? parameterTypesList[0].id : ''}
                    style={{ width: '100%' }}
                    onChange={handleTrackerParaType}
                    input={<Input id="parameterTypeId" />}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Parameter Type List
                    </MenuItem>
                    {
                      parameterTypesList && parameterTypesList.length > 0 ?
                      parameterTypesList.map((tempdata, index) => (
                          <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                        )) : null
                    }

                  </Select>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                  <Select
                    name="parameterId"
                    value={updateformData.parameterId ? updateformData.parameterId  : allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ? allParametersListByParameterTypeData[0].id : ''}
                    style={{ width: '100%' }}
                    onChange={handleTrackerName}
                    input={<Input id="parameterId" />}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="" disabled>
                      Subdimension List
                    </MenuItem>
                    {
                      allParametersListByParameterTypeData && allParametersListByParameterTypeData.length > 0 ?
                      allParametersListByParameterTypeData.map((tempdata, index) => (
                          <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                        )) : null
                    }

                  </Select>
                  </Grid>


                  <Grid item xs={12}>
                    <TextField
                      label="Icon "
                      id="icon"
                      disabled={true}

                      name="icon"
                      fullWidth
                      rowsMax={4}
                      placeholder="Icon Name"
                      value={updateformData.icon}
                    />
                  </Grid>
                  <Grid item xs={12}>

                    <label htmlFor="image">
                      <Button color="default" variant="contained" component="span" >
                        Browse
                              </Button>
                    </label>
                    <input
                      style={{ display: 'none' }}
                      id="image"
                      name="image"
                      type="file"
                      onChange={handleFile}
                    />

                    <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                      Set
                            </Button>



                  </Grid>

                  {(imageWebURL && (imageWebLink === updateformData.icon)) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '170px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : 
                <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={updateformData.imageLinkV2} style={{ width: '170px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                }


                <Grid item xs={12} style={{margin:"0px"}}>

                <label htmlFor="activityList" style={{color:'grey'}}>Activities</label>
                <Select2
                    id="activityList"
                    isMulti
                    defaultValue={(colourDefault)?colourDefault.map((val)=>{return val}):""}
                    name="activityList"
                    onChange={handleTrackerActivities}
                    options={colourOptions}
                    className="basic-multi-select"
                    classNamePrefix="Activities"
                  />


                </Grid>
                <div style={{margin:"7px"}}></div> 

         

                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitted}
                  className={classes.submit}
                  onClick={()=>submitUpdateTrackerDetails()}
                  style={{marginTop:"5%"}}
                >
                  Save
                </Button>
            </div>
          </Container>



</div>
  );
}
