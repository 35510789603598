import { enquiryConstants } from '../_constants';
import { enquiryService } from '../_services';
import { alertActions } from '.';
export const enquiryActions = {
    disableEnquiry,
    createNewEnquiry,
    updateEnquiry,
    getAllEnquiry,
    deleteEnquiry
};

function getAllEnquiry(data) {
    return dispatch => {
        dispatch(request());
        enquiryService.getAllEnquiry(data)
            .then(
                enquiry => dispatch(success(enquiry)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: enquiryConstants.ENQUIRY_GETALL_REQUEST } }
    function success(enquiry) { return { type: enquiryConstants.ENQUIRY_GETALL_SUCCESS, enquiry } }
    function failure(error) { return { type: enquiryConstants.ENQUIRY_GETALL_FAILURE, error } }
}
function createNewEnquiry(data) {
    let tempdata = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }
    return dispatch => {
        dispatch(request());
        enquiryService.createNewEnquiry(data)
            .then(
                enquiry => {
                    dispatch(success(enquiry));
                    dispatch(this.getAllEnquiry(tempdata));
                    dispatch(alertActions.success("Enquiry created successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: enquiryConstants.ADD_ENQUIRY_REQUEST } }
    function success(enquiry) { return { type: enquiryConstants.ADD_ENQUIRY_SUCCESS, enquiry } }
    function failure(error) { return { type: enquiryConstants.ADD_ENQUIRY_FAILURE, error } }
}

function updateEnquiry(data,tempdata) {
    // let tempdata = {
    //     "keyWord": "",  
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(request());
        enquiryService.updateEnquiry(data)
            .then(
                enquiry => {
                    dispatch(success(enquiry));
                    dispatch(this.getAllEnquiry(tempdata));
                    dispatch(alertActions.success("Enquiry updated successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: enquiryConstants.UPDATE_ENQUIRY_REQUEST } }
    function success(enquiry) { return { type: enquiryConstants.UPDATE_ENQUIRY_SUCCESS, enquiry } }
    function failure(error) { return { type: enquiryConstants.UPDATE_ENQUIRY_FAILURE, error } }
}

function deleteEnquiry(data, dataemp) {

    return dispatch => {
        dispatch(request());
        enquiryService.deleteEnquiry(data)
            .then(
                enquiry => {
                    dispatch(success(enquiry));
                    dispatch(this.getAllEnquiry(dataemp));
                    dispatch(alertActions.success("Enquiry deleted successfully."));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: enquiryConstants.DELETE_ENQUIRY_REQUEST } }
    function success(enquiry) { return { type: enquiryConstants.DELETE_ENQUIRY_SUCCESS, enquiry } }
    function failure(error) { return { type: enquiryConstants.DELETE_ENQUIRY_FAILURE, error } }
}
function disableEnquiry(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        enquiryService.disableEnquiry({ id: data.id })
            .then(
                enquiry => {
                    dispatch(success(enquiry));
                    dispatch(this.getAllEnquiry(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: enquiryConstants.ADD_ENQUIRY_REQUEST } }
    function success(enquiry) { return { type: enquiryConstants.ADD_ENQUIRY_SUCCESS, enquiry } }
    function failure(error) { return { type: enquiryConstants.ADD_ENQUIRY_FAILURE, error } }
}