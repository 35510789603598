import { questionsConstants } from '../_constants';
import { questionsService } from '../_services';
import { alertActions } from '.';
export const questionsActions = {
    disableQuestions,
    deleteQuestion,
    createQuestion,
    updateQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    handleNameQuestion,
    handleNameOption,
    deleteOption,
    addOption,
    handleSubmitPreChange,
    surveySubmit,
    handleParaInput,
    handleUpdateWeightage,
    handleNameWeightage
};
function getAllQuestions(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAllQuestions(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}
function createQuestion(data) {
    let survey = {
        surveyId: data.surveyId,
    }
    return dispatch => {
        dispatch(request());
        questionsService.createQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(alertActions.success("Question added successfully."));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}
function updateQuestion(data,call, call2) {
    let tempdata = {
        surveyId: data.surveyId,
    }
    let updateData = {   
        id:data.id,
        surveyId: data.surveyId,
        name: data.name,
        options:[],
        questionTypeId: data.questionTypeId,
        templateId: data.templateId,

        parameterTypeId: data.parameterTypeId,
        parameterId:(data && data.parameterId  && data.parameterId.id )?data.parameterId.id :data.parameterId  ,

        // parameterId: (data.parameterType==="Happiness")? "5f5588ddd90eee99b99db44f"  :  data.parameterId,
        // bucketId: data.bucketId,
        // parameter: data.parameter

    }
    data.options.forEach(element => {
        updateData.options.push({id:element.id , weightage:element.weightage , name:element.name})
    })
    console.log("data.updateData>>>>>> ",updateData)
    console.log("data.updateQuestion>>>>>> ",data)

    
    return dispatch => {
        dispatch(request());
        questionsService.updateQuestion(updateData)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                    if(!call){
                        dispatch(alertActions.success("Update successfully."));
                    }
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
}

function deleteQuestion(data) {

    let survey = {
        surveyId: data.surveyId
    }
    return dispatch => {
        dispatch(request());
        questionsService.deleteQuestion({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(survey));
                    dispatch(alertActions.success("Deleted successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONS_FAILURE, error } }
}
function disableQuestions(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        questionsService.disableQuestions({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getAllQuestions(tempdata));
                    dispatch(alertActions.success("Status updated successfully."));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}
function getQuestionListBySurveyID(data) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getQuestionListBySurveyID(data)
            .then(
                questions => {
                    // console.log("answers_submission list 2", QuesSub)
                    dispatch(success(questions));
                },
                error => {
                    
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}


function handleNameQuestion(questionList, questionId, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);
    questionList[currentQuestionIndex]['name'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleParaInput(questionList, questionId, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);
    questionList[currentQuestionIndex]['parameter'] = value.name;
    questionList[currentQuestionIndex]['parameterId'] = value.id;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleUpdateWeightage(questionList, questionId, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);
    questionList[currentQuestionIndex]['weightage'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameOption(questionList, questionId, optionId, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);
    let currentOptionIndex = questionList[currentQuestionIndex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[currentQuestionIndex]['optionsList'][currentOptionIndex]){
        questionList[currentQuestionIndex]['optionsList'][currentOptionIndex]['name'] = value;
        questionList[currentQuestionIndex]['options'] = questionList[currentQuestionIndex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameWeightage(questionList, questionId, optionId, value) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === questionId);
    let currentOptionIndex = questionList[currentQuestionIndex]['optionsList'].findIndex(x=>x.id === optionId);
    questionList[currentQuestionIndex]['optionsList'][currentOptionIndex]['weightage'] = value;
    questionList[currentQuestionIndex]['options'] = questionList[currentQuestionIndex]['optionsList']

    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function deleteOption(questionList, question, optionId) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);
    let currentOptionIndex = questionList[currentQuestionIndex]['optionsList'].findIndex(x=>x._id === optionId);
    questionList[currentQuestionIndex]['optionsList'].splice(currentOptionIndex,1);
    let questionObj = {
        questionsList: questionList
    }
    let cur_ques =  questionList[currentQuestionIndex];
    console.log('X.1>>> ',cur_ques);

    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        questionsService.deleteQuestionOption({id: optionId},false)
            .then(
                questions => {
                    dispatch(success(questions));
                    // dispatch(this.getQuestionListBySurveyID(tempdata));
                    dispatch(alertActions.success("Delete successfully."));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONOPTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONOPTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONOPTION_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function addOption(questionList, question) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);

    // questionList[currentQuestionIndex]['optionsList'].push({weightage:1,name:"New Choice"})

    questionList[currentQuestionIndex]['options'].push({weightage:1,name:"New Choice"})

    let questionObj = {
        questionsList: questionList
    }
    console.log("{{addOption: ",questionObj)
    delete questionList[currentQuestionIndex].createdBy;
    delete questionList[currentQuestionIndex].createdAt;
    // let cur_ques =  questionList[currentQuestionIndex];
    // let tempdata = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        console.log("{{{question: ",question)
        dispatch(this.updateQuestion(question,true, true));
        // questionsService.updateQuestion(cur_ques)
        //     .then(
        //         questions => {
        //             dispatch(success(questions));
        //             dispatch(this.updateQuestion(survey));
        //             dispatch(this.getQuestionListBySurveyID(tempdata));
        //             dispatch(alertActions.success("Update successfully."));
        //         },
        //         error => {
        //             dispatch(alertActions.error(error));
        //             dispatch(failure(error))
        //         }
        //     );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    // function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,ansIndex) {
    let questionObj={};
    if(currQues.templateId===2){
        
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        console.log('currIndex: ', currIndex)
        if(answersList[currIndex]['answer'] === ""){
            answersList[currIndex]['selected'] = value._id;
            answersList[currIndex]['answer'] = value.name;
            answersList[currIndex]['weightage'] = weightage;
            // answersList[currIndex]['parameter'] = currQues.parameterId;
        }
        else{
            answersList[currIndex]['selected'] = "";
            answersList[currIndex]['answer'] = "";
            answersList[currIndex]['weightage'] = 0;
            // answersList[currIndex]['parameter'] = currQues.parameterType;
        }
        
        questionObj = {
            questionsList:questionsList,
            answersList:answersList
        }
    }
    else{
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        console.log('currIndex: ', currIndex)

        answersList[currIndex]['selected'] = value._id;
        answersList[currIndex]['answer'] = value.name;
        answersList[currIndex]['weightage'] = weightage;
        // answersList[currIndex]['parameter'] = currQues.parameterType;

        questionObj = {
            questionsList:questionsList,
            answersList:answersList
        }
    }
   
    return dispatch => {
        dispatch(success(questionObj));
        console.log("- X- X- C AnswerList: ",questionObj)

    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function surveySubmit(answersList,dataId,props) {
    console.log("surveyData: ", answersList)

    let ansData={
        surveyId: dataId.surveyId,
        employeeId: dataId.employeeId,
        answers: []
    }

    answersList.forEach((surveyData)=>{
        ansData.answers.push({
            questionId:surveyData.questionId,
            answer:surveyData.answer,
            parameterType:surveyData.parameterType,
            weightage:surveyData.weightage,
            parameter:surveyData.parameter
        })
    })


    console.log("finalData: ", ansData)

    

             return dispatch => {
            dispatch(request());
            questionsService.surveySubmit(ansData)
                .then(
                    answers => {
                        console.log("-------------------------------------------answers ==== == = ",answers)
                        dispatch(success(answers));
                        dispatch(alertActions.success("Answer Submit successfully."));
                        props.history.push(`/app/thankyou/${dataId.surveyId}`)
                    },
                    error => {
                        console.log("--------------------------------------------failser ==== == = ",error)
                        dispatch(alertActions.error(error));
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: questionsConstants.ANSWERS_SUBMIT_REQUEST } }
        function success(answers) { return { type: questionsConstants.ANSWERS_SUBMIT_SUCCESS, answers } }
        function failure(error) { return { type: questionsConstants.ANSWERS_SUBMIT_FAILURE, error } }
}