import {
  dashboardConstants
} from '../_constants';

export function dashboard(state = {}, action) {

  switch (action.type) {

    case dashboardConstants.DASHBOARD_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.DASHBOARD_GETALL_SUCCESS:
      return {
        ...state,
        addDashboardSuccess: false,
        dashboardStates:action.dashboard.dashboardStates,
      };
    case dashboardConstants.DASHBOARD_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };

    default:
      return state
  }
}