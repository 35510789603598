
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const premiumactivityService = {
    logout,
    createPremiumActivity,
    updatePremiumActivity,
    disablePremiumActivity,
    getAllPremiumActivity,
    getPremiumActivityList,
    deletePremiumActivity
};
function logout() {
    localStorage.removeItem('adminpremiumactivity');
    window.location.href = "#/login";
}

function createPremiumActivity(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createPremiumActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                createPremiumActivity: data.data
            }
            //console.log("createPremiumActivitycreatePremiumActivitycreatePremiumActivity: ", data);

            return premiumactivityObj;
        });
}

function deletePremiumActivity(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deletePremiumActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                deletePremiumActivity: data.data
            }
            // console.log("After API: ",data.data);

            return premiumactivityObj;
        });
}

function updatePremiumActivity(data) {
    //console.log("data serviceUpdate: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePremiumActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                updatePremiumActivity: data.data
            }
            // console.log("After API: ",data.data);

            return premiumactivityObj;
        });
}

function disablePremiumActivity(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updatePremiumActivityStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                updatePremiumActivity: data.data
            }
            // console.log("After API: ",data.data);

            return premiumactivityObj;
        });
}

function getAllPremiumActivity(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getAllPremiumActivity`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                allActivities: data.data
            }
            // console.log("After API: ",data.data);

            return premiumactivityObj;
        });
}


function getPremiumActivityList(data) {
    //console.log("Data Ready For API: ", data)
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getPremiumActivityList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let premiumactivityObj = {
                allActivitiesList: data.data
            }
            //console.log("getPremiumActivityListgetPremiumActivityListgetPremiumActivityListI: ", premiumactivityObj);

            return premiumactivityObj;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}