
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const insstoryService = {
    disableInsStory,
    logout,
    createNewInsStory,
    updateInsStory,
    getAllInsStory,
    deleteInsStory
};
function logout() {
    localStorage.removeItem('admininsstory');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllInsStory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getInsStoryList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let insstoryObj = {
                listOfInsStorys: data.data
            }
            console.log();
            return insstoryObj;
        });
}

function createNewInsStory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createInsStory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let insstoryObj = {
                createNewInsStory: data.data
            }
            console.log();

            return insstoryObj;
        });
}
function updateInsStory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateInsStory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let insstoryObj = {
                updateNewInsStory: data.data
            }
            console.log(insstoryObj);

            return insstoryObj;
        });
}

function deleteInsStory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteInsStory`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let insstoryObj = {
                deleteNewInsStory: data.data
            }
            console.log(insstoryObj);

            return insstoryObj;
        });
}

function disableInsStory(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateInsStoryStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let insstoryObj = {
                deleteInsStory: data.data
            }
            console.log();

            return insstoryObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}