import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers";
import moment from 'moment';


export default function TableComponent(props) {
  //onDelete
  let { data, offset, onDisable, onUpdate } = props;
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Name</TableCell>
          {/* <TableCell >Description</TableCell> */}
          <TableCell >Link</TableCell>
          <TableCell >Image</TableCell>
          <TableCell >Resource Type</TableCell>
          {/* <TableCell >ImageLink</TableCell> */}
          <TableCell >CreatedAt</TableCell>
          {/* <TableCell >RATING</TableCell> */}
          <TableCell >ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal" style={{ width: '5%' }}>{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal" style={{ width: '15%' }}>{tempdata.name}</TableCell>
              {/* <TableCell className="pl-3 fw-normal" style={{width:'20%'}}>{tempdata.desc}</TableCell> */}
              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>{tempdata.link}</TableCell>
              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>
                <img src={tempdata.imageLinkV2} alt="asldkfj" height="200" width="200"></img>
              </TableCell>
              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>{tempdata.resourceTypeName}</TableCell>
              {/* <TableCell className="pl-3 fw-normal">{tempdata.imageLink}</TableCell> */}
              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>
              <TableCell className="pl-3 fw-normal">

                <Button
                  color={tempdata.isDisable ? "warning" : "success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDisable(tempdata)}
                >
                  {tempdata.isDisable ? 'Disable' : 'Enable'}
                </Button>
                <Button
                  color="info"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onUpdate(tempdata)}
                >
                  Update
                </Button>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
