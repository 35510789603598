import React, { Component } from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  TextField,
  Dialog,
  Slide,
} from "@material-ui/core";
import { connect } from 'react-redux';
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "./components/Table/Table";
import { confirmAlert } from 'react-confirm-alert'; // Import

import { premiummusicActions } from '../../_actions/premiummusic.actions'
import { premiumactivityActions } from '../../_actions/premiumactivity.actions';

import { withRouter } from "react-router";
import { createMuiTheme, MuiThemeProvider, withStyles } from "@material-ui/core/styles";
import Pagination from "material-ui-flat-pagination";
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './styles';

import UpdatePremiumActivity from './Modal/UpdatePremiumActivity';
import AddPremiumActivity from './Modal/AddPremiumActivity';
import { userActions } from '../../_actions/user.actions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createMuiTheme();



class PremiumActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {

      offset: 0,
      size: 10,
      page: 1,
      keyWord: '',

      title: "",
      desc: "",
      name: "",
      resourceList: [],
      premiumactivityTypeName: "",
      premiumactivityTypeId: "",

      rating: "",
      author: "",
      idmb: "",

      submitted: false,
      newPremiumActivityModal: false,
      newUpdatePremiumActivityModal: false,

      imageWebLink: "",

      formData: {
        "name": "",
        "desc": "",
        "type": "",
        "resourceList": [],
        "subpremiumactivityList": [],
        "icon": "",
        "colorCode": ""


      },
      updateformData: {
        "id": "",
        "name": "",
        "desc": "",
        "type": "",
        "resourceList": [],
        "subpremiumactivityList": [],
        "icon": "",
        "colorCode": ""
      },
      premiumactivityType: [
        {
          name: 'Normal',
          value: 'generic'
        },
        {
          name: 'Habit',
          value: 'habit'
        },
      ],

      subName: '',
      subIndex: ''


    }
  }
  componentDidMount() {

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.size
    }
    //console.log(data);

    this.props.dispatch(premiumactivityActions.getPremiumActivityList(data));
    this.props.dispatch(premiummusicActions.getAllPremiumMusic(data));

  }
  static getDerivedStateFromProps(nextProps, prevState) {
    // //console.log(" prevState  addSurveySuccess", prevState);
    // //console.log(" nextProps  addSurveySuccess", nextProps.premiumactivity);
    if (nextProps.users.imageUploadSuccess && !nextProps.premiumactivity.addPremiumActivitySuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.users.filesDetails.uploadedImageName

      }
    }
    if (nextProps.premiumactivity.updatePremiumActivitySuccess) {

      return {
        ...nextProps,
        apikeyModal: false,
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subpremiumactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subpremiumactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        newUpdatePremiumActivityModal: false

      }
    }

    if (nextProps.premiumactivity.addPremiumActivitySuccess) {

      return {
        ...nextProps,
        apikeyModal: false,
        formData: {
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subpremiumactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        updateformData: {
          "id": "",
          "name": "",
          "desc": "",
          "type": "",
          "resourceList": [],
          "subpremiumactivityList": [],
          "icon": "",
          "colorCode": ""
        },
        newPremiumActivityModal: false

      }
    } else {
      return {
        ...nextProps
      }
    }

  }

  handlePremiumActivityName = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handlePremiumActivityNameUpdate = (event) => {

    const { updateformData } = this.state;
    updateformData[event.target.name] = event.target.value;
    this.setState({ updateformData });
  }

  handlePremiumActivityResource = (e, val) => {
    //console.log("Confirm List:::::", val);

    const { formData, updateformData } = this.state;

    if (val.option) {
      formData.resourceList.push(val.option.id);
      updateformData.resourceList.push(val.option.id)
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.resourceList.splice(delIndex, 1);
        }
      })

      updateformData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.resourceList.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
    }

  }

  onDelete = (data) => {
    let datatemp = {
      "keyWord": "",
      "pageNo": this.state.page,
      "size": this.state.size
    }

    confirmAlert({
      name: 'Confirm to delete?',
      message: 'Are you sure to delete ' + data.name,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(premiumactivityActions.deletePremiumActivity({ id: data.id }, datatemp))
        },
        {
          label: 'No'
        }
      ]
    });

  }

  submitPremiumActivityDetails = () => {

    let data = {
      name: this.state.formData.name.trim(),
      desc: this.state.formData.desc,
      musicList:  this.state.formData.resourceList,
      icon: (this.state.imageWebLink)?this.state.imageWebLink: this.state.formData.icon.trim()
      // type: this.state.formData.type.trim(),
      // subpremiumactivityList:(this.state.formData.type!=='habit')?[]: this.state.formData.subpremiumactivityList,
      // colorCode:this.state.formData.colorCode,

    }
    this.props.dispatch(premiumactivityActions.createPremiumActivity(data));
    this.setState({ subIndex: '', imageWebLink: "" });
  }



  submitUpdatePremiumActivityDetails = () => {
    let tempdata = {
      "keyWord": this.state.keyWord,
      "pageNo": this.state.page,
      "size": this.state.size
    }
    //console.log("this.state.updateformData  ",this.state.updateformData);
    let data = {
      id: this.state.updateformData.id,
      name: this.state.updateformData.name.trim(),
      desc: this.state.updateformData.desc,
      musicList: this.state.updateformData.musicList,
      colorCode: this.state.updateformData.colorCode,
      icon: (this.state.imageWebLink) ? this.state.imageWebLink : this.state.updateformData.icon
      // type: this.state.updateformData.type.trim(),
      // resourceList:(this.state.updateformData.type==='habit')?[]: this.state.updateformData.resourceList,
      // subpremiumactivityList:(this.state.updateformData.type!=='habit')?[]: this.state.updateformData.subpremiumactivityList,

    }

    this.setState({ updateformData: data })
    // //console.log("data update: ", data)
    this.props.dispatch(premiumactivityActions.updatePremiumActivity(data, tempdata));
    this.setState({ subIndex: '', imageWebLink: "" });

  }


  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;
    //console.log("search value:", value)
    this.setState({ keyWord: value, offset: 0 });
    let data = {
      "keyWord": value,
      "pageNo": 1,
      "size": this.state.size
    }
    this.props.dispatch(premiumactivityActions.getPremiumActivityList(data));
  }
  handleClick = (offset, page) => {
    // //console.log(offset, page);

    this.setState({ offset, page });

    let data = {
      "keyWord": this.state.keyWord,
      "pageNo": page,
      "size": this.state.size
    }
    this.setState({ page: page })
    this.props.dispatch(premiumactivityActions.getPremiumActivityList(data));
  }
  handleClickOpen = () => {
    this.setState({ newPremiumActivityModal: true });
  }
  handleClose = () => {
    let data = {
      "name": "",
      "desc": "",
      "type": "",
      "resourceList": [],
      "subpremiumactivityList": [],
      "icon": "",
      "colorCode": ""
    }
    this.setState({ newPremiumActivityModal: false, formData: data });

  }


  handleUpdateClose = () => {

    let data = {
      "id": "",
      "name": "",
      "desc": "",
      "type": "",
      "resourceList": [],
      "subpremiumactivityList": [],
      "icon": "",
      "colorCode": ""
    }

    this.setState({ newUpdatePremiumActivityModal: false, updateformData: data });

  }

  onDisable = (data) => {

    let tempdata = {
      "id": data.id
    }
    this.props.dispatch(premiumactivityActions.disablePremiumActivity(tempdata));
  }

  onUpdate = (data) => {

    let tempdata = {
      "id": data.id,
      "name": data.name,
      "desc": data.desc,
      "type": data.type,
      "musicList": data.musicList,
      "resourceList": data.resourceList,
      "subpremiumactivityList": data.subpremiumactivityList,
      "icon": data.icon,
      "colorCode": data.colorCode
    }

    //console.log("tempdatatempdatatempdatatempdata:::", data)

    // if(tempdata.subpremiumactivityList){

    // let tempSubId = tempdata.subpremiumactivityList;

    // tempdata.subpremiumactivityList =[]

    // tempSubId.forEach((res)=>{
    //   tempdata.subpremiumactivityList.push({
    //     name:res.name,
    //     desc:res.desc,
    //     rank:res.rank
    //   })

    // })
    // }

    if (tempdata.resourceList) {
      let tempEmpId = tempdata.resourceList;

      tempdata.resourceList = []

      tempEmpId.forEach((res) => {
        tempdata.resourceList.push(res.id)
      })
    }



    this.setState({ newUpdatePremiumActivityModal: true, updateformData: tempdata });
  }

  addSubpremiumactivityField = () => {
    const { formData } = this.state;

    formData.subpremiumactivityList.push({
      'name': '',
      'desc': '',
      'desc2': '',
      'rank': formData.subpremiumactivityList.length + 1
    })

    this.setState({ formData })
  }

  updateSubpremiumactivityField = () => {
    const { updateformData } = this.state;

    updateformData.subpremiumactivityList.push({
      'name': '',
      'desc': '',
      'desc2': '',
      'rank': updateformData.subpremiumactivityList.length + 1
    })

    this.setState({ updateformData })

    // //console.log("update here: ", updateformData)
  }

  onSubPremiumActivityName = (index) => {
    this.setState({
      subIndex: index
    })
  }


  handleSubPremiumActivityName = (event) => {
    const { formData } = this.state;
    formData.subpremiumactivityList[this.state.subIndex][event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubPremiumActivityNameUpdate = (event) => {
    const { updateformData } = this.state;
    updateformData.subpremiumactivityList[this.state.subIndex][event.target.name] = event.target.value;
    this.setState({ updateformData });
  }

  deleteSubpremiumactivityField = (index) => {
    const { formData } = this.state;
    formData.subpremiumactivityList.splice(index, 1)
    this.setState({ formData });
  }

  deleteSubpremiumactivityFieldUpdate = (index) => {
    const { updateformData } = this.state;
    updateformData.subpremiumactivityList.splice(index, 1)
    this.setState({ updateformData });
  }

  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[event.target.files.length - 1]));
      this.setState({ selectedFile: null });
    }
    else {
      //console.log("No File To Upload!")
    }

  }

  handleActivityResource = (e, val) => {
    // //console.log("Confirm List:::::", val);
    const { formData, updateformData } = this.state;
    if (val.option) {
      formData.resourceList.push(val.option.id);
      updateformData.resourceList.push(val.option.id)
      this.setState({ formData, updateformData });
    }
    else if (val.removedValue) {
      formData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          formData.resourceList.splice(delIndex, 1);
        }
      })

      updateformData.resourceList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.resourceList.splice(delIndex, 1);
        }
      })

      this.setState({ formData, updateformData });
      // //console.log("formData  ",formData);
    }

  }


  handleActivityResourceUpdate = (e, val) => {
  
    const { updateformData } = this.state;
    // const { formData, updateformData } = this.state;
    if (val.option) {
      // formData.musicList.push(val.option.id);
      updateformData.musicList.push(val.option.id)
      this.setState({  updateformData });
    }
    else if (val.removedValue) {
      // formData.musicList.forEach((deleteId, delIndex) => {
      //   if (deleteId === val.removedValue.id) {
      //     formData.musicList.splice(delIndex, 1);
      //   }
      // })

      updateformData.musicList.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          updateformData.musicList.splice(delIndex, 1);
        }
      })

      this.setState({  updateformData });
      // //console.log("formData  ",formData);
    }

  }


  handleUpload = () => {

    const { formData, updateformData, imageWebLink } = this.state;
    formData['icon'] = imageWebLink;
    updateformData['icon'] = imageWebLink;
    this.setState({ formData, updateformData });
  }

  render() {

    let { classes, resource, premiumactivity, premiummusic } = this.props;
    const { allResources } = resource;
    const { items, total } = premiumactivity;
    const { allPremiumMusics } = premiummusic ? premiummusic : {};
    const { formData, updateformData, submitted, premiumactivityType } = this.state;

    return (
      <>


        <PageTitle title="PremiumActivity List" />

        <Grid container spacing={2} style={{ marginTop: '5%' }}>

          <Grid container >
            <Grid item xs={12}>
              <Widget >
                <Grid container >
                  <Grid item xs={8} />
                  <Grid item xs={3}>
                    <TextField style={{ paddingTop: '10px' }}
                      id="standard-search"
                      label="Search field"
                      type="search"
                      name="keyWord"
                      onChange={this.handleSearch}
                      className={classes.textField}
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button variant="contained" color="primary" onClick={() => this.handleClickOpen()} className={classes.button}>
                      Add
                    </Button>
                  </Grid>
                </Grid>

                {
                  items && items.length > 0 ?
                    <>
                      <Table data={items} offset={this.state.offset} onRowClick={this.onRowClick}
                        onDisable={this.onDisable}
                        onUpdate={this.onUpdate}
                        onDelete={this.onDelete} />
                    </>
                    : <Typography >Data not found.</Typography>
                }
                {
                  total && total > 10 ?
                    <MuiThemeProvider theme={theme}>
                      <CssBaseline />
                      <Pagination
                        limit={this.state.size}
                        offset={this.state.offset}
                        total={total}
                        onClick={(e, offset, page) => this.handleClick(offset, page)}
                      />
                    </MuiThemeProvider>
                    : null}
              </Widget>
            </Grid>

          </Grid>
          <Dialog fullScreen open={this.state.newPremiumActivityModal} onClose={this.handleClose} TransitionComponent={Transition}>
            <AddPremiumActivity
              data={this.data}
              classes={classes}
              formData={formData}
              submitted={submitted}
              allPremiumMusics={allPremiumMusics}
              handleClose={this.handleClose}
              handleActivityResource={this.handleActivityResource}

              handlePremiumActivityResource={this.handlePremiumActivityResource}
              handlePremiumActivityName={this.handlePremiumActivityName}
              submitPremiumActivityDetails={this.submitPremiumActivityDetails}

              addSubpremiumactivityField={this.addSubpremiumactivityField}
              handleSubPremiumActivityName={this.handleSubPremiumActivityName}
              onSubPremiumActivityName={this.onSubPremiumActivityName}
              deleteSubpremiumactivityField={this.deleteSubpremiumactivityField}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              imageWebLink={this.state.imageWebLink}
              premiumactivityType={premiumactivityType}

            />
          </Dialog>
          <Dialog fullScreen open={this.state.newUpdatePremiumActivityModal} onClose={this.handleUpdateClose} TransitionComponent={Transition}>
            <UpdatePremiumActivity
              data={this.data}
              classes={classes}
              updateformData={updateformData}
              submitted={submitted}
              handleClose={this.handleUpdateClose}

              allResources={allResources}
              allPremiumMusics={allPremiumMusics}

              handleActivityResourceUpdate={this.handleActivityResourceUpdate}
              handlePremiumActivityResource={this.handlePremiumActivityResource}
              handlePremiumActivityNameUpdate={this.handlePremiumActivityNameUpdate}
              submitUpdatePremiumActivityDetails={this.submitUpdatePremiumActivityDetails}

              updateSubpremiumactivityField={this.updateSubpremiumactivityField}
              handleSubPremiumActivityNameUpdate={this.handleSubPremiumActivityNameUpdate}
              onSubPremiumActivityNameUpdate={this.onSubPremiumActivityName}
              deleteSubpremiumactivityFieldUpdate={this.deleteSubpremiumactivityFieldUpdate}

              handleFile={this.handleFile}
              handleUpload={this.handleUpload}
              imageWebLink={this.state.imageWebLink}

              premiumactivityType={premiumactivityType}
            />
          </Dialog>

        </Grid>

      </>
    );
  }
}
PremiumActivity.defaultProps = {
  center: { lat: 26.953021, lng: 75.739797 },
  zoom: 15
};
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, premiumactivity, resource, premiummusic } = state;
  return {
    loggingIn,
    users,
    premiumactivity,
    premiummusic,
    resource


  };
}
export default connect(mapStateToProps)(withStyles(styles)(withRouter(PremiumActivity)));
