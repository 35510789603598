export const notificationConstants = {

    NOTIFICATION_GETALL_REQUEST: 'NOTIFICATION_GETALL_REQUEST',
    NOTIFICATION_GETALL_SUCCESS: 'NOTIFICATION_GETALL_SUCCESS',
    NOTIFICATION_GETALL_FAILURE: 'NOTIFICATION_GETALL_FAILURE',

    ADD_NOTIFICATION_REQUEST: 'ADD_NOTIFICATION_REQUEST',
    ADD_NOTIFICATION_SUCCESS: 'ADD_NOTIFICATION_SUCCESS',
    ADD_NOTIFICATION_FAILURE: 'ADD_NOTIFICATION_FAILURE',
    
    UPDATE_NOTIFICATION_REQUEST: 'UPDATE_NOTIFICATION_REQUEST',
    UPDATE_NOTIFICATION_SUCCESS: 'UPDATE_NOTIFICATION_SUCCESS',
    UPDATE_NOTIFICATION_FAILURE: 'UPDATE_NOTIFICATION_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

};
