import {
  trackerConstants
} from '../_constants';

export function tracker(state = {}, action) {

  switch (action.type) {

      case trackerConstants.ADD_TRACKER_REQUEST:
        return {
          ...state
        };
      case trackerConstants.ADD_TRACKER_SUCCESS:
        return {
          ...state,
          addTrackerSuccess: true,
          createTracker: action.tracker.createTracker,
        };
      case trackerConstants.ADD_TRACKER_FAILURE:
        return {
          ...state,
          error: action.error
        };

        case trackerConstants.DELETE_TRACKER_REQUEST:
          return {
            ...state
          };
        case trackerConstants.DELETE_TRACKER_SUCCESS:
          return {
            ...state,
            deleteTrackerSuccess: true
          };
        case trackerConstants.DELETE_TRACKER_FAILURE:
          return {
            ...state,
            error: action.error
          };


        case trackerConstants.UPDATE_TRACKER_REQUEST:
          return {
            ...state
          };
        case trackerConstants.UPDATE_TRACKER_SUCCESS:
          return {
            ...state,
            updateTrackerSuccess: true,
            updateTracker: action.tracker.updateTracker,
          };
        case trackerConstants.UPDATE_TRACKER_FAILURE:
          return {
            ...state,
            error: action.error
          };



      case trackerConstants.TRACKER_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case trackerConstants.TRACKER_GETALL_SUCCESS:
        return {
          ...state,      
          addTrackerSuccess: false,    
          updateTrackerSuccess: false,
 
          trackerList: action.tracker.trackerList
        };
      case trackerConstants.TRACKER_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };


        case trackerConstants.TRACKERLIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case trackerConstants.TRACKERLIST_GETALL__SUCCESS:
          return {
            ...state,    
            addTrackerSuccess: false,
            updateTrackerSuccess: false,

            items: action.tracker.trackerList.list,
            total: action.tracker.trackerList.total

          };
        case trackerConstants.TRACKERLIST_GETALL__FAILURE:
          return {
            ...state,
            error: action.error
          };
  


    default:
      return state
  }
}