import {
  benchmarkConstants
} from '../_constants';

export function benchmark(state = {}, action) {

  switch (action.type) {
    case benchmarkConstants.BENCHMARK_GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case benchmarkConstants.BENCHMARK_GETALL_SUCCESS:
      return {
        ...state,
        addBenchMarkSuccess: false,
        updateBenchMarkSuccess:false,
        deleteBenchMarkSuccess: false,

          BenchMarkList: action.benchmark.listOfBenchMarks.list,
          items: action.benchmark.listOfBenchMarks.list,
          total: action.benchmark.listOfBenchMarks.total
      };
    case benchmarkConstants.BENCHMARK_GETALL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case benchmarkConstants.ADD_BENCHMARK_REQUEST:
      return {
        ...state
      };
    case benchmarkConstants.ADD_BENCHMARK_SUCCESS:
      return {
        ...state,
        addBenchMarkSuccess: true
      };
    case benchmarkConstants.ADD_BENCHMARK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case benchmarkConstants.UPDATE_BENCHMARK_REQUEST:
      return {
        ...state
      };
    case benchmarkConstants.UPDATE_BENCHMARK_SUCCESS:
      return {
        ...state,
        updateBenchMarkSuccess: true
      };
    case benchmarkConstants.UPDATE_BENCHMARK_FAILURE:
      return {
        ...state,
        error: action.error
      };
    
      case benchmarkConstants.DELETE_BENCHMARK_REQUEST:
        return {
          ...state
        };
      case benchmarkConstants.DELETE_BENCHMARK_SUCCESS:
        return {
          ...state,
          deleteBenchMarkSuccess: true
        };
      case benchmarkConstants.DELETE_BENCHMARK_FAILURE:
        return {
          ...state,
          error: action.error
        };

    default:
      return state
  }
}