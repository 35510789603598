
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const notificationService = {
    disableNotification,
    logout,
    saveEmployeeNotificationList,
    updateNotification,
    getAllNotification,
    deleteNotification
};
function logout() {
    localStorage.removeItem('adminnotification');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let notificationObj = {
                listOfNotifications: data.data
            }
            return notificationObj;
        });
}
function saveEmployeeNotificationList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveEmployeeNotificationList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let notificationObj = {
                createNewNotification: data.data
            }
            console.log();

            return notificationObj;
        });
}
function updateNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let notificationObj = {
                updateNewNotification: data.data
            }
            console.log(notificationObj);

            return notificationObj;
        });
}
function disableNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateNotificationStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let notificationObj = {
                deleteNotification: data.data
            }
            console.log();

            return notificationObj;
        });
}

function deleteNotification(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteNotification`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let notificationObj = {
                deleteNewdeleteNotification: data.data
            }
            console.log(notificationObj);

            return notificationObj;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}