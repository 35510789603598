export const campaignConstants = {

    ADD_CAMPAIGN_REQUEST: 'ADD_CAMPAIGN_REQUEST',
    ADD_CAMPAIGN_SUCCESS: 'ADD_CAMPAIGN_SUCCESS',
    ADD_CAMPAIGN_FAILURE: 'ADD_CAMPAIGN_FAILURE',

    CAMPAIGN_GETALL_REQUEST: 'CAMPAIGN_GETALL_REQUEST',
    CAMPAIGN_GETALL_SUCCESS: 'CAMPAIGN_GETALL_SUCCESS',
    CAMPAIGN_GETALL_FAILURE: 'CAMPAIGN_GETALL_FAILURE',

    CAMPAIGN_EMPLOYEES_GETALL_REQUEST: 'CAMPAIGN_EMPLOYEES_GETALL_REQUEST',
    CAMPAIGN_EMPLOYEES_GETALL_SUCCESS: 'CAMPAIGN_EMPLOYEES_GETALL_SUCCESS',
    CAMPAIGN_EMPLOYEES_GETALL_FAILURE: 'CAMPAIGN_EMPLOYEES_GETALL_FAILURE',

};
