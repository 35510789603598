
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const companyService = {
    disableCompany,
    getCompanyById,
    logout,
    createNewCompany,
    updateNewCompany,
    getAllCompany,
    getEmployeeList,
    saveEmployeeList,
    getSurveyList,
    getAllSurveyType,

    getAllEmployees,

    createSurvey,
    updateSurvey,
    deleteApiKey,
    getEmployeeColumnName,
    createEmployeeFilter,
    getEmployeeFilterListByCompanyId,
    updateEmployeeFilterStatus,


    getBenchMarkList,
    createBenchMark,
    updateBenchMark,
    deleteBenchMark,
    updateBenchMarkStatus



};
function logout() {
    localStorage.removeItem('admincompany');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllCompany(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCompanyList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let companyObj = {
                listOfCompanys: data.data
            }
            console.log();
            return companyObj;
        });
}
function createNewCompany(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createCompany`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createNewCompany: data.data
            }
            console.log();

            return companyObj;
        });
}
function updateNewCompany(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCompany`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                updateNewCompany: data.data
            }
            console.log(companyObj);

            return companyObj;
        });
}
function disableCompany(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateCompanyStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                deleteCompany: data.data
            }
            console.log();

            return companyObj;
        });
}
function getCompanyById(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getCompanyById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let userObj = {
                companydetails: data.data
            }
            console.log();

            return userObj;
        });
}
function saveEmployeeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/saveEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createNewCompany: data.data
            }
            console.log();

            return companyObj;
        });
}
function getEmployeeList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                employeeList: data.data
            }
            console.log();

            return companyObj;
        });
}

function getAllEmployees(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getEmployeeList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                allEmployeesList: data.data
            }
            console.log();

            return companyObj;
        });
}

function getSurveyList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getSurveyList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                surveyList: data.data
            }
            console.log();

            return companyObj;
        });
}
function getAllSurveyType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch(CONST.BACKEND_URL + `/getAllSurveyType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                surveyTypeList: data.data
            }
            console.log("companyObj  ", companyObj);

            return companyObj;
        });
}
function createSurvey(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}
function updateSurvey(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                updateSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}
function deleteApiKey(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                deleteSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}


function getEmployeeColumnName(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)

    }
    return fetch(CONST.BACKEND_URL + `/getEmployeeColumnName`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                employeeColumnNames: data.data
            }
            console.log("companyObj  ", companyObj);

            return companyObj;
        });
}
function createEmployeeFilter(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)

    }
    return fetch(CONST.BACKEND_URL + `/createEmployeeFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                employeeColumnNames: data.data
            }
            console.log("companyObj  ", companyObj);

            return companyObj;
        });
}

function getEmployeeFilterListByCompanyId(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)

    }
    return fetch(CONST.BACKEND_URL + `/getEmployeeFilterListByCompanyId`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                employeeFilterList: data.data
            }
            console.log("companyObj  ", companyObj);

            return companyObj;
        });
}

function updateEmployeeFilterStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)

    }
    return fetch(CONST.BACKEND_URL + `/updateEmployeeFilterStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                employeeFilterList: data.data
            }
            console.log("companyObj  ", companyObj);

            return companyObj;
        });
}


function getBenchMarkList(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBenchMarkList`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                getBenchMarkListData: data.data
            }
            console.log();

            return companyObj;
        });
}

function createBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}

function updateBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}

function deleteBenchMark(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}

function updateBenchMarkStatus(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBenchMarkStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let companyObj = {
                createSurvey: data.data
            }
            console.log();

            return companyObj;
        });
}



function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

