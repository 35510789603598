
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const badgeService = {
    disableBadge,
    logout,
    createNewBadge,
    updateBadge,
    getAllBadge,
    deleteBadge
};
function logout() {
    localStorage.removeItem('adminbadge');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllBadge(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getBadgeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let badgeObj = {
                listOfBadges: data.data
            }
            console.log();
            return badgeObj;
        });
}

function createNewBadge(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createBadge`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let badgeObj = {
                createNewBadge: data.data
            }
            console.log();

            return badgeObj;
        });
}
function updateBadge(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBadge`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let badgeObj = {
                updateNewBadge: data.data
            }
            console.log(badgeObj);

            return badgeObj;
        });
}

function deleteBadge(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/deleteBadge`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let badgeObj = {
                deleteNewBadge: data.data
            }
            console.log(badgeObj);

            return badgeObj;
        });
}

function disableBadge(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateBadgeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let badgeObj = {
                deleteBadge: data.data
            }
            console.log();

            return badgeObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}