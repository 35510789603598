import {
  premiumactivityConstants
} from '../_constants';

export function premiumactivity(state = {}, action) {

  switch (action.type) {

      case premiumactivityConstants.ADD_PREMIUMACTIVITY_REQUEST:
        return {
          ...state
        };
      case premiumactivityConstants.ADD_PREMIUMACTIVITY_SUCCESS:
        return {
          ...state,
          addPremiumActivitySuccess: true,
          createPremiumActivity: action.premiumactivity.createPremiumActivity,
        };
      case premiumactivityConstants.ADD_PREMIUMACTIVITY_FAILURE:
        return {
          ...state,
          error: action.error
        };

        
        case premiumactivityConstants.DELETE_PREMIUMACTIVITY_REQUEST:
          return {
            ...state
          };
        case premiumactivityConstants.DELETE_PREMIUMACTIVITY_SUCCESS:
          return {
            ...state,
            deletePremiumActivitySuccess: true
          };
        case premiumactivityConstants.DELETE_PREMIUMACTIVITY_FAILURE:
          return {
            ...state,
            error: action.error
          };
    

        case premiumactivityConstants.UPDATE_PREMIUMACTIVITY_REQUEST:
          return {
            ...state
          };
        case premiumactivityConstants.UPDATE_PREMIUMACTIVITY_SUCCESS:
          return {
            ...state,
            updatePremiumActivitySuccess: true,
            updatePremiumActivity: action.premiumactivity.updatePremiumActivity,
          };
        case premiumactivityConstants.UPDATE_PREMIUMACTIVITY_FAILURE:
          return {
            ...state,
            error: action.error
          };
  

      case premiumactivityConstants.PREMIUMACTIVITY_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case premiumactivityConstants.PREMIUMACTIVITY_GETALL_SUCCESS:
        return {
          ...state,           
          addPremiumActivitySuccess: false,   
          updatePremiumActivitySuccess: false,

          allActivities: action.premiumactivity.allActivities
        };
      case premiumactivityConstants.PREMIUMACTIVITY_GETALL_FAILURE:
        return {
          ...state,
          error: action.error
        };


        case premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_SUCCESS:
          return {
            ...state,       
            addPremiumActivitySuccess: false,   
            updatePremiumActivitySuccess: false,

            items: action.premiumactivity.allActivitiesList.list,
            total:action.premiumactivity.allActivitiesList.total,
          };
        case premiumactivityConstants.PREMIUMACTIVITYLIST_GETALL_FAILURE:
          return {
            ...state,
            error: action.error
          };
  


    default:
      return state
  }
}