import React from 'react';
import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';


export default function UpdateBenchMark(props) {

  const { classes, updateformData, submitted, handleUpdateClose, handleUpdateSubmit, updatehandleChangeInput,handleFile, handleUpload,imageWebLink, imageWebURL 
   } = props;

  return (
    <div >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleUpdateClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Update New BenchMark
            </Typography>
          <Button color="inherit" onClick={handleUpdateClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            Update BenchMark
              </Typography>

          <ValidatorForm
            // ref="form"
            onSubmit={handleUpdateSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Name*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="name"
                  id="name"
                  value={updateformData.name}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Value*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="value"
                  id="value"
                  value={updateformData.value}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Color Code 1"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="colorCode"
                  id="colorCode"
                  value={updateformData.colorCode}
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Color Code 2"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="colorCode2"
                  id="colorCode2"
                  value={updateformData.colorCode2}
                  // validators={['required']}
                  // errorMessages={['this field is required']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  label="Icon*"
                  fullWidth
                  onChange={updatehandleChangeInput}
                  name="icon"
                  id="icon"
                  value={updateformData.icon}
                  validators={['required']}
                  errorMessages={['this field is required']}
                />
              </Grid>
              
              <Grid item xs={12}></Grid>
            </Grid>


            <Grid item xs={12}>

            <label htmlFor="image">
              <Button color="default" variant="contained" component="span" >
                Browse
                  </Button>
            </label>
            <input
              style={{ display: 'none' }}
              id="image"
              name="image"
              type="file"
              onChange={handleFile}
            />

            <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
              Set
                </Button>



            </Grid>



            {(imageWebURL && (imageWebLink === updateformData.imageLink)) ?
              <Grid item xs={12} style={{ margin: '4%' }}>
                <img src={imageWebURL} style={{ width: '100px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              : 
              <Grid item xs={12} style={{ margin: '4%' }}>
              <img src={updateformData.imageLinkV2} style={{ width: '100px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
              </Grid>
              }

           


      

            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
            >
              Save
                </Button>
          </ValidatorForm>
        </div>
      </Container>

    </div>
  );
}