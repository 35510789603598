import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";

// const states = {
//   true: "success",
//   false: "warning",
//   declined: "secondary",
// };

export default function TableComponent(props) {
  let { data, offset } = props;
  // var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  // keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >Email</TableCell>
          <TableCell >Mobile</TableCell>
   
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data && data.length > 0 ? data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.email}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.mobile}</TableCell>
 

            </TableRow>
          )) : null
        }
      </TableBody>
    </Table>
  );
}