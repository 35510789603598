import React from "react";
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import moment from 'moment';


export default function TableComponent(props) {

  // let { data, offset, onDisable, onUpdate, } = props;
  let { data, offset, onDisable, onUpdate, onDelete } = props;
  var keys = Object.keys(data[0]).map(i => i.toUpperCase());
  keys.shift(); // delete "id" key

  return (
    <Table className="mb-0">
      <TableHead>
        <TableRow>
          <TableCell >S. NO.</TableCell>
          <TableCell >NAME</TableCell>
          <TableCell >Desc</TableCell>
          <TableCell >Genere</TableCell>
          <TableCell >Points</TableCell>
          {/* <TableCell >Composer</TableCell> */}
          <TableCell >Image Link</TableCell>
          <TableCell >PREMIUMMUSIC Link</TableCell>
          <TableCell >Is Premium </TableCell>
          <TableCell >CreatedAt</TableCell>
          {/* <TableCell >RATING</TableCell> */}
          <TableCell >ACTION</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          data.map((tempdata, index) => (
            <TableRow key={index}>
              <TableCell className="pl-3 fw-normal">{offset + index + 1}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.name}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.desc.substring(0, 70) + "..."}</TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.genere}</TableCell>
               {/* <TableCell className="pl-3 fw-normal">{tempdata.category ? tempdata.category : '-'}</TableCell> */}
              <TableCell className="pl-3 fw-normal">{tempdata.points ? tempdata.points : '-'}</TableCell>
              {/* <TableCell className="pl-3 fw-normal">{tempdata.composer ? tempdata.composer : '-'}</TableCell> */}

              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>
                <img src={tempdata.imageLinkV2} alt="asldkfj" height="50" width="50"></img>
              </TableCell>

              <TableCell className="pl-3 fw-normal" style={{ width: '10%' }}>
                <audio
                  // ref="audio_tag"
                  src={tempdata.musicLinkV2}
                  autoPlay={false}
                  controls={true} >
                  {/* <source type="audio/mp3" src={tempdata.url} /> */}
                </audio>
                {/* <img src={tempdata.imageUrl} alt="asldkfj" height="50" width="50"></img> */}
              </TableCell>
              <TableCell className="pl-3 fw-normal">{tempdata.isPremium ? "YES" : 'NO'}</TableCell>
              <TableCell>{moment(new Date(parseInt(tempdata.createdAt))).utcOffset("+05:30").format("YYYY-MM-DD HH:mm")}</TableCell>
              <TableCell>
                {/* <Button
                  color="success"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onRowClick(tempdata)}
                >
                  More Details..
              </Button> */}

                <Button
                  color={tempdata.isDisable ? "warning" : "success"}
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDisable(tempdata)}
                >
                  {tempdata.isDisable ? 'Disable' : 'Enable'}
                </Button>
                <Button
                  color="danger"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onDelete(tempdata)}
                >
                  Delete
                </Button>
                <Button
                  color="info"
                  size="small"
                  className="px-2"
                  variant="contained"
                  style={{ marginRight: '5px' }}
                  onClick={() => onUpdate(tempdata)}
                >
                  Update
                </Button>

              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  );
}
