
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const questiontypeService = {
    disableQuestionType,
    logout,
    createNewQuestionType,
    updateQuestionType,
    getAllQuestionType
};
function logout() {
    localStorage.removeItem('adminquestiontype');
    window.location.href = "#/login";
    //window.location.reload();

}
function getAllQuestionType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/getQuestionTypeList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questiontypeObj = {
                listOfQuestionTypes: data.data
            }
            console.log();
            return questiontypeObj;
        });
}

function createNewQuestionType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/createQuestionType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questiontypeObj = {
                createNewQuestionType: data.data
            }
            console.log();

            return questiontypeObj;
        });
}
function updateQuestionType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateQuestionType`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questiontypeObj = {
                updateNewQuestionType: data.data
            }
            console.log(questiontypeObj);

            return questiontypeObj;
        });
}
function disableQuestionType(data) {
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/updateQuestionTypeStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questiontypeObj = {
                deleteQuestionType: data.data
            }
            console.log();

            return questiontypeObj;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}