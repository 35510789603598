export const resourceConstants = {

    ADD_RESOURCE_REQUEST: 'ADD_RESOURCE_REQUEST',
    ADD_RESOURCE_SUCCESS: 'ADD_RESOURCE_SUCCESS',
    ADD_RESOURCE_FAILURE: 'ADD_RESOURCE_FAILURE',

    UPDATE_RESOURCE_REQUEST: 'UPDATE_RESOURCE_REQUEST',
    UPDATE_RESOURCE_SUCCESS: 'UPDATE_RESOURCE_SUCCESS',
    UPDATE_RESOURCE_FAILURE: 'UPDATE_RESOURCE_FAILURE',

    RESOURCE_GETALL_REQUEST: 'RESOURCE_GETALL_REQUEST',
    RESOURCE_GETALL_SUCCESS: 'RESOURCE_GETALL_SUCCESS',
    RESOURCE_GETALL_FAILURE: 'RESOURCE_GETALL_FAILURE',

    RESOURCELIST_GETALL_REQUEST: 'RESOURCELIST_GETALL_REQUEST',
    RESOURCELIST_GETALL_SUCCESS: 'RESOURCELIST_GETALL_SUCCESS',
    RESOURCELIST_GETALL_FAILURE: 'RESOURCELIST_GETALL_FAILURE',

};
