import React from 'react';

import {
  Grid,
  Button,
  Typography,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  TextField,
  Select,
  MenuItem,
  Input
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import { CONST } from '../../../_config/index';
import ReactQuill from 'react-quill';


export default function AddCompany(props) {

  const { classes, subjectIndex, formData, submitted, handleClose, handleResourceName,
    handleChangeResourceType, resourceTypesList, submitResourceDetails, handleResourceDesc,

    handleFile, handleUpload, imageWebURL} = props;





  console.log("resourceTypesList: ", resourceTypesList)
  return (
    <div >

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Add New Resource
            </Typography>
          <Button color="inherit" onClick={handleClose}>
            Cancel
            </Button>
        </Toolbar>
      </AppBar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper} style={{marginTop:'5%'}}>

          <Grid container spacing={2}>
        

              <Grid item xs={12}>
                <Typography component="h1" variant="h5">
                  Add Resource
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
              <p>Name:</p>
              <TextField
                // label="Name*"
                id="name"
                name="name"
                fullWidth
                rowsMax={4}
                placeholder="Name"
                value={formData.name}
                // validators={['required']}
                onChange={handleResourceName}
              // errorMessages={['this field is required']}
              />
            </Grid>
            <Grid item xs={12}>
            <p>Desc:</p>
              <ReactQuill 
                theme="snow" 
                value={formData.desc} 
                placeholder="Description"
                onChange={handleResourceDesc}/>

              {/* <TextField
                // label="Desc"
                id="desc"
                name="desc"
                fullWidth
                rowsMax={4}
                placeholder="Description"
                value={formData.desc}
                onChange={handleResourceName}
              /> */}
            </Grid>
            <Grid item xs={12}>
            <p>Link:</p>
              <TextField
                // label="Link"
                id="link"
                name="link"
                fullWidth
                rowsMax={4}
                placeholder="Link"
                value={formData.link}
                onChange={handleResourceName}
              />
            </Grid>

            <Grid item xs={12}>
            <p>Resource Type:</p>
              <Select
                name="name"
                value={subjectIndex ? subjectIndex : ''}
                style={{ width: '100%' }}
                label="Resource Type"
                onChange={(event) => handleChangeResourceType(event.target.value)}
                input={<Input id="name" />}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="" disabled>
                  Resource Type
                                                </MenuItem>
                {
                  resourceTypesList && resourceTypesList.length > 0 ?
                    resourceTypesList.map((tempdata, index) => (
                      <MenuItem key={index} value={tempdata.id}>{tempdata.name}</MenuItem>
                    )) : null
                }

              </Select>
            </Grid>

            {(formData.resourceTypeName !=='Quote' && formData.resourceTypeName !=='Podcasts' && formData.resourceTypeName !=='Podcats') ?

              <Grid item xs={12}>
                <TextField
                  id="rating"
                  name="rating"
                  fullWidth
                  rowsMax={4}
                  placeholder="Rating"
                  value={formData.rating}
                  onChange={handleResourceName}
                />
              </Grid>
              : null}

            {(formData.resourceTypeName ==='Book') ?

              <Grid item xs={12}>
                <TextField
                  id="author"
                  name="author"
                  fullWidth
                  rowsMax={4}
                  placeholder="Author"
                  value={formData.author}
                  onChange={handleResourceName}
                />
              </Grid>
              : null}

            {(formData.resourceTypeName ==='Movie')  ?
              <Grid item xs={12}>
                <TextField
                  id="idmb"
                  name="idmb"
                  fullWidth
                  rowsMax={4}
                  placeholder="Imdb"
                  value={formData.idmb}
                  onChange={handleResourceName}
                />
              </Grid>
              : null}
            {(formData.resourceTypeName ==='Podcasts' || formData.resourceTypeName ==='Podcats')  ?
              <Grid item xs={12}>
                <TextField
                  id="host"
                  name="host"
                  fullWidth
                  rowsMax={4}
                  placeholder="Host"
                  value={formData.host}
                  onChange={handleResourceName}
                />
              </Grid>
              : null}
            {(formData.resourceTypeName ==='Course') ?
              <Grid item xs={12}>
                <TextField
                  id="type"
                  name="type"
                  fullWidth
                  rowsMax={4}
                  placeholder="Type"
                  value={formData.type}
                  onChange={handleResourceName}
                />
              </Grid>
              : null}

            <Grid item xs={12}>
              <TextField
                label="Image Link"
                id="imageLink"
                name="imageLink"
                fullWidth
                rowsMax={4}
                placeholder="Image Link"
                value={formData.imageLink}
                onChange={handleResourceName}
              />
            </Grid>

            <Grid item xs={12}>

              <label htmlFor="image">
                <Button color="default" variant="contained" component="span" >
                  Browse
                        </Button>
              </label>
              <input
                style={{ display: 'none' }}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}
              />

              <Button style={{ marginLeft: '20px' }} color="secondary" variant="contained" component="span" onClick={() => handleUpload()}>
                Set
                      </Button>



            </Grid>

            {(imageWebURL) ?
                <Grid item xs={12} style={{ margin: '4%' }}>
                  <img src={imageWebURL} style={{ width: '340px', marginTop: '2%' }} title={"image1"} alt=" No Image1! " />
                </Grid>
                : null}


            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitted}
              className={classes.submit}
              onClick={() => submitResourceDetails()}
              style={{ marginTop: "5%" }}
            >
              Save
                </Button>
          </Grid>


        </div>
      </Container>



    </div>
  );
}
